import { http } from "../../config";

/**
 * Get request to generate export xls file
 * @param {Object} exportFormData - the data requested
 * @returns {Promise} response
 */
const generateExportFile = (exportFormData = {}) => {
	return http.post("/export", null, { params: exportFormData });
};

/**
 * Request to check export generation status
 * @param id
 */
const checkExportFileStatus = (id) => {
	return http.get(`/export/${id}/status`);
};

/**
 * Request to get export file blob
 * @param id
 */
const getExportFile = (id) => {
	return http.get(`/export/${id}`, { responseType: "blob" });
};

export { generateExportFile, checkExportFileStatus, getExportFile };
