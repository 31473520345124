import { useState, useEffect } from "react";
import { getDriveLogById } from "../../drive-log/drive-log.services";

/**
 * Generic features around drivelog list management
 * @param {string} [id] - config for the hook
 */
const useDriveLog = (id) => {
	const [driveLog, setDriveLog] = useState({});
	const [loading, setLoading] = useState(true);

	const loadDriveLog = () => {
		if (id) {
			getDriveLogById(id).then((response) => {
				const dl = response.data;
				setDriveLog(dl);
			}).catch(err => {
				console.error(err);
			}).finally(() => {
				setLoading(false);
			});
		}
	};

	useEffect(loadDriveLog, [id]);

	return {
		driveLog, loading
	};
};

export default useDriveLog;
