import i18next from "../../config/i18next";
import i18nObservation from "./i18n/fr.json";

// Components
import ObservationDetailsList from "./components/observation-details-list/observation-details-list";

// Pages
import ObservationList from "./pages/observation-list/observation-list";
import AddObservation from "./pages/add-observation/add-observation";
import EditObservation from "./pages/edit-observation/edit-observation";

i18next.addResourceBundle("fr", "observation", i18nObservation);

export {
	ObservationDetailsList,
	ObservationList,
	AddObservation,
	EditObservation
};
