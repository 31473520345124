import React from "react";
import { useTranslation } from "react-i18next";
import GenericHeader from "../generic-header/generic-header";
import PropTypes from "prop-types";

/**
 * Renders a <DbHeader /> component
 * @param {object} props
 * @param {string} props.className - className to add to the Supervisor header Component
 * @param {string} props.selectedLink - the link-related number
 * @param {bool} props.hideNav - hide or not the nav links
 */
const DriverHeader = (props) => {
	const { className = "", selectedLink = 0, hideNav = false } = props;
	const { t } = useTranslation();

	const links = [
		{ target: "/daily-drive-log", text: t("core:header.daily-dl") },
		{ target: "/drive-logs/returned", text: t("core:header.returned-dls"), isReturned: true },
		{ target: "/drive-logs", text: t("core:header.my-dls") }
	];

	return (
		<GenericHeader
			className={className}
			links={links}
			selectedLink={selectedLink}
			hideNav={hideNav}
		/>
	);
};

DriverHeader.propTypes = {
	classname: PropTypes.string,
	selectedLink : PropTypes.number,
	hideNav: PropTypes.bool
};

export default DriverHeader;
