import React from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import DlObservationRow from "../dl-observation-row/dl-observation-row";
import "./mission-observations.scss";

/**
 * Render the list of all observations in a mission
 * @component
 * @param {Object} props
 * @param {string} props.missionId - the mission id (uuid). Generate a mission ID for missions without one (i.e pending missions)
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 * @param {boolean} props.readOnly - the boolean that define if the observation is on readonly mode
 * @param {boolean} props.uncompletedDl - the boolean that define if the driveLog is uncompleted
 */
const MissionObservations = (props) => {
	const { missionId = uuid(), observations, retrieveObservationsList, readOnly = false, redirectUrl = "", uncompletedDl } = props;
	const missionObservations = observations.filter(obs => obs.mission_id === missionId);

	const generateObservationRow = (observation) => (
		<DlObservationRow observation={observation}
						  key={observation.id}
						  retrieveObservationsList={retrieveObservationsList}
						  readOnly={readOnly}
						  redirectUrl={redirectUrl}
						  uncompletedDl={uncompletedDl}
		/>
	);

	return (
		<ul className="mission__observations">
			{Array.isArray(missionObservations) && missionObservations.map(generateObservationRow)}
		</ul>
	);

};

MissionObservations.propTypes = {
	missionId: PropTypes.string,
	observations: PropTypes.array.isRequired,
	retrieveObservationsList: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool
};

export default MissionObservations;
