import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InputField } from "../../../../../core";
import "./observation-delay.scss";

const ObservationDelay = (props) => {
	const { handleChange, value, className = "", title, validator } = props;
	const { t } = useTranslation();

	return (
		<div className={`observation-delay-wrapper ${className}`}>
			<h3 className="observation-delay-wrapper__title">
				{title}
			</h3>
			<label className="observation-delay-wrapper__label">
				<InputField
					className="observation-delay-wrapper__input input-cell--white"
					name="delay"
					type="number"
					value={value}
					onChange={handleChange}
					validation={validator}
				/>
				{t("observation:observation-fields.delay-label")}
			</label>
		</div>
	);
};

ObservationDelay.propTypes = {
	handleChange : PropTypes.func.isRequired,
	mandatory: PropTypes.bool,
	className: PropTypes.string,
	value : PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ObservationDelay;
