import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, PopupFullPage, MultiInputCells } from "../../../core";
import { DriveLogSubHeader } from "../../../drive-log";
import { addTrainNumber } from "../../mission.services";
import { getDriveLogById } from "../../../drive-log/drive-log.services";
import getStatusRedirection from "../../../drive-log/utils/get-status-redirection";
import "./add-train-number.scss";

/**
 * Renders an <AddTrainNumber /> full page
 */
const AddTrainNumber = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;

	const { id: driveLogId, missionId } = useParams();
	const { t } = useTranslation();
	const history = useHistory();

	const editDlLink = `${redirectUrl}/${driveLogId}`;
	// accept only digits for the input cells
	const acceptedCharacters = /^[0-9]?$/i;

	const [trainNumber, setTrainNumber] = useState(Array(4).fill(""));
	const [isFormValid, setIsFormValid] = useState(false);

	const handleSubmit = (formattedTrainNumber) => {
		addTrainNumber(missionId, driveLogId, formattedTrainNumber)
			.then(res => {
				history.push(`${redirectUrl}/${driveLogId}`);
			})
			.catch(err => console.error(err));
	};

	const handleNoTrainNumber = () => {
		handleSubmit("-1");
	};

	const init = useCallback(() => {
		getDriveLogById(driveLogId).then(payload => {
			const driveLog = payload.data;
			getStatusRedirection(driveLog, history);
		}).catch(err => {
			console.error(err);
		});
	}, [driveLogId, history]);

	useEffect(init, [driveLogId, init]);

	return (
		<PopupFullPage title={t("mission:add-train-number.title")} backLink={editDlLink}>
			<div className="add-train-number">
				<DriveLogSubHeader subHeader={t("mission:add-train-number.heading")} />
				<div className="train-number-page__cells">
					<MultiInputCells
						size={4}
						cellTypes="number"
						valueArr={trainNumber}
						setValueArr={setTrainNumber}
						setFormValid={setIsFormValid}
						cellRegex={acceptedCharacters}
					/>
				</div>
				<Button disabled={!isFormValid} className="add-train-number__button"
					onClick={() => handleSubmit(trainNumber.join("").toUpperCase())}>
					{t("core:popup-full-page.button.validate")}
				</Button>
				<Button className="add-train-number__button__link button--link" onClick={handleNoTrainNumber}>{t("mission:add-train-number.no-train-number")}</Button>
			</div>
		</PopupFullPage>
	);
};

export default AddTrainNumber;
