import React, { useEffect } from "react";
import Icon from "../../../../../../../core/components/icon-svg/icon";
import { Button } from "../../../../../../../core";
import Modal from "../../../../../../../core/components/modal/modal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./confirm-train-composition-modal.scss";

const ConfirmTrainCompositionModal = (props) => {
	const { setShowModal, setShowUpdateTrainCompoModal, redirectUrl, setAnimationCount, animationCount } = props;

	const history = useHistory();
	const { t } = useTranslation();

	const animationInterval = React.createRef();
	const animationCountToWait = 3;

	const closeCallback = () => {
		setShowModal(false);
		setAnimationCount(null);
	};

	const cancelCallback = () => {
		setShowModal(false);
		setAnimationCount(null);
		setShowUpdateTrainCompoModal(true);
	};

	const confirmCallback = () => {
		setShowModal(false);
		history.push(redirectUrl);
	};

	useEffect(() => {
		animationInterval.current = setInterval(() => {
			if (animationCount === 3) {
				clearInterval(animationInterval.current);
				animationInterval.current = null;
			} else {
				setAnimationCount((c) => c + 1);
			}
		}, 1000);
	}, [animationCount, animationInterval, setAnimationCount]);

	useEffect(() => {
		return () => {
			if (animationInterval.current) {
				clearInterval(animationInterval.current);
			}
		};
	}, [animationInterval]);

	return (
		<Modal type={"custom"} className={"confirm-train-composition-modal"}>
			<div className={"confirm-train-composition-modal__content"}>
				<div onClick={closeCallback}>
					<Icon name="close" className="confirm-train-composition-modal__content__close" />
				</div>
				<div className="confirm-train-composition-modal__content__block">
					<div className="confirm-train-composition-modal__content__block__title">
						{t("dl:dl-detail-page.confirm-train-composition-modal.title")}
					</div>
					<div className="confirm-train-composition-modal__content__block__subtitle">
						{t("dl:dl-detail-page.confirm-train-composition-modal.subtitle")}
					</div>
					<div className={"confirm-train-composition-modal__button-group"}>
						<Button className={"confirm-train-composition-modal__button"} onClick={cancelCallback} outline>
							{t("dl:dl-detail-page.confirm-train-composition-modal.cancel")}
						</Button>
						<Button className={"confirm-train-composition-modal__button"} onClick={confirmCallback} disabled={animationCount !== animationCountToWait}>
							{t("dl:dl-detail-page.confirm-train-composition-modal.confirm")}
						</Button>
					</div>
					<div className={"confirm-train-composition-modal__instruction"}>
						{animationCountToWait - animationCount > 0 && (
							<p>
								<span>{t("dl:dl-detail-page.confirm-train-composition-modal.instruction-1")}</span>
								<span className={"confirm-train-composition-modal__instruction-counter"}>{animationCountToWait - animationCount}</span>
								<span>{t("dl:dl-detail-page.confirm-train-composition-modal.instruction-2")}</span>
							</p>
						)}
						{animationCountToWait - animationCount === 0 && <p>{t("dl:dl-detail-page.confirm-train-composition-modal.instruction-ok")}</p>}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmTrainCompositionModal;
