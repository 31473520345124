import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ExportListContainer, ExportSectionTitle } from "../../";
import observationTypes from "../../../observation/forms/observation-types";
import "./export-resource-picker.scss";

const RESOURCES = [
	{
		name: "Bulletins",
		isChecked: false 
	},
	{
		name: "Observations",
		isChecked: false
	},
	{
		name: "Questionnaires SF",
		isChecked: false 
	}
];

const ExportResourcePicker = (props) => {
	const { lineSelected = [], onChange = () => {} } = props;
	const [resourcesList, setResourcesList] = useState(RESOURCES);
	const [updatedObservationsList, setUpdatedObservationsList] = useState([]);
	const [displayObservations, setDisplayObservations] = useState(false);
	const { t } = useTranslation("exp");

	// update checked state of element
	const updateListsItemsCheckedState = (list, itemIndex) => {
		const updatedList = list.map((listItem, currentIndex) => {
			if (currentIndex === itemIndex) {
				return { ...listItem, isChecked: !listItem.isChecked };
			}
			return listItem;
		});
		return updatedList;
	};

	// verify if list item state is checked
	const isChecked = (listEl) => !!listEl.isChecked;

	const allChecked = updatedObservationsList.every(isChecked);

	const atLeastOneChecked = updatedObservationsList.some(isChecked);

	// update checked state of resources list item
	const updateResourceItemCheckState = (index) => {
		const updatedResourcesListItems = updateListsItemsCheckedState(resourcesList, index);
		setResourcesList(updatedResourcesListItems);
	};

	// update checked state of observations list item
	const updateObservationItemCheckState = (index) => {
		const updatedObservationsListItems = updateListsItemsCheckedState(updatedObservationsList, index);
		setUpdatedObservationsList(updatedObservationsListItems);
	};

	// handle display state of observations list items
	const checkObservationCheckState = (list) => {
		const observation = list.find(item => item.name === "Observations");
		setDisplayObservations(observation.isChecked);
	};

	// update checked state of every elements
	const updateAllCheckState = (checkedState) => {
		const checkAllState = updatedObservationsList.map(listItem => ({ ...listItem, isChecked: !checkedState }));
		setUpdatedObservationsList(checkAllState);
	};

	// handler to updated observations list with isChecked property
	const updateObservationList = useCallback(() => {
		if (observationTypes) {
			let updatedList = observationTypes.map(observationType => ({ name: observationType.name, isChecked: false }));
			if (!lineSelected.includes("B")) {
				updatedList = observationTypes.filter(listItem => listItem.name !== "rfn");
			}
			setUpdatedObservationsList(updatedList);
		}
	}, [lineSelected]);

	// handler to update formData base on resources selected and observations selected
	// TODO: to improve ! not satisfied with logic
	const handleFormData = useCallback(() => {
		const filteredResourceslist = resourcesList.filter(resourceItem => resourceItem.isChecked);
		const filteredObservationsList = updatedObservationsList.filter(observationItem => observationItem.isChecked);
		if (filteredResourceslist.length > 0) {
			filteredResourceslist.forEach(element => {
				if (element.name === "Bulletins") {
					onChange({ target: { name: "hasDriveLog", value: true } });
				} else if (element.name === "Questionnaires SF") {
					onChange({ target: { name: "hasSurvey", value: true } });
				} else if (element.name === "Observations") {
					onChange({ target: { name: "hasObservation", value: true } });
					if (filteredObservationsList.length > 0) {
						onChange({ target: { name: "observationTypes", value: filteredObservationsList.map(filteredObs => filteredObs.name) } });
					} else {
						onChange({ target: { name: "observationTypes", value: [] } });
					}
				}
			});
		} else {
			onChange({ target: { name: "hasDriveLog", value: false } });
			onChange({ target: { name: "hasSurvey", value: false } });
			onChange({ target: { name: "hasObservation", value: false } });
		}
	}, [onChange, resourcesList, updatedObservationsList]);

	useEffect(() => {
		checkObservationCheckState(resourcesList);
	}, [resourcesList]);

	useEffect(() => {
		updateObservationList();
	}, [updateObservationList]);

	useEffect(() => {
		handleFormData();
	}, [handleFormData]);

	return (
		<div className="resource-picker__section">
			<ExportSectionTitle
				sectionNumber={t("exp:sections.resources-picker.section-number")}
				sectionTitle={t("exp:sections.resources-picker.section-title")}
			/>
			<div className="resource-picker__section__content">
				<ExportListContainer
					list={resourcesList}
					type="resources"
					updateItemCheckStateHandler={updateResourceItemCheckState}
				/> 
				{
					displayObservations &&
						<ExportListContainer
							list={updatedObservationsList}
							subtitle={t("exp:sections.resources-picker.section-content-header.subtitle")}
							type="observations"
							updateItemCheckStateHandler={updateObservationItemCheckState}
							updateAllCheckStateHandler={updateAllCheckState}
							areAllChecked={allChecked}
							atLeastOneChecked={atLeastOneChecked}
						/>
				}
			</div>
		</div>
	);
};

export default ExportResourcePicker;
