import { validateDelay, validateReason } from "../../components/observation-form/observation-validators";

const comment = [
	{
		fieldName: "comment",
		mandatory: true,
		validator: validateReason
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default comment;
