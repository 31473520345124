import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./observation-card.scss";

/**
 * Renders an <ObservationCard /> component
 * @param {object} props
 * @param {object} props.type - the observation type object
 * @param {string} props.driveLogId - the drive log id
 * @param {string} props.missionId - the mission id
 * @param {string} props.position - the position encoded object from Digiplan
 * (ex: type = {label: "rail-security",
 * 		linkedEntities: [ "mission" ],
 * 		linkedLines: Array(3) [ "A", "B", "-1" ],
 * 		name: "trigger-automatic-stop",
 * 		url: "#" })
 */
const ObservationCard = (props) => {
	const { type, driveLogId, missionId, position, redirectUrl = "" } = props;

	const { t } = useTranslation();
	const typeHasLabel = type?.label !== "none";
	const missionURL = missionId ? `/mission/${missionId}` : "";
	
	const getUrl = () => {
		if (position) {
			return `${redirectUrl}/${driveLogId}${missionURL}/observation/${type?.url}?position=${position}`;
		} else {
			return `${redirectUrl}/${driveLogId}${missionURL}/observation/${type?.url}`;
		}
	};

	return (
		<li>
			<Link to={getUrl()} className="observation-card">
				{typeHasLabel && <div className="observation-card__label">{t(`observation:observation-list.types.subtypes.${type.label}`)}</div>}
				<div className="observation-card__type">{t(`observation:observation-list.types.${type.name}`)}</div>
			</Link>
		</li>
	);
};

ObservationCard.propTypes = {
	type : PropTypes.object,
	driveLogId: PropTypes.string,
	missionId: PropTypes.string,
	position: PropTypes.string
};

export default ObservationCard;
