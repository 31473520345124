import { http } from "../../config";

/**
 * post a new file attachment to current drivelog
 * @param {Blob} newFileAttachment - the file attachment
 * @param {string} driveLogId - the dl id
 * @returns {Promise} response
 */
const insertDriveLogFileAttachment = async (newFileAttachment, driveLogId) => {
	const bodyFormData = new FormData();
	bodyFormData.append("file", newFileAttachment);
	return http.post(`/drive-log/${driveLogId}/file-attachment`, bodyFormData, { headers: { "Content-Type": "application/pdf" } });
};

/**
 * post a new file attachment to current drivelog and current observation
 * @param {array} newFilesAttachment - the file attachment
 * @param {string} driveLogId - the dl id
 * @param {string} observationId - the obs id
 * @returns {Promise} response
 */
const insertObservationFileAttachment = async (newFilesAttachment, driveLogId, observationId ) => {
	const formData = new FormData();
	newFilesAttachment.forEach((file => {
		formData.append("arrayOfFile", file);
	}));
	return http.post(`/drive-log/${driveLogId}/observation/${observationId}/file-attachment`, formData, { headers: { "Content-Type": "multipart/form-data" } });
};

/**
 * get all file attachment by drivelog
 * @param {String} driveLogId - the dl id
 * @returns {Promise} response
 */
const getFilesAttachmentByDl = async (driveLogId) => http.get(`/drive-log/${driveLogId}/file-attachment`);

/**
 * get all file attachment by observation
 * @param {String} driveLogId - the dl id
 * @param {String} observationId - the obs id
 * @returns {Promise} response
 */
const getFilesAttachmentByObs = async (driveLogId, observationId) => http.get(`/drive-log/${driveLogId}/observation/${observationId}/file-attachment`);

/**
 * get file attachment in a dl by its id
 * @param {String} driveLogId - the dl id
 * @param {String} fileAttachmentId - the file id
 * @returns {Promise} response
 */
const getFileAttachmentByIdAndDl = async (driveLogId, fileAttachmentId) => http.get(`/drive-log/${driveLogId}/file-attachment/${fileAttachmentId}`, { responseType: "blob" });

/**
 * delete file attachment by drivelod and file id
 * @param {String} driveLogId - the dl id
 * @param {String} fileAttachmentId - the file id
 * @returns {Promise} response
 */
const deleteFileAttachmentByIdInDl = async (driveLogId, fileAttachmentId) => http.delete(`/drive-log/${driveLogId}/file-attachment/${fileAttachmentId}`);


/**
 * delete file attachment by observation and file id
 * @param {String} driveLogId - the dl id
 * @param {Array} filesAttachmentIds - the file ids
 * @param {String} observationId - the observation id
 * @returns {Promise} response
 */
const deleteFileAttachmentByIdInObs = async (driveLogId, filesAttachmentIds, observationId) =>
	http.delete(`/drive-log/${driveLogId}/observation/${observationId}/file-attachment`, { data: { ids: filesAttachmentIds } });

export {
	insertDriveLogFileAttachment,
	insertObservationFileAttachment,
	getFilesAttachmentByDl,
	getFilesAttachmentByObs,
	deleteFileAttachmentByIdInDl,
	getFileAttachmentByIdAndDl,
	deleteFileAttachmentByIdInObs
};
