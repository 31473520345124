import { http, dispatchGetRequest } from "../../config";

/**
 * get all drive-logs
 * @param {object} config Configuration for the getDriveLogsRequest
 * @param {Number} config.monthToLoad index of the month to load (0 is this month, 1 is last month, ...)
 * @returns {Promise} response
 */
const getDriveLogs = async (config = {}) => {
	const { dateSortOrder = "asc", numberResults = 40, monthToLoad, ...rest } = config;

	// Remove empty keys
	const cleanedParams = Object.keys({ dateSortOrder, numberResults, ...rest }).reduce((acc, key) => {
		if (config[key]) {
			acc[key] = config[key];
		}
		return acc;
	}, {});

	if (monthToLoad || monthToLoad === 0) {
		cleanedParams.monthToLoad = monthToLoad;
	}

	return http.get("/drive-log", { params : cleanedParams });
};

/**
 * get current drive log
 * @param {Object} config config object
 * @return {Promise} response
 */
const getDailyDriveLog = async (config) => {
	const { type } = config;
	return dispatchGetRequest("daily-drive-log", `/drive-log?type=${type}`);
};

/**
 * get drive log by its id
 * @param {String} driveLogId drive log uuid
 * @param {object} [params] optional config -  if we need to get also the AMP validator infos
 * @returns {Promise} response
 */
const getDriveLogById = async (driveLogId, params= {}) => {
	const { extended } = params;
	if (extended) {
		return http.get(`/drive-log/${driveLogId}`, { params });
	}
	return dispatchGetRequest(`dl-${driveLogId}`,`/drive-log/${driveLogId}`);
};

/**
 * post a new drive log
 * @param {Object} newDriveLog - the new drive log
 * @param {string} type - check service attachment
 * @returns {Promise} response
 */
const createNewDriveLog = async (newDriveLog, type) => {
	return http.post(`/drive-log?type=${type}`, newDriveLog);
};

const deleteDriveLog = async (id) => {
	return http.delete(`/drive-log/${id}`);
};

/**
 * update drive log
 * @param {Object} driveLog - the updated drive log
 * @param {object} [action] - The action that performed the update
 * @returns {Promise} response
 */
const updateDriveLog = async (driveLog, action) => {
	const { id : driveLogId } = driveLog;
	if (action) {
		return http.put(`/drive-log/${driveLogId}`, driveLog, { params: action });
	} else {
		return http.put(`/drive-log/${driveLogId}`, driveLog);
	}
};

/**
 * Apply a batch actions on driveLogs
 * @param {string} batch - the batch to apply
 * @param {string} attachmentCode - the attachment to apply the batch action on
 * @param {array} driveLogsId - the list of drivelogs ids corresponding to the simple drivelogs displayed on the user screen
 * @returns {Promise} response
 */
const batchUpdateDriveLog = async (batch = "", attachmentCode, driveLogsId) => {
	return http.put("/drive-log/batch", { batch, attachmentCode, driveLogsId });
};

/**
 * get all attachments
 * @returns {Promise} response
 */
const getAttachments = async (params = {}) => {
	return http.get("/attachment", { params });
};

const driveLogServices = {
	getDriveLogById, createNewDriveLog, getDriveLogs, getAttachments, updateDriveLog, deleteDriveLog,
	batchUpdateDriveLog, getDailyDriveLog
};

export default driveLogServices;

export {
	getDriveLogById, createNewDriveLog, getDriveLogs, getAttachments, updateDriveLog, deleteDriveLog,
	batchUpdateDriveLog, getDailyDriveLog
};
