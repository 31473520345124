import React, { useState, useEffect } from "react";
import PopUpDocuments from "../pop-up-documents/pop-up-documents";
import { Button, Icon } from "../../../../../../core";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import "./document-menu-button.scss";

/**
 * Renders an <DocumentMenuButton /> component
 * @param {object} props
 * @param {string} props.driveLogId - the drive log id
 */
const DocumentMenuButton = ({ driveLogId = "" }) => {

	const location = useLocation();
	const queryParams = queryString.parse(location.search);
	const consultingDocuments = queryParams["consulting-documents"];

	const [showDocumentsPopUp, setShowDocumentsPopUp] = useState(false);

	const displayPopUp = () => {
		setShowDocumentsPopUp(!showDocumentsPopUp);
	};

	useEffect(() => {
		if (consultingDocuments) {
			setShowDocumentsPopUp(true);
		}
	}, [consultingDocuments]);

	return (
		<>
			<Button className="dl-mission-list__mission__document" light onClick={displayPopUp} >
				<Icon name="suitcase" className="dl-mission-list__mission__document__icon"/>
			</Button>
			<PopUpDocuments driveLogId={driveLogId} showPopUp={showDocumentsPopUp} setShowPopUp ={setShowDocumentsPopUp}/>
		</>
	);
};

DocumentMenuButton.propTypes = {
	driveLogId: PropTypes.string
};

export default DocumentMenuButton;
