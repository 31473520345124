import React from "react";
import PropTypes from "prop-types";
import { DateUtils } from "../../../../../core";
import "./service-start-cell.scss";
import { useTranslation } from "react-i18next";

const ServiceStartCell = (props) => {
	const { driveLog, header = false } = props;
	const { t } = useTranslation("dl");

	const getDate = (dl) => {
		if ( !!dl?.realized_date_start ) {
			return DateUtils.formatFullDate(dl?.realized_date_start, "dd/MM/yyyy");
		}
		return "";
	};

	const buildHeader = () =>  (
		<div className="service-start-cell">
			{ t("dl-table.headers.date") }
		</div>
	);

	const buildCell = () =>  (
		<div className="service-start-cell">
			{ getDate(driveLog) }
		</div>
	);

	return (
		header ? buildHeader() : buildCell()
	);
};

ServiceStartCell.propTypes = {
	driveLog : PropTypes.object,
	header : PropTypes.bool
};

export default ServiceStartCell;
