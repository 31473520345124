import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Comment, Dropdown, Icon, Modal } from "../../../../index";
import { validateComment, validateDropdownChoice } from "../../utils/feedback-fields-validators";
import { useTranslation } from "react-i18next";
import { sendFeedbackSurveyData } from "../../../../../user/google-form.services";
import "./feedback-modal.scss";

const FeedbackModal = (props) => {
	const { showModal, toggleModal, confirmCallback, cancelCallback = () => null, readOnly } = props;
	const { t } = useTranslation();

	const feedbackChoices = {
		suggestion: t("core:feedback.form-modal.dropdown.suggestion"),
		technicalProblem: t("core:feedback.form-modal.dropdown.technicalProblem"),
		appreciation: t("core:feedback.form-modal.dropdown.appreciation")
	};

	const commentMaxLength = 240;
	const [feedbackValid, setFeedbackValid] = useState(false);

	const [newFeedback, setNewFeedback] = useState({
		choice: "",
		comment: ""
	});

	const validateFeedback = () => {
		const fieldChoiceValid = newFeedback.choice && validateDropdownChoice(newFeedback.choice);
		const fieldCommentValid = newFeedback.comment && validateComment(newFeedback.comment);

		if (fieldCommentValid && fieldChoiceValid) {
			return setFeedbackValid(true);
		}
	};

	const resetFeedback = () => {
		setNewFeedback({ choice: "", comment: "" });
		setFeedbackValid(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const choiceValue = t(`core:feedback.form-modal.dropdown.${newFeedback.choice}`);
		const userInputs = { choice: choiceValue, comment: newFeedback.comment };

		try {
			await sendFeedbackSurveyData(userInputs);
		} catch (error) {
			console.error(error);
		} finally {
			toggleModal(!showModal);
			resetFeedback();
			confirmCallback();
		}
	};

	const handleCancel = () => {
		toggleModal(!showModal);
		resetFeedback();
		cancelCallback();
	};

	const handleChangeData = (name) => (value) => {
		setNewFeedback((previousState) => ({ ...previousState, [name]: value }));
	};

	useEffect(validateFeedback, [newFeedback, validateFeedback]);

	return (
		showModal &&
		<Modal type="custom">
			<div className="feedback-modal__form">
				<button className="feedback-modal__form__icon" onClick={handleCancel}>
					<Icon name="close" />
				</button>
				<div className="feedback-modal__form__container">
					<div className= "feedback-modal__form__container__header">
						<p className="feedback-modal__form__container__header__title" >{t("core:feedback.form-modal.title")}</p>
					</div>
					<div className= "feedback-modal__form__container__content">
						<p className="feedback-modal__form__container__content__sub-title" >{t("core:feedback.form-modal.dropdown.title")}</p>
						<Dropdown
							value={newFeedback.choice}
							onChange={handleChangeData("choice")}
							className={"feedback-modal__form__content__dropdown"}
							dropdownList={feedbackChoices}
							placeholder={t("core:feedback.form-modal.dropdown.placeholder")}
						/>
					</div>
					<div className= "feedback-modal__form__container__content">
						<p className="feedback-modal__form__container__content__sub-title" >{t("core:feedback.form-modal.comment.title")}</p>
						<Comment
							value={newFeedback.comment}
							handleChange={handleChangeData("comment")}
							className={"feedback-modal__form__content__comment"}
							placeholder={t("core:feedback.form-modal.comment.placeholder")}
							maxLength={commentMaxLength}
							readOnly={readOnly}
						/>
					</div>
				</div>
				<div className="feedback-modal__form__button">
					<Button className="button" disabled={!feedbackValid} type="submit" onClick={handleSubmit}>
						{t("core:feedback.form-modal.send")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

FeedbackModal.propTypes = {
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	confirmCallback: PropTypes.func,
	cancelCallback: PropTypes.func,
	readOnly: PropTypes.bool
};

export default FeedbackModal;
