import { validateDelay, validateReason } from "../../components/observation-form/observation-validators";

const stopOnTheWay = [
	{
		fieldName: "upstreamStation",
		mandatory: true
	},
	{
		fieldName: "downstreamStation",
		mandatory: true
	},
	{
		fieldName: "lane",
		mandatory: true
	},
	{
		fieldName: "stopReason",
		maxLength: 140,
		mandatory: true,
		validator: validateReason
	},
	{
		fieldName: "comment",
		validator: validateReason
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default stopOnTheWay;
