import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button, Icon } from "../../../core";
import DisableLineSheetsModal from "../disable-line-sheet-modal/disable-line-sheet-modal";

import "./line-sheets-state-card.scss";

const LineSheetsStateCard = (props) => {
	const { lineSheet, refreshDisabledSheetsList, tomorrowDate } = props;
	const { t } = useTranslation("disable-line-sheets");
	const [ showDisabledModal, setShowDisableModal ] = useState(false);
	const { line, status } = lineSheet || {};
	const isActivated = status === "activated";

	const handleDisableLineSheets = () => {
		setShowDisableModal(!showDisabledModal);
	};

	return (
		<>
			<li className="disable-line-sheets__card">
				<div className="disable-line-sheets__card__header">
					<Icon name={ line === "A" ? "line_A_square" : "line_B_square" } className="disable-line-sheets__card__header__icon"/>
					<span className="disable-line-sheets__card__header__title"> { t("sheets-state.card.title") } </span>
					<div className={classNames("disable-line-sheets__card__header__tag", { "disable-line-sheets__card__header__tag--disabled": !isActivated })}>
						<div className={classNames("disable-line-sheets__card__header__tag__dot",
							{ "disable-line-sheets__card__header__tag__dot--disabled": !isActivated })}/>
						<span className={classNames("disable-line-sheets__card__header__tag__title",
							{ "disable-line-sheets__card__header__tag__title--disabled": !isActivated })}>
							{isActivated ? t("sheets-state.card.activated") : t("sheets-state.card.disabled") }
						</span>
					</div>
				</div>
				<div className="disable-line-sheets__card__content">
					<span className="disable-line-sheets__card__content__text disable-line-sheets__card__content__text--bold">
						{isActivated ? t("sheets-state.card.activated-title") : t("sheets-state.card.disabled-title") }
					</span>
					{isActivated && <span className="disable-line-sheets__card__content__text">{t("sheets-state.card.activated-sub-title")}</span>}
					{!isActivated &&
					<span className="disable-line-sheets__card__content__text">
						<Trans i18nKey="user:line-sheets.sheets-state.card.disabled-sub-title" parent="p">
							<span >{{ date: tomorrowDate }}</span>
						</Trans>
					</span> }
				</div>
				{isActivated && <div className="disable-line-sheets__card__content__button">
					<Button className="button" onClick={handleDisableLineSheets}>
						{t("sheets-state.card.disable")}
					</Button>
				</div>}
			</li>
			{showDisabledModal && <DisableLineSheetsModal
				setShowDisableModal={setShowDisableModal}
				showDisabledModal={showDisabledModal}
				lineSheet={lineSheet}
				refreshDisabledSheetsList={refreshDisabledSheetsList}/>}
		</>
	);
};

export default LineSheetsStateCard;
