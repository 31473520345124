import { useEffect, useState } from "react";
import { getReferentialList } from "../../core/core.services";

const getLocation = (status, stationList, trigram) => {
	const location = {};
	if (status === "gare") {
		location.value = "Gare";
		const station = stationList.find(({ trigram: refTrigram }) => refTrigram === trigram );
		location.children = { station };
	}

	if (status === "intergare") {
		const [trigramDeparture, trigramArrival] = trigram.split("-");
		location.value = "Intergare";
		const interStationStart = stationList.find(({ trigram: refTrigram }) => refTrigram === trigramDeparture );
		const interStationEnd = stationList.find(({ trigram: refTrigram }) => refTrigram === trigramArrival );
		location.children = { interStationStart, interStationEnd };
	}

	return location;
};

const useObservationParams = (position, observationType) => {
	const { trigram, track, status } = position;

	const [stationList, setStationList] = useState();

	useEffect(() => {
		getReferentialList("station").then(res => {
			setStationList(res.data);
		});
	},[]);

	if (!position) {
		return false;
	}

	if (stationList && observationType === "trigger-automatic-stop") {
		const observationParams = {};
		observationParams.location = getLocation(status, stationList, trigram);
		observationParams.lane = track;
		return observationParams;
	}

	if (stationList && observationType === "alarm-signal") {
		const observationParams = {};
		observationParams.location = getLocation(status, stationList, trigram);
		return observationParams;
	}

	if (stationList && observationType === "retention") {
		const observationParams = {};
		observationParams.station = stationList.find(({ trigram: refTrigram }) => refTrigram === trigram );
		return observationParams;
	}

	if (stationList && observationType === "installation") {
		const observationParams = {};
		observationParams.location = getLocation(status, stationList, trigram);
		observationParams.dateTime = new Date();
		observationParams.lane = track;
		return observationParams;
	}

	if (stationList && observationType === "pmr") {
		const observationParams = {};
		if (status === "gare") {
			observationParams.stationOn = stationList.find(({ trigram: refTrigram }) => refTrigram === trigram );
		}
		if (status === "intergare") {
			const [trigramDeparture] = trigram.split("-");
			observationParams.stationOn = stationList.find(({ trigram: refTrigram }) => refTrigram === trigramDeparture );
		}
		return observationParams;
	}

	if (stationList && observationType === "traveller-information") {
		const observationParams = {};
		observationParams.stationLocation = getLocation(status, stationList, trigram);
		return observationParams;
	}

	if (stationList && observationType === "stop-on-the-way") {
		const observationParams = {};
		const { children } = getLocation(status, stationList, trigram) || {};
		const { interStationStart, interStationEnd } = children;
		observationParams.upstreamStation = interStationStart;
		observationParams.downstreamStation = interStationEnd;
		observationParams.lane = track;
		return observationParams;
	}

	if (stationList) {
		// label observationParams as an otherObservation coming from Digiplan
		return { otherObservation: true };
	}
};

export default useObservationParams;
