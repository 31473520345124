import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { differenceInMinutes, parseISO } from "date-fns";
import { AuthContext } from "../../../user/auth/context/auth-context";
import { Button, DateUtils, Icon, Modal } from "../../../core";
import { deleteDriveLog } from "../../drive-log.services";
import "./service-infos.scss";

const ServiceInfos = (props) => {
	const { className = "", driveLog = {}, loading, setDisplayModifyServiceModal } = props;
	const { t } = useTranslation("dl");
	const history = useHistory();
	const { formatHourMinutes, formatDuration, formatFullDate } = DateUtils;

	const { user } = useContext(AuthContext);
	const { role: userRole } = user;
	const isAdminRole = userRole === "admin" || userRole === "localAdmin";

	const [showModal, setShowModal] = useState(false);

	const getHour = (dl, service) => formatHourMinutes(dl[service], ":");

	const getDuration = (dateEnd, dateStart) => {
		if (dateEnd && dateStart) {
			const duration = differenceInMinutes(parseISO(dateEnd), parseISO(dateStart));
			return formatDuration(duration);
		}
		return formatDuration(0);
	};

	const editPlannedService = () => {
		setDisplayModifyServiceModal(true);
	};

	const handleDeleteDrivelog = () => {
		deleteDriveLog(driveLog.id)
			.then(() => {
				setShowModal(false);
				history.goBack();
			})
			.catch((error) => console.error(error));
	};

	const handleShowModal = () => {
		setShowModal(!showModal);
	};

	/**
	 * display a service-info row
	 * @param {string} service - the service type (realized/planned)
	 * @param {string} serviceNumber - the service number value
	 * @param {Date} dateStart - the start date of the service
	 * @param {Date} dateEnd - the start end of the service
	 * @param {string} attachment - the attachment name of the driveLog
	 */
	const displayServiceInfoRow = (service, serviceNumber, dateStart, dateEnd, attachment) => {
		return (
			<li className="service-infos__table__row service-infos__table__values__row">
				<div className="service-infos__table__row__service">
					{ t(`dl-details.service-infos.headers.service.${service}`) }
				</div>
				<div className="service-infos__table__row__number">
					<span className="table__row__number__title">
						{t("dl-details.service-infos.headers.service-number")}</span>
					<span className="table__row__number__value">{serviceNumber !== "-1" ? serviceNumber : "PV"}</span>
				</div>
				<div className="service-infos__table__row__hours">
					{dateStart && (
						<>
							<div className="row__hours-start">
								{getHour(driveLog, `${service}_date_start`)}
							</div>
							<Icon name="arrow-right" className="arrow-right"/>
							<div className="row__hours-end">
								{getHour(driveLog, `${service}_date_end`)}
							</div>
						</>
					)}
				</div>
				<div className="service-infos__table__row__duration">
					{dateEnd && (
						<span className= "row__duration__value">
							<Icon aria-label="Waves" className= "row__duration__icon" name="waves"/>
							{getDuration(dateEnd, dateStart)}
						</span>
					)}
				</div>
				<div className="service-infos__table__row__attachment">
					{attachment}
				</div>
				<div className="service-infos__table__row__action">
					{service === "planned" && <span className="table__row__action__edit" onClick={() => editPlannedService()}>{t("dl-details.service-infos.modify")}</span>}
				</div>
			</li>
		);
	};

	return (
		!loading && (
			<>
				<div className="service-infos__top">
					<h2 className="service-infos__title">
						<span>{t("dl:dl-details.service-infos.title")}</span>
						<span className="service-infos__title__date">{formatFullDate(driveLog?.realized_date_start)}</span>
					</h2>
					{ isAdminRole && driveLog.status && (
						<Button className="service-infos__controls" outline={true} onClick={handleShowModal}>
							{t("dl:dl-details.delete-button")}
						</Button>
					)}
				</div>

				<div className={`service-infos ${className}`}>
					<ul className="service-infos__table">
						<li className="service-infos__table__row service-infos__table__headers">
							<div className="service-infos__table__row__service">
								{ t("dl-details.service-infos.headers.service.title") }
							</div>
							<div className="service-infos__table__row__number">
								{ t("dl-details.service-infos.headers.number") }
							</div>
							<div className="service-infos__table__row__hours">
								<div>
									{ t("dl-details.service-infos.headers.start") }
								</div>
								<Icon name="arrow-right" className="arrow-right"/>
								<div>
									{ t("dl-details.service-infos.headers.end") }
								</div>
							</div>
							<div className="service-infos__table__row__duration">
								{ t("dl-details.service-infos.headers.duration") }
							</div>
							<div className="service-infos__table__row__attachment">
								{ t("dl-details.service-infos.headers.attachment") }
							</div>
						</li>

						<div className="service-infos__table__values">
							{displayServiceInfoRow(
								"planned",
								driveLog?.planned_service_number,
								driveLog?.planned_date_start,
								driveLog?.planned_date_end, driveLog?.planned_station_start)
							}
							{displayServiceInfoRow(
								"realized",
								driveLog?.realized_service_number,
								driveLog?.realized_date_start,
								driveLog?.realized_date_end,
								driveLog?.attachment_name)
							}
						</div>

					</ul>
				</div>
				{showModal && <Modal
					type="confirm"
					title={t("dl:dl-details.delete-dl-modal.title")}
					text="dl:dl-details.delete-dl-modal.content"
					confirmText={t("dl:dl-details.confirm")}
					cancelText={t("dl:dl-details.cancel")}
					confirmCallback={handleDeleteDrivelog}
					cancelCallback={() => setShowModal(false)}
					closeCallback={() => setShowModal(false)}
					iconName="warning-triangle"
				/>}
			</>
		)
	);
};

ServiceInfos.propTypes = {
	className: PropTypes.string
};

export default ServiceInfos;
