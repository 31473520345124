import React, { useContext } from "react";
import PropTypes from "prop-types";
import DriverHeader from "../../headers/driver-header/driver-header";
import PageWrapper from "../page-wrapper/page-wrapper";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../../user/auth/context/auth-context";
import FeedbackMenuButton from "../../feedback/components/feedback-menu-button/menu-feedback-button";
import "./driver-page.scss";

/**
 * Renders a <Page /> component
 * @param {object} props
 * @param {string} props.className - classnames to add to the root of this component
 * @param {Object} props.children - the content to render in the page
 * @param {boolean} props.selectedLink - display or not the footer
 * @param {boolean} props.hideNav - display or not the nav buttons
 */
const DriverPage = props => {
	const { className = "", children, selectedLink = 0, hideNav = false, overrideRoles, style, hideFeedbackButton = false } = props;
	const { user } = useContext(AuthContext);

	const allowedRoles = overrideRoles || ["driver", "amp", "admin", "localAdmin"];

	if (!user?.role || (!allowedRoles.includes(user.role))) {
		return <Redirect to="/error-access" />;
	}

	return (
		<PageWrapper className="driver-page">
			<DriverHeader selectedLink={selectedLink} hideNav={hideNav}/>

			<div className={`driver-page__content ${className}`} style={style}>
				{children}
			</div>
			<FeedbackMenuButton hideButton={hideFeedbackButton} />
		</PageWrapper>
	);
};


DriverPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
	hideFeedbackButton: PropTypes.bool
};


export default DriverPage;

