import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./radio-button.scss";

const RadioButton = props => {
	const { className = "", checkedClassName , onChange, checked, labelId, name, label, showRadioTick, disabled = false, radioLabelClassName = "" } = props;

	const classes = classnames("radio-button", className, {
		"radio-button--checked": checked,
		[checkedClassName]: checked
	});

	return (
		<label
			className={classes}
			htmlFor={labelId}>
			{showRadioTick && <span className="radio-button__tick" />}
			<input
				id={labelId}
				className="radio-button__input"
				name={name}
				onChange={onChange}
				checked={checked}
				type="radio"
				disabled={disabled}
			/>
			<span className={`radio-button__label ${radioLabelClassName}`}>{label}</span>
		</label>
	);
};

RadioButton.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	checkedClassName: PropTypes.string,
	showRadioTick: PropTypes.bool,
	labelId: PropTypes.string,
	radioLabelStyle: PropTypes.string
};

export default RadioButton;
