import React from "react";
import { getHours, getMinutes, isDate, parseISO } from "date-fns";
import PropTypes from "prop-types";
import { TimePicker } from "../../../../../core";
import "./observation-time.scss";
import { setDateTimeWithService } from "../../../../observation.services";

/***
 * Renders an <ObservationTime /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {string} props.label
 * @param {any} props.dateTime
 * @param {function} props.handleChange
 * @param {function} props.setTimeError
 * @param {boolean} props.hasResetToCurrentTime
 * @param {string} props.startServiceDateTime
 * @return {JSX.Element}
 * @constructor
 */
const ObservationTime = (props) => {
	const { className = "", label, dateTime, handleChange, setTimeError = () => {}, hasResetToCurrentTime, startServiceDateTime, fieldSchema } = props;
	const formatDate = isDate(dateTime) ? dateTime : parseISO(dateTime);

	const handleTimeChange = (newValue, type) => {
		const currentDate = dateTime? formatDate : null;
		const newTime = setDateTimeWithService(startServiceDateTime, fieldSchema?.startOfDayHour, currentDate, type, newValue);
		handleChange(newTime);
	};

	const handleHourChange = (newValue) => handleTimeChange(newValue, "hours");

	const handleMinuteChange = (newValue) => handleTimeChange(newValue, "minutes");

	const handleResetToCurrentTime = () => {
		handleChange(setDateTimeWithService(startServiceDateTime, fieldSchema?.startOfDayHour, new Date()));
	};

	return (
		<div className={`observation-time ${className}`}>
			<label className="observation-time__label">{label}</label>
			<TimePicker
				className="observation-time__input"
				hours={dateTime ? getHours(formatDate) : undefined}
				setHours={handleHourChange}
				minutes={dateTime ? getMinutes(formatDate) : undefined}
				setMinutes={handleMinuteChange}
				setTimeError={setTimeError}
				resetToCurrentTime={hasResetToCurrentTime && handleResetToCurrentTime}
			/>
		</div>
	);
};

ObservationTime.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	dateTime: PropTypes.any,
	handleChange: PropTypes.func,
	setTimeError: PropTypes.func,
	hasResetToCurrentTime: PropTypes.bool,
	startServiceDateTime: PropTypes.any
};

export default ObservationTime;
