import * as localForage from "localforage";

/**
 * Store the passed observations id
 * @param driveLogId
 * @param observationId
 */
const storeObservationsPassed = async (driveLogId, observationId) => {
	const passedObservations = await localForage.getItem( `dl-${driveLogId}-observations-passed`);
	const passedObservationsId = passedObservations?.data || [];
	await localForage.setItem(`dl-${driveLogId}-observations-passed`, { data: [...passedObservationsId, observationId] });
};

export default storeObservationsPassed ;
