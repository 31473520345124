import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./bottom-modal.scss";

/**
 * Renders an BottomModal component
 * @param {object} props
 * @param {string} props.className - className external style
 * @param {node} props.children - children
 * @param {boolean} props.hideAnimation - hideAnimation bolean to trigger fade animation
 * @returns {JSX.Element}
 */

const BottomModal = ({ className = "", children, hideAnimation = false }) => {
	const modalClassNames = classNames("bottom-modal__container", className, {
		"bottom-modal--show": !hideAnimation,
		"bottom-modal--hide": hideAnimation
	});

	return (
		<div className={`bottom-modal ${modalClassNames}`}>{children}</div>
	);
};

BottomModal.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	hideAnimation: PropTypes.bool
};

export default BottomModal;
