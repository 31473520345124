import { http } from "../../config";
import { format } from "date-fns";

/**
 * get all consistency report and services
 * @param {string} dateFilter date for the get consistency services request
 * @param {string} currentAttachment date for the get consistency services request
 * @param {string} offset the offset of consistency reports to be loaded
 * @returns {Promise<Array>} an array of all reports unvalidated
 */
const getConsistencyReports = (dateFilter, currentAttachment, offset = 0) => {
	const params = {
		attachment: currentAttachment,
		offset
	};

	if (dateFilter) {
		const date = new Date(dateFilter);
		params.date = format(date, "yyyy-MM-dd");
	}

	return http.get("/consistency-report", { params });
};

/**
 * check the consistency report for the specified date
 * @param {string} date date
 * @returns {Promise<Array>} an array of all reports
 */
const checkConsistencyReportByDate = (date) => {
	const params = {
		date
	};
	return http.get("/consistency-report/check", { params });
};

/**
 * update reason and comment for a given consistency service
 * @param {object} userInputs  justification form data
 * @param {object} serviceId  the service id to update
 * @returns {Promise} response
 */
const updateConsistencyService = (userInputs = {}, serviceId) => {
	const { reason, comment } = userInputs;
	const params = {
		reason,
		comment
	};
	return http.put(`/consistency-service/${serviceId}`, params);
};

/**
 * Overlaps draft sample
 *  [
		{
			id: "8f655157-71c1-4deb-ab6f-a43c1a76e613",
			code: "FZZZ04",
			delete: true
		},
		{
			id: "1aa6ea6d-3f61-4de4-a641-0a19dc2803ef",
			code: "XZZZ04",
			station_end: "Auber",
			hour_end: "2022-10-23T15:57:00+02:00",
			station_end_trigram: "AUB"
		},
		{
			id: "bd5c9d83-1eb3-48f9-a2ba-17abd07036da",
			code: "XZZZ04",
			station_end: "Rueil-Malmaison",
			hour_end: "2022-10-23T15:40:00+02:00",
			station_end_trigram: "RUE"
		}
	];
 */

/**
 * get Overlaps draft
 * @return {Object}
 */
const getConsistencyOverlapsDraft = () => {
	return JSON.parse(localStorage.getItem("consistency-overlaps-draft"));
};

/**
 * set Overlaps update draft
 * @param {Object} mission
 */
const saveConsistencyOverlapsToBeUpdated = (date, mission) => {
	const { id: missionId, ...missionData } = mission;

	let draft = JSON.parse(localStorage.getItem("consistency-overlaps-draft"));

	if (draft) {
		const missionIdx = draft.findIndex(({ id }) => id === missionId);

		if (missionIdx === -1) {
			draft.push({ date, id: missionId, ...missionData });
		} else {
			draft[missionIdx] = { ...draft[missionIdx], ...missionData };
		}
	} else {
		draft = [{ date, id: missionId, ...missionData }];
	}

	localStorage.setItem("consistency-overlaps-draft", JSON.stringify(draft));
};

/**
 * set Overlaps delete draft
 * @param {Object} data
 */
const saveConsistencyOverlapsToBeDeleted = (date, mission, restore = false) => {
	const { id: missionId, code: missionCode } = mission;

	let draft = JSON.parse(localStorage.getItem("consistency-overlaps-draft"));

	if (draft) {
		const missionIdx = draft.findIndex(({ id }) => id === missionId);

		if (missionIdx === -1) {
			draft.push({ date, id: missionId, code: missionCode, delete: true });
		} else {
			draft[missionIdx] = { ...draft[missionIdx], code: missionCode, delete: true };
		}

		if (restore) {
			draft.splice(missionIdx, 1);
		}
	} else {
		draft = [{ date, id: missionId, code: missionCode, delete: true }];
	}

	localStorage.setItem("consistency-overlaps-draft", JSON.stringify(draft));
};

/**
 * clear Overlaps draft
 */
const clearConsistencyOverlapsDraft = () => {
	localStorage.removeItem("consistency-overlaps-draft");
};

/**
 * update missions according to the overlaps validation
 * @param {string} date date
 * @param {Array} missions missions data
 * @returns {Promise} response
 */
const validateConsistencyOverlaps = (date, overlaps) => {
	// Exclude date from the data to update
	const missions = overlaps.map((overlap) => {
		// eslint-disable-next-line no-shadow
		const { date, ...overlapData } = overlap;
		return overlapData;
	});

	const params = {
		date,
		missions
	};
	return http.post("/consistency-overlap", params);
};

export {
	getConsistencyReports,
	checkConsistencyReportByDate,
	updateConsistencyService,
	getConsistencyOverlapsDraft,
	saveConsistencyOverlapsToBeUpdated,
	saveConsistencyOverlapsToBeDeleted,
	clearConsistencyOverlapsDraft,
	validateConsistencyOverlaps
};
