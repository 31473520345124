import { regexValidator, validateDelay } from "../../components/observation-form/observation-validators";
import { RegexUtils } from "../../../core";
import { setDateTimeWithService } from "../../observation.services";

const orderOrNotice = [
	{
		fieldName: "reasonOrderOrNotice",
		mandatory: true,
		children: [
			{
				fieldName: "reasonOrderOrNoticeSecurity",
				mandatory: true,
				parentValue: "Installation de sécurité",
				modalTriggerValue: "yes"
			},
			{
				fieldName: "reasonOrderOrNoticeTraffic",
				mandatory: true,
				parentValue: "Circulations dégradées",
				modalTriggerValue: "yes"
			}
		]
	},
	{
		fieldName: "missionCode",
		readOnly: true,
		visibleOnSide: ["mission"]
	},
	{
		fieldName: "addresseeName",
		mandatory: true,
		visibleOnSide: ["drive-log"]
	},
	{
		fieldName: "currentDate",
		readOnly: true
	},
	{
		fieldName: "dateTime",
		mandatory: true,
		defaultValue: ({ driveLog }) => setDateTimeWithService(driveLog?.realized_date_start, new Date())
	},
	{
		fieldName: "orderNumber",
		mandatory: true,
		validator: regexValidator(RegexUtils.alphaNumericSpecialCharactersWithTwenty)
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default orderOrNotice;
