import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import SpecialNoticeHeader from "../../headers/special-notice-header/special-notice-header";
import PageWrapper from "../page-wrapper/page-wrapper";
import { AuthContext } from "../../../../user/auth/context/auth-context";
import "./special-notice-page.scss";

/**
 * Renders a <SpecialNoticePage /> component
 * @param {object} props
 * @param {string} props.className - classnames to add to the root of this component
 * @param {object} props.children - the content to render in the page
 * @param {boolean} props.selectedLink - display or not the footer
 */
const SpecialNoticePage = props => {
	const { className = "", children, selectedLink = 0, hideNav = false } = props;
	const { user } = useContext(AuthContext);
	const { role: userRole, special_notice_access: userSpecialNoticeAccess } = user || {};

	const isUserAllowed = ["admin", "localAdmin"].includes(userRole) || (userRole === "amp" && userSpecialNoticeAccess);

	if (!userRole || !isUserAllowed) {
		return <Redirect to="/error-access" />;
	}

	return (
		<PageWrapper className="special-notice-page">
			<SpecialNoticeHeader selectedLink={selectedLink} hideNav={hideNav}/>

			<main className={`special-notice-page__content ${className}`}>
				{children}
			</main>
		</PageWrapper>
	);
};

SpecialNoticePage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
	overrideRoles : PropTypes.array
};

export default SpecialNoticePage;

