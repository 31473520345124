import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./comment-cell.scss";

const CommentCell = (props) => {
	const { driveLog, header = false } = props;
	const { t } = useTranslation("dl");

	const getDelay = (dl) => dl?.hr_total_additional_time ? dl?.hr_total_additional_time : 0;

	const getObservationCount = (dl) => {
		if ( dl?.count_observations ) {
			return dl?.count_observations;
		}
		return 0;
	};

	const buildHeader = () => (
		<div className="comment-cell">
			{ t("dl-table.headers.comments") }
		</div>
	);

	const buildCell = () => (
		<div className="comment-cell">
			<div className="comment-cell__delay">
				{ t("dl-table.delay") }
				<span className="comment-cell__delay-value">
					{ t("dl-table.minutes", { delay: getDelay(driveLog) }) }
				</span>
			</div>
			<div className="comment-cell__observations">
				{ t("dl-table.observation", { count: getObservationCount(driveLog) }) }
			</div>
		</div>
	);

	return ( header ? buildHeader() : buildCell() );
};

CommentCell.propTypes = {
	driveLog: PropTypes.object,
	header: PropTypes.bool
};

export default CommentCell;
