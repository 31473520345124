import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useAttachmentList } from "../../attachment.services";
import { getConsultationAttachments, setConsultationAttachments } from "../../utils/attachments-localstorage-utils";
import { Loader, PopupFullPage } from "../../../core";
import { AuthContext } from "../../../user/auth/context/auth-context";
import "./modify-attachment-consultation-page.scss";

const ModifyAttachmentConsultationPage = () => {
	const { user = {} } = useContext(AuthContext);

	const { state : stateLocation } = useLocation();
	const history = useHistory();

	const [ selectedAttachment, setSelectedAttachment] = useState();

	const { attachments, loading } = useAttachmentList(true);
	const { t } = useTranslation("at");

	const validateAttachment = () => {
		setConsultationAttachments(selectedAttachment).then(() => {
			history.push(stateLocation.originPage);
		}).catch(err => {
			console.error(err);
		});
	};

	useEffect(() => {
		getConsultationAttachments(user).then((attachmentInfo) => {
			setSelectedAttachment(attachmentInfo);
		}).catch(err => {
			console.error(err);
		});
	}, [user]);

	const handleChange = (event) => {
		const { value: code, name, checked } = event.target;
		if (checked) {
			setSelectedAttachment((prevAttachments) => [...prevAttachments, { code, name }]);
		} else {
			setSelectedAttachment((prevAttachments) => prevAttachments.filter((attachment => attachment.code !== code)));
		}
	};

	const generateAttachment = (attachment) => {
		const isSelected = selectedAttachment?.map((att) => att.code).includes(attachment.code);
		const initClassName = (className) => classnames(
			className,
			{ [`${className}--selected`]: isSelected });
		return(
			<li key={attachment.code} className={initClassName("modify-attachment-consultation-page__attachment-card")} onClick={handleChange}>
				<label htmlFor={attachment.code} className={initClassName("modify-attachment-consultation-page__attachment-card__content")}>
					{attachment.name}
					<input id={attachment.code} type="checkbox" name={attachment.name}
					   className="attachment-checkbox__input"
					   defaultChecked={isSelected}
					   disabled={false}
					   value={attachment.code}
					   style={{ display: "none" }}
					/>
				</label>
				<label htmlFor={attachment.code} className={initClassName("modify-attachment-consultation-page__attachment-card__select")}>
					{isSelected ? t("modify-attachment-page.deselect") : t("modify-attachment-page.choose")}
				</label>
			</li>
		);
	};

	const generateAttachmentList = (attachmentList) => {
		if (Array.isArray(attachmentList) && attachmentList.length > 0) {
			return (
				<ul className="modify-attachment-consultation-page__attachment-list">
					{attachmentList.map(generateAttachment)}
				</ul>
			);
		}
		return(<ul className="modify-attachment-consultation-page__attachment-list"/>);
	};

	return (
		<PopupFullPage
			overrideRoles={["admin", "amp", "consultant", "localAdmin"]}
			title={t("modify-attachment-page.title")} backLink={stateLocation.originPage}
			className="modify-attachment-consultation-page"
		>
			<div className="modify-attachment-consultation-page__content">
				<h2 className="modify-attachment-consultation-page__title">{ t("modify-attachment-page.sub-title") }</h2>
				<Loader isLoading={!!loading}>
					{generateAttachmentList(attachments)}
				</Loader>
				<button className="button" disabled={selectedAttachment?.length === 0}onClick={validateAttachment}>{ t("modify-attachment-page.validate") }</button>
			</div>
		</PopupFullPage>
	);
};

export default ModifyAttachmentConsultationPage;
