import i18next from "../../config/i18next";
import i18nDriveLog from "./i18n/fr.json";

// Components
import DriveLogCard from "./components/drive-log-card/drive-log-card";
import DriveLogErrorMessage from "./components/drive-log-error-message/drive-log-error-message";
import DriveLogSubHeader from "./components/drive-log-subheader/drive-log-subheader";
import Schedule from "./components/schedule/schedule";
import AttachmentList from "./components/attachment-list/attachment-list";
import AddSheetLine from "./components/add-sheet-line/add-sheet-line";
import ServiceNumber from "./components/service-number/service-number";
import ServiceInfos from "./components/service-infos/service-infos";
import MissionListAllObs from "./components/mission-list-all-obs/mission-list-all-obs";
import FooterDlDetails from "./components/footer-dl-details/footer-dl-details";
import CommentWithFileAttachment from "./components/comment-with-file-attachment/comment-with-file-attachment";
import DlLineSheetPage from "./pages/driver/dl-line-sheet-page/dl-line-sheet-page";

// Pages Driver
import EndOfServiceDelayPage from "./pages/driver/delays/end-of-service-delay-page/end-of-service-delay-page";
import DiffServicePlannedPerformedDelayPage from "./pages/driver/delays/diff-service-planned-performed-delay-page/diff-service-planned-performed-delay-page";
import OtherTcDelayPage from "./pages/driver/delays/other-tc-delay-page/other-tc-delay-page";
import BeyondSixHourThirty from "./pages/driver/delays/beyond-six-hour-thirty/beyond-six-hour-thirty";
import RestShortenedPage from "./pages/driver/delays/rest-shortened-page/rest-shortened-page";
import ModifyEndOfServiceDelayPage from "./pages/driver/delays/modify-end-of-service-delay-page/modify-end-of-service-delay-page";
import DailyDlPage from "./pages/driver/daily-dl-page/daily-dl-page";
import DlPage from "./pages/driver/dl-page/dl-page";
import SignDlPage from "./pages/driver/sign-dl-page/sign-dl-page";
import CreateEmptyDl from "./pages/driver/create-empty-dl/create-empty-dl";
import DelayListPage from "./pages/driver/delay-list-page/delay-list-page";
import SignedDl from "./pages/driver/signed-dl/signed-dl";
import DlDetailsDriver from "./pages/driver/dl-details-driver/dl-details-driver";
import ModifyServiceNumber from "./pages/driver/modify-service-number/modify-service-number";
import ModifySheetLineNumber from "./pages/driver/modify-sheet-line-number/modify-sheet-line-number";
import DlScheduleDocumentPage from "./pages/driver/dl-schedule-document-page/dl-schedule-document-page";
import DlScheduleDocumentListPage from "./pages/driver/dl-shedule-document-list-page/dl-schedule-document-list-page";
import ReturnedDlListPage from "./pages/driver/returned-dl-list-page/returned-dl-list-page";
import DlListPage from "./pages/driver/dl-list-page/dl-list-page";

// Pages Supervisor
import SupervisorDriveLogList from "./pages/supervisor/supervisor-drive-log-list/supervisor-drive-log-list";
import ValidatedDriveLogsPage from "./pages/supervisor/validated-drive-logs-page/validated-drive-logs-page";
import DlDetailsSupervisor from "./pages/supervisor/dl-details-supervisor/dl-details-supervisor";

// Pages HR
import ArchivedDlPage from "./pages/hr/archived-dl-page/archived-dl-page";
import DlToBeArchivedPage from "./pages/hr/dl-to-be-archived-page/dl-to-be-archived";

// Pages Consultant
import DlListReadOnly from "./pages/consultant/dl-list-read-only/dl-list-read-only";

i18next.addResourceBundle("fr", "dl", i18nDriveLog);

export {
	BeyondSixHourThirty,
	EndOfServiceDelayPage,
	RestShortenedPage,
	ModifyEndOfServiceDelayPage,
	DiffServicePlannedPerformedDelayPage,
	OtherTcDelayPage,
	ArchivedDlPage,
	DlToBeArchivedPage,
	DlListReadOnly,
	DailyDlPage,
	DlPage,
	SignDlPage,
	DriveLogCard,
	DriveLogErrorMessage,
	DriveLogSubHeader,
	CreateEmptyDl,
	Schedule,
	AddSheetLine,
	ServiceNumber,
	AttachmentList,
	DelayListPage,
	CommentWithFileAttachment,
	SignedDl,
	SupervisorDriveLogList,
	ValidatedDriveLogsPage,
	DlDetailsDriver,
	DlDetailsSupervisor,
	ServiceInfos,
	ModifyServiceNumber,
	MissionListAllObs,
	FooterDlDetails,
	ModifySheetLineNumber,
	DlScheduleDocumentPage,
	DlScheduleDocumentListPage,
	DlListPage,
	ReturnedDlListPage,
	DlLineSheetPage
};
