import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./sheet-line-number-cell.scss";

const SheetLineNumberCell = (props) => {
	const { driveLog, header = false } = props;
	const { t } = useTranslation("dl");

	const getSheetLineNumber = (dl) => {
		if ( dl?.sheet_line_number ) {
			return (
				<div className="sheet-line-number">
					{ dl?.sheet_line_number !== "-1" ? dl?.sheet_line_number : "PV" }
				</div>
			);
		}
	};

	const buildHeader = () => (
		<div className="sheet-line-number">
			{ t("dl-table.headers.sheet-line") }
		</div>
	);

	const buildCell = () => (
		<div className="sheet-line-number-container">
			{ getSheetLineNumber(driveLog) }
		</div>
	);

	return ( header ? buildHeader() : buildCell() );
};

SheetLineNumberCell.propTypes = {
	driveLog: PropTypes.object,
	header: PropTypes.bool
};

export default SheetLineNumberCell;
