import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DriveLogCard from "../../../../../components/drive-log-card/drive-log-card";
import "./schedule-document.list.scss";

const ScheduleDocumentList = (props) => {
	const { scheduleDocuments, attachment, selectedScheduleDocument, setSelectedScheduleDocument } = props;
	const { t } = useTranslation();

	const generateRows = (scheduleDocument) => (
		<li key={scheduleDocument.service}>
			<DriveLogCard
				isSelected ={selectedScheduleDocument?.service === scheduleDocument?.service}
				action = {() => setSelectedScheduleDocument(scheduleDocument)}
				iconName = "pdf"
				textContent={t("dl:schedule-document-table.attachment-service", { attachment: attachment?.name, service: scheduleDocument.service })}
				cta ={t("dl:schedule-document-table.select")}/>
		</li>
	);

	return (
		<ul className="sd-table">
			{Array.isArray(scheduleDocuments) && scheduleDocuments?.length > 0 && scheduleDocuments.map(generateRows)}
		</ul>
	);
};

ScheduleDocumentList.propTypes = {
	"scheduleDocuments": PropTypes.array,
	"attachment": PropTypes.object
};

export default ScheduleDocumentList;
