import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader, PopupFullPage } from "../../../core";
import SurveyForm from "../../components/survey-form/survey-form";
import { getSurveyById, updateSurvey } from "../../survey.services";
import "./edit-survey.scss";
import SurveyHeader from "../../components/survey-form/survey-header/survey-header";

/**
 * EditSurvey component
 * @returns {JSX.Element}
 */
const EditSurvey = () => {
	const { driveLogId, surveyId } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const [survey, setSurvey] = useState({});
	const [loading, setLoading] = useState(true);

	const retrieveSurveyInfo = useCallback(() => {
		getSurveyById(driveLogId, surveyId).then(res => {
			setSurvey(res?.data);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			setLoading(false);
		});
	}, [driveLogId, surveyId]);

	//update the survey and redirect to the drivelog before signature
	const handleUpdateSurvey = (config = {}) => {
		updateSurvey(config).then(() => {
			history.push(`/drive-log/${driveLogId}/sign`);
		}).catch((err) => console.error(err));
	};


	useEffect(retrieveSurveyInfo, [retrieveSurveyInfo]);

	return (
		<PopupFullPage title={t("survey:survey.title")} backLink={`/drive-log/${driveLogId}/sign`} >
			<Loader isLoading={loading}>
				<SurveyHeader observationType={survey.observation_type} observationContent={survey.observation_content} missionCode={survey.mission_code}/>
				<SurveyForm surveyToEdit={survey}
					submitForm={handleUpdateSurvey}
					handleCancelForm={() => history.goBack()}
					action="back"/>
			</Loader>
		</PopupFullPage>
	);
};

export default EditSurvey;
