import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import "./drive-log-table-headers.scss";
import ServiceStartCell from "../columns/service-start-cell/service-start-cell";
import ServiceNumberCell from "../columns/service-number-cell/service-number-cell";
import IdentityCell from "../columns/identity-cell/identity-cell";
import SheetLineNumberCell from "../columns/sheet-line-number-cell/sheet-line-number-cell";
import HoursCell from "../columns/hours-cell/hours-cell";
import CommentCell from "../columns/comment-cell/comment-cell";
import StateCell from "../columns/state-cell/state-cell";
import AttachmentCell from "../columns/attachment-cell/attachment-cell";
import DelaysCells from "../columns/delays-cells/delays-cells";

const DriveLogTableHeaders = (props) => {
	const { className = "", driverView = false, consultantView = false, ampValidatedView = false, hrToArchiveView = false, hrArchivedView = false ,
		displayDriveLogStatus = false } = props;

	const buildClassName = () => {
		const driverViewClass = (driverView) ? "drive-log-row--driver-view" : "";
		const consultantViewClass = (consultantView) ? "drive-log-row--consultant-view" : "";
		const ampValidatedViewClass = (ampValidatedView) ? "drive-log-row--amp-validated-view" : "";
		const hrToArchiveViewClass = (hrToArchiveView) ? "drive-log-row--hr-to-archive-view" : "";
		const hrArchivedViewClass = (hrArchivedView) ? "drive-log-row--hr-archived-view" : "";
		return `drive-log-row drive-log-row--headers ${className} ${driverViewClass} ${consultantViewClass} ${ampValidatedViewClass}
		 ${hrToArchiveViewClass} ${hrArchivedViewClass}`;
	};

	const generateCells = () => {
		const cellsMapping = {
			driverView: [ServiceStartCell, ServiceNumberCell, SheetLineNumberCell, HoursCell, CommentCell, StateCell],
			ampView: [ServiceStartCell, ServiceNumberCell, SheetLineNumberCell, HoursCell, IdentityCell, StateCell],
			hrToArchiveView : [ServiceStartCell, ServiceNumberCell, AttachmentCell, IdentityCell, DelaysCells],
			hrArchivedView: [ServiceStartCell, ServiceNumberCell, AttachmentCell, IdentityCell, DelaysCells, StateCell]
		};

		if (hrToArchiveView || hrArchivedView ) {
			const headerCells = hrArchivedView ? cellsMapping.hrArchivedView : cellsMapping.hrToArchiveView;
			return (
				<>
					{
						headerCells.map(cell => React.createElement(cell,
							{
								...props,
								header: true,
								key: uuid()
							})
						)
					}
				</>
			);
		}

		return (
			<>
				<ServiceStartCell header />
				<ServiceNumberCell header />
				<SheetLineNumberCell header />
				{ !driverView && <IdentityCell header /> }
				<HoursCell header />
				<CommentCell header />
				{ (driverView || displayDriveLogStatus) && <StateCell header /> }
			</>
		);
	};


	return (
		<li className={buildClassName()}>
			{generateCells()}
		</li>
	);
};

DriveLogTableHeaders.propTypes = {
	"className": PropTypes.string,
	"driverView": PropTypes.bool,
	"consultantView": PropTypes.bool,
	"ampValidatedView": PropTypes.bool,
	"hrToArchiveView" : PropTypes.bool,
	"hrArchivedView" : PropTypes.bool,
	"displayDriveLogStatus": PropTypes.bool
};

export default DriveLogTableHeaders;
