import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import UpdateDelayModal from "../update-delay-modal/update-delay-modal";

/*
	Modal to display when the 'Modify' button is clicked by the supervisor on delays-list
	The supervisor is routed to the drive-logs list page when
	the button "Valider le bulletin" is clicked
 */
const EndOfServiceModal = ({
	showModal,
	setShowModal,
	driveLog,
	currentDriverField,
	delays,
	ampDelays,
	setAmpDelays,
	setDlToUpdate,
	dlToUpdate }) => {

	const [currentDriverDelay, setCurrentDriverDelay] = useState(0);
	const [newSupervisorDelay, setNewSupervisorDelay] = useState(0);
	const [hasErrorMaxDelay, setHasErrorMaxDelay] = useState(false);
	const [hasErrorEOSDelay, setHasErrorEOSDelay] = useState(false);
	const [fieldsToUpdate, setFieldsToUpdate] = useState({});

	const closeCallback = () => {
		setShowModal(false);
		setNewSupervisorDelay(ampDelays.endOfServiceDelay);
		setHasErrorMaxDelay(false);
		setHasErrorEOSDelay(false);
	};


	const handleDelayChange = (newDelay) => {
		if (newDelay === "") {
			setNewSupervisorDelay(newDelay);
		} else {
			//parse & calculate valid delay
			const castedNewDelay = parseInt(newDelay, 10);
			const castedNewDelayValid = castedNewDelay === 0 || (castedNewDelay && castedNewDelay <= 1440);
			setHasErrorMaxDelay(!castedNewDelayValid);

			//specific rules for end of service ("eos") delay update
			const isEosDelayDecreased = newDelay < currentDriverDelay;
			setHasErrorEOSDelay(!isEosDelayDecreased);

			const { daily_legal_time_exceed, rest_time_reduction, diff_service_planned_performed_time, other_tc_time } = driveLog;

			if (isEosDelayDecreased) {

				const delayDifference = currentDriverDelay - newDelay;

				const superiorToTenMinutes = delays.superiorToTenMinutes - delayDifference > 0 ? delays.superiorToTenMinutes - delayDifference : 0;
				const superiorToSixHoursThirty = daily_legal_time_exceed - delayDifference > 0 ? daily_legal_time_exceed - delayDifference : 0;
				const restTimeReduction = rest_time_reduction - delayDifference > 0 ? rest_time_reduction - delayDifference : 0;
				const servicePlannedPerformed = diff_service_planned_performed_time - delayDifference > 0 ? diff_service_planned_performed_time - delayDifference : 0;

				const computedAMPDelays = {
					amp_end_of_service_delay: castedNewDelay,
					amp_daily_legal_time_exceed: superiorToSixHoursThirty,
					amp_rest_time_reduction : restTimeReduction,
					amp_diff_service_planned_performed_time: servicePlannedPerformed,
					amp_other_tc_time: other_tc_time,
					amp_total_additional_time : castedNewDelay + superiorToTenMinutes +
						superiorToSixHoursThirty + restTimeReduction + diff_service_planned_performed_time + other_tc_time
				};
				setNewSupervisorDelay(castedNewDelay);
				setFieldsToUpdate(computedAMPDelays);
			} else {
				// if eos delay is increased recalculate ONLY superiorToTenMinutes delay and total:
				// superiorToSixHoursThirty and restTimeReduction stay with originals delays values.
				const newSupToTenMinutesDelay =  castedNewDelay > 10 ? castedNewDelay - 10 : 0 ;
				const ampTotal = ()  => castedNewDelay + newSupToTenMinutesDelay + delays.superiorToSixHoursThirty +
					delays.restTimeReduction + diff_service_planned_performed_time + other_tc_time ;
				const computedAMPDelays = {
					amp_end_of_service_delay: castedNewDelay,
					amp_daily_legal_time_exceed: delays.superiorToSixHoursThirty,
					amp_rest_time_reduction : delays.restTimeReduction,
					amp_diff_service_planned_performed_time: diff_service_planned_performed_time,
					amp_other_tc_time: other_tc_time,
					amp_total_additional_time : ampTotal()
				};
				setNewSupervisorDelay(castedNewDelay);
				setFieldsToUpdate(computedAMPDelays);
			}
		}
	};

	const handleValidate = () => {
		setDlToUpdate({ ...dlToUpdate, ...fieldsToUpdate });
		setAmpDelays({
			endOfServiceDelay: fieldsToUpdate.amp_end_of_service_delay,
			superiorToTenMinutes: fieldsToUpdate.amp_end_of_service_delay > 10 ? fieldsToUpdate.amp_end_of_service_delay - 10 : 0,
			superiorToSixHoursThirty: fieldsToUpdate.amp_daily_legal_time_exceed,
			restTimeReduction: fieldsToUpdate.amp_rest_time_reduction,
			otherTcTime: fieldsToUpdate.amp_other_tc_time,
			diffServicePlannedPerformedTime: fieldsToUpdate.amp_diff_service_planned_performed_time,
			total: fieldsToUpdate.amp_total_additional_time
		});
		setShowModal(false);
	};

	useEffect( () => {
		if (driveLog) {
			setCurrentDriverDelay(delays.endOfServiceDelay);
			setNewSupervisorDelay(ampDelays.endOfServiceDelay);
		}
	}, [setCurrentDriverDelay, setNewSupervisorDelay, driveLog, delays, ampDelays]);

	return (
		<UpdateDelayModal
			showModal={showModal}
			closeCallback={closeCallback}
			newSupervisorDelay={newSupervisorDelay}
			currentDriverField={currentDriverField}
			handleDelayChange={handleDelayChange}
			hasErrorMaxDelay={hasErrorMaxDelay}
			hasErrorEOSDelay={hasErrorEOSDelay}
			handleValidate={handleValidate}
		/>
	);
};

EndOfServiceModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	driveLog: PropTypes.object,
	currentDriverField: PropTypes.string,
	ampDelays: PropTypes.object,
	delays: PropTypes.object,
	setAmpDelays: PropTypes.func,
	setDlToUpdate: PropTypes.func,
	dlToUpdate: PropTypes.object
};

export default EndOfServiceModal;
