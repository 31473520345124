import { useContext, useEffect } from "react";
import { useAppEnv } from "../../../config";
import { AuthContext } from "../../user/auth/context/auth-context";
import { GlobalAppContext } from "../global-app/global-app-context";
import { hash } from "../utils/hash/hash";

const useMatomoTracker = () => {
	const { MATOMO_URL = "", MATOMO_SITE_ID = "" } = useAppEnv();
	const { trackingEnabled = false } = useContext(GlobalAppContext);
	const { user = {}, userId } = useContext(AuthContext);
	const { line, attachment, role } = user;
	const customDimensions = { dimension1: line, dimension2: attachment, dimension3: role };
	
	const config = {
		siteId: MATOMO_SITE_ID,
		trackerUrl: `${MATOMO_URL}/matomo.php`,
		srcUrl: `${MATOMO_URL}/matomo.js`,
		disabled: !trackingEnabled
	};
	
	window._paq = window["_paq"] || [];
	
	/**
	 * Add data to Matomo tracker
	*/
	const addTrackingData = (args) => {
		return window._paq.push([...args]);
	};
	
	/**
	 * Add a user ID to track the number of unique logins
	*/
	const setUserId = () => {
		addTrackingData(["setUserId", hash(userId)]);
	};

	useEffect(setUserId, ([userId]));
	
	/**
	 * Initialize Matomo tracker
	 */
	const initMatomoTracker = () => {
		const isConfigReady = MATOMO_URL !== "" && MATOMO_SITE_ID !== "";

		if (isConfigReady) {
			addTrackingData(["setTrackerUrl", config.trackerUrl]);
			addTrackingData(["setSiteId", config.siteId]);
			if (config.disabled) {
				addTrackingData(["disableCookies"]);
			}
			addTrackingData(["trackPageView"]);

			const matomoScript = document.createElement("script");
			matomoScript.async = true;
			matomoScript.src = config.srcUrl;

			const initialScript = document.getElementsByTagName("script")[0];
			initialScript?.parentNode?.insertBefore(matomoScript, initialScript);
		}
	};

	/**
	 * Track Matomo Event
	 */
	const trackEvent = ({ category, action, name, value }) => {
		addTrackingData(["trackEvent", category, action, name, value, customDimensions]);
	};

	return {
		initMatomoTracker,
		trackEvent
	};
};

export default useMatomoTracker;
