import { validateDelay, validateReason } from "../../components/observation-form/observation-validators";

const retention = [
	{
		fieldName: "station",
		mandatory: true
	},
	{
		fieldName: "duration",
		mandatory: true,
		validator: validateDelay
	},
	{
		fieldName: "reason",
		validator: validateReason
	}
];

export default retention;
