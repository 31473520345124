/**
 * validate comment
 * @param comment
 * @return {boolean}
 */
const validateConsistencyServiceComment = (comment) => {
	return comment?.length < 500;
};

/**
 * validate dropdown reason
 * @param reason
 * @returns {boolean}
 */
const validateDropdownReason = (reason) => {
	const reasonChoices =  ["paperDlDelivered", "deletedService", "others"];
	return reasonChoices.includes(reason);
};

export { validateConsistencyServiceComment, validateDropdownReason };
