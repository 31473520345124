import i18next from "../../config/i18next";
import i18nExport from "./i18n/fr.json";

// Components
import ExportAttachmentLineSelector from "./components/export-attachment-picker/export-attachment-line-selector/export-attachment-line-selector";
import ExportAttachmentPicker from "./components/export-attachment-picker/export-attachment-picker";
import ExportDatePicker from "./components/export-date-picker/export-date-picker";
import ExportForm from "./components/export-form";
import ExportListContainer from "./components/export-list-container/export-list-container";
import ExportResourcePicker from "./components/export-resource-picker/export-resource-picker";
import ExportSectionTitle from "./components/export-section-title/export-section-title";

// Page
import ExportPage from "./pages/export-page";

export {
	ExportAttachmentLineSelector,
	ExportAttachmentPicker,
	ExportDatePicker,
	ExportForm,
	ExportPage,
	ExportListContainer,
	ExportResourcePicker,
	ExportSectionTitle
};

i18next.addResourceBundle("fr", "exp", i18nExport);
