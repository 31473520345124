import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Icon, ResponsiveText } from "../../../../../../../../core";
import DeleteMissionModal from "../modals/delete-mission-modal/delete-mission-modal";
import DeleteMissionWithObservationsModal from "../modals/delete-mission-with-observations/delete-mission-with-observations";
import { deleteMission } from "../../../../../../../../mission/mission.services";
import { getAllObservationsInDl } from "../../../../../../../../observation/observation.services";
import "./dl-delete-mission.scss";

/**
 * Renders delete mission button and popup confirmation
 * @param {Object} props
 * @param {String} props.className
 * @param {String} props.driveLogId
 * @param {String} props.missionCode
 * @param {String} props.updateDriveLogMissions
 */
const DlDeleteMission = (props) => {
	const { className="", updateDriveLogMissions = () => {}, driveLogId = "", missionCode = "", missionId = "" } = props;

	const { t } = useTranslation();

	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [showDeletePopupWithObservations, setShowDeletePopupWithObservations] = useState(false);
	const [missionToDelete, setMissionToDelete] = useState();
	const [missionWithObservations, setMissionWithObservations] = useState();
	const [observations, setObservations] = useState([]);

	const checkObservationsInMission = useCallback(() => {
		const missionObservations = observations?.filter(obs => obs.mission_id === missionId);
		setMissionWithObservations(missionObservations?.length > 0);
	}, [missionId, observations]);

	useEffect(checkObservationsInMission, [missionId, checkObservationsInMission]);

	const handleShowDeletePopup = (id, code) => {
		if (missionWithObservations) {
			setShowDeletePopupWithObservations(true);
		} else {
			setShowDeletePopup(true);
		}
		setMissionToDelete({ id, code });
	};

	useEffect(() => {
		getAllObservationsInDl(driveLogId).then(payload => {
			setObservations(payload?.data);
		});
	},[driveLogId]);

	const handleDeleteMission = () => {
		deleteMission(missionToDelete.id, driveLogId)
			.then(() => {
				setShowDeletePopup(false);
				updateDriveLogMissions();
			})
			.catch(err => console.error(err));
	};

	const handleCancelDeleteMission = () => {
		setShowDeletePopup(false);
		setMissionToDelete(undefined);
	};

	return (
		<div className={`dl-mission-list__mission__delete ${className}`}>
			<Button onClick={() => handleShowDeletePopup(missionId, missionCode)} className="dl-mission-list__mission__delete__btn">
				<Icon name="close" className="dl-mission-list__mission__delete__icon" />
				<ResponsiveText text="" mobileText={t("dl:dl-detail-page.delete")} className="dl-mission-list__mission__delete__text"/>
			</Button>

			<DeleteMissionModal showModal={showDeletePopup}
				setShowModal={setShowDeletePopup}
				handleDeleteMission={handleDeleteMission}
				handleCancelDeleteMission={handleCancelDeleteMission}
			/>
			<DeleteMissionWithObservationsModal
				showModal={showDeletePopupWithObservations}
				setShowModal={setShowDeletePopupWithObservations}
			/>
		</div>
	);
};

DlDeleteMission.propTypes = {
	className : PropTypes.string,
	updateDriveLogMissions : PropTypes.func,
	driveLogId : PropTypes.string,
	missionCode : PropTypes.string,
	missionId : PropTypes.string
};

export default DlDeleteMission;
