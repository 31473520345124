import { isSameDay, differenceInCalendarYears, differenceInDays, isBefore } from "date-fns";

const DAYS_IN_ONE_YEAR = 365;
const MAX_NUM_YEARS = 5;
const CURRENT_DAY = new Date();

/**
 * Validates that the number of years between the selected date and the current day is less than 5 years
 * @param {String} selectedDate - ISO date string
 * @returns Boolean
 */
const validateYearsRange = (selectedDate) => differenceInCalendarYears(CURRENT_DAY, selectedDate) < MAX_NUM_YEARS;

/**
 * Validates to the day that the date frame is lesser or equal to one year
 * @param {String} startDate - ISO date string
 * @param {String} endDate - ISO date string
 * @returns Boolean
 */
const verifyDaysRange = (startDate, endDate) => differenceInDays(endDate, startDate) <= DAYS_IN_ONE_YEAR;

/**
 * Validates the selected start date
 * @param {String} startDateSelected - ISO date string
 * @param {Sring} endDateSelected - ISO date string
 * @returns Boolean
 */
const validateStartDate = (startDateSelected, endDateSelected) => {
	const isStartDateInRange = validateYearsRange(startDateSelected);
	const isStartDateBeforeEndDate = isBefore(startDateSelected, endDateSelected);
	const isStartDateEqualEndDate = isSameDay(startDateSelected, endDateSelected);
	return isStartDateInRange && (isStartDateBeforeEndDate || isStartDateEqualEndDate);
};

/**
 * Validates the selected end date
 * @param {String} startDateSelected - ISO date string
 * @param {String} endDateSelected - ISO date string
 * @returns Boolean
 */
const validateEndDate = (startDateSelected, endDateSelected) => {
	const isEndDateBeforeCurrentDay = isBefore(endDateSelected, CURRENT_DAY);
	const isEndDateEqualCurrentDay = isSameDay(endDateSelected, CURRENT_DAY);
	const isStartDateEqualEndDate = isSameDay(startDateSelected, endDateSelected);
	const isEndDateBeforeStartDate = !isBefore(endDateSelected, startDateSelected);
	return (isEndDateBeforeStartDate || isStartDateEqualEndDate) && (isEndDateBeforeCurrentDay || isEndDateEqualCurrentDay);
};

export {
	validateStartDate,
	validateEndDate,
	verifyDaysRange
};
