import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader, PopupFullPage } from "../../../core";
import SurveyForm from "../../components/survey-form/survey-form";
import { getSurveyById } from "../../survey.services";
import SurveyHeader from "../../components/survey-form/survey-header/survey-header";

const ReadSurvey = () => {
	const { driveLogId, surveyId } = useParams();

	const [survey, setSurvey] = useState({});
	const [loading, setLoading] = useState(true);

	const history = useHistory();
	const { t } = useTranslation();

	const getBacklink = () => {
		return `/drive-log/${driveLogId}/details`;
	};

	const retrieveSurveyInfo = useCallback(() => {
		getSurveyById(driveLogId, surveyId).then(res => {
			setSurvey(res?.data);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			setLoading(false);
		});
	}, [driveLogId, surveyId]);

	useEffect(retrieveSurveyInfo, [retrieveSurveyInfo]);

	return (
		<PopupFullPage title={t("survey:survey.title")} backLink={getBacklink} >
			<Loader isLoading={loading}>
				<SurveyHeader  observationType={survey.observation_type} observationContent={survey.observation_content} missionCode={survey.mission_code}/>
				<SurveyForm
					handleCancelForm={() => history.goBack()}
					readOnly
					action="back-to-drivelog"
					surveyToEdit={survey} />
			</Loader>
		</PopupFullPage>
	);
};

export default ReadSurvey;
