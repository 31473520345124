import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AddSheetLine } from "../../../index";
import { updateDriveLog } from "../../../drive-log.services";

const ModifySheetLineNumber = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;

	const { t } = useTranslation();
	const history = useHistory();
	const { id: driveLogId } = useParams();

	const redirectionPage = history.location?.state?.from ?? `${redirectUrl}/${driveLogId}`;

	const handleUpdateDL = ({ sheetLineNumber }) => {
		//TODO refactor to delete action parameter

		updateDriveLog( { id: driveLogId, sheet_line_number: sheetLineNumber }, { action: "add-sheet-line" })
			.then(() => {
				history.push(redirectionPage);
			}).catch(err => {
				console.error(err);
			});
	};

	return (
		<>
			<AddSheetLine navigate={handleUpdateDL} modifyDlTitle={t("dl:add-sheet-line-page.modify-sheet-line")} modifyDl />
		</>
	);
};

export default ModifySheetLineNumber;
