import React, { useContext, useEffect, useState } from "react";
import { Button, Loader, PopupFullPage } from "../../../../core";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { getLineSheet } from "../../../sheets-line.service";
import { getDriveLogById, updateDriveLog } from "../../../drive-log.services";
import { useAppEnv } from "../../../../../config";
import { GlobalAppContext } from "../../../../core/global-app/global-app-context";
import { APP_NAME } from "../../../../core/global-app/global-app-constant";
import EmptySplash from "../../../../core/assets/images/empty-splash.svg";
import Icon from "../../../../core/components/icon-svg/icon";
import DriveLogCard from "../../../components/drive-log-card/drive-log-card";
import classNames from "classnames";
import { getTodayDisabledLineSheet } from "../../../disable-sheets-line.service";
import AddSheetLine from "../../../components/add-sheet-line/add-sheet-line";

import "./dl-line-sheet-page.scss";

const DlLineSheetPage = (props) => {
	const env = useAppEnv();
	const { fromApp, setFromApp } = useContext(GlobalAppContext);
	const location = useLocation();
	const { t } = useTranslation();
	const queryParams = queryString.parse(location.search);
	const { status } = props.params;
	const { driveLogId, fromApplication } = queryParams;
	const history = useHistory();

	// states declaration
	const [publishedLineSheetUrl, setPublishedLineSheetUrl] = useState();
	const [comingLineSheetUrl, setComingLineSheetUrl] = useState();
	const [publishedLineSheetName, setPublishedLineSheetName] = useState();
	const [comingLineSheetName, setComingLineSheetName] = useState();
	const [disabledPublishedSheet, setDisabledPublishedSheet] = useState(false);
	const [selectedTab, setSelectedTab] = useState("published");
	const [isLoading, setIsLoading] = useState(true);
	const [line, setLine] = useState();
	const [isMobileDevice, setIsMobileDevice] = useState(false);
	const [disabled, setDisabled] = useState(true);

	// defines the text of the backButton based on the fromApp's value
	const backButtonText = fromApp === APP_NAME.DIGIPLAN ? "back-to-driving" : "back-to-daily-dl";

	const pageTitle = status === "all" ? "line-sheets" : "my-line-sheet";
	const lineSheetUrl = selectedTab === "published" ? publishedLineSheetUrl : comingLineSheetUrl;
	const lineSheetName = selectedTab === "published" ? publishedLineSheetName : comingLineSheetName;
	const publishedLineSheetClass = classNames("line-sheet-page__tab__button", { "line-sheet-page__tab__button--selected": selectedTab === "published" });
	const comingLineSheetClass = classNames("line-sheet-page__tab__button", { "line-sheet-page__tab__button--selected": selectedTab === "coming" });
	const displayAddSheetLine = disabledPublishedSheet && selectedTab === "published";

	const retrieveDisabledLineSheets = () => {
		getTodayDisabledLineSheet(line).then(res => {
			if (res?.data.length > 0) {
				setDisabledPublishedSheet(true);
			}
		}).catch(err => {
			console.error(err);
		});
	};

	const handleTabChange = (tab) => {
		setSelectedTab(tab);
	};

	const displayActionButton = () => {
		if (status === "all" && fromApp === APP_NAME.DIGIPLAN) {
			return (
				<Button className="line-sheet-page__actions__button button" type="button">
					<a href={getBackLink()}>
						{t(`dl:dl-line-sheet-page.${backButtonText}`)}
					</a>
				</Button>
			);
		} else if (status === "all") {
			return (
				<Button className="line-sheet-page__actions__button button" type="button">
					<Link to={getBackLink()}>
						{t(`dl:dl-line-sheet-page.${backButtonText}`)}
					</Link>
				</Button>
			);
		} else if (status !== "all" && !disabledPublishedSheet) {
			return (
				<Button className="line-sheet-page__actions__button button" type="button" disabled={disabled}>
					<Link to={`/drive-log/${driveLogId}`}>
						{t("dl:dl-line-sheet-page.open-daily-dl")}
					</Link>
				</Button>
			);
		}
	};

	const getBackLink = () => {
		if (fromApp === APP_NAME.DIGIPLAN) {
			return `${env?.DIGIPLAN_APP_SCHEME}://Drive`;
		}
		return `/drive-log/${driveLogId}/`;
	};

	const openLineSheet = () => {
		window.open(lineSheetUrl,"_blank");
		setDisabled(false);
	};

	const getDriveLogLine = () => {
		getDriveLogById(driveLogId).then(response => {
			const { data: driveLog } = response || {};
			const { line_number: lineNumber } = driveLog || {};
			setLine(lineNumber);
		});
	};

	const getPublishedAndComingLineSheet = () => {
		getLineSheet({ line, status: "coming" })
			.then(lineSheet => {
				if (lineSheet?.data) {
					const url = URL.createObjectURL(lineSheet?.data);
					setComingLineSheetUrl(url);
					setComingLineSheetName(lineSheet?.fileName);
				}
			});
		getLineSheet({ line, status: "published" })
			.then(lineSheet => {
				if (lineSheet?.data) {
					const url = URL.createObjectURL(lineSheet?.data);
					setPublishedLineSheetUrl(url);
					setPublishedLineSheetName(lineSheet?.fileName);
				}
			}).finally(() => setIsLoading(false));
	};

	const getUserDevice = () => {
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		setIsMobileDevice(isMobile);
	};

	const handleNavigation = ({ sheetLineNumber }) => {
		updateDriveLog({ id: driveLogId, sheet_line_number: sheetLineNumber }, { action: "add-sheet-line" })
			.then(() => {
				history.push(`/drive-log/${driveLogId}`);
			}).catch(err => {
				console.error(err);
			});
	};

	useEffect(getDriveLogLine, [driveLogId]);
	useEffect(getPublishedAndComingLineSheet, [line]);
	useEffect(getUserDevice, []);
	useEffect(retrieveDisabledLineSheets, [line]);

	useEffect(() => {
		setDisabled(status !== "all" && isMobileDevice);
	}, [status, isMobileDevice]);

	useEffect(() => {
		if (fromApplication === APP_NAME.DIGIPLAN) {
			setFromApp(APP_NAME.DIGIPLAN);
		}
	}, [fromApplication, setFromApp]);

	return (
		<PopupFullPage title={t(`dl:dl-line-sheet-page.${pageTitle}`)} backLink={getBackLink()}>
			<div className="line-sheet-page">
				{status === "all" && <div className={"line-sheet-page__tab__button_container"}>
					<Button
						className={publishedLineSheetClass}
						onClick={() => handleTabChange("published")}>
						{t("dl:dl-line-sheet-page.published-line-sheet")}
						{selectedTab === "published" && <Icon name="checkMark" className="line-sheet-page__tab__icon"/>}
					</Button>
					<Button
						className={comingLineSheetClass}
						onClick={() => handleTabChange("coming")}>
						{t("dl:dl-line-sheet-page.coming-line-sheet")}
						{selectedTab === "coming" && <Icon name="checkMark" className="line-sheet-page__tab__icon"/>}
					</Button>
				</div>}
				<Loader isLoading={isLoading}>
					{!displayAddSheetLine && <>
						{lineSheetUrl && !isMobileDevice && <embed className={"line-sheet-page__content"} src={lineSheetUrl} type="application/pdf"/>}
						{lineSheetUrl && isMobileDevice && <div className="line-sheet-page__mobile-content">
							<h1 className="line-sheet-page__mobile-content__title">{t("dl:dl-line-sheet-page.title")}</h1>
							<span className="line-sheet-page__mobile-content__subtitle">{t("dl:dl-line-sheet-page.subtitle")}</span>
							<DriveLogCard
								action={() => openLineSheet()}
								iconName="pdf"
								textContent={t("dl:dl-line-sheet-page.line-sheet-number", { name: lineSheetName })}
								cta={t("dl:dl-line-sheet-page.read")}/>
						</div>}
						{!lineSheetUrl && <div className={"line-sheet-page__content__no-result"}>
							<h1 className="no-result__title"> {t(`dl:dl-line-sheet-page.no-${selectedTab}-line-sheet`)} </h1>
							<div className="no-result__searching-mouse-img" style={{ backgroundImage: `url(${EmptySplash})` }}/>
						</div>}
					</>}
					{displayAddSheetLine && <AddSheetLine navigate={handleNavigation} disabledLineSheet/>}
				</Loader>
				<div className="line-sheet-page__actions">
					{displayActionButton()}
				</div>
			</div>
		</PopupFullPage>
	);
};

export default DlLineSheetPage;
