import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Icon from "../icon-svg/icon";
import useOnClickOutside from "../../custom-hooks/use-on-click-outside";
import "./search-bar.scss";

/**
 * Search drive log component
 * @param props
 * @param {function} props.handleSearch
 * @return {JSX.Element}
 * @constructor
 */

const SearchBar = (props) => {
	const { handleSearch, handleReset, columnsToSearch, className, filter = {} } = props;
	const { t } = useTranslation();
	const columnsDropDown = useRef();
	const { search: searchValue = "", searchOptions: searchColumn } = filter;

	const [selectedColumnToSearch, setSelectedColumnToSearch] = useState(columnsToSearch[0]);
	const [showColumnToSearch, setShowColumnToSearch] = useState(false);
	const [search, setSearch] = useState("");

	useEffect(() => {
		setSearch(searchValue);
		setSelectedColumnToSearch(searchColumn || columnsToSearch[0]);
	}, [columnsToSearch, searchValue, searchColumn]);

	useOnClickOutside(columnsDropDown, () => setShowColumnToSearch(false));

	const handleSelectColumnToSearch = (column) => {
		setSelectedColumnToSearch(column);
		setShowColumnToSearch(false);
	};

	const getColumnToSearchDropDown = () => {
		const filterSelectedColumnToSearch = columnsToSearch.filter(column => column !== selectedColumnToSearch);

		return filterSelectedColumnToSearch.map(column => (
			<div key={column} className="search-bar__columns__dropdown__option" role="button" onClick={() => handleSelectColumnToSearch(column)}>
				{t(`dl:supervisor-dl-list-page.search.columns.${column}`)}
			</div>
		));
	};

	const handleSearchChange = (e) => {
		setSearch(e.target.value);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleSearch(search, selectedColumnToSearch);
		}
	};

	const handleResetSearch = () => {
		setSearch("");
		setSelectedColumnToSearch(columnsToSearch[0]);
		handleReset();
	};

	return (
		<div className={`${className} search-bar`}>
			<div className={`search-bar__field ${showColumnToSearch ? "search-bar__field--open" : ""}`}>
				<button type="button" className="search-bar__columns" onClick={() => setShowColumnToSearch(!showColumnToSearch)}>
					<Icon className="search-bar__columns__search" name="search"/>
					<div className="search-bar__columns__selected">
						{t(`dl:supervisor-dl-list-page.search.columns.${selectedColumnToSearch}`)}
					</div>
					<Icon name="arrow-right" className="search-bar__columns__arrow"/>
					{showColumnToSearch && (
						<div className="search-bar__columns__dropdown" ref={columnsDropDown}>
							{getColumnToSearchDropDown()}
						</div>
					)}
				</button>
				<div className="search-bar__input">
					<input
						aria-label={t("dl:supervisor-dl-list-page.search.input.placeholder")}
						placeholder={t("dl:supervisor-dl-list-page.search.input.placeholder")}
						name={t("dl:supervisor-dl-list-page.search.input.placeholder")}
						type="text"
						className="search-bar__input__input"
						value={search}
						onChange={handleSearchChange}
						onKeyPress={handleKeyPress}
					/>
					{search && <Icon name="close" className="search-bar__columns__arrow" onClick={() => handleResetSearch()}/>}
				</div>
			</div>
		</div>
	);
};

SearchBar.propTypes = {
	handleSearch: PropTypes.func.isRequired,
	columnsToSearch: PropTypes.array,
	className: PropTypes.string
};

export default SearchBar;
