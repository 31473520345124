import { useState, useEffect } from "react";
import { getDriveLogs } from "../../drive-log/drive-log.services";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getStoredFilter } from "../utils/stored-filter/get-stored-filter";

/**
 * Query Config for drive log List
 * @typedef {Object} DriveLogListConfig
 * @property  {Array} [status] - the status or statuses to search for ( archived / signed / ... )
 * @property  {string} [type] - the type of drivelog to get ( simple or complex)
 * @property  {string} [attachmentCode] - the attachmentCode to filter on (RUEC for ex) the request will only load drivelog for this attachmentCode
 * @property  {string} [attachmentsNamesList] - the attachmentsNamesList to filter on (RUEC an TORC for ex) the request will only load drivelog for these attachmentCode
 * @property  {string} [dateSortOrder] - the order to sort the drivelogs in (asc or desc). asc by default
 * @property  {number} [numberResults] - number of initial results to load
 * @property  {Array} [mandatoryForRequest] - mandatory arguments to be waited for before sending a request
 * @property  {Boolean} [onlyMe] - used if only the current user drivelogs should be loaded
 * @property  {string} [originView] - the view from where the user is reading the drive logs
 * @property  {Array} [sortBy] - sort drivelogs by criteria
 */

/**
 * Generic features around drive log list management
 * @param {DriveLogListConfig} [config] - config for the hook
 */
const useDrivelogList = (config) => {
	const { status, type, attachmentCode, mandatoryForRequest, dateSortOrder = "asc", onlyMe, originView, sortBy, attachmentsNamesList, storageName,
		columnsToSearch, restrictedLine } = config;
	const [isLoading, setIsLoading] = useState(false);
	const [driveLogs, setDriveLogs] = useState([]);
	const [totalResultCount, setTotalResultCount] = useState(0);
	const [numberResults, setNumberResults] = useState(config.numberResults || 40);
	const [searchDriveLogs, setSearchDriveLogs] = useState({ search: "", searchOptions: "", searchDate: "" });
	const { search, searchOptions, searchDate } = searchDriveLogs;

	const sendRequest = (requestConfig) => {
		setIsLoading(true);
		getDriveLogs(requestConfig).then((response) => {
			const payload = response.data;
			setDriveLogs(payload.data);
			setTotalResultCount(payload.meta.totalCount);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			clearLoadingTimer();
		});
	};

	const loadDriveLogs = async () => {
		const requestConfig = { type, status, attachmentCode, numberResults, dateSortOrder,
			dateFilter: searchDate, search, searchOptions, onlyMe, sortBy, attachmentsNamesList, restrictedLine, originView };

		if ( mandatoryForRequest ) {
			// Check that all mandatory parameter are loaded, useful for async loaded parameters
			const requestAuthorized = mandatoryForRequest.reduce((requestAuthorizedAcc, mandatoryParameter) => {
				if (!requestConfig[mandatoryParameter]) {
					return false;
				}
				return requestAuthorizedAcc;
			}, true);
			if (requestAuthorized) {
				await sendRequest(requestConfig);
			}
		} else {
			await sendRequest(requestConfig);
		}
	};

	const loadMore = (numberToAdd = 40) => {
		setNumberResults(oldNumberResult => oldNumberResult + numberToAdd);
	};

	const handleSearchDriveLogs = (newSearch, newSearchOptions) => {
		setSearchDriveLogs({ search: newSearch, searchOptions: newSearchOptions, searchDate });
		localStorage.setItem(storageName, JSON.stringify({ search: newSearch, searchOptions: newSearchOptions, searchDate, dateStored: new Date() }) );
	};

	const handleResetDriveLogs = () => {
		setSearchDriveLogs({ search: "", searchOptions: columnsToSearch, searchDate });
		if (searchDate) {
			localStorage.setItem(storageName, JSON.stringify({ search: "", searchOptions: "", searchDate, dateStored: new Date() }) );
		} else {
			localStorage.removeItem(storageName);
		}
	};

	const handleDateFilter = (date) => {
		setSearchDriveLogs({ search, searchOptions, searchDate: date });
		localStorage.setItem(storageName, JSON.stringify({ search, searchOptions, searchDate: date, dateStored: new Date() }) );
	};

	useEffect(() => {
		const filter = getStoredFilter(storageName);
		if (filter) {
			setSearchDriveLogs({ search: filter?.search, searchOptions: filter?.searchOptions, searchDate: filter.searchDate });
		}
	}, [storageName]);

	useDeepCompareEffect(loadDriveLogs, [numberResults, status, search, searchOptions, searchDate, type, attachmentCode, attachmentsNamesList]);

	const clearLoadingTimer = () => {
		const timer = setTimeout(() => setIsLoading(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	};

	return {
		isLoading,
		driveLogs,
		loadMore,
		refresh : loadDriveLogs,
		handleResetDriveLogs,
		handleSearchDriveLogs,
		handleDateFilter,
		totalResultCount,
		numberResults,
		filter: searchDriveLogs
	};
};

export default useDrivelogList;
