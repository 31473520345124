import { validateDelay } from "../../components/observation-form/observation-validators";

const triggerAutomaticStop = [
	{
		fieldName: "equipment",
		mandatory: true,
		children: [
			{
				fieldName: "nightTrain",
				mandatory: true,
				parentValue: "night-train",
				parentTranslated: true
			}
		]
	},
	{
		fieldName: "location",
		mandatory: true,
		children: [
			{
				fieldName: "station",
				mandatory: true,
				parentValue: "station",
				parentTranslated: true
			},
			{
				fieldName: "interStationStart",
				mandatory: true,
				parentValue: "inter-station",
				parentTranslated: true
			},
			{
				fieldName: "interStationEnd",
				mandatory: true,
				parentValue: "inter-station",
				parentTranslated: true
			},
			{
				fieldName: "trainStorageChoice",
				mandatory: true,
				parentValue: "train-storage",
				parentTranslated: true
			}
		]
	},
	{
		fieldName: "lane",
		mandatory: true
	},
	{
		fieldName: "pattern",
		mandatory: true,
		children: [
			{
				fieldName: "crossingNFSignal",
				mandatory: true,
				parentValue: "RPS",
				modalTriggerValue: "yes"
			}
		]
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default triggerAutomaticStop;
