import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { InputField, RadioButton, RegexUtils, ValidateUtils } from "../../../../../../core";
import lineCarList from "./line-car.json";
import "./car-selector.scss";

/**
 * @param props
 * @param {string} props.className
 * @param {string} props.value
 * @param {function} props.onChange
 * @param {string} props.name
 * @param {string} props.listType list type
 * @param {boolean} [props.hasInput] has "other" input field
 * @param {string} [props.inputPlaceholder] - input placeholder
 * @param {object} props.fieldSchema
 */
const CarSelector = props => {
	const { className, value, onChange, name, listType, hasInput, inputPlaceholder, fieldSchema } = props;

	const valueNotInList = !lineCarList[listType]?.find(car => car === value);

	const [otherValue, setOtherValue] = useState(valueNotInList ? value : "");

	const handleChange = (newValue) => {
		onChange(newValue);
		setOtherValue("");
	};

	const handleOtherChange = (newValue) => {
		onChange(newValue);
		setOtherValue(newValue);
	};

	const generateItems = itemValue => (
		<RadioButton
			className={`car-selector__item ${listType}`}
			checkedClassName="car-selector__item--selected"
			checked={value === itemValue}
			onChange={() => handleChange(itemValue)}
			name={name}
			labelId={`car-selector-${listType}-${itemValue}`}
			key={`car-selector-${itemValue}`}
			label={itemValue}
		/>
	);

	const validateInput = (val) => {
		if (fieldSchema?.validator && typeof fieldSchema?.validator === "function") {
			return fieldSchema.validator(val) || val === true;
		} else {
			return ValidateUtils.validateInput(val, RegexUtils.alphaNumericSpecialCharactersWithTwenty);
		}
	};

	const generateOtherField = () => {
		if (hasInput) {
			return (
				<InputField
					className={classnames("car-selector__item", listType)}
					name={name}
					value={otherValue}
					onChange={handleOtherChange}
					placeholder={inputPlaceholder}
					validation={validateInput}
				/>
			);
		}
		return null;
	};

	return (
		<ul className={classnames("car-selector", className, listType)}>
			{lineCarList[listType]?.map(generateItems)}
			{generateOtherField()}
		</ul>
	);
};

CarSelector.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	name: PropTypes.string,
	listType: PropTypes.string,
	hasInput: PropTypes.bool,
	inputPlaceholder: PropTypes.string,
	fieldSchema: PropTypes.object
};

export default CarSelector;
