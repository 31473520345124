import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { PlusIcon } from "../../../../core";
import ObservationFields from "../observation-fields/observation-fields";

/**
 * Root fields that can repeat via an "add more" button
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ObservationRepeatField = (props) => {
	const { line, fieldSchema, fields, setFields, driveLog } = props;
	const { t } = useTranslation();

	const { fieldName, repeat, mandatory, validator } = fieldSchema;
	const instances = fields[fieldName]?.length > 0 ? fields[fieldName] : [fields[fieldName]];

	// add new field
	const handleAddRepeat = (fieldToUpdate) => {
		const repeatFields = fields[fieldToUpdate] || [undefined];
		setFields({ ...fields, [fieldToUpdate]: [...repeatFields, undefined] });
	};

	// repeat fields value is an array
	const handleRepeatChange = (fieldToUpdate, index) => (newValue) => {
		const repeatFields = fields[fieldToUpdate];

		if (!repeatFields) {
			setFields({ ...fields, [fieldToUpdate]: [newValue] });
		} else {
			const newRepeatFields = repeatFields.map((value, i) => i === index ? newValue : value);
			setFields({ ...fields, [fieldToUpdate]: newRepeatFields });
		}
	};

	return (
		<>
			{instances.map((instance, index) => (
				<ObservationFields
					key={`${fieldName}${index}`}
					line={line}
					fieldName={fieldName}
					state={instances[index]}
					handleChange={handleRepeatChange(fieldName, index)}
					mandatory={mandatory}
					validator={validator}
					repeat={index > 0}
					fieldSchema={fieldSchema}
					driveLog={driveLog}
				/>
			))}
			{instances.length < repeat && (
				<div className="observation__repeat-button" onClick={() => handleAddRepeat(fieldName)}>
					<PlusIcon iconText={t(`observation:observation-fields.${fieldName}-button`)} />
				</div>
			)}
		</>
	);
};

ObservationRepeatField.propTypes = {
	line: PropTypes.string,
	fieldSchema: PropTypes.object.isRequired,
	fields: PropTypes.object.isRequired,
	setFields: PropTypes.func.isRequired,
	driveLog: PropTypes.object.isRequired
};

export default ObservationRepeatField;
