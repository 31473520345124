import React from "react";
import { useTranslation } from "react-i18next";
import { ToggleSwitch } from "../../../core";
import PropTypes from "prop-types";
import "./psg-option-card.scss";

/**
 * Renders an <PsgOptionCard/> component
 * @param {object} props
 * @param {boolean} props.isPsgActive - the option psg is active or not
 * @param {function} props.onChange - the function that activate or desactivate the psg option
 */
const PsgOptionCard = (props) => {
	const { isPsgActive, onChange } = props;
	const { t } = useTranslation("at");

	return (
		<div className="psg-option-card">
			<div className="psg-option-card__text-wrapper">
				<span className="psg-option-card__text">{t("psg-option.psg-drivelog")}</span>
				<span className="psg-option-card__text--secondary">{t("psg-option.psg-details")}</span>
			</div>
			<div className="psg-option-card__action">
				<span className="psg-option-card__action__value">{isPsgActive ? t("psg-option.yes") : t("psg-option.no")}</span>
				<ToggleSwitch className="psg-option-card__toggle-status"
						  isChecked={isPsgActive}
						  sliderClassName="psg-option-card__toggle-status__slider"
						  handleOnChange={onChange}/>
			</div>
		</div>
	);
};

PsgOptionCard.propTypes = {
	isPsgActive: PropTypes.bool,
	onChange: PropTypes.func
};

export default PsgOptionCard;
