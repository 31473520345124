import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import PageWrapper from "../page-wrapper/page-wrapper";
import GenericHeader from "../../headers/generic-header/generic-header";
import { AuthContext } from "../../../../user/auth/context/auth-context";

/**
 * Renders a <AdminPage /> component
 * @param {object} props
 * @param {string} props.className - classnames to add to the root of this component
 * @param {Object} props.children - the content to render in the page

 */
const AdminPage = props => {
	const { className = "", children } = props;
	const { user } = useContext(AuthContext);

	const allowedRoles = ["admin", "localAdmin"];

	if (!user?.role || (!allowedRoles.includes(user.role))) {
		return <Redirect to="/error-access" />;
	}

	return (
		<PageWrapper className="admin-page">
			<GenericHeader/>

			<main className={`admin-page__content ${className}`}>
				{children}
			</main>
		</PageWrapper>
	);
};


AdminPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	overrideRoles: PropTypes.array
};


export default AdminPage;

