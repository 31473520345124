import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DateUtils } from "../../../core";
import Button from "../../../core/components/button/button";
import { saveConsistencyOverlapsToBeDeleted } from "../../consistency-report.service";
import "./consistency-overlaps-list-row.scss";

/**
 * ConsistencyOverlapsListRow component display a row for each overlap
 * @param props
 * @param {object} props.reportOverlap overlap from DB
 * @param {object} props.localOverlap overlap from LocalStorage
 * @param {function} props.handleOpenEditModal
 * @param {function} props.fetchLocalOverlaps
 * @param {boolean} props.isLoading
 * @return {JSX.Element}
 * @constructor
 */
const ConsistencyOverlapsListRow = (props) => {
	const { date, reportOverlap, localOverlap, handleOpenEditModal, fetchLocalOverlaps, isLoading } = props;

	const { t } = useTranslation("cr");
	const {
		mission_id: missionId,
		mission_code: missionCode,

		service_number: serviceNumber,
		attachment_name: attachmentName,
		user_id: userId,

		station_start: stationStart,
		station_end: stationEnd,
		hour_start: hourStart,
		hour_end: hourEnd
	} = reportOverlap;

	const toBeDeleted = localOverlap?.delete;
	const toBeDeletedClassName = toBeDeleted ? "consistency-overlaps-to-be-deleted" : "";

	const deleteMissionInLocalStorage = async (restore = false) => {
		saveConsistencyOverlapsToBeDeleted(date, { id: missionId, code: missionCode }, restore);
		fetchLocalOverlaps();
	};

	const handleOpenEditModalByMode = (mode = "start") => {
		const { station_start_trigram: stationStartTrigram, station_end_trigram: stationEndTrigram, line } = reportOverlap;
		const mission = {
			id: missionId,
			mission_code: missionCode,
			station_start: stationStart,
			station_start_trigram: stationStartTrigram,
			station_end: stationEnd,
			station_end_trigram: stationEndTrigram,
			hour_start: hourStart,
			hour_end: hourEnd,
			line,
			service_number: serviceNumber,
			attachment_name: attachmentName,
			user_id: userId
		};

		if (localOverlap) {
			handleOpenEditModal({ ...mission, ...localOverlap }, mode);
		} else {
			handleOpenEditModal(mission, mode);
		}
	};

	const renderStationBlock = (reportStation, localStation, endStation = false) => {
		const endStationClassName = endStation ? "consistency-overlaps-list__row__station-end" : "";

		if (localStation && localStation !== reportStation) {
			return <span className={`consistency-overlaps-list__row__station ${endStationClassName} consistency-overlaps-edited ${toBeDeletedClassName}`}>{localStation}</span>;
		}

		return <span className={`consistency-overlaps-list__row__station ${endStationClassName} ${toBeDeletedClassName}`}>{reportStation}</span>;
	};

	const renderHourBlock = (reportHour, localHour) => {
		const reportHourMinutes = DateUtils.formatHourMinutes(reportHour, ":");
		const localHourMinutes = DateUtils.formatHourMinutes(localHour, ":");

		if (localHour && localHourMinutes !== reportHourMinutes) {
			return <span className={`consistency-overlaps-list__row__hour consistency-overlaps-edited ${toBeDeletedClassName}`}>{localHourMinutes}</span>;
		}

		return <span className={`consistency-overlaps-list__row__hour ${toBeDeletedClassName}`}>{reportHourMinutes}</span>;
	};

	const renderEditBlock = (mode) => {
		if (toBeDeleted) {
			return null;
		}

		return (
			<Button className="button--link consistency-overlaps-list__row__validate" disabled={isLoading} onClick={() => handleOpenEditModalByMode(mode)}>
				<span>{t("consistency-report.consistency-overlaps.row.edit")}</span>
			</Button>
		);
	};

	return (
		<div className="consistency-overlaps-list__row-container">
			<div className="consistency-overlaps-list__row">
				<span className="consistency-overlaps-list__row__cell consistency-overlaps-list__row__cell-identity">
					<span className={`consistency-overlaps-list__row__identity ${toBeDeletedClassName}`}>
						{t("consistency-report.consistency-overlaps.row.title", { serviceNumber, attachmentName, userId })}
					</span>
				</span>

				<span className="consistency-overlaps-list__row__cell consistency-overlaps-list__row__cell-start">
					{renderStationBlock(stationStart, localOverlap?.station_start)}
					{renderEditBlock("start")}
					{renderHourBlock(hourStart, localOverlap?.hour_start)}
				</span>

				<span className="consistency-overlaps-list__row__cell consistency-overlaps-list__row__cell-end">
					{renderStationBlock(stationEnd, localOverlap?.station_end, true)}
					{renderHourBlock(hourEnd, localOverlap?.hour_end)}
					{renderEditBlock("end")}
				</span>

				<span className="consistency-overlaps-list__row__cell consistency-overlaps-list__row__cell-delete">
					<Button className="button--link consistency-overlaps-list__row__delete" disabled={isLoading} onClick={() => deleteMissionInLocalStorage(toBeDeleted)}>
						<span>{t(`consistency-report.consistency-overlaps.row.${toBeDeleted ? "restore" : "delete"}`)}</span>
					</Button>
				</span>
			</div>
		</div>
	);
};

ConsistencyOverlapsListRow.propTypes = {
	reportOverlap: PropTypes.object,
	localOverlap: PropTypes.object,
	handleOpenEditModal: PropTypes.func,
	fetchLocalOverlaps: PropTypes.func,
	isLoading: PropTypes.bool
};

export default ConsistencyOverlapsListRow;
