import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button, PopupFullPage } from "../../../core";
import { AuthContext } from "../../../user/auth/context/auth-context";
import AttachmentCard from "./attachment-card/attachment-card";
import { DriveLogSubHeader } from "../../index";
import { getAttachments } from "../../drive-log.services";
import PsgOptionCard from "../../../attachment/components/psg-option-card/psg-option-card";
import PsgDrivelogModal from "../../../attachment/components/psg-drivelog-modal/psg-drivelog-modal";
import "./attachment-list.scss";

// The <AttachmentList/> component is used for the creation of drivelog. Thus, it doesn't require the PSG attachment.
const AttachmentList = (props) => {
	const { t } = useTranslation();
	const { navigate, title = t("dl:dl-new-page.title"), showOptionPSG = false, backlink } = props;
	const [isFormValid, setIsFormValid] = useState(false);
	const [attachments, setAttachments] = useState("");
	const [attachmentName, setAttachmentName] = useState("");
	const [isPsg, setIsPsg] = useState(false);
	const [showPsgDriveLogModal, setShowPsgDriveLogModal] = useState(false);
	const { user } = useContext(AuthContext) || {};
	const isAdminRole = ["admin", "localAdmin"].includes(user.role);
	const isAmpPsg = user.attachment_code === "BPSG";

	const toggleOptionPSG = () => {
		setIsPsg(!isPsg);
	};

	useEffect(() => {
		setIsFormValid(!!attachmentName);
		// when mode is set to global attachments are not pre-filtered by line for applicable roles
		getAttachments({ mode: "global" }).then(payload => {
			// The <AttachmentList/> component is used for the creation of drive log. Thus, it doesn't require the PSG attachment.
			let attachmentListFiltered = payload.data.filter(attachmentInfo => attachmentInfo?.code !== "BPSG");
			if (!(isAdminRole || isAmpPsg)) {
				attachmentListFiltered = attachmentListFiltered.filter((a) => a.line === user.line);
			}
			setAttachments(attachmentListFiltered);
		}).catch(err => {
			console.error(err);
		});
	}, [attachmentName, user?.line, isAmpPsg, isAdminRole]);

	/**
	 * @function handleSelected - set the selectedCode state with the data retrieved from the children component
	 * @param {String} name - the attachment card code
	 */
	const handleClick = (name) => () => {
		const alreadySelected = name === attachmentName;
		if (alreadySelected) {
			// Deselect Current attachment
			setAttachmentName("");
		} else {
			setAttachmentName(name);
		}
	};

	const validateStep = () => {
		const attachment = attachments.find(attach => attach.name === attachmentName);
		if (attachment) {
			const { line, bigram, code } = attachment;
			navigate({ line, attachmentBigram: bigram, attachmentName, isPsg, code });
		}
	};

	const handleSubmit = () => {
		if(isPsg) {
			setShowPsgDriveLogModal(!showPsgDriveLogModal);
		} else {
			validateStep();
		}
	};

	const generateAttachmentCard = (attachment) => {
		const { code, name } = attachment;
		return (
			<AttachmentCard isSelected={name === attachmentName} handleClick={handleClick(name)}
				code={code} key={code} name={name}/>
		);
	};

	return (
		<PopupFullPage title={title} backLink={backlink}>
			<div className="attachment-list">
				<DriveLogSubHeader subHeader={t("dl:attachment-page:title")} />
				<ul className="attachment-list__content">
					{attachments && attachments.map(generateAttachmentCard)}
				</ul>
				{showOptionPSG  && <PsgOptionCard isPsgActive={isPsg} onChange={toggleOptionPSG}/>}
				<Button disabled={!isFormValid} className="attachment__button" onClick={handleSubmit}>
					{t("core:popup-full-page.button.validate")}
				</Button>
				<PsgDrivelogModal showModal={showPsgDriveLogModal} setShowPsgDriveLogModal={setShowPsgDriveLogModal} setIsPsg={setIsPsg} validateStep={validateStep}/>
			</div>
		</PopupFullPage>
	);
};

export default AttachmentList;
