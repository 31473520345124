import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Comment, DriverPage, FeedbackMenuButton } from "../../../../core";
import { CommentWithFileAttachment, FooterDlDetails, MissionListAllObs, ServiceInfos } from "../../../index";
import { Redirect, useParams } from "react-router-dom";
import { getDriveLogById } from "../../../drive-log.services";
import DlDetailsSubheader from "../../../components/dl-details-subheader/dl-details-subheader";
import DelaysTable from "../delay-list-page/components/delays-table/delays-table";
import SurveyList from "../../../components/survey-list/survey-list";
import DrivelogPrintablePdf from "../../../components/drivelog-printable-pdf/drivelog-printable-pdf";
import "./dl-details-driver.scss";

const DlDetailsDriver = (props) => {
	const { overrideRoles, params = {} } = props;
	const { redirectUrl = "", domain = "" } = params;

	const { id: driveLogId } = useParams();
	const { t } = useTranslation();
	const [currentDriveLog, setCurrentDriveLog] = useState({});
	const [loading, setLoading] = useState(true);
	const [isOwnDl, setIsOwnDl] = useState(true);

	useEffect(() => {
		getDriveLogById(driveLogId, { extended : true }).then((payload) => {
			const driveLog = payload.data;
			setCurrentDriveLog(driveLog);
		}).catch(err => {
			console.error(err);
			setIsOwnDl(false);
		}).finally(() => {
			setLoading(false);
		});
	}, [driveLogId]);

	return (
		<>
			{isOwnDl &&
			<DriverPage className="drive-log-details-driver" overrideRoles={overrideRoles} hideNav hideFeedbackButton={true}>
				<DlDetailsSubheader driveLog={currentDriveLog} readOnly={true} redirectUrl={redirectUrl} domain={domain} />
				<div className="drive-log-details-driver__content">
					<ServiceInfos
						driveLog={currentDriveLog}
						loading={loading}
					/>
					<DelaysTable
						loading={loading}
						driveLog={currentDriveLog}
						readOnly={true}
						showTitle
						onDriveLogDetails
						redirectUrl={redirectUrl}
					/>
					<div className="drive-log-details-driver--missions">
						<MissionListAllObs
							loading={loading}
							setLoading={setLoading}
							redirectUrl={redirectUrl}
						/>
					</div>
					<div className="drive-log-details-driver--survey">
						<SurveyList redirectUrl={redirectUrl}/>
					</div>
					<DrivelogPrintablePdf/>
					<CommentWithFileAttachment
						title={t("dl:dl-details.comment-amp")}
						currentDriveLog={currentDriveLog}
						readOnly={true}
					/>
					{ currentDriveLog?.hr_comment && <Comment
						className={"drive-log-details-driver__hr-comment"}
						title={t("dl:dl-details.comment-hr")}
						value={currentDriveLog?.hr_comment}
						readOnly={true}
					/> }
					<Button className="drive-log-details-driver__controls" onClick={() => window.print()}>
						{t("dl:dl-details.generate-pdf")}
					</Button>
				</div>
				<FooterDlDetails currentDriveLog={currentDriveLog} />
				<FeedbackMenuButton className={"drive-log-details-driver__menu__feedback-button"}/>
			</DriverPage>}
			{!isOwnDl && <Redirect to="/error-access" />}
		</>
	);
};

export default DlDetailsDriver;
