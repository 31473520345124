import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DelayLine from "../delay-line/delay-line";
import computeTime from "../../../../../../core/utils/compute-time/compute-time";
import EndOfServiceModal from "../../modals/end-of-service-modal/end-of-service-modal";
import OtherDelayModal from "../../modals/other-delay-modal/other-delay-modal";
import { updateDriveLog } from "../../../../../drive-log.services";
import "./delays-table.scss";

const DelaysTable = (props) => {
	const {
		loading = true,
		driveLog = {},
		readOnly = false,
		showTitle,
		showModalOnEditClick = false,
		loadDriveLog,
		onDriveLogDetails,
		setDlToUpdate,
		dlToUpdate,
		redirect = "",
		redirectUrl = ""
	} = props;

	const { t } = useTranslation();
	const history = useHistory();
	const [delays, setDelays] = useState({});
	const [diffDelays, setDiffDelays] = useState({});
	const [currentModal, setCurrentModal] = useState(null);
	const delayPageParams = redirect ? `?redirect=${redirect}` : "";
	const isReturnedDl = redirectUrl === "/drive-log/returned";

	useEffect(() => {
		if (driveLog?.id) {
			const {
				end_of_service_delay,
				daily_legal_time_exceed,
				rest_time_reduction,
				diff_service_planned_performed_time,
				other_tc_time,
				rfn_bonus,
				realized_date_start,
				realized_date_end,
				total_additional_time,
				amp_end_of_service_delay,
				amp_daily_legal_time_exceed,
				amp_rest_time_reduction,
				amp_diff_service_planned_performed_time,
				amp_other_tc_time,
				amp_rfn_bonus,
				amp_total_additional_time,
				hr_end_of_service_delay,
				hr_daily_legal_time_exceed,
				hr_rest_time_reduction,
				hr_diff_service_planned_performed_time,
				hr_other_tc_time,
				hr_rfn_bonus,
				hr_total_additional_time
			} = driveLog;
			const driverComputePayload = {
				shiftStartDate : realized_date_start,
				shiftEndDate : realized_date_end,
				endOfShiftDelay : end_of_service_delay,
				dailyLegalTimeExceed : daily_legal_time_exceed,
				restTimeReduction : rest_time_reduction,
				diffServicePlannedPerformedTime: diff_service_planned_performed_time,
				otherTcTime: other_tc_time
			};
			const computedDriverTime = computeTime(driverComputePayload, driveLog);
			setDelays(computedDriverTime);

			const getDelayDiff = (driverDelay, ampDelay, hrDelay) => {
				if (driverDelay === ampDelay && ampDelay === hrDelay) {
					return "driver";
				}
				if (driverDelay !== ampDelay && ampDelay === hrDelay) {
					return "amp";
				}
				if (ampDelay !== hrDelay) {
					return "hr";
				}
			};

			if (onDriveLogDetails || isReturnedDl) {
				const delayFromRoles = [
					getDelayDiff(end_of_service_delay, amp_end_of_service_delay, hr_end_of_service_delay),
					getDelayDiff(daily_legal_time_exceed, amp_daily_legal_time_exceed, hr_daily_legal_time_exceed),
					getDelayDiff(rest_time_reduction, amp_rest_time_reduction, hr_rest_time_reduction),
					getDelayDiff(diff_service_planned_performed_time, amp_diff_service_planned_performed_time, hr_diff_service_planned_performed_time),
					getDelayDiff(other_tc_time, amp_other_tc_time, hr_other_tc_time)
				];
				let diffComputePayload;
				let dlFieldsToUpdate;
				if (delayFromRoles.includes("hr")) {
					diffComputePayload = {
						shiftStartDate: realized_date_start,
						shiftEndDate: realized_date_end,
						endOfShiftDelay: hr_end_of_service_delay,
						dailyLegalTimeExceed: hr_daily_legal_time_exceed,
						restTimeReduction: hr_rest_time_reduction,
						diffServicePlannedPerformedTime: hr_diff_service_planned_performed_time,
						otherTcTime: hr_other_tc_time
					};
					dlFieldsToUpdate = {
						end_of_service_delay,
						daily_legal_time_exceed,
						rest_time_reduction,
						diff_service_planned_performed_time,
						other_tc_time,
						rfn_bonus,
						total_additional_time
					};
				} else if (delayFromRoles.includes("amp")) {
					diffComputePayload = {
						shiftStartDate: realized_date_start,
						shiftEndDate: realized_date_end,
						endOfShiftDelay: amp_end_of_service_delay,
						dailyLegalTimeExceed: amp_daily_legal_time_exceed,
						restTimeReduction: amp_rest_time_reduction,
						diffServicePlannedPerformedTime: amp_diff_service_planned_performed_time,
						otherTcTime: amp_other_tc_time
					};
					dlFieldsToUpdate = {
						amp_end_of_service_delay,
						amp_daily_legal_time_exceed,
						amp_rest_time_reduction,
						amp_diff_service_planned_performed_time,
						amp_other_tc_time,
						amp_rfn_bonus,
						amp_total_additional_time
					};
				} else if (delayFromRoles.includes("driver")) {
					diffComputePayload = {
						shiftStartDate: realized_date_start,
						shiftEndDate: realized_date_end,
						endOfShiftDelay: end_of_service_delay,
						dailyLegalTimeExceed: daily_legal_time_exceed,
						restTimeReduction: rest_time_reduction,
						diffServicePlannedPerformedTime: diff_service_planned_performed_time,
						otherTcTime: other_tc_time
					};
					dlFieldsToUpdate = {
						hr_end_of_service_delay,
						hr_daily_legal_time_exceed,
						hr_rest_time_reduction,
						hr_diff_service_planned_performed_time,
						hr_other_tc_time,
						hr_rfn_bonus,
						hr_total_additional_time
					};
				}
				const computedDiffTime = computeTime(diffComputePayload, driveLog);
				setDiffDelays(computedDiffTime);
				!readOnly && setDlToUpdate(oldDl => ({
					...oldDl,
					...dlFieldsToUpdate
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [driveLog, readOnly, setDlToUpdate, onDriveLogDetails]);
	/**
	 * handle the right edit link to update the delays: a redirection or a modal
	 * @param {string} link - the provided url
	 * @param {string} modal - the name of the modal to show
	 */
	const handleEditClick = (link, modal) => {
		if (showModalOnEditClick) {
			setCurrentModal(modal);
		} else {
			history.push(link);
		}
	};

	const chooseValueToDisplay = (dbAmpField, dbDriverField, computedField) => {
		if (onDriveLogDetails) {
			return delays[computedField] === diffDelays[computedField] ? delays[computedField] : diffDelays[computedField];
		}
		return delays?.[computedField];
	};

	const chooseEditedValueToDisplay = (dbAmpField, dbDriverField, computedField) => {
		if (onDriveLogDetails) {
			return delays[computedField] === diffDelays[computedField] ? null : delays[computedField];
		}
		if (isReturnedDl) {
			return delays[computedField] === diffDelays[computedField] ? null : diffDelays[computedField];
		}
	};

	const handleDlUpdate = (field, value) => {
		const updatedDriveLog = { ...driveLog };
		updatedDriveLog[field] = value;

		updateDriveLog(updatedDriveLog, { action: "modifyDelay" }).then(() => {
			loadDriveLog();
		}).catch(err => {
			console.error(err);
		});
	};

	return (
		<>
			{showTitle && (
				<h2 className="delays-title">
					<span>{t("dl:dl-details.delays.title")}</span>
				</h2>
			)}
			<ul className="delays-table">
				<DelayLine
					description={t("dl:delay-list-page.service-end-delay")}
					value={chooseValueToDisplay("amp_end_of_service_delay", "end_of_service_delay", "endOfServiceDelay")}
					loading={loading}
					editable={!readOnly}
					handleEditClick={() => handleEditClick(`${redirectUrl}/${driveLog?.id}/modify-delay${delayPageParams}`, "end-of-service")}
					editedValue={chooseEditedValueToDisplay("amp_end_of_service_delay", "end_of_service_delay", "endOfServiceDelay")}
				/>
				<DelayLine
					description={t("dl:delay-list-page.delay-superior-10m")}
					value={chooseValueToDisplay("amp_end_of_service_delay", "end_of_service_delay", "superiorToTenMinutes")}
					shortDescription={t("dl:delay-list-page.delay-superior-10m-short")}
					loading={loading}
					editedValue={chooseEditedValueToDisplay("amp_end_of_service_delay", "end_of_service_delay", "superiorToTenMinutes")}
				/>
				<DelayLine
					description={t("dl:delay-list-page.delay-superior-6h-30m")}
					value={chooseValueToDisplay("amp_daily_legal_time_exceed", "daily_legal_time_exceed", "superiorToSixHoursThirty")}
					loading={loading}
					editable={!readOnly}
					handleEditClick={() => handleEditClick(`${redirectUrl}/${driveLog?.id}/modify-daily-time${delayPageParams}`, "daily-legal-time-exceed")}
					editedValue={chooseEditedValueToDisplay("amp_daily_legal_time_exceed", "daily_legal_time_exceed", "superiorToSixHoursThirty")}
				/>
				<DelayLine
					description={t("dl:delay-list-page.rest-shortened")}
					value={chooseValueToDisplay("amp_rest_time_reduction", "rest_time_reduction", "restTimeReduction")}
					loading={loading}
					editable={!readOnly}
					handleEditClick={() => handleEditClick(`${redirectUrl}/${driveLog?.id}/modify-rest-shortened${delayPageParams}`, "rest-time-reduction")}
					editedValue={chooseEditedValueToDisplay("amp_rest_time_reduction", "rest_time_reduction", "restTimeReduction")}
				/>
				<DelayLine
					description={t("dl:delay-list-page.diff-service-planned-performed")}
					shortDescription={t("dl:delay-list-page.diff-service-planned-performed-short")}
					value={chooseValueToDisplay("amp_diff_service_planned_performed_time", "diff_service_planned_performed_time", "diffServicePlannedPerformedTime")}
					loading={loading}
					editable={!readOnly}
					handleEditClick={() => handleEditClick(
						`${redirectUrl}/${driveLog?.id}/modify-difference-service-planned-performed${delayPageParams}`,
						"diff-service-planned-performed")}
					editedValue={chooseEditedValueToDisplay("amp_diff_service_planned_performed_time", "diff_service_planned_performed_time", "diffServicePlannedPerformedTime")}
				/>
				<DelayLine
					description={t("dl:delay-list-page.other-tc")}
					value={chooseValueToDisplay("amp_other_tc_time", "other_tc_time", "otherTcTime")}
					details={driveLog["other_tc_reason"]}
					loading={loading}
					editable={!readOnly}
					handleEditClick={() => handleEditClick(
						`${redirectUrl}/${driveLog?.id}/modify-other-tc${delayPageParams}`, "other-tc")}
					editedValue={chooseEditedValueToDisplay("amp_other_tc_time", "other_tc_time", "otherTcTime")}
				/>
				{driveLog.line_number === "A" && <DelayLine
					description={t("dl:delay-list-page.rfn-bonus")}
					value={driveLog["rfn_bonus"]}
					valueType={"boolean"}
					loading={loading}
					editable={!readOnly}
					handleEditClick={() => handleDlUpdate("rfn_bonus", !driveLog["rfn_bonus"])}
				/>}
			</ul>
			<div className="delays__total">
				<DelayLine
					description={t("dl:delay-list-page.total")}
					loading={loading}
					value={chooseValueToDisplay("amp_total_additional_time", "total_additional_time", "total")}
					editedValue={chooseEditedValueToDisplay("amp_total_additional_time", "total_additional_time", "total")}
				/>

			</div>
			<EndOfServiceModal
				showModal={currentModal === "end-of-service"}
				setShowModal={setCurrentModal}
				driveLog={driveLog}
				currentDriverField="end_of_service_delay"
				delays={delays}
				ampDelays={diffDelays}
				setAmpDelays={setDiffDelays}
				setDlToUpdate={setDlToUpdate}
				dlToUpdate={dlToUpdate}
			/>
			<OtherDelayModal
				showModal={currentModal === "daily-legal-time-exceed"}
				loadDriveLog={loadDriveLog}
				setShowModal={setCurrentModal}
				driveLog={driveLog}
				fieldToUpdate="amp_daily_legal_time_exceed"
				ampDelays={diffDelays}
				setAmpDelays={setDiffDelays}
				ampDelaysFieldToUpdate="superiorToSixHoursThirty"
				currentDriverField="daily_legal_time_exceed"
				dlToUpdate={dlToUpdate}
				setDlToUpdate={setDlToUpdate}
			/>
			<OtherDelayModal
				showModal={currentModal === "rest-time-reduction"}
				loadDriveLog={loadDriveLog}
				setShowModal={setCurrentModal}
				driveLog={driveLog}
				fieldToUpdate="amp_rest_time_reduction"
				ampDelays={diffDelays}
				setAmpDelays={setDiffDelays}
				ampDelaysFieldToUpdate="restTimeReduction"
				currentDriverField="rest_time_reduction"
				dlToUpdate={dlToUpdate}
				setDlToUpdate={setDlToUpdate}
			/>
			<OtherDelayModal
				showModal={currentModal === "diff-service-planned-performed"}
				loadDriveLog={loadDriveLog}
				setShowModal={setCurrentModal}
				driveLog={driveLog}
				fieldToUpdate="amp_diff_service_planned_performed_time"
				ampDelays={diffDelays}
				setAmpDelays={setDiffDelays}
				ampDelaysFieldToUpdate="diffServicePlannedPerformedTime"
				currentDriverField="diff_service_planned_performed_time"
				dlToUpdate={dlToUpdate}
				setDlToUpdate={setDlToUpdate}
			/>
			<OtherDelayModal
				showModal={currentModal === "other-tc"}
				loadDriveLog={loadDriveLog}
				setShowModal={setCurrentModal}
				driveLog={driveLog}
				fieldToUpdate="amp_other_tc_time"
				ampDelays={diffDelays}
				setAmpDelays={setDiffDelays}
				ampDelaysFieldToUpdate="otherTcTime"
				currentDriverField="other_tc_time"
				dlToUpdate={dlToUpdate}
				setDlToUpdate={setDlToUpdate}
			/>
		</>
	);
};

DelaysTable.propTypes = {
	loading: PropTypes.bool,
	driveLog: PropTypes.object,
	readOnly: PropTypes.bool,
	isDriveLogSigned: PropTypes.bool,
	showTitle: PropTypes.bool,
	showModalOnEditClick: PropTypes.bool,
	loadDriveLog: PropTypes.func,
	onDriveLogDetails: PropTypes.bool,
	setDlToUpdate: PropTypes.func,
	dlToUpdate: PropTypes.object
};

export default DelaysTable;
