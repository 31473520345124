import { useEffect, useState } from "react";
import axios from "axios";

const useAppEnv = () => {
	const [env, setEnv] = useState({});

	useEffect(() => {
		axios.get("/app-env.json").then((rsp) => {
			if (rsp && rsp.data) {
				setEnv(rsp.data);
			}
		});
	}, [setEnv]);

	return env;
};

export { useAppEnv };
