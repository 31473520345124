import React from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO, isDate } from "date-fns";
import { fr } from "date-fns/locale";
import { ResponsiveText } from "../../../../core";
import { useMissionCode } from "../../../custom-hooks/observation-custom-hooks";
import { dateTime } from "../observation-fields/observation-fields-list";
import "./observation-read-only.scss";

const ObservationReadOnly = (props) => {
	const { fieldName, driveLog, fields, observationFields } = props;
	const { t } = useTranslation();

	const [missionCode, missionCodeLoading] = useMissionCode();

	const getCurrentDay = () => {
		const flattenObservationFields = observationFields.map(field => {
			const { group, children, ...rest } = field;
			if (group) {
				return [ { ...rest }, ...group ];
			}
			if (children) {
				return [ { ...rest }, ...children ];
			}
			return rest;
		}).flat(Infinity);
		const findDateInFields = flattenObservationFields.find(field => {
			const { fieldName: f } = field;
			return dateTime.includes(f);
		});
		if (findDateInFields?.fieldName && fields[findDateInFields?.fieldName]) {
			return isDate(fields[findDateInFields?.fieldName]) ? fields[findDateInFields?.fieldName] : parseISO(fields[findDateInFields?.fieldName]);
		}
		return driveLog?.realized_date_start ? parseISO(driveLog?.realized_date_start) : new Date();
	};

	return (
		<div className="observation__read-only">
			<div>{t(`observation:observation-read-only.${fieldName}`)}</div>
			<div className="observation__read-only__value">
				{fieldName === "missionCode" && !missionCodeLoading && missionCode}
				{fieldName === "currentDate" && (
					<ResponsiveText
						className="observation-reminder-current-date__content"
						text={format(getCurrentDay(), "dd LLLL yyyy", { locale: fr })}
						mobileText={format(getCurrentDay(), "dd/MM/yyyy", { locale: fr })}
					/>
				)}
			</div>
		</div>
	);
};

export default ObservationReadOnly;
