import React, { useCallback, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DateUtils, PopupFullPage } from "../../core";
import { getDisabledLineSheets } from "../../drive-log/disable-sheets-line.service";
import LineSheetsStateCard from "../components/line-sheets-state-card/line-sheets-state-card";
import DisableLineSheetsTable from "../components/disable-line-sheets-table/disable-line-sheets-table";
import { AuthContext } from "../../user/auth/context/auth-context";
import { format, addDays } from "date-fns";

import "./disable-line-sheets-page.scss";

const DisableLineSheetsPage = () => {
	const { t } = useTranslation("disable-line-sheets");
	const { user: currentUser = {} } = useContext(AuthContext);
	const { state } = useLocation();
	const { role: currentUserRole, line: currentUserLine } = currentUser;
	const isAdmin = currentUserRole === "admin";
	const [ cardFields, setCardFields ] = useState([]);

	const initLocalAdminAmpLineSheet = {
		line: currentUserLine,
		status: "activated"
	};

	const initAdminLineSheet = {
		"line_A_group": {
			line: "A",
			status: "activated"
		},
		"line_B_group": {
			line: "B",
			status: "activated"
		}
	};

	const [ lineSheets, setLineSheets ] = useState(isAdmin ? initAdminLineSheet : initLocalAdminAmpLineSheet);
	const [ disabledLineSheets, setDisabledLineSheets ] = useState([]);
	const { line_A_group: lineAGroup, line_B_group: lineBGroup, line, name, status } = lineSheets || {};

	// get the tomorrow date
	const tomorrow = addDays(new Date(), 1);
	const formattedTomorrow = format(tomorrow, "dd/MM/yyyy");

	const initLineSheet = () => {
		const now = DateUtils.formatFullDate(new Date(), "dd/MM/yyyy");
		if(currentUserRole === "localAdmin" || currentUserRole === "amp") {
			disabledLineSheets.map(disabledSheet => {
				if (DateUtils.formatFullDate(disabledSheet?.disabled_at, "dd/MM/yyyy") === now) {
					setLineSheets(oldData => {
						const newFormData = { ...oldData };
						return { ...newFormData, status: "disabled" };
					});
				}
				return null;
			});
		} else {
			disabledLineSheets.map(disabledSheet => {
				if (DateUtils.formatFullDate(disabledSheet?.disabled_at, "dd/MM/yyyy") === now) {
					setLineSheets(oldData => {
						const newFormData = { ...oldData };
						const group = `line_${disabledSheet.line}_group`;
						const groupToUpdate = group === "line_A_group" ? { ...lineAGroup } : { ...lineBGroup };
						return { ...newFormData, [group]: { ...groupToUpdate, status: "disabled" } };
					});
				}
				return null;
			});
		}
	};

	const retrieveDisabledLineSheets = useCallback(() => {
		getDisabledLineSheets().then(res => {
			if (res?.data) {
				setDisabledLineSheets(res?.data);
			}
		}).catch(err => {
			console.error(err);
		});
	}, []);

	const initCardsFields = () => {
		if(currentUser?.role === "admin") {
			setCardFields([
				{
					line: "A",
					status: lineAGroup?.status
				},
				{
					line: "B",
					status: lineBGroup?.status
				}
			]);
		} else {
			setCardFields([{ line, status }]);
		}
	};

	const displayLineSheetCard = (lineSheet) =>
		<LineSheetsStateCard lineSheet={lineSheet} key={lineSheet.line} refreshDisabledSheetsList={retrieveDisabledLineSheets} tomorrowDate={formattedTomorrow} />;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(retrieveDisabledLineSheets, [currentUser]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initLineSheet, [disabledLineSheets, currentUserRole]);
	useEffect(initCardsFields, [currentUser?.role, line, lineAGroup?.name, lineAGroup?.status, lineBGroup?.name, lineBGroup?.status, name, status]);

	return (
		<PopupFullPage backLink={state.originPage} title={t("title")} overrideRoles={["admin", "amp", "localAdmin"]}>
			<div className="disable-line-sheets-page__wrapper">
				<div className="disable-line-sheets-page__wrapper__block">
					<h1 className="disable-line-sheets-page__wrapper__block__title"> { t("sheets-state.title") } </h1>
					<span className="disable-line-sheets-page__wrapper__block__sub-title">
						<Trans i18nKey="disable-line-sheets:sheets-state.sub-title" parent="p">
							<span>{{ date: formattedTomorrow }}</span>
						</Trans>
					</span>
					<ul className="disable-line-sheets-page__wrapper__block__cards">
						{cardFields.map(displayLineSheetCard)}
					</ul>
				</div>
				<div className="disable-line-sheets-page__wrapper__block">
					<h1 className="disable-line-sheets-page__wrapper__block__title"> { t("deactivation-history.title") } </h1>
					{disabledLineSheets.length !== 0 && <DisableLineSheetsTable disabledLineSheets={disabledLineSheets} />}
					{disabledLineSheets.length === 0 && <span>
						{ t("deactivation-history.no-sheets") }
					</span>}
				</div>
			</div>
		</PopupFullPage>
	);
};

export default DisableLineSheetsPage;
