import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { parseISO, formatISO } from "date-fns";
import queryString from "query-string";
import base64 from "base-64";
import { Button, Loader, Modal } from "../../../core";
import { getReferentialList } from "../../../core/core.services";
import { getMissionInfo, updateMission } from "../../mission.services";
import { useLine } from "../../../observation/custom-hooks/observation-custom-hooks";
import PopupFullPage from "../../../core/components/page-wrappers/popup-full-page/popup-full-page";
import ObservationSearch from "../../../observation/components/observation-form/observation-fields/observation-search/observation-search";
import ObservationTime from "../../../observation/components/observation-form/observation-fields/observation-time/observation-time";
import { getAllObservationsInDl } from "../../../observation/observation.services";
import Icon from "../../../core/components/icon-svg/icon";
import { useAppEnv } from "../../../../config";
import { getDriveLogById } from "../../../drive-log/drive-log.services";
import { getLineSheet } from "../../../drive-log/sheets-line.service";
import "./modify-stations.scss";

const ModifyStations = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;

	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const param = queryString.parse(location.search);

	const env =  useAppEnv();

	const { id: driveLogId, missionId, station } = useParams();

	const [mission, setMission] = useState();
	const [stationsList, setStationsList] = useState();
	const { id: currentMissionId, code: missionCode, train_number: trainNumber, train_composition: trainComposition } = mission || {};

	const [timeError, setTimeError] = useState(true);

	const [formValid, setFormValid] = useState({ station: false, dateTime: false });
	const [line] = useLine();

	const [observations, setObservations] = useState([]);
	const [driveLog, setDriveLog] = useState({});
	const [rfnStation, setRfnStation] = useState([]);
	const [showRfnConfirmModal, setShowRfnConfirmModal] = useState(false);
	const [hasLineSheet, setHasLineSheet] = useState(false);

	const isStationStart = station && station === "station-start";

	const [fields, setFields] = useState({
		station: {}
	});

	const launchMission = param.start === "launch-mission";
	// get "fromApp" & "station" params from Digiplan redirection when stopping the mission
	const stopMission = param.fromApp === "DigiPlan" && !!param.position;
	// redirect to the previous page according to the redirectUrl param
	const previousPage = `${redirectUrl}/${driveLogId}` ;

	useEffect(() => {
		if (mission && stationsList) {
			const findStation = (trigramStation) => stationsList?.find(({ trigram }) => trigram === trigramStation);

			const getTrigram = () => {
				if (isStationStart) {
					return mission?.station_start_trigram;
				}
				if (stopMission) {
					const position = !!param?.position ? JSON.parse(base64.decode(param?.position)) : {};
					// Get first trigram if 2 provided
					const positionTrigram = position?.trigram?.split("-");
					return positionTrigram?.length > 0 ? positionTrigram[0] : "";
				}
				return mission?.station_end_trigram;
			};

			const foundStation = findStation(getTrigram());

			const missionDateTime = () => {
				if (isStationStart) {
					setTimeError(!mission?.hour_start);
					return mission?.hour_start ? parseISO(mission?.hour_start) : 0 ;
				}
				if (stopMission) {
					setTimeError(false);
					return new Date();
				}
				setTimeError(!mission?.hour_end);
				return mission?.hour_end ? parseISO(mission?.hour_end) : 0 ;
			};

			const updateFields = (old) => {
				return { ...old, station: foundStation, dateTime: missionDateTime() };
			};
			setFields(updateFields);
		}
	}, [isStationStart, mission, stationsList, param.station, stopMission, param?.position]);

	useEffect(() => {
		getMissionInfo(driveLogId, missionId).then(payload => {
			setMission(payload?.data);
		}).catch(err => {
			console.error(err);
		});
		getReferentialList("station").then(res => {
			setStationsList(res.data);
		});
		getAllObservationsInDl(driveLogId).then(res => {
			setObservations(res?.data);
		}).catch(err => {
			console.error(err);
		});
		getDriveLogById(driveLogId).then(res => {
			setDriveLog(res?.data);
		}).catch(err => {
			console.error(err);
		});
		getLineSheet({ line, status: "published" }).then((res) => {
			if (res) {
				setHasLineSheet(true);
			}
		});
		getLineSheet({ line, status: "coming" }).then((res) => {
			if (res) {
				setHasLineSheet(true);
			}
		});
	}, [missionId, driveLogId, line]);

	useEffect(() => {
		const isDateTimeValid = !!fields?.dateTime && !timeError;
		const isStationValid = !!fields?.station?.key;

		setFormValid({ station: isStationValid, dateTime: isDateTimeValid });
	}, [fields, mission, isStationStart, timeError]);

	const handleChange = (fieldToUpdate) => (value) => {
		setFields({ ...fields, [fieldToUpdate]: value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		let content;
		if (isStationStart) {
			content = {
				station_start: fields?.station.name,
				hour_start: formatISO(fields?.dateTime),
				station_start_trigram: fields?.station?.trigram
			};
		} else {
			content = {
				station_end: fields?.station.name,
				hour_end: formatISO(fields?.dateTime),
				station_end_trigram: fields?.station?.trigram
			};
		}
		updateMission(driveLogId, missionId, content).then(() => {
			// Launch digiplan
			if (launchMission) {
				// If observation have one rfn station
				if (rfnStation && rfnStation.length > 0) {
					setShowRfnConfirmModal(true);
				} else {
					startDigiPlan(false);
				}
			} else {
				history.push(previousPage);
			}
		}).catch(err => {
			console.error(err);
		});
	};

	const subHeader = () => {
		return (
			<>
				<h2 className="subheader__code">{mission?.code}</h2>
			</>
		);
	};

	const getTitlePage = () => {
		if (launchMission) {
			return t("mission:modify-stations.launch-mission");
		}
		if (stopMission) {
			return t("mission:modify-stations.stop-mission");
		}
		return t("mission:modify-stations.title");
	};

	useEffect(() => {
		if (observations && observations.length && !/W[A-Za-z]W[A-Za-z][0-9][0-9]/.test(mission?.code)) {
			setRfnStation(observations
				.filter(e => e?.observation_type === "rfn" && e.draft === false)
				.map((e) => {
					return {
						trigramList: e?.content?.rfnStation.map(ee => ee?.trigram),
						dateTimeStart: e?.content?.dateTimeStart,
						dateTimeEnd: e?.content?.dateTimeEnd
					};
				}));
		}
	}, [observations, mission?.code]);

	const startDigiPlan = (withRfnStation) => {
		setShowRfnConfirmModal(false);

		let currentLine = line;

		// For TN attachment
		if (currentLine === "-1") {
			currentLine = driveLog.line_number;
		}

		// Only if user accept to send to digiplan rfn station
		const rfnStationEncoded = withRfnStation ? base64.encode(JSON.stringify(rfnStation)) : base64.encode(JSON.stringify([]));

		const urlValues = {
			fromApp: "DigiBulletin",
			driveLogId,
			missionId: currentMissionId,
			missionCode,
			line: currentLine,
			activeItem: "drive",
			rfnStation: rfnStationEncoded,
			trainNumber: trainNumber ?? null,
			trainComposition,
			hasLineSheet: hasLineSheet
		};
		const queryParams = queryString.stringify(urlValues);

		window.location.href = `${env?.DIGIPLAN_APP_SCHEME}://Drive?${queryParams}`;
		window.close();
	};

	return (
		<>
			<PopupFullPage title={getTitlePage()} backLink={previousPage} subHeader={subHeader()}>
				<Loader isLoading={!mission || !stationsList}>
					<form className="modify-stations" onSubmit={handleSubmit}>
						<ObservationSearch
							searchLabel={station === "station-start" ? t("mission:modify-stations.departure.label") : t("mission:modify-stations.arrival.label")}
							referential="station"
							selectedStation={fields?.station}
							setSelectedStation={handleChange("station")}
						/>
						<ObservationTime
							className="modify-stations__time"
							label={station === "station-start" ? t("mission:modify-stations.departure.time") : t("mission:modify-stations.arrival.time")}
							dateTime={fields?.dateTime}
							handleChange={handleChange("dateTime")}
							startServiceDateTime={driveLog?.realized_date_start}
							hasResetToCurrentTime
							setTimeError={setTimeError}
						/>
						<Button className="modify-stations__button" disabled={!formValid.station || !formValid.dateTime} type="submit">
							{t("mission:modify-stations.button")}
						</Button>
					</form>
				</Loader>
			</PopupFullPage>
			{ showRfnConfirmModal && <Modal type="custom">
				<div>
					<div onClick={() => setShowRfnConfirmModal(false)}>
						<Icon name="close" className="confirm-modal__icon"/>
					</div>
					<div className="confirm-modal__text">
						<p>{t("mission:modify-stations.modal-rfn-station.text-1")}</p>
						<p className="modify-stations__rfn-station-station__text-alert">{t("mission:modify-stations.modal-rfn-station.text-2")}</p>
						<p>{t("mission:modify-stations.modal-rfn-station.text-3")}</p>
					</div>


					<div className="confirm-modal__controls">
						<button className={"confirm-modal__cancel-btn button button--outline"} onClick={() => startDigiPlan(false)}>
							{t("core:modal.no")}
						</button>
						<button className={"confirm-modal__confirm-btn button"} onClick={() => startDigiPlan(true)}>
							{t("core:modal.yes")}
						</button>
					</div>
				</div>
			</Modal>}
		</>
	);
};

export default ModifyStations;
