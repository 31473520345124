import React from "react";
import PropTypes from "prop-types";
import Icon from "../../icon-svg/icon";
import classNames from "classnames";
import "./input-field.scss";

/**
 * Renders an <InputField /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {string} props.type - input type
 * @param {string} props.placeholder - input placeholder
 * @param {string} props.name - input name
 * @param {boolean} props.icon - icon name
 * @param {number} props.value -input value
 * @param {function} props.onChange - function to handle input events
 * @param {boolean} props.required - input field is required or not
 * @param {boolean} props.hasError - boolean if form has error
 * @param {function} props.validation - validation function
 */
const InputField = (props) => {
	const { className = "", placeholder, type = "text", name, icon, value = "", maxLength, onChange, onBlur, required, hasError, validation, disabled = false } = props;

	const inputFieldClassNames = classNames(className, { "input-field--error" : hasError });

	const handleChange = (e) => {
		e.stopPropagation();

		// If the component was not provided a validation function or if the result of the function is true
		if (!validation || validation(e.target.value || e.target.value === "")) {
			onChange(e.target.value);
		}
	};

	const handleKeyPress = (e) => {
		if (validation && !validation(e.key)) {
			e.preventDefault();
		}
	};

	return (
		<label className={`input-field ${inputFieldClassNames}`}>
			{icon && <Icon className="input-field__icon" name={icon} />}
			<input
				aria-label="Enter character"
				placeholder={placeholder}
				name={name}
				type={type}
				className="input-field__input"
				value={value}
				onChange={handleChange}
				onBlur={onBlur}
				onKeyPress={handleKeyPress}
				required={required}
				disabled={disabled}
				maxLength={maxLength}
			/>
		</label>
	);
};

InputField.propTypes = {
	className : PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	icon: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) ,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	validation: PropTypes.func,
	required: PropTypes.bool,
	hasError: PropTypes.bool
};

export default InputField;
