import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./card.scss";

const Card = (props) => {
	const { className, children } = props;

	const cardClassNames = classNames( className);

	return(
		<div className={cardClassNames}>
			{children}
		</div>
	);
};

Card.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
};

export default Card;
