import localForage from "../local-forage";
import { postDataToApi } from "./dispatch-post-request";
import { putDataToApi } from "./dispatch-put-request";
import { deleteDataFromApi } from "./dispatch-delete-request";

/**
 * Store key as having pending data
 * @param {string} key
 * @return {Promise<void>}
 */
const storePostPending = async (key) => {
	const currentPending = await localForage.getItem("dbl-pending-post");
	const newPending = currentPending?.length > 0 ? [...new Set([...currentPending, key])] : [key];
	await localForage.setItem("dbl-pending-post", newPending);
};

/**
 * Store key as having pending put data
 * @param {string} key
 * @return {Promise<void>}
 */
const storePutPending = async (key) => {
	const currentPending = await localForage.getItem("dbl-pending-put");
	const newPending = currentPending?.length > 0 ? [...new Set([...currentPending, key])] : [key];
	await localForage.setItem("dbl-pending-put", newPending);
};

/**
 * Store url as pending deletion
 * @param {string} url
 * @return {Promise<void>}
 */
const storeDeletePending = async (url) => {
	const currentPending = await localForage.getItem("dbl-pending-delete");
	const newPending = currentPending?.length > 0 ? [...new Set([...currentPending, url])] : [url];
	await localForage.setItem("dbl-pending-delete", newPending);
};

/**
 * Upload data to API
 * @param {object} data
 * @return {Promise<void>}
 */
const uploadPostDataFromStorage = async (data) => {
	const { dblCache, dblUrl, dblFields, dblMethod, ...pendingData } = data;
	await postDataToApi(dblUrl, pendingData);
};

/**
 * Upload data to API
 * @param {object} data
 * @return {Promise<void>}
 */
const uploadPutDataFromStorage = async (data) => {
	const { dblCache, dblUrl, dblFields, dblMethod, ...pendingData } = data;
	const dataToUpdateEntries = dblFields.map(fieldName => [fieldName, pendingData[fieldName]]);
	await putDataToApi(dblUrl, Object.fromEntries(dataToUpdateEntries));
};

/**
 * Retrieve pending post storage and upload to API
 * @param {string} key
 * @return {Promise<void>}
 */
const uploadPendingPostStorage = async (key) => {
	const pendingStorage = await localForage.getItem(key);

	// storage is either an array or an object
	if (pendingStorage?.data && Array.isArray(pendingStorage.data)) {
		const pendingPostElements = pendingStorage.data.filter(storageItem => {
			const { dblMethod } = storageItem;
			return dblMethod === "POST";
		});
		await Promise.allSettled(pendingPostElements.map(uploadPostDataFromStorage));
		const clearLocalFlags = pendingStorage.data.map(storageItem => {
			const { dblCache, dblUrl, dblFields, dblMethod,  ...data } = storageItem;
			if (dblMethod === "POST") {
				return data;
			}
			return storageItem;
		});
		await localForage.setItem(key, { data: clearLocalFlags, cacheDate: pendingStorage.cacheDate });
	} else {
		const { dblCache, dblUrl, dblFields, dblMethod, ...clearedData } = pendingStorage.data;
		if (dblMethod === "POST") {
			await uploadPostDataFromStorage(pendingStorage.data);
			await localForage.setItem(key, { data: clearedData, cacheDate: pendingStorage.cacheDate } );
		}
	}
};

/**
 * Retrieve pending storage and upload to API
 * @param {string} key
 * @return {Promise<void>}
 */
const uploadPendingPutStorage = async (key) => {
	const pendingStorage = await localForage.getItem(key);

	// storage is either an array or an object
	if (pendingStorage?.data && Array.isArray(pendingStorage.data)) {
		const pendingPostElements = pendingStorage.data.filter(storageItem => {
			const { dblMethod } = storageItem;
			return dblMethod === "PUT";
		});
		await Promise.allSettled(pendingPostElements.map(uploadPutDataFromStorage));
		const clearLocalFlags = pendingStorage.data.map(storageItem => {
			const { dblCache, dblUrl, dblFields, dblMethod,  ...data } = storageItem;
			if (dblMethod === "PUT") {
				return data;
			}
			return storageItem;
		});
		await localForage.setItem(key, { data: clearLocalFlags, cacheDate: pendingStorage.cacheDate });
	} else {
		const { dblCache, dblUrl, dblFields, dblMethod, ...clearedData } = pendingStorage.data;
		if (dblMethod === "PUT") {
			await uploadPutDataFromStorage(pendingStorage.data);
			await localForage.setItem(key, { data: clearedData, cacheDate: pendingStorage.cacheDate } );
		}
	}
};

/**
 * Synchronize all local storage
 * @return {Promise<void>}
 */
const synchronizeAllStorage = async () => {
	const currentDeletePending = await localForage.getItem("dbl-pending-delete");
	if (currentDeletePending?.length > 0) {
		await Promise.allSettled(currentDeletePending.map(async (url) => {
			await deleteDataFromApi(url);
		}));
		await localForage.setItem("dbp-pending-delete", []);
	}

	const currentPostPending = await localForage.getItem("dbl-pending-post");
	if (currentPostPending?.length > 0) {
		await Promise.allSettled(currentPostPending.map(async (key) => {
			await uploadPendingPostStorage(key);
		}));
		await localForage.setItem("dbl-pending-post", []);
	}

	const currentPutPending = await localForage.getItem("dbl-pending-put");
	if (currentPutPending?.length > 0) {
		await Promise.allSettled(currentPutPending.map(async (key) => {
			await uploadPendingPutStorage(key);
		}));
		await localForage.setItem("dbl-pending-put", []);
	}
};

export { storePostPending, storePutPending, storeDeletePending, synchronizeAllStorage };
