import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DateUtils, InputCell } from "../../../index";
import { RegexUtils } from "../../../index";
import "./time-picker.scss";

/**
 * Renders an <TimePicker /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {number} props.hours - display the hours
 * @param {function} props.setHours - set the hours
 * @param {number} props.minutes - display the minutes
 * @param {function} props.setMinutes - set the setMinutes
 * @param {function} props.setTimeError - set the setTimeError
 * @param {function} props.resetToCurrentTime - reset to current time
 */
const TimePicker = (props) => {
	const { className = "", hours, setHours, minutes, setMinutes, setTimeError, resetToCurrentTime, disabled = false } = props;
	const { t } = useTranslation();

	const [emptyHours, setEmptyHours] = useState(false);
	const [emptyMinutes, setEmptyMinutes] = useState(false);

	const [hoursBlurred, setHoursBlurred] = useState(true);
	const [minutesBlurred, setMinutesBlurred] = useState(true);

	const addPadding = (newValue) => {
		if (newValue >= 0 && newValue < 10) {
			return DateUtils.formatPadStart(newValue);
		}
		return newValue;
	};

	/**
	 * test if the value exist and under the max number provided, then save it
	 * @param {function} setter - the function which set the value
	 * @param {number} max - the maximal value to not exceed
	 */
	const saveUnderMaxValue = (setter, max) => (newValue) => {
		const parsedNewValue = parseInt(newValue, 10);
		if (parsedNewValue <= max) {
			setter(parsedNewValue);
		}
	};

	const handleClearHour = () => {
		setHours(0);
		setEmptyHours(true);
		setTimeError(true);
		setHoursBlurred(false);
	};

	const handleClearMinute = () => {
		setMinutes(0);
		setEmptyMinutes(true);
		setTimeError(true);
		setMinutesBlurred(false);
	};

	const handleHourChange = (newValue) => {
		if (!newValue) {
			handleClearHour();
		} else {
			saveUnderMaxValue(setHours, 23)(newValue);
			setEmptyHours(false);
			if (!emptyMinutes) {
				setTimeError(false);
			}
			setHoursBlurred(false);
		}
	};
	const handleMinutesChange = (newValue) => {
		if (!newValue) {
			handleClearMinute();
		} else {
			saveUnderMaxValue(setMinutes, 59)(newValue);
			setEmptyMinutes(false);
			if (!emptyHours) {
				setTimeError(false);
			}
			setMinutesBlurred(false);
		}
	};

	const getHourValue = () => {
		if (emptyHours) {
			return undefined;
		}
		if (hoursBlurred) {
			return addPadding(hours);
		}
		return hours;
	};

	const getMinuteValue = () => {
		if (emptyMinutes) {
			return undefined;
		}
		if (minutesBlurred) {
			return addPadding(minutes);
		}
		return minutes;
	};

	const handleResetToCurrentTime = () => {
		resetToCurrentTime();
		setEmptyHours(false);
		setHoursBlurred(true);
		setEmptyMinutes(false);
		setMinutesBlurred(true);
		setTimeError(false);
	};

	return (
		<>
			<div className={`time-picker ${className}`}>
				<InputCell
					className="input__hours"
					type="tel"
					pattern={RegexUtils.sequenceOfDigits}
					onChange={handleHourChange}
					value={getHourValue()}
					regex={RegexUtils.twoDigitNumber}
					onClick={handleClearHour}
					onBlur={() => setHoursBlurred(true)}
					disabled={disabled}
				/>
				<span className="time__cells__separator">:</span>
				<InputCell
					className="input__minutes"
					type="tel"
					pattern={RegexUtils.sequenceOfDigits}
					onChange={handleMinutesChange}
					value={getMinuteValue()}
					regex={RegexUtils.twoDigitNumber}
					onClick={handleClearMinute}
					onBlur={() => setMinutesBlurred(true)}
					disabled={disabled}
				/>
			</div>
			{resetToCurrentTime && (
				<button className="time-picker__reset" type="button" onClick={handleResetToCurrentTime}>
					{t("mission:modify-stations.now")}
				</button>
			)}
		</>
	);
};

TimePicker.propTypes = {
	className: PropTypes.string,
	hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setHours: PropTypes.func,
	minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setMinutes: PropTypes.func,
	setTimeError: PropTypes.func,
	resetToCurrentTime: PropTypes.func
};

export default TimePicker;
