import http from "../axios";
import localForage from "../local-forage";
import { storeDeletePending } from "./synchronize-storage";
import checkNetwork from "./check-network";

/**
 * put data to API and remove from local storage
 * @param {string} url - url to call
 * @return {Promise<Object>}
 */
const deleteDataFromApi = async (url) => await http.delete(url);

/**
 * Saves data to local storage
 * @param {string} id - item tech id
 * @param {string} itemKey - local storage key for item to delete
 * @param {string} listKey - local storage key for affected list of items
 * @param {string} url - request url
 * @return {Promise<void>}
 */
const saveRequestToLocalStorage = async (id, itemKey, listKey, url) => {
	await storeDeletePending(url);

	// update local storage
	const cacheItem = await localForage.getItem(itemKey);
	if (cacheItem) {
		await localForage.removeItem(itemKey);
	}

	const cacheList = await localForage.getItem(listKey);
	if (cacheList) {
		const { data: storedArr, cacheDate } = cacheList;
		const filteredArr = storedArr?.filter(el => el?.id !== id);
		await localForage.setItem(listKey, { data: filteredArr, cacheDate });
	}
};

/**
 * Dispatch put request to either local storage or to API
 * @param {string} id - item tech id
 * @param {string} itemKey - item to delete
 * @param {string} listKey - list to delete from
 * @param {string} url
 * @return {Promise<Object|void>}
 */
const dispatchDeleteRequest = async (id, itemKey, listKey, url) => {
	const online = await checkNetwork();

	if (online) {
		return await deleteDataFromApi(url);
	}

	return saveRequestToLocalStorage(id, itemKey, listKey, url);
};

export { deleteDataFromApi, saveRequestToLocalStorage };
export default dispatchDeleteRequest;
