import React, { useState } from "react";
import { Button } from "../../../../index";
import { useTranslation } from "react-i18next";
import FeedbackModal from "../feedback-modal/feedback-modal";
import ThanksFeedbackModal from "../thanks-feedback-modal/thanks-feedback-modal";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./menu-feedback-button.scss";

/**
 * Renders an <FeedbackMenuButton /> component
 */
const FeedbackMenuButton = (props) => {
	const { className, hideButton = false } = props;
	const { t } = useTranslation();

	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [showThanksModal, setShowThanksModal] = useState(false);

	const displayFeedbackModal = () => {
		setShowFeedbackModal(!showFeedbackModal);
	};

	const handleValidate = () => {
		setShowThanksModal(!showThanksModal);
	};

	const menuFeedbackButtonClassNames = classNames("feedback-menu__button", className);


	return (
		!hideButton &&
		<>
			<Button className={menuFeedbackButtonClassNames} light onClick={displayFeedbackModal} >
				<span className="feedback-modal__button__text">{t("core:feedback.menu-button.give-feedback")}</span>
			</Button>
			<FeedbackModal
				showModal={showFeedbackModal}
				toggleModal={setShowFeedbackModal}
				confirmCallback={() => handleValidate()}
			/>
			<ThanksFeedbackModal
				showModal={showThanksModal}
				toggleModal={setShowThanksModal}
			/>
		</>
	);
};

FeedbackMenuButton.propTypes = {
	hideButton: PropTypes.bool,
	className: PropTypes.string
};

export default FeedbackMenuButton;
