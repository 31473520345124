import React from "react";
import ObservationFields from "../observation-fields/observation-fields";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

/**
 * Grouped fields have no value of their own but group together different fields with or without a text separator
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ObservationGroupedField = (props) => {
	const { line, fieldSchema, fields, setFields, driveLog, observationFiles, setObservationFiles, observationId } = props;
	const { t } = useTranslation();

	const { fieldName, group, mandatory, separator } = fieldSchema;

	const isSignalObservation = fieldName === "signalDetail";
	const obsGroupClassNames = classNames("observation__group", { "observation__group--column": isSignalObservation });

	/**
	 * handler for grouped form fields change
	 * @param {string} fieldToUpdate
	 * @return {function(*): void}
	 */
	const handleGroupChange = (fieldToUpdate) => (newValue) => {
		setFields({ ...fields, [fieldToUpdate]: newValue });
	};

	/**
	 * return fields in a group
	 * @return {function(*=, *)}
	 */
	const getGroupedField = (childSchema, index) => {
		const { fieldName: childName, validator, setTimeError } = childSchema;
		const hasSeparator = index < Object.keys(group).length - 1;

		return (
			<React.Fragment key={`${fieldName}-${childName}`}>
				<ObservationFields
					line={line}
					fieldName={childName}
					state={fields[childName]}
					handleChange={handleGroupChange(childName)}
					mandatory={mandatory}
					validator={validator}
					setTimeError={setTimeError}
					fieldSchema={fieldSchema}
					driveLog={driveLog}
					observationFiles={observationFiles}
					setObservationFiles={setObservationFiles}
					observationId={observationId}
				/>
				{hasSeparator && (
					<div className="observation__group__separator">
						{separator && t(`observation:observation-fields.${separator}`)}
					</div>
				)}
			</React.Fragment>
		);
	};

	return (
		<div className={obsGroupClassNames}>
			{group.map(getGroupedField)}
		</div>
	);
};

export default ObservationGroupedField;
