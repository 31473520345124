import { http } from "../../config";

/**
 * Get the latest purge Date (Oldest driveLog and Latest Purge)
 * @returns {Promise} response
 */
const getDriveLogPurgeData = async () =>  http.get("/purge/drive-log");

export { getDriveLogPurgeData };

const PurgeServices = {
	getDriveLogPurgeData
};

export default PurgeServices;
