import React from "react";
import { useLocation, useParams } from "react-router-dom";
import InputDelayPage from "../../../../components/input-delay-page/input-delay-page";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

const BeyondSixHourThirty = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;
	const { id } = useParams();
	const location = useLocation();
	const param = queryString.parse(location.search);
	const { t } = useTranslation();

	return (
		<InputDelayPage
			driveLogId={id}
			fieldToUpdate="daily_legal_time_exceed"
			title={t("dl:delay-list-page.modify-six-hour-thirty")}
			redirect={param?.redirect}
			redirectUrl={redirectUrl}
		/>
	);
};

export default BeyondSixHourThirty;
