import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { parseISO, startOfDay } from "date-fns";
import DriveLogDelayDetailsTableRow from "./drive-log-delay-details-table-row/drive-log-delay-details-table-row";
import "./drive-log-delay-details-table.scss";

const DriveLogDelayDetailsTable = (props) => {
	const { driveLogs, hrToArchiveView = false, hrArchivedView = false, refresh = () => {} } = props;
	const [ driveLogsByDays, setDriveLogsByDays ] = useState([]);

	const generateDriveLogsRowsByDay = useCallback((dlByDay) => {
		return <div className={"drive-log-delay-details-table__box-by-day"} key={dlByDay?.label}>
			<div className={"drive-log-delay-details-table__subtitle_date"}>{dlByDay?.label}</div>
			{dlByDay?.dl.map((dl) => <DriveLogDelayDetailsTableRow driveLog={dl} key={dl?.id} hrToArchiveView={hrToArchiveView} hrArchivedView={hrArchivedView}
				refresh={refresh}/>)}
		</div>;
	}, [refresh, hrToArchiveView, hrArchivedView]);

	useEffect(() => {
		if (Array.isArray(driveLogs)) {
			let dlGroupedByDays = [];
			driveLogs.forEach((dl) => {
				if (dl?.realized_date_start) {
					const dlDate = parseISO(dl.realized_date_start);
					const stringDate = dlDate.toLocaleString("fr-FR", { day: "numeric", month: "long", year: "numeric" });
					let index = dlGroupedByDays.findIndex(e => e.label === stringDate);
					if (index === -1) {
						index = dlGroupedByDays.length;
						dlGroupedByDays.push({ dl: [], label: stringDate, startDate: startOfDay(dlDate) });
					}

					dlGroupedByDays[index]?.dl?.push(dl);
				}
			});

			setDriveLogsByDays(dlGroupedByDays);
		}
	}, [driveLogs]);

	return (
		<div className={"drive-log-delay-details-table__container"}>
			{Array.isArray(driveLogsByDays) && driveLogsByDays.map(generateDriveLogsRowsByDay)}
		</div>
	);
};

DriveLogDelayDetailsTable.propTypes = {
	driveLogs: PropTypes.array,
	hrToArchiveView: PropTypes.bool,
	hrArchivedView: PropTypes.bool,
	refresh: PropTypes.func
};

export default DriveLogDelayDetailsTable;
