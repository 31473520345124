/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { RadioButton } from "../..";
import "./multi-radio-field.scss";

const MultiRadioField = (fieldProps) => {
	const { translation = "", fieldName = "", options = [], value = "", handleChange, className = "", disabled = false } = fieldProps;
	const { t } = useTranslation(translation);

	const onChange = (v) => {
		handleChange(fieldName, v);
	};

	const renderRadioButtons = (option, idx) => (
		<RadioButton
			key={`multi-radio__field__button-${idx}`}
			className="multi-radio__field__button"
			checkedClassName="multi-radio__field__button--checked"
			name={fieldName}
			checked={option.value === value}
			onChange={() => onChange(option.value)}
			label={option.label}
			showRadioTick
			disabled={disabled}
		/>
	);

	return (
		<div className={`multi-radio__field ${className}`}>
			<div className={`multi-radio__field__radio ${className}`}>{t(`${translation}.forms.fields.labels.${fieldName}`)}</div>

			<div className={`multi-radio__field__container ${className}`}>{options.map(renderRadioButtons)}</div>
		</div>
	);
};

export default MultiRadioField;
