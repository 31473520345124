import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import useOnClickOutside from "../../../../../../core/custom-hooks/use-on-click-outside";
import { Icon } from "../../../../../../core";
import DocumentCard from "../document-card/document-card";
import { getLineSheet } from "../../../../../sheets-line.service";
import { getDriveLogById } from "../../../../../drive-log.services";
import "./pop-up-documents.scss";

/**
 * Renders a <PopUpDocuments /> component
 * @param {Object} props
 * @param {boolean} props.showPopUp - the boolean that defines if the PopUpDocuments is opened
 * @param {function} props.setShowPopUp - set the setShowPopUp
 * @param {string} props.driveLogId - the drive log id
 */
const PopUpDocuments = ({ showPopUp, setShowPopUp, driveLogId  }) => {
	const { t } = useTranslation();
	const modalRef = useRef();

	const [line, setLine] = useState("");
	const [dlAttachment, setDlAttachment] = useState("");
	const [publishedLineSheet, setPublishedLineSheet] = useState({});
	const [comingLineSheet, setComingLineSheet] = useState({});
	const noPublishedLineSheet = Object.keys(publishedLineSheet).length === 0 && Object.keys(comingLineSheet).length === 0;

	const closePopUp = () => {
		setShowPopUp (!showPopUp);
	};

	useOnClickOutside(modalRef, closePopUp);

	const getDriveLogLine = () => {
		getDriveLogById(driveLogId).then(response => {
			const { data: driveLog } = response || {};
			const { line_number: lineNumber, attachment_name: attachmentName } = driveLog || {};
			setLine(lineNumber);
			setDlAttachment(attachmentName);
		});
	};

	//retrieve published and coming line sheets
	const getPublishedAndComingLineSheet =  () => {
		getLineSheet({ line, status: "published" })
			.then((res) => {
				if (res) {
					setPublishedLineSheet(res);
				}
			});
		getLineSheet({ line, status: "coming" })
			.then((res) => {
				if (res) {
					setComingLineSheet(res);
				}
			});
	};

	useEffect(getDriveLogLine, [driveLogId]);
	useEffect(getPublishedAndComingLineSheet, [driveLogId, line]);

	return (
		showPopUp && <div className="dl-popup-documents" >
			<div className="dl-popup-documents__card" ref={modalRef} >
				<div className="dl-popup-documents__header">
					<h3 className={"dl-popup-documents__title"}>{t("dl:dl-documents.documents")}</h3>
					<button className="dl-popup-documents__icon-container" onClick={closePopUp}>
						<Icon name="close" className="dl-popup-documents__icon" />
					</button>
				</div>
				<div className={"dl-popup-documents__core"}>
					<DocumentCard
						disabled={noPublishedLineSheet}
						mainText={t("dl:dl-documents.line-sheet")}
						secondaryText={dlAttachment}
						url={`/line-sheets?driveLogId=${driveLogId}`}
					/>
					<DocumentCard
						mainText={t("dl:dl-documents.schedule-documents")}
						secondaryText={dlAttachment}
						url={`/drive-log/${driveLogId}/schedule-document/daily`}/>
				</div>
			</div>
		</div>
	);
};

PopUpDocuments.propTypes = {
	showPopUp: PropTypes.bool.isRequired,
	setShowPopUp: PropTypes.func.isRequired,
	driveLogId: PropTypes.string.isRequired
};

export default PopUpDocuments;
