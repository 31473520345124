import React from "react";
import PropTypes from "prop-types";

import "./observation-reminder-text.scss";

/**
 * Renders an <ObservationReminderText /> component
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.text
 */
const ObservationReminderText = (props) => {
	const { className = "", text } = props;

	return (
		<div className={`observation-reminder-text ${className}`}>
			{text}
		</div>
	);
};

ObservationReminderText.propTypes = {
	className : PropTypes.string,
	text: PropTypes.string
};

export default ObservationReminderText;
