import http from "../axios";

/**
 * Check if application is online, display an offline message in console otherwise
 * @return {Promise<number>}
 */
const checkNetwork = async () => {
	try {
		const ping = await http.get("/status");
		return ping?.status;
	} catch (err) {
		console.info("Application is offline", err);
	}
};

export default checkNetwork;
