import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, checkArrayEmpty } from "../../../core";
import { ConsistencyOverlapsListRow } from "../";
import { validateConsistencyOverlaps } from "../../consistency-report.service";
import "./consistency-overlaps-list-group.scss";

/**
 * ConsistencyOverlapsListGroup component for each mission code
 * @param props
 * @param {string} props.date date
 * @param {array} props.groupOverlaps overlaps list from DB
 * @param {array} props.localOverlaps overlaps list from LocalStorage
 * @param {string} props.missionCode mission_code
 * @param {function} props.handleOpenEditModal edit modal handler
 * @param {function} props.handleOpenInfoModal info modal handler
 * @param {function} props.fetchLocalOverlaps fetchLocalOverlaps
 * @param {function} props.retrieveConsistencyReports retrieveConsistencyReports from DB
 * @return {JSX.Element}
 * @constructor
 */
const ConsistencyOverlapsListGroup = (props) => {
	const { t } = useTranslation("cr");

	const { date, groupOverlaps, localOverlaps = [], missionCode, handleOpenEditModal, handleOpenInfoModal, fetchLocalOverlaps, retrieveConsistencyReports = () => {} } = props;
	const canValidate = missionCode && localOverlaps?.length > 0 && localOverlaps.find(({ code: localMissionCode }) => missionCode === localMissionCode);
	const [isLoading, setIsLoading] = useState(false);

	const handleValidate = () => {
		// Get only the overlaps with the corresponding mission_code for the current date
		setIsLoading(true);
		const groupLocalOverlaps = localOverlaps?.filter(({ date: localDate, code: localCode }) => localCode === missionCode && localDate === date);
		
		validateConsistencyOverlaps(date, groupLocalOverlaps)
			.then(() => {
				retrieveConsistencyReports();
			})
			.catch(() => {
				// There has been a validation by another user since last data fetch and the overlap is no longer applicable
				handleOpenInfoModal();
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const renderReportOverlapsGroupRows = () => {
		if (!groupOverlaps || checkArrayEmpty(groupOverlaps)) {
			return null;
		}

		return groupOverlaps?.map((reportOverlap) => {
			const key = reportOverlap?.id;
			const missionId = reportOverlap?.mission_id;
			const localOverlap = missionId && localOverlaps?.length > 0 ? localOverlaps.find(({ id }) => id === missionId) : null;

			return (
				<ConsistencyOverlapsListRow
					key={key}
					date={date}
					reportOverlap={reportOverlap}
					localOverlap={localOverlap}
					handleOpenEditModal={handleOpenEditModal}
					fetchLocalOverlaps={fetchLocalOverlaps}
					isLoading={isLoading}
				/>
			);
		});
	};

	return (
		<div className="consistency-overlaps__list__group__container">
			<div className="consistency-overlaps__list__group__mission-code-container">
				<span className="consistency-overlaps__list__group__mission-code">
					<span className="consistency-overlaps__list__group__mission-code-chip">{missionCode}</span>
				</span>
			</div>

			<div>{renderReportOverlapsGroupRows()}</div>

			<div className="consistency-overlaps__list__group-action">
				<span className="consistency-overlaps-list__row__cell consistency-overlaps-list__row__cell-validate">
					<Button className="consistency-overlaps-list__row__cell__validate" disabled={!canValidate || isLoading} onClick={handleValidate}>
						<span>{t("consistency-report.consistency-overlaps.row.validate")}</span>
					</Button>
				</span>
			</div>
		</div>
	);
};

ConsistencyOverlapsListGroup.propTypes = {
	groupOverlaps: PropTypes.array,
	localOverlaps: PropTypes.array,
	missionCode: PropTypes.string,
	handleOpenEditModal: PropTypes.func,
	fetchLocalOverlaps: PropTypes.func,
	handleValidate: PropTypes.func,
	isLoading: PropTypes.bool
};

export default ConsistencyOverlapsListGroup;
