import React from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";

// import { Icon } from "../../../../core";
import "./input-checkbox.scss";

/**
 * Renders an <InputCheckbox /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {string} props.name - the name of the field
 * @param {string} props.labelClassname - the span label's name
 * @param {string} props.checked - verify if the checkbox is checked
 * @param {function} props.onChange - function to handle change events
 */
const InputCheckbox = (props) => {
	const { name = "", isChecked = false, handleClick = () => {} } = props;

	const handleChange = (e) => {
		e.stopPropagation();
		const { checked, name: inputName } = e.target;
		handleClick(checked, inputName);
	};

	return (
		<label htmlFor={name}>
			<input
				type="checkbox"
				className="input__checkbox"
				name={name}
				checked={isChecked}
				onChange={handleChange}
			/>
		</label>
	);
};

// InputCheckbox.propTypes = {
// };

export default InputCheckbox;
