import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./toggle-switch.scss";

/**
 * Renders an <ToggleSwitch /> component
 * @param {Object} props
 * @param {string} props.className
 * @param {boolean} props.isChecked
 * @param {string} props.sliderClassName
 * @param {function} props.handleOnChange
 * @param {function} props.name
 */
const ToggleSwitch = (props) => {
	const { className, isChecked, sliderClassName, handleOnChange, name = "" } = props;

	const toggleSwitchClassNames = classNames("toggle-switch__slider", sliderClassName, { "checked": isChecked });

	return (
		<label className={`${className} toggle-switch`}>
			<input className="toggle-switch__input" type="checkbox" checked={isChecked} onChange={handleOnChange} name={name} />
			<span className={toggleSwitchClassNames}/>
		</label>
	);
};

ToggleSwitch.propTypes = {
	className : PropTypes.string,
	isChecked : PropTypes.bool,
	sliderClassName : PropTypes.string,
	handleOnChange : PropTypes.func,
	name : PropTypes.string
};

export default ToggleSwitch;
