import i18next from "../../config/i18next";
import i18nCore from "./i18n/fr.json";
import Button from "./components/button/button";
import DriverHeader from "./components/headers/driver-header/driver-header";
import Icon from "./components/icon-svg/icon";
import InputCell from "./components/input-fields/input-cell/input-cell";
import InputDropdown from "./components/input-fields/input-dropdown/input-dropdown";
import InputField from "./components/input-fields/input-field/input-field";
import InputTextarea from "./components/input-fields/input-textarea/input-textarea";
import InputCheckbox from "./components/input-fields/input-checkbox/input-checkbox";
import MultiInputCells from "./components/input-fields/multi-input-cells/multi-input-cells";
import ResponsiveText from "./components/responsive-text/responsive-text";
import Modal from "./components/modal/modal";
import AdminPage from "./components/page-wrappers/admin-page/admin-page";
import DriverPage from "./components/page-wrappers/driver-page/driver-page";
import SupervisorPage from "./components/page-wrappers/supervisor-page/supervisor-page";
import SpecialNoticePage from "./components/page-wrappers/special-notice-page/special-notice-page";
import ConsultantPage from "./components/page-wrappers/consultant-page/consultant-page";
import HrPage from "./components/page-wrappers/hr-page/hr-page";
import PageWrapper from "./components/page-wrappers/page-wrapper/page-wrapper";
import PopupFullPage from "./components/page-wrappers/popup-full-page/popup-full-page";
import SearchField from "./components/input-fields/search-field/search-field";
import TimePicker from "./components/input-fields/time-picker/time-picker";
import DatePickerComponent from "./components/date-picker-component/date-picker-component";
import SearchStationReferential from "./components/search-station-referential/search-station-referential";
import DateUtils from "./utils/date-utils/date-utils";
import * as RegexUtils from "./utils/regex-utils/regex-utils";
import stripUnnecessaryCharacters from "./utils/strip-unnecessary-characters/strip-unnecessary-characters";
import useDebounce from "./custom-hooks/use-debounce";
import useDriveLog from "./custom-hooks/use-drive-log";
import updateArrayElement from "./utils/update-array-element/update-array-element";
import * as ValidateUtils from "./utils/validation-utils/validation-utils";
import PlusIcon from "./components/plus-icon/plus-icon";
import RadioButton from "./components/radio-button/radio-button";
import LoadMore from "./components/load-more/load-more";
import Comment from "./components/comment/comment";
import SearchBar from "./components/search-bar/search-bar";
import Loader from "./components/loader/loader";
import Dropdown from "./components/dropdown/dropdown";
import ToggleSwitch from "./components/toggle-switch/toggle-switch";
import TwoChoicesButtons from "./components/two-choices-buttons/two-choices-buttons";
import checkArrayEmpty from "./utils/check-array-empty/check-array-empty";
import checkObjectEmpty from "./utils/check-object-empty/check-object-empty";
import Assets from "./assets/assets";
import properCase from "./utils/proper-case/proper-case";
import arrayHasEmptyValues from "./utils/array-has-empty-values/array-has-empty-values";
import Card from "./components/card/card";
import FeedbackModal from "./components/feedback/components/feedback-modal/feedback-modal";
import FeedbackMenuButton from "./components/feedback/components/feedback-menu-button/menu-feedback-button";
import ThanksFeedbackModal from "./components/feedback/components/thanks-feedback-modal/thanks-feedback-modal";
import Overlay from "./components/overlay/overlay";
import ExportPageWrapper from "./components/page-wrappers/export-page-wrapper/export-page-wrapper";

i18next.addResourceBundle("fr", "core", i18nCore);

export {
	Button,
	AdminPage,
	DriverPage,
	SupervisorPage,
	SpecialNoticePage,
	ConsultantPage,
	DriverHeader,
	ResponsiveText,
	PopupFullPage,
	InputCell,
	InputDropdown,
	InputField,
	InputTextarea,
	InputCheckbox,
	PageWrapper,
	LoadMore,
	MultiInputCells,
	Modal,
	HrPage,
	Icon,
	SearchField,
	TimePicker,
	DatePickerComponent,
	SearchStationReferential,
	DateUtils,
	RegexUtils,
	ValidateUtils,
	stripUnnecessaryCharacters,
	updateArrayElement,
	useDebounce,
	useDriveLog,
	PlusIcon,
	RadioButton,
	Comment,
	Loader,
	SearchBar,
	Dropdown,
	ToggleSwitch,
	TwoChoicesButtons,
	checkArrayEmpty,
	checkObjectEmpty,
	properCase,
	arrayHasEmptyValues,
	Assets,
	Card,
	FeedbackModal,
	FeedbackMenuButton,
	ThanksFeedbackModal,
	Overlay,
	ExportPageWrapper
};
