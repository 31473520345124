import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { Schedule, AddSheetLine, ServiceNumber, AttachmentList } from "../../../index";
import { createNewDriveLog, getDailyDriveLog, updateDriveLog } from "../../../drive-log.services";
import { getLineSheet } from "../../../sheets-line.service";
import ReplaceExistingDlModal from "../../../components/add-sheet-line/modals/replace-existing-dl-modal/replace-existing-dl-modal";

const CreateEmptyDl = () => {
	const [newDriveLog, setNewDriveLog] = useState({});
	const [activeStep, setActiveStep] = useState(0);
	const [newDriveLogId, setNewDriveLogId] = useState();
	const [displayAddSheetLine, setDisplayAddSheetLine] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const history = useHistory();
	const location = useLocation();
	const queryParams = queryString.parse(location.search);
	const createNewDl = Boolean(queryParams["new-drivelog"]);


	const [showReplaceExistingDlModal, setReplaceExistingDlModal] = useState(false);

	const toggleReplaceExistingDlModal = () => setReplaceExistingDlModal(!showReplaceExistingDlModal);

	const handleNavigation = (newDriveLogData) => {
		const newStep = activeStep + 1;
		let updatedDriveLog = { ...newDriveLog, ...newDriveLogData };
		const driveLogLine = updatedDriveLog?.line;
		if (newStep < 3) {
			setActiveStep(newStep);
			setNewDriveLog(updatedDriveLog);
		} else if (newStep === 3) {
			setNewDriveLog(updatedDriveLog);
			setActiveStep(newStep);
			getLineSheet({ line: driveLogLine, status: "published" }).then(response => {
				if (response.data) {
					const sheetLineNumber = response.fileName;
					updatedDriveLog = { ...newDriveLog, ...newDriveLogData, sheetLineNumber };
					setNewDriveLog(updatedDriveLog);
					if (createNewDl) {
						toggleReplaceExistingDlModal();
					} else {
						produceNewDL(updatedDriveLog, true);
					}
				} else {
					setDisplayAddSheetLine(!displayAddSheetLine);
				}
			}).catch(err => {
				console.error(err);
				setDisplayAddSheetLine(!displayAddSheetLine);
				setNewDriveLog(updatedDriveLog);
			});
		} else {
			produceNewDL(updatedDriveLog, false);
		}
	};

	// Drive log creation
	const produceNewDL = (driveLogData = {}, sheetLineFound) => {
		const { attachmentName, attachmentBigram, line, realized_date_end, realized_date_start, serviceNumber, sheetLineNumber, isPsg } = driveLogData;

		let formattedNewDl = {
			"attachment_name": attachmentName,
			"attachment_bigram": attachmentBigram,
			"line_number": line,
			"sheet_line_number": sheetLineNumber,
			"realized_service_number": serviceNumber,
			"realized_date_start": realized_date_start,
			"realized_date_end": realized_date_end,
			"is_psg": isPsg
		};

		createNewDriveLog(formattedNewDl, "empty")
			.then(res => {
				const createdDriveLogId = res?.data;
				setNewDriveLogId(createdDriveLogId);
				const lineSheetUrl = `/line-sheet?driveLogId=${createdDriveLogId}`;
				const driveLogUrl = `/drive-log/${createdDriveLogId}`;
				const redirectionUrl = sheetLineFound ? lineSheetUrl : driveLogUrl;
				return history.push(redirectionUrl);
			})
			.catch(err => {
				console.error(err);
				history.push("/daily-drive-log");
			});
	};

	// Updates drive log and handles redirection
	const manageLineSheets = useCallback(async (currentDl = {}, lineSheetFileName = "") => {
		const { id: driveLogID } = currentDl;
		// If line sheets are present, we redirect to line sheet page
		if (lineSheetFileName.length > 0) {
			const updatedDl = { ...currentDl, "sheet_line_number": lineSheetFileName };
			await updateDriveLog(updatedDl, { action: "add-sheet-line" });
			return history.push(`/line-sheet?driveLogId=${driveLogID}`);
		}
		// If no line sheets, we manually add line sheet
		return history.push(`/drive-log/${driveLogID}/modify-sheet-line-number`);
	}, [history]);

	const getAllData = useCallback(() => {
		getDailyDriveLog({ type: "daily" })
			.then(response => {
				if (response) {
					const driveLog = response?.data;
					const line = driveLog?.line_number;
					getLineSheet({ line, status:"published" })
						.then(async res => {
							const lineSheetNumber = res?.fileName || "";

							/**
							 * Empty drive log
							 * - if we need to make sure to create a new empty drive log - see modifying service
							 * - if no line sheets are present, we create a brand new drive log - see createNewDriveLog above
							 */
							if (createNewDl && !driveLog?.sheet_line_number) {
								return;
							}

							if (driveLog?.id && !driveLog?.sheet_line_number) {
								await manageLineSheets(driveLog, lineSheetNumber);
							}
						});
				}
			})
			.catch(err => console.error(err))
			.finally(() => {
				setIsLoading(false);
			});
	}, [createNewDl, manageLineSheets]);

	useEffect(getAllData, [getAllData]);

	return (
		<>
			{activeStep === 0 && <Schedule navigate={handleNavigation} loading={isLoading}/>}
			{activeStep === 1 && <AttachmentList navigate={handleNavigation} showOptionPSG={true}/>}
			{activeStep === 2 && <ServiceNumber navigate={handleNavigation}/>}
			{activeStep === 3 && displayAddSheetLine && <AddSheetLine navigate={handleNavigation} newDriveLogId={newDriveLogId} />}
			{activeStep === 3 && <ReplaceExistingDlModal
				showModal={showReplaceExistingDlModal}
				toggleModal={toggleReplaceExistingDlModal}
				navigate={setActiveStep}
				callback={() => produceNewDL(newDriveLog, !displayAddSheetLine )}
				isNewDl
			/>}
		</>
	);
};

export default CreateEmptyDl;
