import React from "react";
import { useTranslation } from "react-i18next";
import "./card.scss";

/**
 * Card put together different fields in a card design
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const Card = (props) => {
	const { index = "", title = "", children = null, className = "" } = props;
	const { t } = useTranslation("special-notice");

	return (
		<div className={`card ${className}`}>
			{title && (
				<span className={`card__title ${className}`}>
					{index && <span>{index}. </span>}
					{t(title)}
				</span>
			)}

			{children}
		</div>
	);
};

export default Card;
