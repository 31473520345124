import React, { useState } from "react";
import { Icon, InputTextarea } from "../../index";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./comment.scss";

/**
 * Renders a Comment component
 * @param {Object} props
 * @param {function} props.handleChange
 * @param {String} props.value
 * @param {String} props.className
 * @param {String} props.title
 * @param {String} props.placeholder
 * @param {boolean} props.hasError
 */
const Comment = ({ handleChange, value, className = "", title = "", placeholder,
					 hasError , maxLength = 500, readOnly = false, collapse = false }) => {
	const { t } = useTranslation();

	const [ showBlock, setShowBlock ] = useState(!collapse);

	return (
		<div className={`comment-text-area-wrapper ${className}`}>
			{title && (
				<>
					<div className="comment-text-area-wrapper__header">
						<h3 className="comment-text-area-wrapper__title">
							{title}
						</h3>
						{ collapse && <Icon
							name="arrow-right"
							className={`comment-text-area-wrapper__icon comment-text-area-wrapper__icon--${showBlock ? "on": "off"}`}
							onClick={() => setShowBlock(!showBlock)}/>}
					</div>

					{showBlock && !readOnly && <div className="comment-text-area-wrapper__label">
						{t("core:comment.max-characters", { maxLength })}
					</div>}
				</>
			)}
			{showBlock && <InputTextarea
				className="comment-text-area-wrapper__input"
				placeholder={placeholder}
				name="reason"
				value={value}
				rows={4}
				maxLength={maxLength}
				onChange={handleChange}
				hasError={hasError}
				disabled={readOnly}
			/>}
		</div>
	);
};

Comment.propTypes = {
	handleChange: PropTypes.func,
	className: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	placeholder: PropTypes.string,
	hasError: PropTypes.bool
};

export default Comment;
