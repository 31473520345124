/**
 * Check if a delay is valid (delays should always be below 1440min)
 * @param {Number | string} delay - the delay to validate
 * @param {boolean} mandatory - the delay can be empty
 * @returns {Boolean} returns if delay is valid or not
 */
const validateDelay = (delay = 0, mandatory = false) => {
	if (!mandatory && delay === "") {
		return true;
	}

	if (!isNaN(delay)) {
		const castedDelay = typeof delay === "string" ? parseInt(delay, 10) : delay;
		return castedDelay <= 1440 && castedDelay >= 0;
	} else {
		return false;
	}
};

/**
 * Check if a reason is valid (reasons should be between 0 and 140 characters)
 * @param {string} reason
 * @param {boolean} mandatory
 * @return {boolean} returns true if reason valid, false otherwise
 */
const validateReason = (reason, mandatory = false) => {
	if (!mandatory && reason === "") {
		return true;
	}

	return reason?.length > 0 && reason?.length < 140;
};

/**
 * @function validateInput - Check if an input is valid (empty or valid regex)
 * @param {Number | String} value - the input to validate
 * @param {RegExp | String} regex - the regex to match the input value
 * @returns {Boolean} returns if the input value is valid or not
 */
const validateInput = (value, regex) => {
	const valueEmpty = !value || value === "";
	const validRegex = regex.test(value);
	return valueEmpty || validRegex;
};

const validatePlaces = (place, placeReferential) => {
	const places = {
		station: "Gare",
		intermediateStation: "Intergare",
		storage: "Faisceau"
	};

	return (
		place &&
		((place === places.station && placeReferential?.station?.key) ||
			(place === places.intermediateStation && placeReferential?.stationStart?.key && placeReferential?.stationEnd?.key) ||
			(place === places.storage && placeReferential?.storage?.key))
	);
};

const validateMissionId = (missionId) => {
	const regex = /^[a-zA-Z]{4}[0-9]{2}$/;
	const missionIdString = missionId.join("");
	return regex.test(missionIdString);
};

export { validateDelay, validateInput, validateReason, validatePlaces, validateMissionId };
