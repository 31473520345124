import { http } from "../../config";

/**
 * add observation to drive log
 * @param {Object} config
 * @returns {Promise} response
 */
const addSurvey = (config = {}) => {
	const { driveLogId, observationId, formData: survey } = config;
	return http.post(`/drive-log/${driveLogId}/observation/${observationId}/survey`, { content: { ...survey } });
};

/**
 * get the list of surveys related to the drive log id
 * @param {String} driveLogId drive log uuid
 * @returns {Promise} response
 */

const getAllSurveysInDl = async (driveLogId) => {
	return http.get(`/drive-log/${driveLogId}/survey`);
};

/**
 * get a survey by id
 * @param {String} driveLogId drive log uuid
 * @param {String} surveyId survey uuid
 * @returns {Promise} response
 */

const getSurveyById = async (driveLogId, surveyId) => {
	return http.get(`/drive-log/${driveLogId}/survey/${surveyId}`);
};

/**
 * delete a survey in a given drive-log
 * @param {string} driveLogId - drive log uuid
 * @param {string} observationId - the observation uuid
 * @param {string} surveyId - the survey uuid
 * @returns {Promise} response
 */
const deleteSurvey = (driveLogId, observationId, surveyId) => {
	return http.delete(`/drive-log/${driveLogId}/observation/${observationId}/survey/${surveyId}`);
};

/**
 * update a survey in a given drive-log
 * @param {Object} config
 * @returns {Promise} response
 */
const updateSurvey = (config = {}) => {
	const { driveLogId, observationId, surveyId, formData } = config;
	return http.put(`/drive-log/${driveLogId}/observation/${observationId}/survey/${surveyId}`, { content: { ...formData } });
};

export { addSurvey, getAllSurveysInDl, getSurveyById, deleteSurvey, updateSurvey };


