import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "../../../../../../../core";
import retrieveNextObservationWithoutSurvey from "../../../../../../../survey/utils/retrieve-next-observation-id-for-survey";

/*
	Modal to display when the 'Finalize Drive log' button is clicked
	The user is either routed to the add delay page if the answer is yes
	or to the sign drive log page if the answer is no
 */
const FinalizeDlModal = ({ showModal, hideModal, redirectUrl = ""  }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id } = useParams();

	const modalText = t("dl:dl-detail-page.modals.add-delay");

	const confirmCallback = () => {
		history.push(`${redirectUrl}/${id}/add-delay`);
	};
	const cancelCallback = async () => {
		const observationId = await retrieveNextObservationWithoutSurvey(id);
		if (observationId) {
			history.push(`${redirectUrl}/${id}/observation/${observationId}/add-survey`);
		} else {
			history.push(`${redirectUrl}/${id}/sign`);
		}
	};

	// handle click to simply close modal
	const closeCallback = () => hideModal();

	return (
		showModal && <Modal type="confirm" title={modalText} confirmCallback={confirmCallback} cancelCallback={cancelCallback} closeCallback={closeCallback}/>
	);
};

FinalizeDlModal.propTypes = {
	showModal: PropTypes.bool,
	hideModal: PropTypes.func
};

export default FinalizeDlModal;
