import { http } from "../../config";

/**
 * get all users
 * @param {object} config Configuration for the getDriveLogPurgeData request
 * @returns {Promise} response
 */
const getUsers = async (config = {}) => {
	const { numberResults = 40, search, searchOptions, currentUser } = config;
	const params = { numberResults };
	if (search && searchOptions) {
		params.search = search;
		params.searchOptions = searchOptions;
	}
	if (currentUser.role === "localAdmin") {
		params.line = currentUser.line;
	}
	return http.get("/user", { params });
};

/**
 * get an user by its id from digiref (and check before if it does exist in digibul)
 * @param {object} userId the userId
 * @param {object} config Configuration for the getUserById request
 * @returns {Promise} response
 */
const getUserById = async (userId, config= {}) => {
	const { extendedSearch } = config;
	const params = { extendedSearch };
	return http.get(`/user/${userId}`, { params });
};

/**
 * create a new user
 * @param {object} userData the user data object
 * @returns {Promise} response
 */
const createUser = async (userData) => http.post("/user", userData);

/**
 * deleteUser
 * @param {string} userId the user id to delete
 * @param {object} userData the user data object
 * @returns {Promise} response
 */
const deleteUser = async (userId, userData) => http.delete(`/user/${userId}`, userData);


/**
 * updateUser
 * @param {string} userId the user id to update
 * @param {object} userData the user data object
 * @returns {Promise} response
 */
const updateUser = async (userId, userData) => http.put(`/user/${userId}`, userData);

export {
	getUsers,
	createUser,
	updateUser,
	deleteUser,
	getUserById
};
