import React from "react";
import PropTypes from "prop-types";

import UserSatisfactionForm from "./user-satisfaction-form/user-satisfaction-form";
import { Modal } from "../../../../core";
import Icon from "../../../../core/components/icon-svg/icon";
import "./user-satisfaction-survey.scss";

/**
 * Renders an UserSatisfactionSurvey component
 * it renders the UserSatisfactionForm component within custom modal component
 * @param {object} props
 * @param {function} props.handleUserSatisfactionModal - handleUserSatisfactionModal event handler
 * @param {function} props.onClose - onClose event handler
 * @returns {JSX.Element}
 */
const UserSatisfactionSurveyModal = (props) => {
	const { handleUserSatisfactionModal = () => {}, closeModal = () => {} } = props;

	return (
		<Modal type="bottom-modal" className="satisfaction-survey-modal">
			<span className="satisfaction-survey-modal__icon-close">
				<Icon className="icon-close" aria-label="Close" name="close" onClick={closeModal}/>
			</span>
			<UserSatisfactionForm onConfirm={handleUserSatisfactionModal} />
		</Modal>
	);
};

UserSatisfactionSurveyModal.propTypes = {
	handleUserSatisfactionModal: PropTypes.func,
	onClose: PropTypes.func
};

export default UserSatisfactionSurveyModal;
