import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { DateUtils, Icon } from "../../../../../../core";
import DeleteObservationModal from "./delete-observation-modal/delete-observation-modal";
import OfflineObservationModal from "./offline-delete-observation-modal/offline-delete-observation-modal";
import checkNetwork from "../../../../../../../config/offline/check-network";
import ObservationDetails from "../../../../../../observation/components/observation-details/observation-details";
import "./dl-observation-row.scss";

/**
 * Component for display one observation in a row
 * @component
 * @param {Object} props
 * @param {Object} props.observation - the observation object data
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 * @param {boolean} props.readOnly - the boolean that define if the observation is on readonly mode
 * @param {boolean} props.uncompletedDl - the boolean that define if the driveLog is uncompleted
 */
const DlObservationRow = (props) => {
	const { observation, retrieveObservationsList, readOnly = false, redirectUrl = "", uncompletedDl } = props;

	const { t } = useTranslation();
	const { formatFullDate, formatHourMinutes } = DateUtils;

	const history = useHistory();
	const [showDeleteObsModal, setShowDeleteObsModal] = useState(false);
	const [showOfflineModal, setShowOfflineModal] = useState(false);
	const [showObservationDetails, setShowObservationDetails] = useState(false);

	const displayEditButton = observation?.observation_type !== "manual-drive";

	const redirection = (action) => {
		if (observation.mission_id) {
			return history.push(`${redirectUrl}/${observation.drive_log_id}/mission/${observation.mission_id}/observation/${observation.id}/${action}`);
		} else {
			return history.push(`${redirectUrl}/${observation.drive_log_id}/observation/${observation.id}/${action}`);
		}
	};

	const toggleModal = (actionType) => {
		isOnline().then((response) => {
			if (response) {
				if (actionType === "edit") {
					redirection(actionType);
				} else if (actionType === "delete") {
					setShowDeleteObsModal(!showDeleteObsModal);
				}
			} else {
				setShowOfflineModal(!showOfflineModal);
			}
		});
	};

	const isOnline = async () => {
		const online = await checkNetwork();
		return !!online;
	};

	const type = observation?.observation_type;
	const delay = observation?.content.delay;
	const displayDelay = () => {
		if (!delay || delay === 0 || delay === "0") {
			return "";
		}
		return `. Retard ${delay} minutes`;
	};

	const displayFullDate = () => {
		const creationDate = formatFullDate(observation?.creation_date, "dd MMM yyyy");
		const creationTime = formatHourMinutes(observation?.creation_date);

		return (
			<div className="observation__row__full-date">
				<Trans
					parent="span"
					className="full-date__d-y"
					i18nKey="dl:dl-detail-page.obs-raw.date"
					values={{ day: creationDate, time: creationTime }}>
				</Trans>
			</div>
		);
	};

	return (
		<li key={observation.id} className={`observation__row ${uncompletedDl && observation?.draft ? "uncompleted" : ""}`}>
			<div className="observation__row__description">
				{displayFullDate()}
				<div className="observation__row__type-and-delay">
					<span className="type-and-delay__type">{t(`observation:observation-list.types.${type}`)}</span>
					<span className="type-and-delay__delay">{`${displayDelay()}`}</span>
				</div>
				{observation?.draft && <div className="observation__row__draft">{t("observation:observation-draft")}</div>}
				<div className="observation__row__actions">
					{!readOnly && (
						<>
							{ displayEditButton && <div className="observation__row__actions" onClick={() => toggleModal("edit")}>
								{t("dl:dl-detail-page.edit")}
							</div>}
							<div className="observation__row__actions" onClick={() => toggleModal("delete")}>
								{t("dl:dl-detail-page.delete")}
							</div>
							<DeleteObservationModal  showModal={showDeleteObsModal}
												 setShowModal={setShowDeleteObsModal}
												 obsType={type}
												 observationId={observation.id}
												 retrieveObservationsList={retrieveObservationsList}
							/>
							<OfflineObservationModal  showModal={showOfflineModal}
												 		setShowModal={setShowOfflineModal}
							/>
						</>
					)}
					{readOnly && (
						<Icon
							name="arrow-right"
							className={`observation__row__details--${showObservationDetails ? "on": "off"}`}
							onClick={() => setShowObservationDetails(!showObservationDetails)}/>
					)}
				</div>
			</div>
			{showObservationDetails && (
				<ObservationDetails observation={observation} />
			)}
		</li>
	);

};

DlObservationRow.propTypes = {
	observation: PropTypes.object.isRequired,
	retrieveObservationsList: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool
};

export default DlObservationRow;
