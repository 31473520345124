import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../../core";
import { useLine } from "../../custom-hooks/observation-custom-hooks";
import { addObservation, updateObservationById } from "../../observation.services";
import ObservationRootField from "./observation-root-field/observation-root-field";
import ObservationModal from "./observation-modal/observation-modal";
import { useAppEnv } from "../../../../config";
import { getDriveLogById } from "../../../drive-log/drive-log.services";
import { deleteFileAttachmentByIdInObs, getFilesAttachmentByObs, insertObservationFileAttachment } from "../../../drive-log/file-attachment.services";
import findFilesToAddOrDelete from "../../utils/observation-file-attachment/find-files-to-add-or-delete";
import "./observation-form.scss";

const ObservationForm = (props) => {
	const { observationData, observationParams, observationFields, observationType, linkedEntity, redirectUrl = "" } = props;

	const { id: driveLogId, missionId } = useParams();
	const [driveLog, setDriveLog] = useState({});
	const history = useHistory();
	const { t } = useTranslation();
	const [line, lineLoading] = useLine();
	const env =  useAppEnv();

	const [fields, setFields] = useState({});
	const currentDate = new Date();
	const [formValid, setFormValid] = useState(false);
	const [timeError, setTimeError] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [observationFiles, setObservationFiles ] = useState([]);
	const [currentObsFiles, setCurrentObsFiles ] = useState([]);

	const dlUrl = `${redirectUrl}/${driveLogId}`;
	const authorizationUrl = `${redirectUrl}/${driveLogId}/mission/${missionId}/observation/add-move-signal-authorization`;

	const signalObservation = ["installation", "cleanness", "material"];
	const isSignalObservation = signalObservation.includes(observationType);

	const disabledSubmitButton = !formValid && observationType === "manual-drive";

	/**
	 * load old data
	 */
	useEffect(() => {
		if (observationData?.content) {
			setFields(observationData?.content);
		}
		if (observationParams) {
			setFields(observationParams?.otherObservation ? {} : observationParams);
		}
	}, [observationData, observationParams]);

	/**
	 * load default values
	 */
	useEffect(() => {
		if (!observationData?.content) {
			const defaultValues = observationFields.map((fieldSchema) => {
				const { fieldName, defaultValue } = fieldSchema;
				if (defaultValue) {
					return [fieldName, defaultValue({ driveLog })];
				}
				return [fieldName];
			}).filter(([,defaultValue]) => {
				return !!defaultValue;
			});
			if (defaultValues?.length > 0) {
				const defaultFields = Object.fromEntries(defaultValues);
				setFields(defaultFields);
			}
		}
	},[observationFields, observationData, driveLog]);

	/**
	 * Load drive log
	 */
	useEffect(() => {
		getDriveLogById(driveLogId).then(res => {
			setDriveLog(res?.data);
		}).catch(err => {
			console.error(err);
		});
	}, [driveLogId]);

	/**
	 * if a line array exists, check if it includes current line
	 * @param {Array} lines
	 * @return {boolean}
	 */
	const lineMatches = useCallback((lines) => {
		return !lines || lines.includes(line);
	},[line]);

	/**
	 * check if child field should be shown or not
	 * @param {string} fieldName
	 * @param {Object} childSchema
	 * @return {boolean}
	 */
	const isChildShown = useCallback((fieldName, childSchema) => {
		const { parentValue, parentTranslated, lines } = childSchema;

		if (!lineMatches(lines)) {
			return false;
		}

		const parentStateValue = fields[fieldName]?.value;


		const matchedValue = parentTranslated ? t(`observation:observation-fields.${fieldName}-values.${parentValue}`) : parentValue;

		// child depends on value of the parent field
		return parentStateValue === matchedValue;
	},[fields, lineMatches, t]);

	/**
	 * check if parent field is valid
	 * @return {boolean}
	 */
	const isParentFieldValid = useCallback((fieldName, mandatory, lines, validator) => {
		// field valid if not shown, if not mandatory and empty
		if (!lineMatches(lines) || (!mandatory && !fields[fieldName])) {
			return true;
		}

		// if no validator, field valid if not mandatory or if mandatory and has a value
		if (!validator) {
			return !mandatory || fields[fieldName];
		}

		// If mandatory, check if not undefined or empty
		if (mandatory && (fields[fieldName] === undefined || fields[fieldName] === "")) {
			return false;
		}

		return validator(fields[fieldName]);
	},[fields, lineMatches]);

	/**
	 * check if grouped field is valid
	 * @return {boolean}
	 */
	const isGroupedFieldValid = useCallback((fieldSchema, groupMandatory) => {
		const { group } = fieldSchema;

		const groupedFieldsNotValid = group.some((groupedField) => {
			const { fieldName, mandatory, validator, lines } = groupedField;
			return !isParentFieldValid(fieldName, mandatory, lines, validator);
		});

		// if group is not mandatory then it's valid if all children are valid
		if (!groupMandatory) {
			return !groupedFieldsNotValid;
		}

		// if group is mandatory then it has at least one child with a value and all children are valid
		const groupHasValue = group.some((groupedField) => fields[groupedField.fieldName]);
		return groupHasValue && !groupedFieldsNotValid;

	},[fields, isParentFieldValid]);


	const isRepeatFieldValid = useCallback((fieldSchema, mandatory) => {
		const { fieldName, validator, lines } = fieldSchema;

		// field valid if not shown, if not mandatory and empty
		if (!lineMatches(lines) || (!mandatory && !fields[fieldName])) {
			return true;
		}

		// field valid if at least one field is valid ( not undefined, not null )
		if (fields[fieldName]?.some((fieldValue) => !!fieldValue)) {
			return true;
		}

		// field not valid if mandatory and doesn't have any values
		if (mandatory && !fields[fieldName]?.length > 0) {
			return false;
		}

		// field not valid if it has non valid repeater fields
		const repeatFieldNotValid = fields[fieldName].some((fieldValue) => {
			// if no validator, field not valid if mandatory and doesn't have a value
			if (!validator) {
				return mandatory && !fieldValue;
			}

			return !validator(fieldValue);
		});

		return !repeatFieldNotValid;
	},[fields, lineMatches]);

	/**
	 * check if child field is valid
	 * @return {boolean}
	 */
	const isChildFieldValid = useCallback((fieldName, childSchema) => {
		const { fieldName: childName, mandatory, validator, modalTriggerValue } = childSchema;

		const childValue = fields[fieldName]?.children[childName];

		// child valid if not shown
		if (!isChildShown(fieldName, childSchema)) {
			return true;
		}
		// if child is shown, check if modal needs to be triggered on submit
		if (modalTriggerValue && modalTriggerValue === childValue) {
			setModalActive(true);
		}

		// child valid if mandatory and empty
		if (!isChildShown(fieldName, childSchema) || (!mandatory && !childValue)) {
			return true;
		}

		// if no validator, child valid if not mandatory or mandatory and has a value
		if (!validator) {
			return !mandatory || childValue;
		}

		return validator(childValue);
	},[fields, isChildShown]);

	/**
	 * check if form is valid
	 */
	useEffect(() => {
		const fieldsNotValid = observationFields.filter((fieldSchema) => {
			const { fieldName, children, group, mandatory: defaultMandatory, lines, validator, repeat, visibleOnSide } = fieldSchema;

			const childrenNotValid = children && children.some((childSchema) => !isChildFieldValid(fieldName, childSchema));

			const mandatory = visibleOnSide ? visibleOnSide.includes(linkedEntity) && defaultMandatory : defaultMandatory;

			let isFieldValid;

			if (group) {
				isFieldValid = isGroupedFieldValid(fieldSchema, mandatory);
			} else if (repeat) {
				isFieldValid = isRepeatFieldValid(fieldSchema, mandatory);
			} else {
				isFieldValid = isParentFieldValid(fieldName, mandatory, lines, validator);
			}

			return !isFieldValid || childrenNotValid;
		});

		setFormValid(fieldsNotValid?.length === 0);
	},[observationFields, isChildFieldValid, isParentFieldValid, isGroupedFieldValid, isRepeatFieldValid, linkedEntity]);

	const formReset = () => {
		setFields({});
		setFormValid(false);
		setModalActive(false);
		setShowModal(false);
	};

	/**
	 * Load observation files if needed
	 */
	useEffect(() => {
		if (isSignalObservation) {
			getFilesAttachmentByObs(driveLogId, observationData?.id).then((res) => {
				const { data } = res;
				setCurrentObsFiles(data);
			}).catch(err => {
				console.error(err);
			});
		}
	}, [driveLogId, observationData?.id, isSignalObservation]);

	const updateObservationFiles = () => {
		const filesToAddOrDelete = findFilesToAddOrDelete(observationFiles, currentObsFiles);
		// delete the files that have to be deleted when updating the observation
		const { addedFiles, deletedFiles } = filesToAddOrDelete;
		if (deletedFiles?.length > 0) {
			const filesIdsToDelete = deletedFiles.map(file => file.id);
			return deleteFileAttachmentByIdInObs(driveLogId, filesIdsToDelete, observationData.id)
				.catch(err => {
					console.error(err);
				});
		}
		// insert the files that have been added when updating the observation
		if (addedFiles?.length > 0) {
			insertObservationFileAttachment(addedFiles, driveLogId, observationData.id)
				.catch(err => {
					console.error(err);
				});
		}
		return null;
	};

	const submitForm = (formRedirectUrl) => {
		const draft = !formValid || timeError;

		// filter out empty or undefined parents
		const formatFieldsArr = Object.entries(fields).filter(([, fieldValue]) => !!fieldValue);

		const observation = {
			observation_type: observationType,
			creation_date: currentDate.toISOString(),
			content: Object.fromEntries(formatFieldsArr)
		};

		if (missionId) {
			observation.mission_id = missionId;
		}
		if (observationData) {
			updateObservationById(driveLogId, observationData.id, observation, draft).then((res) => {
				updateObservationById(driveLogId, observationData.id, observation, draft).then(async (response) => {
					if (isSignalObservation) {
						await updateObservationFiles();
					}
				});
				formReset();
				history.push(formRedirectUrl);
			}).catch((err) => console.error(err));
		} else {
			addObservation(driveLogId, observation, draft).then((res) => {
				if (observationFiles.length !== 0 && res) {
					const { data: createdObservationId } = res;
					insertObservationFileAttachment(observationFiles, driveLogId, createdObservationId)
						.catch(err => {
							console.error(err);
						});
				}
				formReset();
				if (observationParams && observationType === "pmr" && observation.content.hasOwnProperty("stationOff")) {
					window.location.href = `${env?.DIGIPLAN_APP_SCHEME}://Drive?&observation=${observationType}&pmr=${observation.content.stationOff.trigram}`;
					window.close();
				} else if (observationParams) {
					window.location.href = `${env?.DIGIPLAN_APP_SCHEME}://Drive?&observation=${observationType}`;
					window.close();
				} else {
					history.push(formRedirectUrl);
				}
			}).catch((err) => console.error(err));
		}
	};

	const confirmCallback = () => {
		submitForm(dlUrl);
	};

	const cancelCallback = () => {
		submitForm(authorizationUrl);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!modalActive) {
			submitForm(dlUrl);
		} else {
			setShowModal(true);
		}
	};

	// some fields must only be displayed on one side - a side is related to an entity : mission or drive-log
	const renderObservationFields = () => observationFields
		.filter(obsField => !obsField?.visibleOnSide || obsField?.visibleOnSide?.includes(linkedEntity))
		.map(fieldSchema => (
			<ObservationRootField
				key={fieldSchema.fieldName}
				line={line}
				fieldSchema={fieldSchema}
				fields={fields}
				observationFields={observationFields}
				setFields={setFields}
				isChildShown={isChildShown}
				setTimeError={setTimeError}
				driveLog={driveLog}
				observationFiles={observationFiles}
				setObservationFiles={setObservationFiles}
				observationId={observationData?.id}
			/>
		));

	return (
		<form className="observation-form" onSubmit={handleSubmit}>
			{!lineLoading && renderObservationFields()}
			<Button className="observation-form__validate-button button" type="submit" disabled={disabledSubmitButton}>
				{t("observation:observation-fields.validate-button")}
			</Button>
			<ObservationModal
				showModal={showModal}
				toggleModal={setShowModal}
				confirmCallback={confirmCallback}
				cancelCallback={cancelCallback}
				modalText={t(`observation:observation-modals.${observationType}`)}
			/>
		</form>
	);
};

export default ObservationForm;
