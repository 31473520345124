import React, { forwardRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./input-cell.scss";

/**
 * Renders an <InputCell /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {string} props.type - input type
 * @param {number} props.maxLength - the max characters size on the input
 * @param {number|string} props.value - the input value
 * @param {function} props.onChange - function to handle change events
 * @param {function} props.onClick - function to handle click events
 * @param {function} props.onBlur - function to handle blur events
 * @param {function} props.onFocus - function to handle focus events
 * @param {function} props.regex - regex for acceptable characters
 * @param {boolean} props.required - input field is required or not
 * @param {function} props.goPrevious - function to handle delete events
 * @param {string} props.pattern - pattern for input field
 * @param {Object} ref - mutable ref object for the input
 */
const InputCell = forwardRef((props, ref) => {
	const { className, type, maxLength, value, onChange, onClick, onBlur, onFocus, regex, required, goPrevious, pattern, disabled = false } = props;

	/**
	 * if there's a regex provided, check to see if value is accepted
	 * use onChange instead of onKeyPress because Android virtual keyboard returns keyCode = 229 on any combination keys
	 */
	const handleChange = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!regex || regex.test(e.target.value)) {
			onChange(e.target.value);
		}
	};

	/**
	 * Handle backspace when field is empty. Prevent default for any characters not matching regex
	 * Prevent default necessary on desktop
	 * (i.e. onChange is not triggered for letters for inputs of type number, has to be handled in a key down event)
	 */
	const handleKeyDown = (e) => {
		// keyCode 8 = Backspace
		if (e.keyCode === 8 && value === "" && goPrevious) {
			goPrevious();
		} else if (e.keyCode !== 8 && regex && !regex.test(e.key)) {
			e.preventDefault();
		}
	};

	const extraInputProps = { maxLength, onFocus, onBlur, onClick, pattern };

	return (
		<label className={classnames("input-cell", className, { "input-cell--filled": value !== "" })}>
			<input
				aria-label="Enter character"
				type={type}
				className="input-cell__field"
				onKeyDown={handleKeyDown}
				onChange={handleChange}
				value={value}
				ref={ref}
				required={required}
				disabled={disabled}
				{...extraInputProps}
			/>
		</label>
	);
});

InputCell.defaultProps = {
	className: "",
	type: "text",
	value: "",
	onChange: () => {},
	onClick: () => {},
	onBlur: () => {},
	onFocus: () => {},
	regex: undefined,
	required: false,
	goPrevious: undefined
};

InputCell.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	maxLength: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	regex: PropTypes.instanceOf(RegExp),
	required: PropTypes.bool,
	goPrevious: PropTypes.func
};

export default InputCell;
