import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { GlobalAppProvider } from "./domains/core/global-app/global-app-context";
import { NotificationProvider } from "./domains/core/notification/notification-context";
import MatomoContainer from "./domains/core/matomo/matomo-container";
import { LoadOffline } from "./config";
import { ErrorBoundary } from "./domains/error";
import {
	EndOfServiceDelayPage,
	DiffServicePlannedPerformedDelayPage,
	OtherTcDelayPage,
	RestShortenedPage,
	BeyondSixHourThirty,
	DailyDlPage,
	DlPage,
	SignDlPage,
	CreateEmptyDl,
	DelayListPage,
	ModifyEndOfServiceDelayPage,
	SupervisorDriveLogList,
	ValidatedDriveLogsPage,
	DlDetailsDriver,
	DlDetailsSupervisor,
	DlToBeArchivedPage,
	ArchivedDlPage,
	ModifyServiceNumber,
	SignedDl,
	DlListReadOnly,
	ModifySheetLineNumber,
	DlScheduleDocumentPage,
	DlScheduleDocumentListPage,
	DlLineSheetPage,
	ReturnedDlListPage,
	DlListPage
} from "./domains/drive-log";
import { AddMission, AddNoMissionCode, AddTrainNumber, ModifyStations } from "./domains/mission";
import { ObservationList, EditObservation, AddObservation } from "./domains/observation";
import { AuthProvider, UserHome, UsersList } from "./domains/user";
import { TermsOfService } from "./domains/terms-of-service";
import { DisableLineSheetsPage } from "./domains/disable-line-sheets";
import { ErrorPage } from "./domains/error";
import { ModifyAttachmentGlobalScopePage, ModifyAttachmentScheduleDocumentPage, ModifyAttachmentConsultationPage } from "./domains/attachment";
import { AddSurvey, ReadSurvey } from "./domains/survey";
import EditSurvey from "./domains/survey/pages/edit-survey/edit-survey";
import { ExportPage } from "./domains/export";
import { CreateSpecialNoticePage, SpecialNoticesPage, UpdateSpecialNoticePage } from "./domains/special-notice";
import { ConsistencyReportPage } from "./domains/consistency-report";
import "./app.scss";

const App = () => {
	const protectedRoutesList = [
		// Drive log routes
		{ path: "/daily-drive-log", component: DailyDlPage },
		{ path: "/drive-logs", component: DlListPage },
		{ path: "/drive-logs/returned", component: ReturnedDlListPage },
		{ path: "/drive-log/create", component: CreateEmptyDl },
		{ path: "/drive-log/choose-service", component: ModifyServiceNumber },
		{ path: "/drive-log/:id", component: DlPage, overrideRoles: ["driver", "amp", "hr", "admin", "consultant", "localAdmin"], params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id", component: DlPage, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/sign", component: SignDlPage, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/sign", component: SignDlPage, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/add-delay", component: EndOfServiceDelayPage, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/add-delay", component: EndOfServiceDelayPage, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/modify-delay", component: ModifyEndOfServiceDelayPage, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/modify-delay", component: ModifyEndOfServiceDelayPage, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/modify-service", component: ModifyServiceNumber },
		{ path: "/drive-log/:id/modify-sheet-line-number", component: ModifySheetLineNumber, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/modify-sheet-line-number", component: ModifySheetLineNumber, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/modify-rest-shortened", component: RestShortenedPage, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/modify-rest-shortened", component: RestShortenedPage, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/modify-difference-service-planned-performed", component: DiffServicePlannedPerformedDelayPage, params: { redirectUrl: "/drive-log" } },
		{
			path: "/drive-log/returned/:id/modify-difference-service-planned-performed",
			component: DiffServicePlannedPerformedDelayPage,
			params: { redirectUrl: "/drive-log/returned" }
		},
		{ path: "/drive-log/:id/modify-other-tc", component: OtherTcDelayPage, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/modify-other-tc", component: OtherTcDelayPage, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/modify-daily-time", component: BeyondSixHourThirty, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/modify-daily-time", component: BeyondSixHourThirty, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/delays", component: DelayListPage, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/delays", component: DelayListPage, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/signed-dl", component: SignedDl, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/signed-dl", component: SignedDl, params: { redirectUrl: "/drive-log/returned" } },
		{
			path: "/drive-log/:id/details",
			component: DlDetailsDriver,
			overrideRoles: ["driver", "amp", "hr", "admin", "consultant", "localAdmin"],
			params: { redirectUrl: "/drive-log", domain: "driver" }
		},
		{
			path: "/drive-log/returned/:id/details",
			component: DlDetailsDriver,
			overrideRoles: ["driver", "amp", "hr", "admin", "consultant", "localAdmin"],
			params: { redirectUrl: "/drive-log/returned", domain: "driver" }
		},
		{ path: "/drive-log/:id/schedule-document/daily", component: DlScheduleDocumentPage },
		{ path: "/drive-log/:id/schedule-document", component: DlScheduleDocumentListPage },
		{ path: "/drive-log/:id/schedule-document/modify-attachment", component: ModifyAttachmentScheduleDocumentPage },

		// Mission routes
		{ path: "/drive-log/:id/mission/add", component: AddMission, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/mission/add", component: AddMission, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/mission/add/no-code", component: AddNoMissionCode },
		{ path: "/drive-log/:id/mission/:missionId/train-number", component: AddTrainNumber, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/mission/:missionId/train-number", component: AddTrainNumber, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/mission/:missionId/:station/edit", component: ModifyStations, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/mission/:missionId/:station/edit", component: ModifyStations, params: { redirectUrl: "/drive-log/returned" } },

		// Observation list pages (with or without mission
		{ path: "/drive-log/:id/add-observation", component: ObservationList, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/add-observation", component: ObservationList, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/mission/:missionId/add-observation", component: ObservationList, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/mission/:missionId/add-observation", component: ObservationList, params: { redirectUrl: "/drive-log/returned" } },

		// Survey page
		{ path: "/drive-log/:driveLogId/observation/:observationId/add-survey", component: AddSurvey }, // TODO: returned ?
		{ path: "/drive-log/:driveLogId/observation/:observationId/survey/:surveyId/edit", component: EditSurvey, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:driveLogId/observation/:observationId/survey/:surveyId/edit", component: EditSurvey, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:driveLogId/observation/:observationId/survey/:surveyId", component: ReadSurvey },

		// Add observations with and without missions
		{ path: "/drive-log/:id/observation/:typeUrl", component: AddObservation, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/observation/:typeUrl", component: AddObservation, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/mission/:missionId/observation/:typeUrl", component: AddObservation, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/mission/:missionId/observation/:typeUrl", component: AddObservation, params: { redirectUrl: "/drive-log/returned" } },

		// Edit observations with and without missions
		{ path: "/drive-log/:id/observation/:observationId/edit", component: EditObservation, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/observation/:observationId/edit", component: EditObservation, params: { redirectUrl: "/drive-log/returned" } },
		{ path: "/drive-log/:id/mission/:missionId/observation/:observationId/edit", component: EditObservation, params: { redirectUrl: "/drive-log" } },
		{ path: "/drive-log/returned/:id/mission/:missionId/observation/:observationId/edit", component: EditObservation, params: { redirectUrl: "/drive-log/returned" } },

		// Line sheet routes
		{ path: "/line-sheet", component: DlLineSheetPage, params: { status: "single" } },
		{ path: "/line-sheets", component: DlLineSheetPage, params: { status: "all" } },

		// Export routes
		{ path: "/export", component: ExportPage },

		// Supervisor drive log routes
		{ path: "/supervisor/drive-log-list", component: SupervisorDriveLogList },
		{ path: "/supervisor/validated-drive-logs", component: ValidatedDriveLogsPage },
		{ path: "/supervisor/drive-log/:id/details", component: DlDetailsSupervisor, params: { redirectUrl: "/drive-log" } },
		{ path: "/supervisor/consistency-report", component: ConsistencyReportPage },

		// Multi-role Pages
		{ path: "/modify-attachment", component: ModifyAttachmentGlobalScopePage },
		{ path: "/modify-attachment-for-consultation", component: ModifyAttachmentConsultationPage },
		{ path: "/terms-of-service", component: TermsOfService, overrideRoles: ["driver", "amp", "hr", "admin", "consultant", "localAdmin"] },
		{ path: "/disable-line-sheets", component: DisableLineSheetsPage },

		// Human Resources drive log routes
		{ path: "/hr/drive-log-list", component: DlToBeArchivedPage },
		{ path: "/hr/archived-drive-logs", component: ArchivedDlPage },
		{ path: "/hr/drive-log/:id/details", component: DlDetailsSupervisor, params: { domain: "hr" } },
		{
			path: "/hr/drive-log/archived/:id/details",
			component: DlDetailsDriver,
			overrideRoles: ["driver", "amp", "hr", "admin", "consultant", "localAdmin"],
			params: { redirectUrl: "/drive-log", domain: "hr" }
		},
		// Consultant drive log routes
		{ path: "/consultant/drive-logs", component: DlListReadOnly },
		{ path: "/consultant/drive-log/:id/details", component: DlDetailsSupervisor, params: { domain: "consultant" } },

		// Back-office page
		{ path: "/admin/users-list", component: UsersList },

		// Special notices page
		{ path: "/special-notices", component: SpecialNoticesPage, params: { enclosed: false } },
		{ path: "/special-notices/enclosed", component: SpecialNoticesPage, params: { enclosed: true } },
		{ path: "/special-notice/add", component: CreateSpecialNoticePage },
		{ path: "/special-notice/:id/edit", component: UpdateSpecialNoticePage, params: { action: "edit" } },
		{ path: "/special-notice/:id/republish", component: UpdateSpecialNoticePage, params: { action: "republish" } },

		// Homepage
		{ path: "/", component: UserHome, overrideRoles: ["driver", "amp", "hr", "admin", "consultant", "localAdmin"] }
	];

	const renderProtectedRoutes = (route) => {
		const { component: Component, path, overrideRoles, params = {} } = route;
		return (
			<Route key={path} exact path={path}>
				<AuthProvider>
					<MatomoContainer />
					<LoadOffline />
					<Component overrideRoles={overrideRoles} params={params} />
				</AuthProvider>
			</Route>
		);
	};

	return (
		<Router>
			<ErrorBoundary>
				<div className="digibul">
					<GlobalAppProvider>
						<NotificationProvider>
							<Switch>
								<Route exact path="/error-access" component={ErrorPage} />
								{protectedRoutesList.map(renderProtectedRoutes)}
								<Redirect from="*" to="/error-access" />
							</Switch>
						</NotificationProvider>
					</GlobalAppProvider>
				</div>
			</ErrorBoundary>
		</Router>
	);
};

export default App;
