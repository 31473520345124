import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SurveyListItem from "./survey-list-item/survey-list-item";
import { getAllSurveysInDl } from "../../../survey/survey.services";
import "./survey-list.scss";

const SurveyList = ({ showUpdateButton = false, redirectUrl = "" }) => {
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const [surveys, setSurveys] = useState([]);

	const retrieveSurveysList = useCallback(() => {
		getAllSurveysInDl(driveLogId).then(res => {
			setSurveys(res?.data);
		}).catch(err => {
			console.error(err);
		});
	}, [driveLogId]);

	const generateSurveyRow = (survey) => (
		<SurveyListItem
			survey={survey}
			key={survey.id}
			retrieveSurveysList={retrieveSurveysList}
			showUpdateButton={showUpdateButton}
			redirectUrl={redirectUrl}
		/>
	);

	useEffect(retrieveSurveysList, [retrieveSurveysList]);

	return surveys?.length > 0 && (
		<div className="survey-list">
			<div className="survey-list__title">{t("dl:dl-detail-page.railway-safety-survey")}</div>
			<ul className="survey-list__survey">
				{Array.isArray(surveys) && surveys.map(generateSurveyRow)}
			</ul>
		</div>
	);

};

export default SurveyList;
