import { format } from "date-fns";
import { fr } from "date-fns/locale";

/**
 * Transform a string into date or keep it a date if it is already one
 * @param {Date | string} date - date to format
 * @returns Date The formatted date
 */
const castStringIntoDate = (date) => {
	return (typeof date === "string") ? Date.parse(date) : date;
};

/**
 * Format a javascript Date to a full date format localized to french (2 janvier 2021 for ex)
 * @param {Date | string} date - date to format
 * @param {string} formatType - the wanted format (ex: "dd MMMM YYYY")
 * @returns string The formatted date
 */
const formatFullDate = (date, formatType = "dd MMMM yyyy") => {
	if (date) {
		const castedDate = castStringIntoDate(date);
		return format(castedDate, formatType, { locale: fr });
	} else {
		return "";
	}
};

/**
 * Extract from a javascript Date hour and minutes and format them (13h21 for ex)
 * @param {Date | string} date - date to format
 * @param {string} separator the character to be used to separate hours and minutes
 * @returns string The formatted date
 */
const formatHourMinutes = (date, separator = "h") => {
	if (date) {
		const castedDate = castStringIntoDate(date);
		return format(castedDate, `HH'${separator}'mm`);
	} else {
		return "00:00";
	}

};

/**
 * Format a single number of hours or minutes into a padded format with a "0" first (ex : 1 returns 01 )
 * @param {Integer} hoursOrMinutes - date to format
 * @returns The formatted duration
 * @example formatPadStart(1) returns "01"
 */
const formatPadStart = (hoursOrMinutes) => String(hoursOrMinutes).padStart(2, "0");

/**
 * Format a number of minutes to a mm:ss format (ex : 08h21)
 * @param {Integer} minutes - date to format
 * @returns string The formatted duration
 * @example formatDuration(181) returns "03H01"
 */
const formatDuration = (minutes= 0) => {
	if (typeof  minutes === "number") {
		const hours = Math.floor(minutes / 60);
		const minutesLeft = minutes % 60;
		const minutesLeftString = String(minutesLeft).padStart(2, "0");
		return `${hours}h${minutesLeftString}`;
	} else {
		return "";
	}
};

const DateUtils = {
	formatFullDate,
	formatHourMinutes,
	formatDuration,
	formatPadStart
};

export default DateUtils;
