import { regexValidator, validateDelay, validateReason } from "../../components/observation-form/observation-validators";
import { RegexUtils } from "../../../core";
import { setDateTimeWithService } from "../../observation.services";

const garage = [
	{
		fieldName: "garageAction",
		mandatory: true
	},
	{
		fieldName: "cabin",
		mandatory: true,
		group: [
			{
				fieldName: "cabinOne",
				validator: regexValidator(RegexUtils.fourDigitNumber),
				mandatory: true
			},
			{
				fieldName: "cabinTwo",
				validator: regexValidator(RegexUtils.fourDigitNumber),
				mandatory: true
			}
		]
	},
	{
		fieldName: "trainStorage",
		mandatory: true
	},
	{
		fieldName: "position",
		mandatory: true,
		validator: regexValidator(RegexUtils.alphaNumericAndSpecialCharactersWithFifteen)
	},
	{
		fieldName: "dateTime",
		mandatory: true,
		defaultValue: ({ driveLog }) => setDateTimeWithService(driveLog?.realized_date_start, new Date())
	},
	{
		fieldName: "realizedManeuvers",
		children: [
			{
				fieldName: "cabinsAndTrafficLanes",
				parentValue: "yes",
				validator: validateReason
			}
		]
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default garage;
