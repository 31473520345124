import { http } from "../../config";

/**
 * post to Google Form
 * @param {Object} userInputs - user satisfaction form data
 * @param {String} userInputs.rating - value from 0 to 10
 * @param {String} userInputs.comment - user comment
 * @returns {Promise} response
 */
const sendSatisfactionSurveyData = (userInputs = {}) => http.post("/user/satisfaction-survey", userInputs);

/**
 * post to Google Form
 * @param {Object} userInputs - user satisfaction form data
 * @param {String} userInputs.choice - choice between given selection
 * @param {String} userInputs.comment - user comment
 * @returns {Promise} response
 */
const sendFeedbackSurveyData = (userInputs = {}) => http.post("/user/feedback-survey", userInputs);

export { sendSatisfactionSurveyData, sendFeedbackSurveyData };
