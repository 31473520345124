import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { formatISO } from "date-fns";

import { PopupFullPage, Button, Loader } from "../../../core";
import DLFullDatePicker from "./components/dl-full-date-picker";
import DriveLogErrorMessage from "../drive-log-error-message/drive-log-error-message";
import "./schedule.scss";
/**
 * Renders a Schedule component, DateTime inputs for a start date and end date
 * @param {Object} props
 * @param {function} props.navigate - the navigate function derived from provided props
 */
const Schedule = (props) => {
	const { navigate, loading = true } = props;
	const { t } = useTranslation();

	const [startFullDate, setStartFullDate] = useState(new Date());
	const [endFullDate, setEndFullDate] = useState(new Date());

	const [isFormValid, setIsFormValid] = useState(false);
	const [chronologyValid, setChronologyValid] = useState(false);

	const [timeErrorStart, setTimeErrorStart] = useState(false);
	const [timeErrorEnd, setTimeErrorEnd] = useState(false);

	const [isValidated, setIsValidated] = useState(false);


	/**
	 * Navigate to next step in drive log create and synchronise data with parent
	 * @param {Date} startDate -  drive log start date
	 * @param {Date} endDate - drive log end date
	 */
	const handleSubmit = (startDate, endDate) => () => {
		const finalSchedule = {
			 "realized_date_start": formatISO(startDate),
			 "realized_date_end": formatISO(endDate)
		};
		setIsValidated(true);
		navigate(finalSchedule);
	};

	useEffect(() => {
		const datesFilled = !!(startFullDate && endFullDate);

		if (!timeErrorStart && !timeErrorEnd && datesFilled) {
			const startDateBeforeEndDate = startFullDate <= endFullDate;

			setChronologyValid(startDateBeforeEndDate);
			setIsFormValid(startDateBeforeEndDate);
		} else {
			setChronologyValid(true);
			setIsFormValid(false);
		}

	}, [startFullDate, endFullDate, timeErrorStart, timeErrorEnd]);


	return (
		<Loader isLoading={loading}>
			<PopupFullPage title={t("dl:dl-new-page.title")} backLink={"/daily-drive-log"}>
				<div className="schedule">

					<div className="schedule__content">
						<DLFullDatePicker
							title={t("dl:schedule-page.label.start")}
							dlDateTime={startFullDate}
							setDlDateTime={setStartFullDate}
							setTimeError={setTimeErrorStart}
						/>
						<DLFullDatePicker
							title={t("dl:schedule-page.label.end")}
							dlDateTime={endFullDate}
							setDlDateTime={setEndFullDate}
							setTimeError={setTimeErrorEnd}
						/>
					</div>
					<DriveLogErrorMessage message={t("dl:schedule-page.error-message")} showError={!chronologyValid}/>
					<Loader isLoading={isValidated}>
						<Button disabled={!isFormValid} className="schedule__button"
							onClick={handleSubmit(startFullDate, endFullDate)}>
							{t("core:popup-full-page.button.validate")}
						</Button>
					</Loader>
				</div>
			</PopupFullPage>
		</Loader>
	);
};

Schedule.propTypes = {
	navigate: PropTypes.func,
	loading: PropTypes.bool
};

export default Schedule;
