import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "../../../core";
import Icon from "../../../core/components/icon-svg/icon";
import "./psg-drivelog-modal.scss";


const PsgDrivelogModal = (props) => {
	const { t } = useTranslation("at");
	const { showModal, setShowPsgDriveLogModal, validateStep, setIsPsg } = props;

	const confirmCallback = () => {
		validateStep();
	};

	const cancelCallback = () => {
		setIsPsg(false);
		setShowPsgDriveLogModal(!showModal);
	};

	return (
		showModal &&
		<Modal type="custom">
			<button className="psg-modal__icon" onClick={cancelCallback}>
				<Icon name="close" />
			</button>
			<div className="psg-modal__container">
				<p className="psg-modal__container__content" >
					{t("psg-option.psg-modal-confirmation")}</p>
				<p className="psg-modal__container__sub-content">
					{t("psg-option.psg-modal-precision")}</p>
			</div>
			<div className="psg-modal__container__button">
				<Button className="button--outline" onClick={cancelCallback}>{t("psg-option.cancel")}</Button>
				<Button className="button" onClick={confirmCallback}>{t("psg-option.validate")}</Button>
			</div>
		</Modal>
	);
};

export default PsgDrivelogModal;
