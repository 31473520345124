import React from "react";
import { Loader } from "../../../core";
import AddObservation from "../add-observation/add-observation";
import { useObservation } from "../../custom-hooks/observation-custom-hooks";

/**
 * edit observation page
 * @returns {JSX.Element}
 * @constructor
 */
const EditObservation = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;

	const [observation, loadingObservation] = useObservation();

	return (
		<Loader isLoading={!!loadingObservation }>
			<AddObservation observationData={observation} params={{ redirectUrl }} />
		</Loader>
	);
};

export default EditObservation;
