import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./state-cell.scss";
import { DateUtils, Icon } from "../../../../../core";

const StateCell = (props) => {
	const { driveLog, header = false } = props;

	const { t } = useTranslation("dl");

	const getState = (dl) => {
		const generateI18nParams = (date) => {
			return {
				date: DateUtils.formatFullDate(date, "dd/MM"),
				hour: DateUtils.formatHourMinutes(date)
			};
		};
		if ( dl.status === "validated" ) {
			return (
				<>
					<Icon className="state-cell__status-icon state-cell__status-icon--validate" name="validate"/>
					{ t("dl-table.validated-at", generateI18nParams(dl.amp_signature_date)) }
				</>
			);
		}
		if ( dl.status === "signed" || dl.status === "signed-not-transmitted") {
			return (
				<>
					<Icon className="state-cell__status-icon state-cell__status-icon--ink-pen" name="ink-pen"/>
					{ t("dl-table.signed-at", generateI18nParams(dl.driver_signature_date)) }
				</>
			);
		}
		if ( dl.status === "archived" ) {
			return (
				<>
					<Icon className="state-cell__status-icon state-cell__status-icon--validate" name="validate"/>
					{ t("dl-table.archived-at", generateI18nParams(dl.archived_at)) }
				</>
			);
		}
		return (
			<>
				<Icon className="state-cell__status-icon state-cell__status-icon--empty-pen" name="empty-pen"/>
				{ t("dl-table.modified-at", generateI18nParams(dl.updated_at)) }
			</>
		);
	};

	const buildHeader = () => (
		<div className="state-cell__state">
			{ t("dl-table.headers.state") }
		</div>
	);

	const buildCell = () => (
		<div className="state-cell__state">
			{ getState(driveLog) }
		</div>
	);

	return (
		header ? buildHeader() : buildCell()
	);
};

StateCell.propTypes = {
	driveLog: PropTypes.object,
	header: PropTypes.bool
};

export default StateCell;
