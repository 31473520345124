/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { checkObjectEmpty } from "../../../core";
import { getReferentialList } from "../../../core/core.services";
import { ConsistencyOverlapsListGroup, ConsistencyOverlapsEditModal, ConsistencyOverlapsInfoModal } from "../";
import { getConsistencyOverlapsDraft, clearConsistencyOverlapsDraft } from "../../consistency-report.service";
import "./consistency-overlaps-list.scss";

/**
 * ConsistencyOverlapsList component
 * @param props
 * @param {string} props.date report date
 * @param {array} props.reportOverlaps report overlaps list
 * @param {string} props.className custom class name
 * @param {function} props.retrieveConsistencyReports custom class name
 * @return {JSX.Element}
 * @constructor
 */
const ConsistencyOverlapsList = (props) => {
	const { t } = useTranslation("cr");

	const { date, reportOverlaps, className, retrieveConsistencyReports } = props;
	const totalResultCount = checkObjectEmpty(reportOverlaps) ? "" : Object.keys(reportOverlaps).length;
	const [stationsList, setStationsList] = useState();
	const [localOverlaps, setLocalOverlaps] = useState([]);
	const [displayEditModal, setDisplayEditModal] = useState(false);
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [editModalData, setEditModalData] = useState({ date, mission: {}, mode: "start" });

	useEffect(() => {
		// Clear any existing local draft
		clearConsistencyOverlapsDraft();
		setLocalOverlaps([]);

		getReferentialList("station").then((res) => {
			setStationsList(res.data);
		});
	}, []);

	const handleOpenEditModal = (mission, mode) => {
		setEditModalData({ date, mission, mode });
		setDisplayEditModal(true);
	};

	const handleCloseEditModal = () => {
		setDisplayEditModal(false);
		fetchLocalOverlaps();
	};

	const handleOpenInfoModal = () => {
		setDisplayInfoModal(true);
	};

	const handleCloseInfoModal = () => {
		setDisplayInfoModal(false);
		retrieveConsistencyReports();
	};

	const fetchLocalOverlaps = () => {
		// Fetch consistency overlaps local draft
		const latestLocalOverlaps = getConsistencyOverlapsDraft();
		setLocalOverlaps(latestLocalOverlaps);
	};

	const renderConsistencyOverlapsListHeader = () => {
		return (
			<div className="consistency-overlaps__list__header-container">
				<span />
				<span className="consistency-overlaps-list__header">
					<span className="consistency-overlaps-list__header-identity">{t("consistency-report.consistency-overlaps.header.identity")}</span>
					<span className="consistency-overlaps-list__header-start">{t("consistency-report.consistency-overlaps.header.start")}</span>
					<span className="consistency-overlaps-list__header-end">{t("consistency-report.consistency-overlaps.header.end")}</span>
					<span />
				</span>
				<span />
			</div>
		);
	};

	const renderConsistencyOverlapsListBody = () => {
		return Object.keys(reportOverlaps)
			?.sort()
			?.map((missionCode) => {
				return (
					<ConsistencyOverlapsListGroup
						key={missionCode}
						date={date}
						groupOverlaps={reportOverlaps[missionCode]}
						localOverlaps={localOverlaps}
						missionCode={missionCode}
						displayEditModal={displayEditModal}
						handleOpenEditModal={handleOpenEditModal}
						handleOpenInfoModal={handleOpenInfoModal}
						fetchLocalOverlaps={fetchLocalOverlaps}
						retrieveConsistencyReports={retrieveConsistencyReports}
					/>
				);
			});
	};

	if (!reportOverlaps || checkObjectEmpty(reportOverlaps)) {
		return null;
	}

	return (
		<section className={`${className} consistency-overlaps`}>
			<Trans parent="span" className="consistency-overlaps-list__title" i18nKey="cr:consistency-report.consistency-overlaps.title" values={{ count: totalResultCount }}>
				<span className="consistency-overlaps-list__title__count" />
			</Trans>
			{renderConsistencyOverlapsListHeader()}
			{renderConsistencyOverlapsListBody()}
			<ConsistencyOverlapsEditModal displayModal={displayEditModal} closeModal={handleCloseEditModal} stationsList={stationsList} data={editModalData} />
			<ConsistencyOverlapsInfoModal displayModal={displayInfoModal} closeModal={handleCloseInfoModal} />
		</section>
	);
};

ConsistencyOverlapsList.propTypes = {
	reportOverlaps: PropTypes.object,
	className: PropTypes.string
};

export default ConsistencyOverlapsList;
