import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Modal } from "../../../../../../../core";
import { deleteObservation } from "../../../../../../../observation/observation.services";


/**
 * Component Modal to display when the 'Delete' button is clicked on observation raw
 * The observation is deleted if the answer is yes or redirect to the dl-page if the answer is no
 * @component
 * @param {Object} props
 * @param {Object} props.showModal - the observation object data
 * @param {function} props.setShowModal - display or not the modal
 * @param {string} props.obsType - the type of the observation
 * @param {string} props.observationId - the id (uuid) of the observation
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 */
const DeleteObservationModal = ({ showModal, setShowModal, obsType, observationId, retrieveObservationsList }) => {
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const modalText = t("dl:dl-detail-page.obs-raw.delete", { type: t(`observation:observation-list.types.${obsType}`) });

	const confirmCallback = () => {
		deleteObservation(driveLogId, observationId)
			.then(() => {
				setShowModal(false);
				retrieveObservationsList();
			})
			.catch(err => console.error(err));
	};
	const cancelCallback = () => {
		setShowModal(false);
	};

	return (
		showModal &&
		<Modal
			type="confirm"
			title={modalText}
			confirmCallback={confirmCallback}
			cancelCallback={cancelCallback}
			closeCallback={cancelCallback}
		/>
	);
};

DeleteObservationModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	setShowModal: PropTypes.func.isRequired,
	obsType: PropTypes.string.isRequired,
	observationId: PropTypes.string.isRequired,
	retrieveObservationsList: PropTypes.func.isRequired
};

export default DeleteObservationModal;
