import React from "react";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PopupFullPage } from "../../../core";
import { DriveLogSubHeader } from "../../../drive-log";
import observationTypes from "../../forms/observation-types";
import ObservationCard from "./observation-card/observation-card";
import { useLine, useMissionCode } from "../../custom-hooks/observation-custom-hooks";
import { useAppEnv } from "../../../../config";
import "./observation-list.scss";

const ObservationList = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;

	const { t } = useTranslation();
	const { id: driveLogId, missionId } = useParams();
	const location = useLocation();

	const [missionCode, missionCodeLoading] = useMissionCode();
	const [line, lineLoading] = useLine();

	const env = useAppEnv();

	const linkedEntity = missionId ? "mission" : "drive-log";
	const param = queryString.parse(location.search);

	const observationSubHeader = () => {
		if (missionId) {
			return (
				<>
					{t("observation:observation-list.heading.mission-related")}
					{!missionCodeLoading && <div className="observation-list__subheader__code">{missionCode}</div>}
				</>
			);
		}
		return t("observation:observation-list.heading.drive-log-related");
	};

	const displayObservationCard = (observationType) => {
		return (
			<ObservationCard driveLogId={driveLogId} key={observationType.name} type={observationType} missionId={missionId} position={param?.position} redirectUrl={redirectUrl} />
		);
	};

	const getBackLink = () => {
		if (param.fromApp === "DigiPlan") {
			return `${env?.DIGIPLAN_APP_SCHEME}://Drive`;
		}
		return `${redirectUrl}/${driveLogId}`;
	};

	/**
	 * get the observation list cards filtered by entity and by line
	 * @returns {Object} Returns <ObservationCard> filtered list
	 */
	const getObsListFilteredByEntityAndLine = () => {
		const obsFiltered = observationTypes.filter(({ linkedEntities, linkedLines }) => {
			const matchEntity = linkedEntities.includes(linkedEntity);
			const matchLine = linkedLines.includes(line);
			return matchEntity && matchLine;
		});

		return obsFiltered.map(displayObservationCard);
	};

	return (
		<PopupFullPage className="observation-list" title={t("observation:observation-list.title")} backLink={getBackLink()}>
			<DriveLogSubHeader className="observation-list__subheader">{observationSubHeader()}</DriveLogSubHeader>
			<ul className="observation-list__wrapper">{!lineLoading && getObsListFilteredByEntityAndLine()}</ul>
		</PopupFullPage>
	);
};

export default ObservationList;
