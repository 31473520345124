import React from "react";
import "./custom-modal.scss";

const CustomModal = ({ className = "", children }) => {
	return (
		<div className={`${className} custom-modal`}>{children}</div>
	);
};

export default CustomModal;
