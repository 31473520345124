import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, InputCell, Modal, RegexUtils } from "../../../../../../core";
import Icon from "../../../../../../core/components/icon-svg/icon";
import "./update-delay-modal.scss";

const UpdateDelayModal = (props) => {
	const {
		showModal,
		closeCallback,
		newSupervisorDelay,
		currentDriverField,
		handleDelayChange,
		hasErrorMaxDelay,
		hasErrorEOSDelay,
		handleValidate
	} = props;
	const { t } = useTranslation();

	return (
		showModal &&
		<Modal type="custom">
			<div className="update-delay-modal">
				<div onClick={closeCallback}>
					<Icon name="close" className="update-delay-modal__close"/>
				</div>
				<div className="update-delay-modal__content">
					<div className="update-delay-modal__title">{t(`dl:delay-list-page.modals.${currentDriverField}`)}</div>
					<div className="update-delay-modal__delay">
						<InputCell
							className="update-delay-modal__input-cell"
							maxLength={4}
							value={newSupervisorDelay}
							onChange={handleDelayChange}
							regex={RegexUtils.numericOrEmpty}
						/>

						<div className="update-delay-modal__minutes">
							{t("dl:add-delay-page.minutes")}
						</div>
					</div>
					<div className={`update-delay-modal__error-message 
						${!hasErrorMaxDelay && !hasErrorEOSDelay && "update-delay-modal__error-message--hidden"}`}
					>
						{hasErrorMaxDelay && t("dl:delay-list-page.modals.error-max-delay")}
						{hasErrorEOSDelay && t("dl:delay-list-page.modals.error-eos-delay-increased")}
					</div>
					<div className="update-delay-modal__content__btn">
						<Button className="update-delay-modal__validate-btn" onClick={handleValidate} disabled={newSupervisorDelay === "" || hasErrorMaxDelay}>
							{t("dl:delay-list-page.modals.update-delay-btn")}
						</Button>
					</div>
				</div>
			</div>

		</Modal>
	);
};

UpdateDelayModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	closeCallback: PropTypes.func,
	newSupervisorDelay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	currentDriverField: PropTypes.string,
	handleDelayChange: PropTypes.func,
	hasErrorMaxDelay: PropTypes.bool,
	hasErrorEOSDelay: PropTypes.bool,
	handleValidate: PropTypes.func
};

export default UpdateDelayModal;
