import { validateDelay } from "../../components/observation-form/observation-validators";

const rfn = [
	{
		fieldName: "rfnStation",
		mandatory: true,
		repeat: 5
	},
	{
		fieldName: "schedule",
		mandatory: true,
		group: [
			{
				fieldName: "dateTimeStart",
				mandatory: true
			},
			{
				fieldName: "dateTimeEnd",
				mandatory: true
			}
		],
		startOfDayHour: 3
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default rfn;
