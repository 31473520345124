import React, { useEffect, useState } from "react";
import { Button, Comment, Dropdown, Icon, Modal } from "../../../core";
import { useTranslation } from "react-i18next";
import { updateConsistencyService } from "../../consistency-report.service";
import { validateConsistencyServiceComment, validateDropdownReason } from "../../utils";
import "./consistency-service-modal.scss";

const ConsistencyServiceModal = (props) => {
	const { showModal, toggleModal, confirmCallback, reportService } = props;
	const { service_number: serviceNumber, attachment_name: attachmentName, id: serviceId } = reportService;

	const { t } = useTranslation("cr");

	const reasonChoices = {
		paperDlDelivered: t("consistency-report.consistency-services.modal.dropdown.paperDlDelivered"),
		deletedService: t("consistency-report.consistency-services.modal.dropdown.deletedService"),
		others: t("consistency-report.consistency-services.modal.dropdown.others")
	};

	const commentMaxLength = 500;
	const [formValid, setFormValid] = useState(false);

	const [newForm, setNewForm] = useState({
		reason: "",
		comment: ""
	});

	const validateForm = () => {
		const fieldReasonValid = newForm.reason && validateDropdownReason(newForm.reason);
		const fieldCommentValid = validateConsistencyServiceComment(newForm.comment);

		if (fieldCommentValid && fieldReasonValid) {
			return setFormValid(true);
		}
	};

	const resetForm = () => {
		setNewForm({ reason: "", comment: "" });
		setFormValid(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const reasonValue = reasonChoices[newForm.reason];
		const userInputs = { reason: reasonValue, comment: newForm.comment };

		try {
			await updateConsistencyService(userInputs, serviceId);
		} catch (error) {
			console.error(error);
		} finally {
			resetForm();
			confirmCallback();
			toggleModal(!showModal);
		}
	};

	const handleCancel = () => {
		toggleModal(!showModal);
		resetForm();
	};

	const handleChangeData = (name) => (value) => {
		setNewForm((previousState) => ({ ...previousState, [name]: value }));
	};

	useEffect(validateForm, [newForm, validateForm]);

	return (
		showModal && (
			<Modal type="custom" className="consistency-service__modal">
				<div className="consistency-service__modal__form">
					<button className="consistency-service__modal__form__icon" onClick={handleCancel}>
						<Icon name="close" />
					</button>
					<div className="consistency-service__modal__form__container">
						<div className="consistency-service__modal__form__container__header">
							<p className="consistency-services__modal__form__container__header__title">{t("consistency-report.consistency-services.modal.title")}</p>
							<p className="consistency-services__modal__form__container__header__sub-title">
								{t("consistency-report.consistency-services.modal.sub-title", { serviceNumber, attachmentName })}
							</p>
						</div>
						<div className="consistency-service__modal__form__container__content">
							<p className="consistency-service__modal__form__container__content__title">{t("consistency-report.consistency-services.modal.dropdown.title")}</p>
							<Dropdown
								value={newForm.reason}
								onChange={handleChangeData("reason")}
								className={"consistency-service__modal__form__container__content__dropdown"}
								dropdownList={reasonChoices}
								placeholder={t("consistency-report.consistency-services.modal.dropdown.placeholder")}
							/>
						</div>
						<div className="consistency-service__modal__form__container__content consistency-service__modal__form__container__content__comment">
							<p className="consistency-service__modal__form__container__content__title consistency-service__modal__form__container__content__title__comment">
								{t("consistency-report.consistency-services.modal.comment.title")}
							</p>
							<Comment
								value={newForm.comment}
								handleChange={handleChangeData("comment")}
								className={"consistency-service__modal__form__container__content__comment"}
								placeholder={t("consistency-report.consistency-services.modal.comment.placeholder")}
								maxLength={commentMaxLength}
								changeColor={true}
							/>
						</div>
						<div className="consistency-service__modal__form__button-container">
							<Button className="button" disabled={!formValid} type="submit" onClick={handleSubmit}>
								{t("consistency-report.consistency-services.modal.justify")}
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		)
	);
};

export default ConsistencyServiceModal;
