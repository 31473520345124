import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Icon from "../../../icon-svg/icon";
import Loader from "../../../loader/loader";

const ConfirmModal = (props) => {
	const {
		title = "",
		text = "",
		iconName = "",
		confirmCallback = () => {},
		cancelCallback = () => {},
		confirmText,
		cancelText,
		closeCallback = null,
		offline
	} = props;
	const { t } = useTranslation();

	const [isButtonValidated, setIsButtonValidated] = useState(false);

	const validatedButton = (e) => {
		setIsButtonValidated(true);
		confirmCallback(e);
	};

	const confirmButton = (
		<button className={`confirm-modal__confirm-btn button ${!text ? "button--alert" : ""}`} 
			 onClick={validatedButton} disabled={!!isButtonValidated}>
			<Loader spinnerClass={"dbl-spinner-modal"} isLoading={!!isButtonValidated}>{confirmText || t("core:modal.yes")}</Loader>
    	</button>
	);


	const cancelButton = (
		<button disabled={isButtonValidated} className={"confirm-modal__cancel-btn button button--outline"} onClick={cancelCallback}>
			{cancelText || t("core:modal.no")} 
		</button>
	);

	const offlineButton = (
		<button className={"confirm-modal__confirm-btn button button--outline"} onClick={cancelCallback}>
			{t("core:modal.understand")}
		</button>
	);

	const getButtons = () => {
		if (offline) {
			return <>{offlineButton}</>;
		}

		if (alert) {
			return (
				<>
					{cancelButton}
					{confirmButton}
				</>
			);
		}

		return (
			<>
				{confirmButton}
				{cancelButton}
			</>
		);
	};

	return (
		<div className="confirm-modal">
			<button className="confirm-modal__close-icon" onClick={closeCallback}>
				<Icon name="close" />
			</button>
			<p className={`confirm-modal__title ${text ? "confirm-modal__title--large" : ""}`}>{title}</p>
			{iconName && <Icon className="confirm-modal__action-icon" aria-label={iconName} name={iconName} />}
			{text && (
				<div className="confirm-modal__text">
					<Trans i18nKey={text} parent="p">
						<span className="confirm-modal__text--bold" />
					</Trans>
				</div>
			)}

			<div className="confirm-modal__controls">{getButtons()}</div>
		</div>
	);
};

ConfirmModal.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	iconName: PropTypes.string,
	confirmCallback: PropTypes.func,
	cancelCallback: PropTypes.func,
	closeCallback: PropTypes.func,
	confirmText: PropTypes.string,
	cancelText: PropTypes.string,
	offline: PropTypes.bool,
	isLoading: PropTypes.bool
};

export default ConfirmModal;
