import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Button, InputCell, Loader, PopupFullPage, RegexUtils } from "../../../core";
import { DriveLogSubHeader } from "../../../drive-log";
import Icon from "../../../core/components/icon-svg/icon";
import ServiceNumberModal from "./modals/service-number-modal";
import "./service-number.scss";


/**
 * Renders an <ServiceNumber /> full page
*/
const ServiceNumber = (props) => {
	const { t } = useTranslation();
	const { navigate, title = t("dl:dl-new-page.title"), noServiceNumberOption = true, backlink, showDLExistsModal, showAttachmentNotValidModal, buttonValidated } = props;
	
	
	
	const [serviceNumber, setServiceNumber] = useState("");
	const [isFormValid, setIsFormValid] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	useEffect(()=> {
		setIsValidated(false);
	},[buttonValidated]);

	const [showNoServiceNumberModal, setShowNoServiceNumberModal] = useState(false);
	const toggleNoServiceNumberModal = () => {
		setIsValidated(false);
		setShowNoServiceNumberModal(!showNoServiceNumberModal);
	};
	


	const validCharacters = useMemo(() => /^[0-9a-zA-Z]*$/, []);

	const validate = () => {
		setIsValidated(true);
		navigate({ serviceNumber: serviceNumber });
		
	};
	

	useEffect(() => {
		const formValid = serviceNumber && serviceNumber !== "0" && !!serviceNumber.match(validCharacters);
		setIsFormValid(formValid);
	}, [validCharacters, serviceNumber]);

	/**
	 * @function handleChangeServiceNumber - checks if the entered value matches the format of the expected value
	 * @param {String} number - the entered number
	 * @return {void}
	 */
	const handleChangeServiceNumber = (number) => {
		if (number.match(validCharacters)) {
			setServiceNumber(number);
		}
	};

	const handleReset = () => {
		setServiceNumber("");
	};

	return (
		<PopupFullPage title={title} backLink={backlink}>
			<div className="service-number-page">
				<DriveLogSubHeader subHeader={t("dl:service-number.title")} />
				<div className="service-number-page__cells">
					<InputCell
						className="service-number-page__cell"
						regex={RegexUtils.alphaNumericWithFiveCharacters}
						value={serviceNumber}
						onChange={handleChangeServiceNumber}
					/>
					<Icon aria-label="Close" className="service-number__icon-close" name="close" onClick={handleReset}/>
				</div>
				<Loader isLoading={!!isValidated && (!showDLExistsModal && !showAttachmentNotValidModal)}>				
					<Button disabled={!isFormValid || isValidated} className="service-number-page__button" onClick={validate}>{t("core:popup-full-page.button.validate")}</Button>
					{noServiceNumberOption && <Button className="button button--link" onClick={toggleNoServiceNumberModal}>{t("dl:service-number.no-service")}</Button>}
				</Loader>
			</div>
			<ServiceNumberModal showModal={showNoServiceNumberModal} toggleModal={toggleNoServiceNumberModal} navigate={navigate}/>
		</PopupFullPage>
	);
};

ServiceNumber.propTypes = {
	navigate: PropTypes.func
};

export default ServiceNumber;
