import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";

const DlAddMission = (props) => {
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();

	const { displayButtonForm = false, redirectUrl = "" } = props;
	
	const addMissionPath = `${redirectUrl}/${driveLogId}/mission/add`; 

	const buttonForm = (
		<button className="dl-add-mission-button-form button button--outline">
			<Link to={addMissionPath}>
				{t("dl:dl-detail-page.add-mission")}
			</Link>
		</button>
	);

	const regularForm = (
		<button className="dl-add-mission-button button button--outline">
			<Link className="dl-add-mission" to={addMissionPath}>
				{t("dl:dl-detail-page.add-mission")}
			</Link>
		</button>
	);

	return displayButtonForm ? buttonForm : regularForm;
};

DlAddMission.propTypes = {
	buttonForm : PropTypes.bool
};

export default DlAddMission;
