import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputDelayPage from "../../../../components/input-delay-page/input-delay-page";
import queryString from "query-string";

const ModifyEndOfServiceDelayPage = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;
	const { id } = useParams();
	const location = useLocation();
	const param = queryString.parse(location.search);
	const { t } = useTranslation();

	return (
		<InputDelayPage
			driveLogId={id} fieldToUpdate="end_of_service_delay"
			additionnalFields={{}}
			title={t("dl:add-delay-page.input-delay")}
			redirect={param?.redirect}
			redirectUrl={redirectUrl}
		/>
	);
};

export default ModifyEndOfServiceDelayPage;
