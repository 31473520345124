import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FinalizeDlModal from "../modals/finalize-dl-modal/finalize-dl-modal";

/**
 * Renders an <FinalizeDl/> component
 * @param {object} props
 * @param {array} props.missionsList - missions array
 * @param {array} props.observations - observations array
 * @param {function} props.setUncompletedDl - function that set the uncompleted drive log variable
 */
const FinalizeDl = (props) => {
	const { driveLogId, missionsList, observations, setUncompletedDl, redirectUrl = "" } = props;
	const { t } = useTranslation();

	const [showFinalizeModal, setShowFinalizeModal] = useState(false);

	const uncompletedTrainNumber = missionsList?.some(mission => !mission.train_number);
	const uncompletedComposition = missionsList?.some(mission => !mission.train_composition);
	const errorMissionsHours = () => {
		const missingHours = missionsList?.some(mission => !mission.hour_start || !mission.hour_end);
		const errorHours = missionsList?.some(mission => mission.hour_start > mission.hour_end);
		return missingHours || errorHours;
	};
	const uncompletedObservations = observations?.some(observation => observation.draft);

	const checkCompletedDl = () => {
		if ((missionsList && (errorMissionsHours() || uncompletedTrainNumber || uncompletedComposition)) || uncompletedObservations) {
			setUncompletedDl(true);
		} else {
			setShowFinalizeModal(!showFinalizeModal);
		}
	};

	const toggleModal = () => setShowFinalizeModal(false);

	return (
		<>
			<button className="drive-log-detail__finalize-dl-btn button" onClick={checkCompletedDl}>
				{t("dl:dl-detail-page.finalize-dl")}
			</button>
			<FinalizeDlModal showModal={showFinalizeModal} hideModal={toggleModal} driveLogId={driveLogId} redirectUrl={redirectUrl} />
		</>
	);
};

FinalizeDl.propTypes = {
	missionList : PropTypes.array
};

export default FinalizeDl;
