import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../core";
import "./returned-drive-log-table-headers.scss";

const ReturnedDriveLogTableHeaders = () => {
	const { t } = useTranslation("dl");

	return (
		<li className="returned-drive-log-header">
			<div className="service-start-cell">{t("dl-table.headers.date")}</div>
			<div className="service-number-cell">{t("dl-table.headers.service")}</div>
			<div className="sheet-line-number">{t("dl-table.headers.sheet-line")}</div>
			<div className="hours-cell__hours">
				<div>{t("dl-table.headers.hour-start")}</div>
				<Icon name="arrow-right" className="arrow-right" />
				<div>{t("dl-table.headers.hour-end")}</div>
			</div>
			<div className="comment-cell">{t("dl-table.headers.comments")}</div>
			<div className="return-date-cell">{t("dl-table.headers.return-date")}</div>
		</li>
	);
};

export default ReturnedDriveLogTableHeaders;
