import { v4 as uuid } from "uuid";
import { dispatchGetRequest, dispatchPostRequest, dispatchPutRequest, dispatchDeleteRequest } from "../../config";

/**
 * get list of missions from digiref
 * @returns {Promise<Object>} response
 */
const getMissionsFromRef = (key, url, config = {}) => dispatchGetRequest(key, url, config);

/**
 * get drive log mission list from api
 * @param {String} driveLogId drive log uuid
 * @returns {Promise} response
 */
const getDriveLogMissions = async (driveLogId) => {
	const payload = await dispatchGetRequest(`dl-${driveLogId}-missions`, `/drive-log/${driveLogId}/mission`);

	// if individual missions have been modified offline, the changes won't show up on the list. Get mission info from local storage instead
	const getIndividualMissionInfo = async (mission) => {
		const { id } = mission;
		if (!id) {
			return mission;
		}
		const missionPayload = await dispatchGetRequest(`dl-${driveLogId}-mission-${id}`, `/drive-log/${driveLogId}/mission/${id}`);
		return missionPayload?.data;
	};

	const missionList = await Promise.all(payload?.data.map(getIndividualMissionInfo));

	return { data: missionList };
};

/**
 * get mission information from api
 * @param {string} driveLogId - drive-log tech_id (uuid)
 * @param {string} missionId - mission tech_id (uuid)
 * @returns {Promise<Object>} response
 */
const getMissionInfo = (driveLogId, missionId) => {
	return dispatchGetRequest(`dl-${driveLogId}-mission-${missionId}`,`/drive-log/${driveLogId}/mission/${missionId}`);
};

/**
 * add mission to drive log
 * @param {Object} mission - mission json
 * @param {string} driveLogId - drive log id mission is attached to
 * @returns {Promise<Object>} response
 */
const addMission = (mission, driveLogId) => {
	const generateId = uuid();
	return dispatchPostRequest(`dl-${driveLogId}-missions`, `/drive-log/${driveLogId}/mission`, mission, `dl-${driveLogId}-mission-${generateId}`, generateId);
};

/**
 * add mission to drive log
 * @param {string} missionId - 6 letter mission code
 * @param {string} driveLogId - drive log id mission is attached to
 * @param {string} trainNumber - train number to add or update
 * @returns {Promise<Object>} response
 */
const addTrainNumber = (missionId, driveLogId, trainNumber) => {
	return dispatchPutRequest(
		`dl-${driveLogId}-mission-${missionId}`,
		`/drive-log/${driveLogId}/mission/${missionId}`,
		{ train_number: trainNumber },
		`dl-${driveLogId}-missions`,
		missionId
	);
};

/**
 * add train composition to mission
 * @param {string} missionId - 6 letter mission code
 * @param {string} driveLogId - drive log id mission is attached to
 * @param {number} trainComposition - train composition to add or update (1 or 2)
 * @returns {Promise<Object>} response
 */
const addTrainComposition = (missionId, driveLogId, trainComposition) => {
	return dispatchPutRequest(
		`dl-${driveLogId}-mission-${missionId}`,
		`/drive-log/${driveLogId}/mission/${missionId}`,
		{ train_composition: trainComposition },
		`dl-${driveLogId}-missions`,
		missionId
	);
};

/**
 * delete mission from drive log
 * @param {String} missionId
 * @param {String} driveLogId
 * @return {Promise<Object>}
 */
const deleteMission = (missionId, driveLogId) => {
	return dispatchDeleteRequest(missionId, `dl-${driveLogId}-mission-${missionId}`, `dl-${driveLogId}-missions`, `/drive-log/${driveLogId}/mission/${missionId}`);
};

/**
 * update a mission in a given drive-log
 * @param {string} driveLogId - drive log id
 * @param {string} missionId - mission id
 * @param {Object} mission - the updated mission
 * @returns {Promise<Object>} response
 */
const updateMission = (driveLogId, missionId, mission) => {
	return dispatchPutRequest(`dl-${driveLogId}-mission-${missionId}`, `/drive-log/${driveLogId}/mission/${missionId}`, mission, `dl-${driveLogId}-missions`, missionId);
};

export { getMissionInfo, addMission, addTrainNumber, deleteMission, getMissionsFromRef, updateMission, getDriveLogMissions, addTrainComposition };
