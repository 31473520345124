import http from "../axios";
import localForage from "../local-forage";
import { storePutPending } from "./synchronize-storage";
import checkNetwork from "./check-network";

/**
 * put data to API and remove from local storage
 * @param {string} url - url to call
 * @param {Object} data - data to put
 * @return {Promise<Object>}
 */
const putDataToApi = async (url, data) => await http.put(url, data);

/**
 * Saves data to local storage
 * @param {string} key - local storage key
 * @param {string} url - request url
 * @param {Object} data - data to post
 * @param {string} listKey - data list key
 * @param {string} id - data id
 * @return {Promise<void>}
 */
const saveDataToLocalStorage = async (key, url, data, listKey, id) => {
	const dataToUpdate = { ...data, dblCache: true, dblUrl: url, dblFields: Object.keys(data), dblMethod: "PUT" };

	// update local storage
	const cache = await localForage.getItem(key);
	if (cache) {
		const { data: storedData, cacheDate } = cache;

		if (storedData?.dblCache && !storedData?.dblFields) {
			// the item is pending POST, don't do anything, only update local values
			const newData = { ...storedData, ...data };
			await localForage.setItem(key, { data: newData, cacheDate });
		} else {
			const dblFields = storedData?.dblFields ?  [...new Set([...storedData?.dblFields, ...dataToUpdate?.dblFields ])] : dataToUpdate?.dblFields;
			const newData = { ...storedData, ...dataToUpdate, dblFields };
			await localForage.setItem(key, { data: newData, cacheDate });
			await storePutPending(key);
		}
	}

	const listCache = await localForage.getItem(listKey);
	if (listCache) {
		const { data: storedList, cacheDate } = listCache;
		const updateStoredList = storedList.map((item) => {
			if (item?.id === id) {
				return { ...item, ...data };
			} else {
				return item;
			}
		});
		await localForage.setItem(listKey, { data: updateStoredList, cacheDate });
	}
};

/**
 * Dispatch put request to either local storage or to API
 * @param {string} key
 * @param {string} url
 * @param {Object} data
 * @param {string} listKey
 * @param {string} id
 * @return {Promise<Object|void>}
 */
const dispatchPutRequest = async (key, url, data, listKey, id) => {
	const online = await checkNetwork();

	if (online) {
		return await putDataToApi(url, data);
	}

	return saveDataToLocalStorage(key, url, data, listKey, id);
};

export { putDataToApi, saveDataToLocalStorage };
export default dispatchPutRequest;
