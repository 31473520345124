/**
 * find files to add or to delete
 * @param {object} nextFiles - the new files array
 * @param {object} prevFiles - the previous files array
 */
const findFilesToAddOrDelete = (nextFiles, prevFiles) => {
	let prevFilesCopy = [...prevFiles];
	const addedFiles = nextFiles.reduce((acc, cur) => {
		const { file_name: nextFileName } = cur;
		const fileIndexFound = prevFiles.findIndex((prevFile) => {
			const { file_name: prevFileName } = prevFile;
			return prevFileName === nextFileName;
		});

		if (fileIndexFound === -1) {
			acc.push(cur);
		} else {
			prevFilesCopy = prevFilesCopy.filter(file => {
				return file.file_name !== nextFileName;
			});
		}
		return acc;

	}, []);

	return { addedFiles, deletedFiles: prevFilesCopy };
};

export default findFilesToAddOrDelete ;
