import React from "react";
import PropTypes from "prop-types";
import { RadioButton } from "../../../../../../../core";
import "./survey-radio-button.scss";
import { useTranslation } from "react-i18next";

/**
 * Renders an <SurveyRadioButton /> component
 */
const SurveyRadioButton = (props) => {
	const { t } = useTranslation();
	const { className = "",
		title,
		subtitle,
		value,
		handleChange,
		name,
		validator,
		readOnly = false } = props;

	const radioButtons = ["yes", "no", "idk"];

	const onChange = (newValue) => {
		if (!validator || validator(newValue)) {
			handleChange(newValue);
		}
	};

	const renderRadioButton = (radioValue) => {
		return <RadioButton
			key={`${title + radioValue}`}
			name={name}
			className="survey-radio-button__choices__radio"
			checked={value === radioValue}
			onChange={() => onChange(radioValue)}
			label={t(`survey:survey-fields.radio-value.${radioValue}`)}
			disabled={readOnly}
			showRadioTick />;
	};

	return (
		<div className={`survey-radio-button ${className}`}>
			<div className={"survey-radio-button__title-container"}>
				<label className="survey-radio-button__question">{title}</label>
				<label className="survey-radio-button__question-explanation">{subtitle}</label>
			</div>
			<div className="survey-radio-button__choices">
				{radioButtons.map(renderRadioButton)}
			</div>
		</div>
	);
};

SurveyRadioButton.propTypes = {
	className : PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	subtitle: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	validator: PropTypes.func
};

export default SurveyRadioButton;
