import { getLineSheetDataFromLocalStorage } from "../../config/offline/dispatch-line-sheet-get-request";

/**
 * get line sheet
 * @param {Object} params
 * @return {Promise} response
 */
const getLineSheet = async (params = {}) => {
	const { line, status } = params;
	const key = `line-sheet-${line}-${status}`;
	return getLineSheetDataFromLocalStorage(key);
};


export { getLineSheet };
