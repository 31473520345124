import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { formatISO } from "date-fns";
import { Button, PopupFullPage, MultiInputCells } from "../../../core";
import { DriveLogSubHeader, DriveLogErrorMessage } from "../../../drive-log";
import { addMission } from "../../mission.services";
import checkMission from "../../utils/check-mission";
import { useLine } from "../../../observation/custom-hooks/observation-custom-hooks";
import { getDriveLogById } from "../../../drive-log/drive-log.services";
import "./add-mission.scss";

/**
 * Renders an <AddMission /> full page
 */
const AddMission = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;
	
	const { id: driveLogId } = useParams();

	const editDlLink = `${redirectUrl}/${driveLogId}` ;

	const history = useHistory();
	const { t } = useTranslation();

	// accept only alphanumeric characters for the input cells
	const acceptedCharacters = /^[a-z0-9]?$/i;

	const [line, lineLoading] = useLine();
	const [missionId, setMissionId] = useState(Array(6).fill(""));

	const [formValid, setFormValid] = useState(false);
	const [hasError, setHasError] = useState();
	const [loading, setLoading] = useState(false);
	const [displayNoMissionCode, setDisplayNoMissionCode] = useState(false);

	const sendMissionInfo = (mission) => {
		setLoading(true);
		const missionPayload = { ...mission };
		if (mission?.hour_start) {
			missionPayload.hour_start = formatISO(mission.hour_start);
		}
		if (mission?.hour_end) {
			missionPayload.hour_end = formatISO(mission.hour_end);
		}
		addMission(missionPayload, driveLogId)
			.then(() => {
				history.push(editDlLink);
			});
	};

	const handleSubmit = () => {
		if (!lineLoading && missionId && missionId.length === 6) {
			setLoading(true);
			checkMission(missionId, line).then(res => {
				const { data } = res;
				sendMissionInfo(data);
			}).catch(() => {
				setLoading(false);
				setHasError("error-invalid-code");
			});
		}

	};

	useEffect(() => {
		getDriveLogById(driveLogId).then(res => {
			const driveLog = res?.data;
			if (driveLog?.attachment_bigram === "TN") {
				setDisplayNoMissionCode(true);
			}
		}).catch(err => {
			console.error(err);
		});
	}, [driveLogId]);

	return (
		<PopupFullPage title={t("mission:add-mission.title")} backLink={editDlLink}>
			<div className="add-mission">
				<DriveLogSubHeader subHeader={t("mission:add-mission.heading")} />

				<MultiInputCells
					size={6}
					cellTypes="text"
					valueArr={missionId}
					setValueArr={setMissionId}
					setFormValid={setFormValid}
					cellRegex={acceptedCharacters}
				/>

				<DriveLogErrorMessage message={t(`mission:add-mission.${hasError}`)} showError={!!hasError} />
				<Button disabled={!formValid || lineLoading || loading} className="add-mission__button" onClick={handleSubmit}>{t("core:popup-full-page.button.validate")}</Button>
				{displayNoMissionCode && <div className={"add-mission__no-mission-code-link"} onClick={() => history.push(`${redirectUrl}/${driveLogId}/mission/add/no-code`)} >
					{t("mission:add-mission.no-mission-code")}
				</div>}
			</div>
		</PopupFullPage>
	);
};

export default AddMission;
