import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { AuthContext } from "../../../../user/auth/context/auth-context";
import ScrollToTop from "../../scroll-to-top/scroll-to-top";
import PopupFullPageHeader from "../../headers/popup-full-page-header/popup-full-page-header";
import "./popup-full-page.scss";

/**
 * Renders an <PopupFullPage /> component
 * @param {object} props
 * @param {string} props.title - the i18n key for the title of the PopupFullPage
 * @param {object} props.children - the content to render in the page
 * @param {string} props.backLink - the page to go to when the back button is clicked
 * @param {string} props.className - classnames to add to the root of this component
 */
const PopupFullPage = (props) => {
	const { t } = useTranslation();
	const { title, children, subHeader, backLink, className = "", overrideRoles } = props;

	const { user } = useContext(AuthContext);

	const allowedRoles = overrideRoles || ["driver", "amp", "admin", "localAdmin"];

	if (!user?.role || (!allowedRoles.includes(user.role))) {
		return <Redirect to="/error-access" />;
	}

	return (
		<div className={`popup-full-page ${className}`}>
			<ScrollToTop />
			<PopupFullPageHeader title={t(title)} backLink={backLink} subHeader={subHeader}/>
			<main className="popup-full-page__content">
				{children}
			</main>
		</div>
	);
};

PopupFullPage.propTypes = {
	title : PropTypes.string,
	children : PropTypes.node,
	subHeader : PropTypes.node,
	backLink : PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	className : PropTypes.string
};

export default PopupFullPage;

