import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../core";

import { ExportAttachmentPicker, ExportDatePicker ,ExportResourcePicker } from "../";
import { checkFormError } from "../utils/export-form-utils";
import validateExportForm from "../utils/validate-export-form";

const ExportForm = (props) => {
	const { onSubmitHandler = () => {}, isLoading = false } = props;
	const [formData, setFormData] = useState({
		from: null,
		to: null,
		lines: [],
		attachments: [],
		hasDriveLog: false,
		hasSurvey: false,
		hasObservation: false,
		observationTypes: []
	});

	const { t } = useTranslation("exp");

	const formError = validateExportForm(formData);

	//TODO: déplacer cette vérification dans le formulaire
	const resourcesValid = (formData?.hasDriveLog || formData?.hasSurvey) || (formData?.hasObservation && formData?.observationTypes.length > 0);

	const activeBtn = resourcesValid && !checkFormError(formError);

	const handleChange = useCallback((event) => {
		const { name, value } = event.target;
		setFormData(previousForm => ({ ...previousForm, [name]: value }));
	}, []);

	const handleFormSubmit = (requestData) => (event) => onSubmitHandler(requestData, event);

	return (
		<React.Fragment>
			<ExportDatePicker onChange={handleChange} />
			<form>
				<ExportAttachmentPicker onChange={handleChange} />
				<ExportResourcePicker onChange={handleChange} lineSelected={formData.lines} />
				<div className="export-form__button">
					<Button disabled={!activeBtn || isLoading} handleClick={handleFormSubmit(formData)}>
						{t("exp:cta")}
					</Button>
				</div>
			</form>
		</React.Fragment>
	);
};

export default ExportForm;
