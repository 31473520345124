import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Icon from "../icon-svg/icon";
import useOnClickOutside from "../../custom-hooks/use-on-click-outside";
import "./dropdown.scss";


/**
 * Dropdown component with its label
 * @param props
 * @param {string} props.className
 * @param {string} props.value
 * @param {string} props.label
 * @param {object} props.dropdownList
 * @param {function} props.onChange
 * @param {string} props.placeholder
 * @return {JSX.Element}
 * @constructor
 */

const Dropdown = (props) => {
	const { className, value, label = "", dropdownList = {}, onChange, placeholder } = props;

	const [selectedChoice, setSelectedChoice] = useState(value);

	const [showDropdownList, setShowDropdownList] = useState(false);
	const dropdown = useRef();

	const handleSelectChoiceToSearch = (choice) => {
		onChange(choice);
		setSelectedChoice(choice);
		setShowDropdownList(false);
	};

	useOnClickOutside(dropdown, () => setShowDropdownList(false));

	const getDropDownList = () => {
		const filterSelectedChoiceToSearch = Object.keys(dropdownList).filter(choice => choice !== selectedChoice);

		return filterSelectedChoiceToSearch.map(choice => (
			<li key={choice} className="dropdown__select__choices__option" role="button" onClick={() => handleSelectChoiceToSearch(choice)}>
				{dropdownList[choice]}
			</li>
		));
	};

	/**
	 * Display instruction if provided, or the selected option
	 * @param {string} instruction - the placeholder instruction provided or not
	 * @param {string } selectedOptionValue - the value of selected option
	 * @param {string} selectedOptionTranslated - the translation of the selected value
	 * @return {string} - the instruction or the translated selected value
	 */
	const displaySelectedOption = (instruction, selectedOptionValue, selectedOptionTranslated) => {
		return (instruction && selectedOptionValue === "") ? instruction : selectedOptionTranslated;
	};

	return (
		<form className={`${className} wrapper__dropdown`}>
			<div className="dropdown__label">{label}</div>

			<div className="dropdown__select" onClick={() => setShowDropdownList(!showDropdownList)}>
				<div className="dropdown__select__option">
					{displaySelectedOption(placeholder, selectedChoice, dropdownList[selectedChoice])}
				</div>
				<Icon name="arrow-right" className="dropdown__select__arrow"/>
				{showDropdownList && (
					<ul className="dropdown__select__choices" ref={dropdown}>
						{getDropDownList()}
					</ul>
				)}
			</div>
		</form>
	);
};

Dropdown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	label: PropTypes.string,
	dropdownList: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	placeholder: PropTypes.string
};

export default Dropdown;
