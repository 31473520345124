import { set, addDays, subMilliseconds } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import { getMissionsFromRef } from "../mission.services";
import { getReferentialList, getStationCodes } from "../../core/core.services";
import decodeMission from "./decode-mission";

/**
 * @typedef {import("./typedefs")}
 */

/**
 * Get current date with timestamp
 * @param {string} timeStamp of format 00:00:00. Time can go over 24h, for times after midnight ie. 25:43:00
 * @return {Date}
 */
const getTimestampFromTime = (timeStamp) => {
	const [stHours, stMinutes, stSeconds] = timeStamp.split(":");
	const parsedHours = parseInt(stHours, 10);

	// Convert to UTC timezone depending of server timezone
	const utcNow = zonedTimeToUtc(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone);
	// Convert to Paris timezone for business rule
	const parisNow = utcToZonedTime(utcNow, "Europe/Paris");

	const tzDifference = parisNow - utcNow;

	// normalize hours if over 24
	const hours = parsedHours > 24 ? parsedHours - 24 : parsedHours;

	// add one day if hours over 24
	const date = parsedHours > 24 ? addDays(new Date(), 1) : new Date();

	const dateTimeNoTz = set(date, { hours, minutes: parseInt(stMinutes, 10), seconds: parseInt(stSeconds, 10) });

	return subMilliseconds(dateTimeNoTz, tzDifference);
};

/**
 * return the mission object from DigiRef referential
 * @param {String} missionCode from referential
 * @param missionLine
 * @return {Promise<Mission|undefined>}
 */
const isMissionInRef = async (missionCode, missionLine) => {
	const missionCodeLetter = missionCode.slice(0,4);

	if (!missionCode || !missionLine) {
		return undefined;
	}
	const { data: missionsRefList } = await getMissionsFromRef("missions", "/mission");

	const matchCodeAndLine = (mission) => mission.code.toUpperCase() === missionCode && (mission.line.toUpperCase() === missionLine || missionLine === "-1");
	const matchCodeLetterAndLine = (mission) => mission.code.slice(0,4).toUpperCase() === missionCodeLetter && (mission.line.toUpperCase() === missionLine || missionLine === "-1");

	// if missionCode and missionLine have a match in missionsRefList, return the match
	if (missionsRefList?.find(matchCodeAndLine)) {
		return missionsRefList?.find(matchCodeAndLine);
	}

	const result = missionsRefList?.find(matchCodeLetterAndLine);
	// if missionCodeLetter and missionLine have a match in missionsRefList,
	// keep the stations and line infos from the match and update code, hour_end, and start_end properties
	// return the new mission object
	if (result) {
		return { ...result, code: missionCode, hour_end: "00:00:00", hour_start: "00:00:00" };
	}

	return result;
};

/**
 * return a station from an array of station codes
 * @param {Array} stationsCodes
 * @param {String} missionLine
 * @param {String} letterCode
 * @param {String} stationTypeSearch - Default letter
 * @return {undefined|Object}
 */
const findStationByLetterCode = (stationsCodes, missionLine, letterCode, stationTypeSearch = "letter") => {
	let stationCodeMatch = (station, line) => true;

	if (stationTypeSearch === "letter") {
		stationCodeMatch = ({ letter, line }) => letter === letterCode && (line === missionLine || missionLine === "-1");
	} else if (stationTypeSearch === "trigram") {
		stationCodeMatch = ({ trigram, line }) => trigram === letterCode && (line === missionLine || missionLine === "-1");
	}

	return stationsCodes.find(stationCodeMatch);
};

/**
 *
 * return departure and arrival stations names from single letter codes or trigram
 * @param {String} missionCode
 * @param {MissionCode} missionInfo
 * @param {String} line
 * @param {String} stationTypeSearch - (letter or trigram) Default letter
 * @return {Promise<{data: {code: *, station_start: String, station_end: String}}>}
 */
const getMissionInfo = async (missionCode, missionInfo, line, stationTypeSearch = "letter") => {
	const { departureLetter, arrivalLetter } = missionInfo;

	let loadStationListRef = getStationCodes;

	if (stationTypeSearch === "trigram") {
		loadStationListRef = async () => await getReferentialList("station");
	}
	const { data: stationCodes } = await loadStationListRef();

	if (!stationCodes || stationCodes.length <= 0) {
		throw Error("code_not_found");
	}
	const departureStation = findStationByLetterCode(stationCodes, line, departureLetter, stationTypeSearch);
	const arrivalStation = findStationByLetterCode(stationCodes, line, arrivalLetter, stationTypeSearch);

	if (!departureStation || !arrivalStation) {
		throw Error("station_not_found");
	}

	const { name: station_start, bigram: station_start_bigram, trigram: station_start_trigram } = departureStation;
	const { name: station_end, bigram: station_end_bigram, trigram: station_end_trigram } = arrivalStation;

	const mission = {
		code: missionCode,
		station_start,
		station_start_bigram,
		station_start_trigram,
		station_end,
		station_end_bigram,
		station_end_trigram
	};

	return { data: mission };
};

/**
 * check mission code format and return mission info if its a valid format
 * @param {Array} code
 * @param {String} missionLine
 * @return {Promise<MissionResponse>}
 */
const checkMission = async (code, missionLine) => {
	// line number is mandatory
	if (!missionLine) {
		throw Error("missing_line");
	}

	// transform everything to uppercase
	const missionCode = code.join("").toUpperCase();
	const formattedMissionLine = missionLine.toUpperCase();

	// check if mission code exists in digiref
	const refMission = await isMissionInRef(missionCode, formattedMissionLine);

	if (refMission) {
		const { line, hour_start, hour_end, ...mission } = refMission;
		const hourStart = getTimestampFromTime(hour_start);
		const hourEnd = getTimestampFromTime(hour_end);
		return { data: { ...mission, hour_start: hourStart, hour_end: hourEnd } };
	}

	// check if the code is well-formatted
	const missionInfo = decodeMission(missionCode);
	if (missionInfo) {
		return await getMissionInfo(missionCode, missionInfo, formattedMissionLine);
	}

	throw Error("not_found");
};

export default checkMission;
export { getMissionInfo };
