import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "../../../../../../core";
import { deleteUser } from "../../../../../user.services";
import"./delete-user-modal.scss";

const DeleteUserModal = (props) => {
	const { showModal, setShowModal, user, refreshUsersList } = props;
	const { id, first_name: firstName, last_name: lastName } = user;
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const deleteUserById = () => {
		setIsLoading(true);
		deleteUser(id, user)
			.then(() => {
				setShowModal(false);
				refreshUsersList();
			})
			.catch((error) => console.error(error))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const cancelCallback = () => {
		setShowModal(false);
	};

	return (
		showModal && (
			<Modal type="custom" className="delete-user-modal">
				<div className="delete-user-modal__container">
					<div className="delete-user-modal__container__header">
						<p className="delete-user-modal__container__header__title">{t("user:modals.delete-user.title")}</p>
						<p className="delete-user-modal__container__header__title">{`${firstName[0]}.${lastName} (${id}) ?`}</p>
						<Icon aria-label="WarningTriangle" className="delete-user-modal__container__header__icon" name="warning-triangle" />
					</div>

					<div className="delete-user-modal__container__content">
						<Trans i18nKey="user:modals.delete-user.warning" parent="p">
							<span className="delete-user-modal__container__content--bold" />
						</Trans>
					</div>
				</div>

				<div className="delete-user-modal__container__button-container">
					<Button className="button--outline delete-user-modal__container__button-container__item" onClick={cancelCallback}>
						{t("user:modals.delete-user.cancel")}
					</Button>
					<Button className="button delete-user-modal__container__button-container__item" disabled={isLoading} onClick={deleteUserById}>
						{t("user:modals.delete-user.confirm")}
					</Button>
				</div>
			</Modal>
		)
	);
};

export default DeleteUserModal;
