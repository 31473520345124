/**
 * Get the status of the driveLog and redirect if the driveLog is signed
 * @param {object} driveLog - the driveLog object
 * @param {object} history - the history object to redirect
 */
const getStatusRedirection = (driveLog, history, redirectUrl) => {

	if (driveLog.status === "signed") {
		history.push(`${redirectUrl}/${driveLog.id}/details`);
	}
};

export default getStatusRedirection;

