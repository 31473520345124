import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, SupervisorPage } from "../../../core";
import { AuthContext } from "../../../user/auth/context/auth-context";
import { ChangeAttachmentBanner } from "../../../attachment";
import SearchDriveLogsByDates from "../../../drive-log/components/search-drive-logs-by-dates/search-drive-logs-by-dates";
import { ConsistencyReportsList } from "../../components";
import { getConsistencyReports, checkConsistencyReportByDate } from "../../consistency-report.service";
import "./consistency-report-page.scss";

/**
 * Renders the list of consistency report
 */
const ConsistencyReportPage = () => {
	const { t } = useTranslation("cr");

	const { getAttachment, user = {} } = useContext(AuthContext);
	const [currentAttachment, setCurrentAttachment] = useState("");
	const [attachmentRetrieved, setAttachmentRetrieved] = useState(false);
	const attachmentName = user?.attachment;
	const [dateFilter, setDateFilter] = useState("");
	const [offset, setOffset] = useState(0);
	const [reports, setReports] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleDateFilter = (date) => {
		resetList();
		setDateFilter(date);
	};

	const resetList = () => {
		setOffset(0);
		setReports([]);
	};

	const resetDateFilter = () => {
		setOffset(0);
		setDateFilter("");
	};

	const loadMore = () => {
		if (dateFilter !== "") {
			resetDateFilter();
		} else {
			setOffset(offset + 5);
		}
	};

	const retrieveAttachment = useCallback(() => {
		setIsLoading(true);
		getAttachment()
			.then((attachmentInfo) => {
				setCurrentAttachment(attachmentInfo);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setAttachmentRetrieved(true);
				setIsLoading(false);
			});
	}, [getAttachment]);

	const retrieveConsistencyReports = useCallback(() => {
		if (attachmentRetrieved) {
			setIsLoading(true);
			getConsistencyReports(dateFilter, currentAttachment.code, offset)
				.then((res) => {
					const { data } = res;
					if (offset === 0) {
						setReports(data);
					} else {
						setReports([...reports, ...data]);
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentRetrieved, dateFilter, currentAttachment.code, offset]);

	const refreshConsistencyReport = (date) => {
		setIsLoading(true);
		checkConsistencyReportByDate(date)
			.then(() => {
				resetList();
				if (offset === 0) {
					retrieveConsistencyReports();
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(retrieveAttachment, [retrieveAttachment]);
	useEffect(retrieveConsistencyReports, [retrieveConsistencyReports, dateFilter, currentAttachment]);

	return (
		<SupervisorPage selectedLink={2} className="consistency-report-page">
			{currentAttachment && (
				<ChangeAttachmentBanner
					attachment={currentAttachment}
					userAttachment={attachmentName}
					className="consistency-report__attachment-banner"
					originPage="/supervisor/consistency-report"
					text="change-attachment-banner.drive-log-from"
				/>
			)}

			<div className="consistency-report__header">
				<div className="consistency-report__header__content">
					<h1 className="consistency-report__header__content__title">{t("consistency-report.header.title")}</h1>
					<div className="consistency-report__header__content__separation" />
					<SearchDriveLogsByDates handleDateFilter={handleDateFilter} date={dateFilter} />
				</div>
			</div>

			<Loader isLoading={isLoading}>
				<ConsistencyReportsList
					reports={reports}
					dateFilter={dateFilter}
					retrieveConsistencyReports={retrieveConsistencyReports}
					refreshConsistencyReport={refreshConsistencyReport}
					loadMore={loadMore}
				/>
			</Loader>
		</SupervisorPage>
	);
};

export default ConsistencyReportPage;
