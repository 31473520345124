import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Button, InputCell, Loader, PopupFullPage, RegexUtils } from "../../../core";
import { DriveLogSubHeader } from "../../../drive-log";
import Icon from "../../../core/components/icon-svg/icon";
import NoSheetLineModal from "./modals/no-sheet-line-modal/no-sheet-line-modal";
import ReplaceExistingDlModal from "./modals/replace-existing-dl-modal/replace-existing-dl-modal";
import { getDailyDriveLog } from "../../drive-log.services";

import "./add-sheet-line.scss";

/**
 * Renders an <AddSheetLine /> full page
 */
const AddSheetLine = (props) => {
	const { navigate, modifyDl, modifyDlTitle, disabledLineSheet } = props;
	const { t } = useTranslation();

	const [sheetLineNumber, setSheetLineNumber] = useState("");

	const [formValid, setFormValid] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const [showNoSheetLineModal, setNoSheetLineModal] = useState(false);
	const toggleNoSheetLineModal = () => {
		setIsValidated(false);
		setNoSheetLineModal(!showNoSheetLineModal);
	};

	const [showReplaceExistingDlModal, setReplaceExistingDlModal] = useState(false);
	const toggleReplaceExistingDlModal = () => {
		setIsValidated(false);
		setReplaceExistingDlModal(!showReplaceExistingDlModal);
	};

	const handleChange = (value) => {
		setSheetLineNumber(value);
	};


	const handleSubmit = () => {
		const sheetLineNumberResult = { sheetLineNumber: sheetLineNumber === "" ? "-1" : sheetLineNumber };
		if (modifyDl || disabledLineSheet) {
			navigate(sheetLineNumberResult);
		} else {
			// on submit, check if there is an existing drive log and display the warning modal
			getDailyDriveLog({ type: "daily" }).then(payload => {
				if (payload?.data?.id) {
					setIsValidated(true);
					toggleReplaceExistingDlModal();
				} else {
					
					navigate(sheetLineNumberResult);
				}
			}).catch(err => {
				console.error(err);
			});
		}
	};

	const handleReset = () => {
		setSheetLineNumber("");
	};

	useEffect(() => {
		//Check if sheetLineNumber is empty or falsy
		const validField = !sheetLineNumber || sheetLineNumber === "";
		setFormValid(!validField);
	}, [sheetLineNumber]);


	return (
		<>
			{!disabledLineSheet && <PopupFullPage title={modifyDlTitle ? modifyDlTitle : t("dl:dl-new-page.title")} backLink={"/daily-drive-log"}>
				<div className="add-sheet-line">
					<DriveLogSubHeader subHeader={t("dl:add-sheet-line-page.heading")} />

					<div className="add-sheet-line__cells">
						<InputCell
							className="add-sheet-line__cell"
							regex={RegexUtils.alphaNumericSpecialCharactersWithTwenty}
							onChange={handleChange}
							value={sheetLineNumber}
						/>
						<Icon aria-label="Close" className="add-sheet-line__icon-close" name="close" onClick={handleReset}/>
					</div>
					<Loader isLoading={isValidated}>
						<Button disabled={!formValid} className="add-sheet-line__button" onClick={handleSubmit}>{t("core:popup-full-page.button.validate")}</Button>
					
						<div className={"add-sheet-line__link"} onClick={toggleNoSheetLineModal}>{t("dl:add-sheet-line-page.no-sheet-line-approved")}</div>
					</Loader>
				</div>

				<NoSheetLineModal
					showModal={showNoSheetLineModal}
					toggleModal={toggleNoSheetLineModal}
					navigate={navigate}
					modifyDl={modifyDl}
					callback={handleSubmit}
				/>
				<ReplaceExistingDlModal
					showModal={showReplaceExistingDlModal}
					toggleModal={toggleReplaceExistingDlModal}
					sheetLineNumber={sheetLineNumber}
					navigate={navigate}
				/>
			</PopupFullPage>}
			{disabledLineSheet &&
				<div className="add-sheet-line__wrapper">
					<span className="add-sheet-line__wrapper__header__title"> {t("dl:dl-line-sheet-page.disabled.title")} </span>
					<span className="add-sheet-line__wrapper__header__text"> {t("dl:dl-line-sheet-page.disabled.content")} </span>
					<div className="add-sheet-line__cells">
						<InputCell
							className="add-sheet-line__cell"
							regex={RegexUtils.alphaNumericSpecialCharactersWithTwenty}
							onChange={handleChange}
							value={sheetLineNumber}
						/>
						<Icon aria-label="Close" className="add-sheet-line__icon-close" name="close" onClick={handleReset}/>

					</div>
					<Button disabled={!formValid} className="add-sheet-line__button" onClick={handleSubmit}>{t("core:popup-full-page.button.validate")}</Button>
					<div className={"add-sheet-line__link"} onClick={toggleNoSheetLineModal}>{t("dl:add-sheet-line-page.no-sheet-line-approved")}</div>

					<NoSheetLineModal
						showModal={showNoSheetLineModal}
						toggleModal={toggleNoSheetLineModal}
						navigate={navigate}
						modifyDl={modifyDl}
						callback={handleSubmit}
					/>
					<ReplaceExistingDlModal
						showModal={showReplaceExistingDlModal}
						toggleModal={toggleReplaceExistingDlModal} sheetLineNumber={sheetLineNumber}
						navigate={navigate}
					/>
				</div>
			}
		</>
	);
};

AddSheetLine.propTypes = {
	navigate: PropTypes.func
};

export default AddSheetLine;
