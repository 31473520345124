import React, { useContext, useEffect, useState } from "react";
import { SupervisorPage, Assets, checkArrayEmpty, SearchBar, Loader } from "../../../../core";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../user/auth/context/auth-context";
import { ChangeAttachmentBanner } from "../../../../attachment";
import SimpleDriveLogs from "./components/simple-drive-logs/simple-drive-logs";
import useDrivelogList from "../../../../core/custom-hooks/use-drive-log-list";
import ComplexDriveLogs from "./components/complex-drive-logs/complex-drive-logs";
import SearchDriveLogsByDates from "../../../components/search-drive-logs-by-dates/search-drive-logs-by-dates";
import "./supervisor-drive-log-list.scss";

/**
 * Renders the list of drivelogs for the supervisor
 */
const SupervisorDriveLogList = () => {
	const { t } = useTranslation("dl");
	const { getAttachment, user= {} } = useContext(AuthContext);
	const [currentAttachment, setCurrentAttachment] = useState("");
	const attachmentName = user?.attachment;

	const storageName = "supervisorDlToValidate";
	const columnsToSearch = ["name", "service", "id", "mission", "train-number"];

	const {
		isLoading: complexLoading,
		driveLogs : complexDriveLogs,
		loadMore : loadMoreComplexDriveLogs,
		handleResetDriveLogs: handleResetComplexDl,
		handleDateFilter: handleDateFilterComplexDl,
		handleSearchDriveLogs: handleSearchComplexDl,
		totalResultCount : totalResultCountComplexDl
	} = useDrivelogList({
		type: "complex",
		storageName,
		status: ["signed"],
		attachmentCode : currentAttachment?.code,
		numberResults: 5,
		mandatoryForRequest : ["attachmentCode"],
		originView: "amp"
	});

	const {
		isLoading: simpleLoading,
		driveLogs : simpleDriveLogs,
		refresh : refreshSimpleDl,
		loadMore : loadMoreSimpleDriveLogs,
		handleResetDriveLogs: handleResetSimpleDl,
		handleDateFilter: handleDateFilterSimpleDl,
		handleSearchDriveLogs: handleSearchSimpleDl,
		totalResultCount: totalResultCountSimpleDl,
		filter
	} = useDrivelogList({
		type: "simple",
		storageName,
		status: ["signed"],
		attachmentCode: currentAttachment?.code,
		numberResults : 40,
		mandatoryForRequest: ["attachmentCode"]
	});

	useEffect(() => {
		getAttachment().then((attachmentInfo) => {
			setCurrentAttachment(attachmentInfo);
		}).catch(err => {
			console.error(err);
		});
	}, [getAttachment]);

	const handleSearchDriveLogs = (search, searchOptions) => {
		handleSearchComplexDl(search, searchOptions);
		handleSearchSimpleDl(search, searchOptions);
	};

	const handleResetDriveLogs = () => {
		handleResetComplexDl();
		handleResetSimpleDl();
	};

	const handleDateFilter = (date) => {
		handleDateFilterComplexDl(date);
		handleDateFilterSimpleDl(date);
	};

	const generateDlTables = () => {
		return (
			<>
				<Loader isLoading={!!(complexLoading || simpleLoading) }>
					<ComplexDriveLogs
						className="drive-log-list__complex-dls"
						driveLogs={complexDriveLogs}
						loadMore={loadMoreComplexDriveLogs}
						totalResultCount={totalResultCountComplexDl}
					/>
					<SimpleDriveLogs
						currentAttachment={currentAttachment}
						driveLogs={simpleDriveLogs}
						refresh={refreshSimpleDl}
						loadMore={loadMoreSimpleDriveLogs}
						totalResultCount={totalResultCountSimpleDl}
					/>
				</Loader>
			</>
		);
	};

	const renderPage = () => {
		const noDriveLogToValidate = checkArrayEmpty(complexDriveLogs) && checkArrayEmpty(simpleDriveLogs);
		if (noDriveLogToValidate) {
			return (
				<div className="drive-log-list__no-drive-log-to-validate">
					<img className="drive-log-list__searching-mouse-img" src={Assets.SearchingMouse} alt="No DriveLog to validate"/>
					<div className="drive-log-list__label-no-drive-log-to-validate">
						{ t("supervisor-dl-list-page.no-drive-log-to-validate") }
					</div>
				</div>
			);
		}
		return generateDlTables();
	};

	return (
		<SupervisorPage className="drive-log-list">
			{currentAttachment &&
				<ChangeAttachmentBanner
					attachment={currentAttachment}
					userAttachment={attachmentName}
					className="drive-log-list__attachment-banner"
					originPage="/supervisor/drive-log-list"
					text="change-attachment-banner.drive-log-from"/>
			}
			<div className="drive-log-list__filters">
				<SearchBar handleSearch={handleSearchDriveLogs} handleReset={handleResetDriveLogs} columnsToSearch={columnsToSearch} filter={filter}/>
				<SearchDriveLogsByDates handleDateFilter={handleDateFilter} date={filter?.searchDate}/>
			</div>
			{renderPage()}
		</SupervisorPage>
	);
};

export default SupervisorDriveLogList;
