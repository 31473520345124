import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "../../../../../../../core";
import TwoChoicesButtons from "../../../../../../../core/components/two-choices-buttons/two-choices-buttons";
import { addTrainComposition } from "../../../../../../../mission/mission.services";

import "./update-train-composition-modal.scss";

const UpdateTrainCompositionModal = (props) => {
	const { trainComposition, setShowModal, driveLogId, missionId, updateDriveLogMissions, setEmptyTrainComposition } = props;

	const { t } = useTranslation();

	const [newComposition, setNewComposition] = useState(trainComposition?.toString());
	const formValid = newComposition === "1" || newComposition === "2";

	const handleChangeTrainComposition = (value) => {
		setNewComposition(value);
	};

	const confirmCallback = async (newTrainComposition) => {
		addTrainComposition(missionId, driveLogId, parseInt(newTrainComposition))
			.then(() => {
				setEmptyTrainComposition(false);
				setShowModal(false);
				updateDriveLogMissions();
			})
			.catch(err => console.error(err));
	};

	const closeCallback = () => {
		setShowModal(false);
	};

	return (
		<Modal type="custom" className="update-train-composition-modal">
			<div className="update-train-composition-modal__content">
				<div onClick={closeCallback}>
					<Icon name="close" className="update-train-composition-modal__content__close"/>
				</div>
				<div className="update-train-composition-modal__content__block">
					<div className="update-train-composition-modal__content__block__title">
						{t("dl:dl-detail-page.update-train-composition-modal.title")}
					</div>
					<div className="update-train-composition-modal__content__block__subtitle">
						{t("dl:dl-detail-page.update-train-composition-modal.subtitle")}
					</div>
					<TwoChoicesButtons
						className="update-train-composition-modal__content__block__choice"
						value={newComposition}
						onChange={handleChangeTrainComposition}
						name={"composition"}
						firstOptionLabel={"US"}
						firstOptionValue={"1"}
						secondOptionLabel={"UM"}
						secondOptionValue={"2"}/>
				</div>
				<div className="update-train-composition-modal__content__btn">
					<Button
						className="update-train-composition-modal__content__validate-btn"
						onClick={() => confirmCallback(newComposition)} disabled={!formValid}>
						{t("dl:dl-detail-page.update-train-composition-modal.validate")}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default UpdateTrainCompositionModal;
