import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { checkArrayEmpty } from "../../../core";
import PropTypes from "prop-types";

import ConsistencyServicesTableRow from "../consistency-services-table-row/consistency-services-table-row";
import ConsistencyServiceModal from "../consistency-service-modal/consistency-service-modal";

import "./consistency-services-table.scss";

const ConsistencyServicesTable = (props) => {
	const { reportServices, className, retrieveConsistencyReports = () => {} } = props;
	const { t } = useTranslation("cr");

	const totalResultCount = reportServices?.length || "";
	const [showModal, setShowModal] = useState(false);
	const [modalReportService, setModalReportService] = useState({});

	const updateConsistencyServices = () => {
		retrieveConsistencyReports();
	};

	const renderConsistencyServicesTable = () => {
		const complexConsistencyServicesEmpty = checkArrayEmpty(reportServices);

		if (!reportServices || complexConsistencyServicesEmpty) {
			return null;
		}

		return (
			<>
				<Trans
					parent="p"
					className="consistency-services__table__title"
					i18nKey="cr:consistency-report.consistency-services.title"
					values={{ count: totalResultCount }} >
					<span className="consistency-services__table__title__count" />
				</Trans>
				<p className="consistency-services__table__sub-title">
					{ t("consistency-report.consistency-services.sub-title") }
				</p>
				<ul className="consistency-service__table__row">
					{reportServices?.map((reportService) =>
						<ConsistencyServicesTableRow
							className={className}
							reportService={reportService}
							key={reportService?.id}
							showModal={showModal}
							setShowModal={setShowModal}
							setModalReportService={setModalReportService}
						/>
					)}
				</ul>
				<ConsistencyServiceModal
					showModal={showModal}
					toggleModal={setShowModal}
					reportService={modalReportService}
					confirmCallback={() => updateConsistencyServices()}
				/>
			</>
		);
	};

	return (
		<section className={`${className} consistency-service`}>
			{renderConsistencyServicesTable()}
		</section>
	);
};

ConsistencyServicesTable.propTypes = {
	className: PropTypes.string,
	services: PropTypes.array
};

export default ConsistencyServicesTable;
