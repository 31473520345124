import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../../core";

/*
	Modal to display when the 'No sheet line approved' link is clicked
	The user is either routed to daily drive-log if the answer is yes
	or stay on the add-sheet-line page if the answer is no
 */
const NoSheetLineModal = (props) => {
	const { t } = useTranslation();
	const { showModal, toggleModal, callback = () => {} } = props;
	const modalText = t("dl:add-sheet-line-page.no-sheet-line-modal");

	const confirmCallback = () => {
		callback();
	};
	const cancelCallback = () => {
		toggleModal();
	};

	return (
		showModal && <Modal type="confirm" title={modalText} confirmCallback={confirmCallback} cancelCallback={cancelCallback} closeCallback={cancelCallback}/>
	);
};

NoSheetLineModal.propTypes = {
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	createNewDl: PropTypes.func
};

export default NoSheetLineModal;
