import React, { createContext, useState } from "react";

import { useAppEnv } from "../../../config";

const GlobalAppContext = createContext({
	fromApp: "",
	setFromApp: () => {},
	trackingEnabled: false
});

const GlobalAppProvider = (props) => {
	const { children } = props;

	// matomo initialize
	const { MATOMO_TRACKING_ENABLED = false } = useAppEnv();
	const trackingEnabled = JSON.parse(MATOMO_TRACKING_ENABLED);

	//fromApp is used to know if the user comes from DigiPlan or not
	const [fromApp, setFromApp] = useState(null);

	return <GlobalAppContext.Provider value= {{ fromApp,
		setFromApp,
		trackingEnabled }}>
		{children}
	</GlobalAppContext.Provider>;
};

export { GlobalAppContext, GlobalAppProvider };
