import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import ConfirmModal from "./components/confirm-modal/confirm-modal";
import CustomModal from "./components/custom-modal/custom-modal";
import ErrorModal from "./components/error-modal/error-modal";
import BottomModal from "./components/bottom-modal/bottom-modal";
import "./modal.scss";

const modalMapping = {
	"confirm" : ConfirmModal,
	"custom" : CustomModal,
	"error" : ErrorModal,
	"bottom-modal": BottomModal
};

const Modal = (props) => {
	const { type, children, overlay = true } = props;
	const modalContainerRef = useRef(null);
	const modalRoot = document.getElementById("modal-root");

	if (!modalContainerRef.current) {
		const modalContainer = document.createElement("div");
		modalContainer.classList.add("modal-container");
		modalContainerRef.current = modalContainer;
	}

	useEffect(() => {
		modalRoot.appendChild(modalContainerRef.current);
		return () => {
			const currentContainer = modalContainerRef.current;
			if (modalRoot.contains(currentContainer)) {
				modalRoot.removeChild(currentContainer);
			}

		};
	}, [modalRoot]);

	if (modalMapping[`${type}`] ) {
		const modalToRender = React.createElement(modalMapping[`${type}`], { ...props }, children);
		return createPortal(<div className={overlay ? "modal" : ""}>{modalToRender}</div>, modalContainerRef.current);
	} else {
		return null;
	}

};

Modal.propTypes = {
	type : PropTypes.string,
	children : PropTypes.node,
	closeCallback : PropTypes.func
};

export default Modal;
