import { RegexUtils } from "../../../core";
import { validateDelay, regexValidator } from "../../components/observation-form/observation-validators";

const authorizedPerson = [
	{
		fieldName: "id",
		mandatory: true,
		separator: "or",
		group: [
			{
				fieldName: "ratpId",
				validator: regexValidator(RegexUtils.sixDigitNumber)
			},
			{
				fieldName: "name",
				validator: regexValidator(RegexUtils.alphabeticAndSpecialCharacters)
			}
		]
	},
	{
		fieldName: "trainDriving",
		mandatory: true,
		children: [
			{
				fieldName: "stationStartDriving",
				mandatory: true,
				parentValue: "yes"
			},
			{
				fieldName: "stationEndDriving",
				mandatory: true,
				parentValue: "yes"
			},
			{
				fieldName: "stationStartNoDriving",
				mandatory: false,
				parentValue: "no"
			},
			{
				fieldName: "stationEndNoDriving",
				mandatory: false,
				parentValue: "no"
			}
		]
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default authorizedPerson;
