import { differenceInHours } from "date-fns";
import http from "../axios";
import localForage from "../local-forage";
import checkNetwork from "./check-network";
import { synchronizeAllStorage } from "./synchronize-storage";

/**
 * Retrieves data from API and refresh local storage
 * @param {string} key - local storage key
 * @param {string} url - url to call
 * @param config
 * @return {Promise<Object>}
 */
const getDataFromApi = async (key, url, config = {}) => {
	try {
		// check if there's pending data in local storage and synchronize
		await synchronizeAllStorage();

		const apiData = await http.get(url, config);
		const { data } = apiData;

		// refresh local storage
		localForage.setItem(key, { data, cacheDate: new Date() }).then();
		return apiData;
	} catch (err) {
		console.info("Data fetch failed", err);
	}
};

/**
 * Retrieves data from local storage
 * @param {string} key - local storage key
 * @return {Promise<{data: Object}>}
 */
const getDataFromLocalStorage = async (key) => {
	const response = await localForage.getItem(key);
	const { data } = response || {};

	// return data using same format as axios responses
	return { data };
};

/**
 * Retrieve expired data from API
 * @param {string} key
 * @param {string} url
 * @param {number} maxCacheAge - cache age limit in hours
 * @param config
 * @return {Promise<void>}
 */
const getExpiredDataFromApi = async (key, url, maxCacheAge, config) => {
	try {
		const cache = await localForage.getItem(key);

		if (!cache) {
			return getDataFromApi(key, url, config);
		}

		const { cacheDate } = cache;
		// cache date is over the cache age limit.
		const isExpired = differenceInHours(new Date(), new Date(cacheDate.toString())) > maxCacheAge;

		// cache no longer valid if there's no max age, no date or is expired
		const cacheNotValid = !maxCacheAge || !cacheDate || isExpired;

		// renew cache if not valid
		if (cacheNotValid) {
			return getDataFromApi(key, url, config);
		}

		return getDataFromLocalStorage(key);
	} catch (err) {
		console.info("Data fetch failed", err);
	}
};

/**
 * Dispatch request to either api (if app is online) or local storage (if app is offline)
 * @param {string} key - local storage key
 * @param {string} url - url to call (GET request)
 * @param config
 * @return {Promise<{data: Object}>}
 */
const dispatchGetRequest = async (key, url, config = {}) => {
	const online = await checkNetwork();
	if (online) {
		return await getDataFromApi(key, url, config);
	}

	return getDataFromLocalStorage(key);
};

export { getDataFromApi, getExpiredDataFromApi, getDataFromLocalStorage };
export default dispatchGetRequest;
