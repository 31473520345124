import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Icon } from "../../../core";
import "./dl-details-subheader.scss";

const DlDetailsSubheader = (props) => {
	const { driveLog = {}, displayBackLink = true, readOnly = false, isAnonymous = false, domain = "", redirectUrl = "" } = props;

	const history = useHistory();
	const { t } = useTranslation("dl");

	const { first_name: firstName, last_name: lastName, user_id: id, attachment_name: attachment, id: drivelogId } = driveLog;

	const goBackLink = () => {
		const { status } = driveLog;

		switch(domain) {
		case "driver":
			history.push("/drive-logs");
			break;
		case "consultant":
			history.push("/consultant/drive-logs");
			break;
		case "hr":
			if (status === "archived") {
				history.push("/hr/archived-drive-logs");
			} else {
				history.push("/hr/drive-log-list");
			}
			break;
		default:
			if (status === "validated" || status === "archived") {
				history.push("/supervisor/validated-drive-logs");
			} else {
				history.push("/supervisor/drive-log-list");
			}
		}
	};

	const handleEditSheetLineNumber = () => {
		if (!readOnly) {
			history.push(`${redirectUrl}/${drivelogId}/modify-sheet-line-number`, { from: window.location.pathname });
		}
	};


	return (
		<div className="dl-details-subheader">
			{!isAnonymous && <>
				{displayBackLink && <div className="dl-details-subheader__item dl-details-subheader__names" onClick={goBackLink}>
					<div className="dl-details-subheader__back-link">
						<Icon name="back-left-arrow"/>
					</div>
					<span className="dl-details-subheader__item__value">{`${firstName} ${lastName}`}</span>
				</div>}
				{!displayBackLink && <div className="dl-details-subheader__item">
					<span className="dl-details-subheader__item__value">{`${firstName} ${lastName}`}</span>
				</div>}

			 <div className="dl-details-subheader__item dl-details-subheader__user-id">
					<span>{t("dl-details.dl-details-subheader.user-id")}</span>
					<span className="dl-details-subheader__item__value">{id}</span>
				</div>
			</>}

			<div className="dl-details-subheader__item dl-details-subheader__attachment">
				<span>{t("dl-details.dl-details-subheader.attachment")}</span>
				<span className="dl-details-subheader__item__value">{attachment}</span>
			</div>

			<div className="dl-details-subheader__item dl-details-subheader__sheet-line">
				<div className="dl-details-subheader__sheet-line--label" onClick={() => handleEditSheetLineNumber()}>
					<span>{t("dl-details.dl-details-subheader.sheet-line")}</span>
					<span className="dl-details-subheader__item__value">{driveLog?.sheet_line_number !== "-1" ? driveLog?.sheet_line_number : "PV"}</span>
					{ !readOnly && <Icon className="dl-details-subheader__icon" name="pen"/>}
				</div>
			</div>
		</div>
	);
};

DlDetailsSubheader.propTypes = {
	driveLog : PropTypes.object.isRequired
};

export default DlDetailsSubheader;
