import { useTranslation } from "react-i18next";
import { Assets, Button, checkArrayEmpty, checkObjectEmpty } from "../../../core";
import { ConsistencyServicesTable, ConsistencyOverlapsList } from "../";
import "./consistency-report-list.scss";

const checkServicesAndOverlaps = (reports) => {
	const servicesAndOverlapsCount = reports.reduce((noServicesAndOverlaps, currentReport) => {
		if (!checkArrayEmpty(currentReport.services) || !checkObjectEmpty(currentReport.overlaps)) {
			noServicesAndOverlaps += 1;
		}
		return noServicesAndOverlaps;
	}, 0);

	return servicesAndOverlapsCount === 0;
};

const ConsistencyReportsList = (props) => {
	const { reports, dateFilter, retrieveConsistencyReports, refreshConsistencyReport, loadMore } = props;
	const { t } = useTranslation("cr");

	const noReportToJustify = checkArrayEmpty(reports);
	const noServicesNorOverlaps = checkServicesAndOverlaps(reports);

	const handleRefresh = (date) => {
		refreshConsistencyReport(date);
	};

	const formatDate = (reportDate) => {
		const formatDateFilter = new Date(dateFilter ? dateFilter : reportDate);
		return formatDateFilter.toLocaleDateString("fr");
	};

	if (noReportToJustify || noServicesNorOverlaps) {
		return (
			<div className="consistency-report__services__no-services">
				<img className="consistency-report__services__no-services__searching-mouse-img" src={Assets.SearchingMouse} alt="No service to justify" />
				<div className="consistency-report__services__no-services__label-no-drive-log-to-validate">{t("consistency-report.no-service-to-justify")}</div>
				{dateFilter && (
					<Button className="button--link" onClick={() => handleRefresh(dateFilter)}>
						{t("consistency-report.header.refresh")}
					</Button>
				)}
			</div>
		);
	}

	return (
		<>
			{reports?.map((report) => {
				const { date, services, overlaps } = report;
				const displayDate = formatDate(date);

				return (
					<div className="consistency-report" key={report.id}>
						<div className="consistency-report__header__date">
							<h2 className="consistency-report__header__date__title">{t("consistency-report.header.date-title", { date: displayDate })}</h2>
							<Button className="button--link" onClick={() => handleRefresh(date)}>
								{t("consistency-report.header.refresh")}
							</Button>
						</div>

						<ConsistencyServicesTable className="consistency-report__services" reportServices={services} retrieveConsistencyReports={retrieveConsistencyReports} />
						<ConsistencyOverlapsList
							className="consistency-report__overlaps"
							date={date}
							reportOverlaps={overlaps}
							retrieveConsistencyReports={retrieveConsistencyReports}
						/>
					</div>
				);
			})}
			<div className="consistency-report__footer">
				<Button className="button--primary" onClick={loadMore}>
					{t("consistency-report.footer.load-more")}
				</Button>
			</div>
		</>
	);
};

export default ConsistencyReportsList;
