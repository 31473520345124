import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../../../../core";

/**
 * Component Modal to display when the 'Delete' button is clicked on observation raw when the application is offline
 * @component
 * @param {Object} props
 * @param {Object} props.showModal - the observation object data
 * @param {function} props.setShowModal - display or not the modal
 */
const OfflineObservationModal = ({ showModal, setShowModal }) => {
	const { t } = useTranslation();
	const modalText =  t("dl:dl-detail-page.obs-raw.offline");

	const confirmCallback = () => {
		setShowModal(false);
	};

	const cancelCallback = () => {
		setShowModal(false);
	};

	return (
		showModal && <Modal type="confirm" offline title={modalText} confirmCallback={confirmCallback} cancelCallback={cancelCallback} closeCallback={cancelCallback}/>
	);
};

OfflineObservationModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	setShowModal: PropTypes.func.isRequired
};

export default OfflineObservationModal;
