import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { getDriveLogById, updateDriveLog } from "../../../drive-log.services";
import { Button, Modal, SupervisorPage } from "../../../../core";
import DlDetailsSubheader from "../../../components/dl-details-subheader/dl-details-subheader";
import { CommentWithFileAttachment, FooterDlDetails, MissionListAllObs, ServiceInfos } from "../../../index";
import DelaysTable from "../../driver/delay-list-page/components/delays-table/delays-table";
import Icon from "../../../../core/components/icon-svg/icon";
import SurveyList from "../../../components/survey-list/survey-list";
import { MATOMO_ACTIONS, MATOMO_CATEGORIES } from "../../../../core/matomo/matomo-constants";
import DrivelogPrintablePdf from "../../../components/drivelog-printable-pdf/drivelog-printable-pdf";
import useMatomoTracker from "../../../../core/matomo/matomo-tracker";
import "./dl-details-supervisor.scss";

const DlDetailsSupervisor = (props) => {
	const { params = {} } = props;
	const { domain = "", redirectUrl = "" } = params;

	const { id: driveLogId } = useParams();
	const location = useLocation();
	const queryParams = queryString.parse(location.search);
	const isSimpleDL = Boolean(queryParams["simple"]);
	const { t } = useTranslation();
	const history = useHistory();
	const { trackEvent } = useMatomoTracker();
	const [openedAt, setOpenedAt] = useState(null);
	const [currentDriveLog, setCurrentDriveLog] = useState({});
	const [showReturnModal, setShowReturnModal] = useState(false);
	const [showTransmitModal, setShowTransmitModal] = useState(false);
	const [showPdfModal, setShowPdfModal] = useState(false);
	const [isAnonymous, setIsAnonymous] = useState();
	const [loading, setLoading] = useState(true);
	const [dlToUpdate, setDlToUpdate] = useState({
		comment: "",
		id: driveLogId
	});

	const { is_psg: isDrivelogPsg, attachment_name: attachmentName, status } = currentDriveLog;
	const isDriveLogSigned = status === "signed";

	const handleReturnModal = (show = false) => {
		setShowReturnModal(show);
	};

	const handleTransmitModal = (show = false) => {
		setShowTransmitModal(show);
	};

	const handlePdfModal = (show = false) => {
		setShowPdfModal(show);
	};

	const handleUpdateDriveLog = (action, data) => {
		updateDriveLog(data, { action })
			.then((res) => {
				if(["validate", "archive"].includes(action)) {
					const validatedAt = new Date();
					const processingTime = (validatedAt - openedAt ) / 1000; // in seconds
					// Track the processing duration between the opening time of the DL and its validation
					trackEvent({ category: MATOMO_CATEGORIES.DRIVELOG, action: MATOMO_ACTIONS.PROCESSING_TIME, value: processingTime });
					// Track if DL is eligible to batch validation but has been manually validated
					if(isSimpleDL) {
						trackEvent({ category: MATOMO_CATEGORIES.DRIVELOG, action: MATOMO_ACTIONS.VALIDATE_MANUAL, name: attachmentName, value: 1 });
					}
				}
				history.push("/supervisor/drive-log-list");
			})
			.catch((err) => console.error(err));
	};

	const handlePrintPdf = async (printAnonymous) => {
		await setShowPdfModal(false);
		await setIsAnonymous(printAnonymous);
		window.print();
	};


	const loadDriveLog = () => {
		getDriveLogById(driveLogId, { extended: true })
			.then((payload) => {
				const driveLog = payload.data;
				const { comment } = driveLog;

				setCurrentDriveLog(driveLog);
				setDlToUpdate((oldDl) => ({
					...oldDl,
					comment
				}));
				setOpenedAt(new Date());
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(loadDriveLog, [driveLogId]);

	return (
		<SupervisorPage className="drive-log-details-supervisor" overrideRoles={["amp", "admin", "consultant", "hr", "localAdmin"]} hideNav={true}>
			<DlDetailsSubheader driveLog={currentDriveLog} isAnonymous={isAnonymous} domain={domain}/>

			<div className="drive-log-details-supervisor__content">
				<ServiceInfos driveLog={currentDriveLog} loading={loading}/>
				<DelaysTable
					loading={loading}
					loadDriveLog={loadDriveLog}
					driveLog={currentDriveLog}
					readOnly={!isDriveLogSigned}
					showModalOnEditClick={isDriveLogSigned}
					showTitle
					onDriveLogDetails
					setDlToUpdate={setDlToUpdate}
					dlToUpdate={dlToUpdate}
				/>

				{isDriveLogSigned && (
					<div className="drive-log-details-supervisor__content__warning-delays">
						<Icon aria-label="Warning" className="warning-delays__icon" name="warning" />
						<div className="warning-delays__text">{t("dl:dl-details.delays.warning")}</div>
					</div>
				)}

				<MissionListAllObs loading={loading} setLoading={setLoading} />
				<SurveyList redirectUrl={redirectUrl}/>
				<DrivelogPrintablePdf />
				<CommentWithFileAttachment formData={dlToUpdate} setFormData={setDlToUpdate} currentDriveLog={currentDriveLog} readOnly={!isDriveLogSigned} />

				<div className="drive-log-details-supervisor__controls__action">
					<div className="drive-log-details-supervisor__controls__pdf" onClick={() => handlePdfModal(!showPdfModal)}>
						{t("dl:dl-details.generate-pdf")}
					</div>

					<div className="drive-log-details-supervisor__controls__action__main">
						{isDriveLogSigned && (
							<Button className="drive-log-details-supervisor__controls" onClick={() => handleReturnModal(!showReturnModal)} outline>
								{t("dl:dl-details.return-button")}
							</Button>
						)}
						{isDrivelogPsg && isDriveLogSigned && (
							<Button className="drive-log-details-supervisor__controls" onClick={() => handleTransmitModal(!showTransmitModal)} outline>
								{t("dl:dl-details.transmit-button")}
							</Button>
						)}
						{isDriveLogSigned && (
							<Button
								className="drive-log-details-supervisor__controls"
								onClick={() => handleUpdateDriveLog(currentDriveLog.is_psg ? "archive" : "validate", dlToUpdate)}
							>
								{t("dl:dl-details.validate-button")}
							</Button>
						)}
					</div>
				</div>
			</div>

			<FooterDlDetails currentDriveLog={currentDriveLog} />

			{showReturnModal && (
				<Modal
					type="confirm"
					title={t("dl:dl-details.return-modal.title")}
					text="dl:dl-details.return-modal.text"
					confirmText={t("dl:dl-details.confirm")}
					cancelText={t("dl:dl-details.cancel")}
					confirmCallback={() => handleUpdateDriveLog("return", dlToUpdate)}
					cancelCallback={() => handleReturnModal()}
					closeCallback={() => handleReturnModal()}
					iconName="arrow-undo"
				/>
			)}

			{showTransmitModal && (
				<Modal
					type="confirm"
					title={t("dl:dl-details.transmit-modal.title")}
					text="dl:dl-details.transmit-modal.text"
					confirmText={t("dl:dl-details.confirm")}
					cancelText={t("dl:dl-details.cancel")}
					confirmCallback={() => handleUpdateDriveLog("revert-psg-tag", currentDriveLog)}
					cancelCallback={() => handleTransmitModal()}
					closeCallback={() => handleTransmitModal()}
					iconName="transmit"
				/>
			)}

			{showPdfModal && (
				<Modal
					type="confirm"
					title={t("dl:dl-details.pdf-modal.title")}
					text="dl:dl-details.pdf-modal.text"
					confirmText={t("dl:dl-details.no")}
					cancelText={t("dl:dl-details.yes")}
					confirmCallback={() => handlePrintPdf(false)}
					cancelCallback={() => handlePrintPdf(true)}
					closeCallback={() => handlePdfModal()}
					iconName="anonymous"
				/>
			)}
		</SupervisorPage>
	);
};

export default DlDetailsSupervisor;
