import axios from "axios";
import setupInterceptor from "./setup-interceptor";
import timeoutInterceptor from "./timeout-interceptor";
import pingInterceptor from "./ping-interceptor";

/**
 * Create axios instance
 */
const { REACT_APP_API_BASE_URL = "" } = process.env;

const http = axios.create({
	baseURL: REACT_APP_API_BASE_URL,
	timeout: 30000,
	withCredentials: true,
	validateStatus: status => status < 400
});


http.interceptors.request.use(timeoutInterceptor);
http.interceptors.request.use(pingInterceptor);

setupInterceptor(http);

export default http;
