import { RegexUtils } from "../../../../core";
import { regexValidator } from "../../../../observation/components/observation-form/observation-validators";

/**
 * Validate user rating
 * @param rating selected user rating
 * @return {boolean}
 */
const validateRating = (rating = "") => {
	const acceptedValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	return rating.length > 0 && acceptedValues.includes(parseInt(rating));
};

/**
 * Validate user comment
 * @param comment user comment
 * @return {boolean}
 */
const validateComment = (comment = "") => regexValidator(RegexUtils.alphaNumericAndSpecialCharacters)(comment);

export { validateRating, validateComment };
