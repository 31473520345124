const requiredFieldValidator = (value) => !value ? ["falsy"] : [];

const dateValidator = (value) => !value || !(value instanceof Date) ? ["falsy"] : [];

const arrayValidator = (value) => !value || !Array.isArray(value) ? ["falsy"] : [];

const booleanValidator = (value) => value || typeof value === "boolean" ? [] : ["falsy"];

const checkFormError = (formError = {}) => {
	const isErrorList = Object.values(formError);
	return isErrorList.reduce((errorInForm, curFieldError) => errorInForm || curFieldError.length !== 0, false);
};

export {
	arrayValidator,
	booleanValidator,
	checkFormError,
	dateValidator,
	requiredFieldValidator
};
