import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Modal } from "../../../../../core";

/*
	Modal to display when the Validate button on AddSheetLinePage is clicked AND a drive-log for this date is already existing
	The user is either routed to daily drive-log if the answer is yes
	or stay on the add-sheet-line page if the answer is no
 */
const ReplaceExistingDlModal = (props) => {
	const { t } = useTranslation();
	const { showModal, toggleModal, navigate, sheetLineNumber, callback, isNewDl = false } = props;

	const modalText = t("dl:add-sheet-line-page.replace-existing-dl-modal");

	const confirmCallback = () => {
		if (isNewDl) {
			callback();
		}
		navigate({ sheetLineNumber });
	};
	const cancelCallback = () => {
		if (isNewDl) {
			navigate(state => state - 1);
		}
		toggleModal();
	};


	return (
		showModal && <Modal type="confirm" title={modalText} confirmCallback={confirmCallback} cancelCallback={cancelCallback} closeCallback={cancelCallback}/>
	);
};

ReplaceExistingDlModal.propTypes = {
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	navigate: PropTypes.func,
	sheetLineNumber: PropTypes.string,
	callback: PropTypes.func,
	isNewDl: PropTypes.bool
};

export default ReplaceExistingDlModal;
