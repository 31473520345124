import React from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../core/components/icon-svg/icon";
import { DateUtils } from "../../../core";

import "./disable-line-sheets-table.scss";

const DisableLineSheetsTable = (props) => {
	const { disabledLineSheets = [] } = props;
	const { t } = useTranslation("disable-line-sheets");

	const displayHeaders = () => {
		return (
			<li className="disable-line-sheets__table__headers">
				<div className="disable-line-sheets__table__headers__cell">
					{ t("deactivation-history.table.line") }
				</div>
				<div className="disable-line-sheets__table__headers__cell">
					{ t("deactivation-history.table.date") }
				</div>
				<div className="disable-line-sheets__table__headers__cell">
					{ t("deactivation-history.table.identity") }
				</div>
			</li>
		);
	};

	const displayTableRows = (disabledLineSheet) => {
		const { line, disabled_by: disabledBy, disabled_at: disabledAt } = disabledLineSheet;

		return (
			<li className="disable-line-sheets__table__rows-wrapper__rows">
				<div className="disable-line-sheets__table__rows-wrapper__rows__line">
					<span className="disable-line-sheets__table__rows-wrapper__rows__line__text"> { t("deactivation-history.table.line") } </span>
					<Icon name={ line === "A" ? "line_A_square" : "line_B_square" } className="disable-line-sheets__table__rows-wrapper__rows__line__icon"/>
				</div>
				<div className="disable-line-sheets__table__cells__text">
					{DateUtils.formatFullDate(disabledAt, "dd/MM/yyyy")}
				</div>
				<div className="disable-line-sheets__table__cells__text">
					{disabledBy}
				</div>
			</li>
		);
	};

	return (
		<div className="disable-line-sheets__table">
			{displayHeaders()}
			<ul className="disable-line-sheets__table__rows-wrapper">
				{disabledLineSheets?.map(displayTableRows)}
			</ul>
		</div>
	);
};

export default DisableLineSheetsTable;
