/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { PopupFullPage } from "../../core";
import "./terms-of-service.scss";

/**
 * Renders a <TermsOfService /> component
 */
const TermsOfService = (props) => {
	const { t } = useTranslation("terms-of-service");
	const { state } = useLocation();
	const { overrideRoles } = props;

	return (
		<PopupFullPage title={t("title")} backLink={state.originPage} overrideRoles={overrideRoles} >
			<div className="terms-of-service">
				<h3 className="terms-of-service__app__name">{t("digibulletin.content.app-name")}</h3>
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digibulletin.content.description" />
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digibulletin.content.tasks.title" />
				<Trans
					parent="ul"
					className="terms-of-service__paragraph terms-of-service__tasks"
					i18nKey="terms-of-service:digibulletin.content.tasks.list" />
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digibulletin.content.private-data.paragraph-1" />
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digibulletin.content.private-data.paragraph-2" />
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digibulletin.content.private-data.paragraph-3" />

				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digibulletin.content.information.contact"
					values={{ emailCil: "cil-ratp@ratp.fr" }} >
					<a className="terms-of-service__link" href="mailto::cil-ratp@ratp.fr"/>
				</Trans>

				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digibulletin.content.information.external-pages.text"
					values={{ gdpr: t("digibulletin.content.information.external-pages.gdpr"), gdprGroup: t("digibulletin.content.information.external-pages.gdpr-group") }} >
					<a className="terms-of-service__link" href="https://urbanweb.ratp.net/jcms/pr_3018050/protection-des-donnees-personnelles"/>
					<a className="terms-of-service__link" href="https://urbanweb.ratp.net/jcms/pr_569324/delegation-a-la-protection-des-donnees"/>
				</Trans>

				<h3 className="terms-of-service__app__name digiplan">{t("digiplan.content.app-name")}</h3>
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digiplan.content.description" />
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digiplan.content.tasks.title" />
				<Trans
					parent="ul"
					className="terms-of-service__paragraph terms-of-service__tasks"
					i18nKey="terms-of-service:digiplan.content.tasks.list" />
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digiplan.content.private-data.paragraph-1" />
				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digiplan.content.private-data.paragraph-2" />
				<Trans
					parent="ul"
					className="terms-of-service__paragraph terms-of-service__authorized-list"
					i18nKey="terms-of-service:digiplan.content.private-data.authorized-list" />

				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digiplan.content.information.contact"
					values={{ emailCil: "cil-ratp@ratp.fr" }} >
					<a className="terms-of-service__link" href="mailto::cil-ratp@ratp.fr"/>
				</Trans>

				<Trans
					parent="p"
					className="terms-of-service__paragraph"
					i18nKey="terms-of-service:digiplan.content.information.external-pages.text"
					values={{ gdpr: t("digiplan.content.information.external-pages.gdpr"), gdprGroup: t("digiplan.content.information.external-pages.gdpr-group") }} >
					<a className="terms-of-service__link" href="https://urbanweb.ratp.net/jcms/pr_3018050/protection-des-donnees-personnelles"/>
					<a className="terms-of-service__link" href="https://urbanweb.ratp.net/jcms/pr_569324/delegation-a-la-protection-des-donnees"/>
				</Trans>
			</div>
		</PopupFullPage>
	);
};

export default TermsOfService;
