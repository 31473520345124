import { dispatchGetRequest, dispatchPostRequest, http } from "../../config";
import { addDays, getHours, getMinutes, isBefore, isDate, parseISO, set, setHours, setMinutes, setSeconds, startOfDay, formatISO } from "date-fns";

/**
 * add observation to drive log
 * @param {string} driveLogId - drive log uuid
 * @param {Object} observation - observation content
 * @param {boolean} draft - save observation as draft
 * @returns {Promise} response
 */
const addObservation = (driveLogId, observation, draft = false) =>
	dispatchPostRequest(`dl-${driveLogId}-observations`, `/drive-log/${driveLogId}/observation`, { ...observation, draft });

/**
 * get all observations in a given drive-log
 * @param {string} driveLogId - drive log uuid
 * @returns {Promise<Array>} an array of all observations
 */
const getAllObservationsInDl = (driveLogId) => {
	return dispatchGetRequest(`dl-${driveLogId}-observations`, `/drive-log/${driveLogId}/observation`);
};

/**
 * get all observations in Dl with "trigger_automatic_stop" type with a default void action queryparam
 * @param {string} driveLogId - drive log uuid
 * @param queryParam
 * @returns {Promise<Array>} an array of all observations for a specific type
 */
const getAllObservationsInDlByType = (driveLogId, queryParam = "") => {
	return dispatchGetRequest(`dl-${driveLogId}-observations`, `/drive-log/${driveLogId}/observation?action=${queryParam}`);
};

/**
 * get an observation in a given drive-log
 * @param {string} driveLogId - drive log id
 * @param {string} observationId - observation id
 * @returns {Promise<Array>} an array of the observation
 */
const getObservationById = (driveLogId, observationId) => {
	return http.get(`/drive-log/${driveLogId}/observation/${observationId}`);
};

/**
 * update an observation in a given drive-log
 * @param {string} driveLogId - drive log id
 * @param {string} observationId - observation id
 * @param {Object} observation - the updated observation
 * @param {boolean} draft - save observation as draft
 * @returns {Promise<Object>} response
 */
const updateObservationById = (driveLogId, observationId, observation, draft) => {
	return http.put(`/drive-log/${driveLogId}/observation/${observationId}`, { ...observation, draft });
};

/**
 * delete an observation in a given drive-log
 * @param {string} driveLogId - drive log uuid
 * @param {string} observationId - the observation uuid
 * @returns {Promise} response
 */
const deleteObservation = (driveLogId, observationId) => {
	return http.delete(`/drive-log/${driveLogId}/observation/${observationId}`);
};

/***
 *
 * @param startServiceDateTime {string}
 * @param startOfDayHour {number}
 * @param currentDate {Date}
 * @param updateType {string} - "hours" or "minutes" ("none" if no update)
 * @param updateValue {number}
 */
const setDateTimeWithService = (startServiceDateTime = formatISO(new Date()), startOfDayHour = undefined, currentDate = null, updateType = "none", updateValue = 0) => {
	let formatStartServiceDateTime = isDate(startServiceDateTime) ? startServiceDateTime : parseISO(startServiceDateTime);
	if (startOfDayHour) {
		formatStartServiceDateTime = set(formatStartServiceDateTime, { hours: getHours(startOfDayHour), minutes: getMinutes(startOfDayHour) });
	}

	const startDay = startOfDay(formatStartServiceDateTime);
	const startNextDay = addDays(startDay, 1);

	// add default values, otherwise observation fields don't initialize with current hour (returns undefined)
	let newTimeDay = formatStartServiceDateTime;
	let newTimeNextDay = addDays(formatStartServiceDateTime, 1);

	// Set old value for day of service and next day of service
	if (currentDate) {
		newTimeDay = setHours(startDay, getHours(currentDate));
		newTimeDay = setMinutes(newTimeDay, getMinutes(currentDate));

		newTimeNextDay = setHours(startNextDay, getHours(currentDate));
		newTimeNextDay = setMinutes(newTimeNextDay, getMinutes(currentDate));
	}

	// Update with time changed if required
	if (updateType !== "none") {
		const setType = updateType === "hours"? setHours : setMinutes;

		newTimeDay = setType(currentDate ? newTimeDay : startDay, updateValue);
		newTimeNextDay = setType(currentDate ? newTimeNextDay : startNextDay, updateValue);
	}

	// If newTime is before start of day, set time for next day
	let newTime;
	if (isBefore(newTimeDay, setSeconds(formatStartServiceDateTime, 0))) {
		newTime = newTimeNextDay;
	} else {
		newTime = newTimeDay;
	}
	return newTime;
};

export { addObservation, getAllObservationsInDl, deleteObservation, getObservationById, updateObservationById, setDateTimeWithService, getAllObservationsInDlByType };
