import axios from "axios";

// timeout 1000 for ping requests, allows for fast switching to offline mode without waiting for default axios timeout
const TIMEOUT = 5000;

/**
 * Axios interceptor for ping requests (requests to /status).
 * If there's no internet connection or server not reachable, the request times out after 1000ms
 * Interceptor uses CancellationToken to manually cancel axios request
 * @param config
 * @return {{cancelToken: CancelToken}|*}
 */
const pingInterceptor = config => {
	// only intercept requests to /status
	if (config.url !== "/status") {
		return config;
	}

	const source = axios.CancelToken.source();

	setTimeout(() => {
		source.cancel("Application is offline");
	}, TIMEOUT);

	// If caller configures cancelToken, preserve cancelToken behaviour.
	if (config.cancelToken) {
		config.cancelToken.promise.then(cancel => {
			source.cancel(cancel.message);
		});
	}

	return { ...config, cancelToken: source.token };
};

export default pingInterceptor;
