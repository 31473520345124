import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../../core";
import "./plus-icon.scss";

/**
 * Renders an <PlusIcon /> component
 * @param {Object} props
 * @param {string} props.iconText - the icon text
 */
const PlusIcon = (props) => {
	const { iconText } = props;

	return (
		<div>
			<Icon name="plus" className="plus-icon"/>
			<span className="plus-icon-span">{iconText}</span>
		</div>
	);
};

PlusIcon.propTypes = {
	iconText: PropTypes.string
};

export default PlusIcon;
