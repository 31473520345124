import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "../../../core/components/button/button";
import "./consistency-services-table-row.scss";

/**
 * ConsistencyReportServicesRow component display a row for each service
 * @return {JSX.Element}
 * @constructor
 */
const ConsistencyServicesTableRow = (props) => {
	const { className, reportService, showModal, setShowModal, setModalReportService } = props;
	const { t } = useTranslation("cr");
	const { service_number: serviceNumber, attachment_name: attachmentName } = reportService;

	const handleShowModal = () => {
		setModalReportService(reportService);
		setShowModal(!showModal);
	};

	return (
		<li className={`${className} consistency-service-list`}>
			<div className="consistency-service-list__row__cell">
				<p className="consistency-service-list__row__cell__service-number">
					{ t("consistency-report.consistency-services.service-row.title", { serviceNumber, attachmentName }) }
				</p>
			</div>
			<div className="consistency-service-list__row__cell">
				<Button className="consistency-service-list__row__cell__button button--link" onClick={handleShowModal}>
					{ t("consistency-report.consistency-services.service-row.justify")}
				</Button>
			</div>
		</li>
	);
};

ConsistencyServicesTableRow.propTypes = {
	className: PropTypes.string,
	service: PropTypes.object
};

export default ConsistencyServicesTableRow;
