import React from "react";
import { useTranslation } from "react-i18next";

import { PopupFullPage } from "../../../core";
import { SpecialNoticeForm } from "../../forms";

import "./create-special-notice-page.scss";

const CreateSpecialNoticePage = (props) => {
	const { t } = useTranslation("special-notice");

	return (
		<PopupFullPage title={t("special-notice.forms.titles.create")} backLink={"/special-notices"}>
			<SpecialNoticeForm />
		</PopupFullPage>
	);
};

export default CreateSpecialNoticePage;
