import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import UpdateDelayModal from "../update-delay-modal/update-delay-modal";

/*
	Modal to display when the 'Modify' button is clicked by the supervisor on delays-list
	The supervisor is routed to the drive-logs list page when
	the button "Valider le bulletin" is clicked
 */
const OtherDelayModal = ({
	showModal,
	setShowModal,
	driveLog,
	fieldToUpdate,
	currentDriverField,
	ampDelays,
	setAmpDelays,
	ampDelaysFieldToUpdate,
	setDlToUpdate,
	dlToUpdate }) => {

	const [newSupervisorDelay, setNewSupervisorDelay] = useState(0);
	const [hasErrorMaxDelay, setHasErrorMaxDelay] = useState(false);
	const [hasErrorEOSDelay, setHasErrorEOSDelay] = useState(false);

	const closeCallback = () => {
		setShowModal(false);
		setNewSupervisorDelay(ampDelays[ampDelaysFieldToUpdate]);
		setHasErrorMaxDelay(false);
		setHasErrorEOSDelay(false);
	};

	const ampTotal = ()  => ampDelays.total + newSupervisorDelay - ampDelays[ampDelaysFieldToUpdate];

	const handleDelayChange = (newDelay) => {

		if (newDelay === "") {
			setNewSupervisorDelay(newDelay);
		} else {
			//parse & calculate valid delay
			const castedNewDelay = parseInt(newDelay, 10);
			const castedNewDelayValid = castedNewDelay === 0 || (castedNewDelay && castedNewDelay <= 1440);

			// if other delay field update
			setHasErrorMaxDelay(!castedNewDelayValid);
			setNewSupervisorDelay(castedNewDelay);
		}
	};

	const handleValidate = () => {
		setDlToUpdate({ ...dlToUpdate, [fieldToUpdate]: newSupervisorDelay, amp_total_additional_time: ampTotal() });
		setAmpDelays({ ...ampDelays, [ampDelaysFieldToUpdate]: newSupervisorDelay, total: ampTotal() });
		setShowModal(false);
	};

	useEffect( () => {
		if (driveLog) {
			setNewSupervisorDelay(ampDelays[ampDelaysFieldToUpdate]);
		}
	}, [setNewSupervisorDelay, driveLog, ampDelays, ampDelaysFieldToUpdate]);

	return (
		<UpdateDelayModal
			showModal={showModal}
			closeCallback={closeCallback}
			newSupervisorDelay={newSupervisorDelay}
			currentDriverField={currentDriverField}
			handleDelayChange={handleDelayChange}
			hasErrorMaxDelay={hasErrorMaxDelay}
			hasErrorEOSDelay={hasErrorEOSDelay}
			handleValidate={handleValidate}
		/>
	);
};

OtherDelayModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	driveLog: PropTypes.object,
	fieldToUpdate: PropTypes.string,
	currentDriverField: PropTypes.string,
	ampDelays: PropTypes.object,
	setAmpDelays: PropTypes.func,
	ampDelaysFieldToUpdate: PropTypes.string,
	setDlToUpdate: PropTypes.func,
	dlToUpdate: PropTypes.object
};

export default OtherDelayModal;
