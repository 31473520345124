import purgeServices from "./purge.services";
import i18next from "../../config/i18next";
import i18nUser from "./i18n/fr.json";

import PurgeInfoBanner from "./components/purge-info-banner/purge-info-banner";

i18next.addResourceBundle("fr", "pg", i18nUser);

export {
	purgeServices, PurgeInfoBanner
};
