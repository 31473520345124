import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SpecialNoticePage, Button, SearchBar, Loader } from "../../../core";
import EmptySplash from "../../../core/assets/images/empty-splash.svg";
import { SpecialNoticeListHeader, SpecialNoticeListRow } from "../../components";
import useStoredFilter from "../../../core/custom-hooks/use-stored-filter";
import { getSpecialNotices } from "../../special-notice.services";
import "./special-notices-page.scss";

const translationEntity = "special-notice";

const SpecialNoticesPage = (props) => {
	const { t } = useTranslation(translationEntity);

	const { enclosed } = props.params;
	const specialNoticePageUrl = "/special-notice";
	const columnsToSearch = ["station", "mission", "title"];
	const storageName = enclosed ? "ampEnclosedSpecialNotices" : "ampActiveSpecialNotices";
	const status = enclosed ? "enclosed" : "active";
	const sortBy = enclosed ? "enclosed_at" : "created_at";

	const {
		data: specialNotices,
		refresh: refreshSpecialNotices,
		handleResetData: handleResetNotices,
		handleSearchData: handleSearchNotices,
		filter,
		isLoading
	} = useStoredFilter(
		{
			storageName,
			columnsToSearch: columnsToSearch[0],
			getData: () => getSpecialNotices({ status, sortBy, ...filter })
		});

	const renderList = () => {
		if (!Array.isArray(specialNotices) || (Array.isArray(specialNotices) && specialNotices.length === 0)) {
			return (
				<div className="empty-dl-list__content">
					<h1 className="empty-dl-list__content__title">{enclosed ? t("special-notice.list.no-enclosed-notices") : t("special-notice.list.no-ongoing-notices")}</h1>
					<div className="empty-dl-list__empty-splash" style={{ backgroundImage: `url(${EmptySplash})` }} />
				</div>
			);
		}

		return (
			<div className="special-notices-page__list">
				<SpecialNoticeListHeader enclosed={enclosed} />
				{specialNotices.map(renderRows)}
			</div>
		);
	};

	const renderRows = (specialNotice) => (
		<SpecialNoticeListRow specialNotice={specialNotice} key={specialNotice.id} enclosed={enclosed} refreshSpecialNotices={refreshSpecialNotices} />
	);

	return (
		<SpecialNoticePage selectedLink={enclosed ? 1 : 0} className="special-notices-page">
			<div className="special-notices-page__header">
				<SearchBar
					handleSearch={handleSearchNotices}
					handleReset={handleResetNotices}
					columnsToSearch={columnsToSearch}
					filter={filter}/>
				{!enclosed && (
					<>
						<span className="special-notices-page__header__separator"/>
						<Link to={`${specialNoticePageUrl}/add`}>
							<Button className="special-notices-page__header__button button">{t("special-notice.list.create-btn")}</Button>
						</Link>
					</>
				)}
			</div>

			<Loader isLoading={isLoading}>{renderList()}</Loader>
		</SpecialNoticePage>
	);
};

export default SpecialNoticesPage;
