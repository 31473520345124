import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link  } from "react-router-dom";
import DriveLogDelayDetailsTableCell from "../drive-log-delay-details-table-cell/drive-log-delay-details-table-cell";
import { Button, DateUtils, Icon } from "../../../../core";
import StateCell from "../../drive-log-table/columns/state-cell/state-cell";
import computeTime from "../../../../core/utils/compute-time/compute-time";
import { updateDriveLog } from "../../../drive-log.services";
import { validateDelay } from "../../../../core/utils/validation-utils/validation-utils";
import Comment from "../../../../core/components/comment/comment";

import "./drive-log-delay-details-table-row.scss";


const DriveLogDelayDetailsTableRow = (props) => {
	const { driveLog, hrToArchiveView = false, refresh } = props;
	const { t } = useTranslation("dl");
	const { formatHourMinutes } = DateUtils;
	const [ comment, setComment ] = useState(null);

	const isEditable = hrToArchiveView;

	const [delays, setDelays] = useState({
		totalAdditionalTime: driveLog.hr_total_additional_time,
		endOfServiceDelay: driveLog.hr_end_of_service_delay,
		dailyLegalTimeExceed: driveLog.hr_daily_legal_time_exceed,
		restTimeReduction: driveLog.hr_rest_time_reduction,
		diffServicePlannedPerformed: driveLog.hr_diff_service_planned_performed_time,
		otherTcTime: driveLog.hr_other_tc_time,
		rfnBonus: driveLog.rfn_bonus
	});
	const [t3tc, setT3tc] = useState(0);

	useEffect(() => {
		const computedTime = computeTime({
			shiftStartDate: driveLog.realized_date_start,
			shiftEndDate: driveLog.realized_date_end,
			endOfShiftDelay: parseInt(delays.endOfServiceDelay || "0"),
			dailyLegalTimeExceed: parseInt(delays.dailyLegalTimeExceed || "0"),
			restTimeReduction: parseInt(delays.restTimeReduction || "0"),
			diffServicePlannedPerformedTime: parseInt(delays.diffServicePlannedPerformed || "0"),
			otherTcTime: parseInt(delays.otherTcTime || "0")
		}, driveLog);
		setT3tc(computedTime.t3tc);
	}, [delays, driveLog]);

	const getSuperiorTenMin = useCallback(() => {
		if (delays.endOfServiceDelay && delays.endOfServiceDelay > 10) {
			return delays.endOfServiceDelay - 10;
		}
		return 0;
	}, [delays.endOfServiceDelay]);

	const generateDetailsLink = () => {
		if (hrToArchiveView) {
			return `/hr/drive-log/${driveLog.id}/details`;
		} else {
			return `/hr/drive-log/archived/${driveLog.id}/details`;
		}
	};

	const handleArchive = (e) => {
		e.stopPropagation();
		updateDriveLog({
			id : driveLog.id,
			hr_end_of_service_delay: delays.endOfServiceDelay,
			hr_daily_legal_time_exceed : delays.dailyLegalTimeExceed,
			hr_rest_time_reduction : delays.restTimeReduction,
			hr_diff_service_planned_performed_time: delays.diffServicePlannedPerformed,
			hr_other_tc_time: delays.otherTcTime,
			rfn_bonus: delays.rfnBonus,
			hr_comment: comment
		}, { action : "archive" }).then(refresh).catch(err => {
			console.error(err);
		});
	};

	const handleDelayChange = (value, valueKey, valueType) => {
		if (valueType === "number") {
			if (validateDelay(value)) {
				const parsedValue = value === "" ? value : parseInt(value);
				setDelays({ ...delays, [valueKey]: parsedValue });
			}
		} else {
			setDelays({ ...delays, [valueKey]: value });
		}

	};

	const getHour = (date) => formatHourMinutes(date, ":");

	return (
		<div className={"drive-log-delay-details-table-row"}>
			<div className={"drive-log-delay-details-table-row__box"}>
				<div className={"drive-log-delay-details-table-row__box-left-side"}>
					<div className={"drive-log-delay-details-table-row__name"}>{`${driveLog?.first_name} ${driveLog?.last_name}`}</div>
					<div className={"drive-log-delay-details-table-row__id"} >{driveLog?.user_id}</div>
					<div className={"drive-log-delay-details-table-row__attachment"} >{t("dl-delay-details-table.attachment")}</div>
					<div className={"drive-log-delay-details-table-row__attachment-name"} >{driveLog?.attachment_name}</div>
					<div className={"drive-log-delay-details-table-row__service"} >{t("dl-delay-details-table.service-number", { number: driveLog?.realized_service_number })}</div>
					<div className={"drive-log-delay-details-table-row__service-date"}>
						<div className="row__hours">
							{getHour(driveLog?.realized_date_start)}
						</div>
						<Icon name="arrow-right" className="arrow-right"/>
						<div className="row__hours">
							{getHour(driveLog?.realized_date_end)}
						</div>
					</div>
				</div>
				<div className={"drive-log-delay-details-table-row__box-middle-side"}>
					<div className={"drive-log-delay-details-table-row__box-middle-side__cell-column"}>
						<DriveLogDelayDetailsTableCell label={t("delay-list-page.delay-superior-10m")}
													   value={getSuperiorTenMin()} />
						<DriveLogDelayDetailsTableCell label={t("delay-list-page.delay-superior-6h-30m")} isEditable={isEditable} handleChange={handleDelayChange}
													   valueKey={"dailyLegalTimeExceed"} value={delays.dailyLegalTimeExceed} />
						<DriveLogDelayDetailsTableCell label={t("delay-list-page.rest-shortened")} isEditable={isEditable} handleChange={handleDelayChange}
													   valueKey={"restTimeReduction"} value={delays.restTimeReduction} />
						<DriveLogDelayDetailsTableCell label={t("delay-list-page.diff-service-planned-performed")} isEditable={isEditable} handleChange={handleDelayChange}
													   valueKey={"diffServicePlannedPerformed"} value={delays.diffServicePlannedPerformed} />
					</div>
					<div className={"drive-log-delay-details-table-row__box-middle-side__cell-column"}>
						<DriveLogDelayDetailsTableCell label={t("delay-list-page.other-tc")} isEditable={isEditable} handleChange={handleDelayChange}
													   valueKey={"otherTcTime"} value={delays.otherTcTime} comment={driveLog?.other_tc_reason} />
						<DriveLogDelayDetailsTableCell label={t("delay-list-page.additionnal-time")} theme={"pink"} isEditable={isEditable} handleChange={handleDelayChange}
													   valueKey={"endOfServiceDelay"} value={delays.endOfServiceDelay} />
						<DriveLogDelayDetailsTableCell label={t("delay-list-page.total-t3-tc")} theme={"pink"}
													   value={t3tc} />
						{driveLog?.line_number === "A" && <DriveLogDelayDetailsTableCell label={t("delay-list-page.rfn-bonus")} theme={"transparent"}
																				  isEditable={isEditable} handleChange={handleDelayChange}
																				  valueKey={"rfnBonus"} value={delays.rfnBonus} valueType={"bool"}/>}
					</div>
				</div>
				<div className={"drive-log-delay-details-table-row__box-right-side"}>
					<Link to={generateDetailsLink}>
						<Button className={"button--outline drive-log-delay-details-table-row__button"} >
							{t("dl-delay-details-table.details")}</Button>
					</Link>

					{isEditable && <Button className={"button  drive-log-delay-details-table-row__button"} onClick={handleArchive}>
						{t("dl-delay-details-table.archive")}
					</Button>}
					{!isEditable && <StateCell driveLog={driveLog} header={false} />}
				</div>
			</div>
			{isEditable && <div className="drive-log-delay-details-table-row__comment">
				<hr className="drive-log-delay-details-table-row__comment__separator"/>
				<Comment
					value={comment}
					title={t("dl-delay-details-table.comment.title")}
					handleChange={setComment}
					className={"drive-log-delay-details-table-row__comment__input"}
					placeholder={t("dl-delay-details-table.comment.placeholder")}
					maxLength={144}
					collapse={true}
				/>
			</div>
			}
		</div>
	);
};

DriveLogDelayDetailsTableRow.propTypes = {
	driveLog: PropTypes.object,
	type: PropTypes.string,
	refresh: PropTypes.func
};

export default DriveLogDelayDetailsTableRow;
