import React from "react";
import PropTypes from "prop-types";
import { Comment } from "../../../../../core";

const ObservationComment = (props) => {
	const { handleChange, value, className = "", title, placeholder, validator, fieldSchema } = props;

	const onChange = (newValue) => {
		if (!validator || validator(newValue) || newValue === "") {
			handleChange(newValue);
		}
	};

	return (
		<Comment
			value={value}
			title={title}
			handleChange={onChange}
			className={className}
			placeholder={placeholder}
			maxLength={fieldSchema?.maxLength}
		/>
	);
};

ObservationComment.propTypes = {
	handleChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	value: PropTypes.string,
	title: PropTypes.string,
	placeholder: PropTypes.string,
	validator: PropTypes.func
};

export default ObservationComment;
