import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, InputCell, PopupFullPage, RegexUtils } from "../../../core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DriveLogSubHeader } from "../../index";
import { getDriveLogById, updateDriveLog } from "../../drive-log.services";
import getStatusRedirection from "../../utils/get-status-redirection";
import Comment from "../../../core/components/comment/comment";
import computeTime from "../../../core/utils/compute-time/compute-time";
import "./input-delay-page.scss";

const InputDelayPage = (props) => {
	const { driveLogId, title="", fieldToUpdate="", additionnalFields={}, lockDelay, backlink, reasonField, redirect, redirectUrl = "" } = props;

	const { t } = useTranslation();
	const [currentDelay, setCurrentDelay] = useState(0);
	const [currentReason, setCurrentReason] = useState("");
	const [disableValidate, setDisableValidate] = useState(false);
	const [loading, setLoading] = useState(true);
	const [endOfServiceDelay, setEndOfServiceDelay] = useState(0);
	const [hasErrorEndOfDayDelay, setHasErrorEndOfDayDelay] = useState(false);
	const [hasErrorMaxDelay, setHasErrorMaxDelay] = useState(false);
	const history = useHistory();

	let delaysPageLink = `${redirectUrl}/${driveLogId}/delays`;
	if (redirect === "sign") {
		delaysPageLink = `${redirectUrl}/${driveLogId}/sign`;
	}

	// Click on the close button sends the user to the drivelog delays list page unless overwritten by passing a
	// backlink props
	const initedBackLink = backlink ? backlink : delaysPageLink;

	const updateDisableValidate = (delay, reason) => {
		setDisableValidate((!reason || reason?.length === 0) && delay > 0);
	};

	/**
	 * Handle a change of delay in the interface
	 * @param {String} newDelay
	 */
	const handleDelayChange = (newDelay) => {
		if (newDelay === "") {
			setCurrentDelay(newDelay);
		} else {
			const castedNewDelay = parseInt(newDelay, 10);
			const validInferiorEndOfServiceDelay = !lockDelay || (lockDelay && castedNewDelay <= endOfServiceDelay);
			const castedNewDelayValid = castedNewDelay === 0 || (castedNewDelay && castedNewDelay <= 1440);
			if (validInferiorEndOfServiceDelay && castedNewDelayValid ) {
				setCurrentDelay(newDelay);
			}
			setHasErrorEndOfDayDelay(!validInferiorEndOfServiceDelay);
			setHasErrorMaxDelay(!castedNewDelayValid);

			if (reasonField) {
				updateDisableValidate(castedNewDelay, currentReason);
			}
		}

	};

	/**
	 * Handle a change of reason in the interface
	 * @param {String} newReason
	 */
	const handleReasonChange = (newReason) => {
		if (newReason?.length < 140) {
			setCurrentReason(newReason);
			updateDisableValidate(currentDelay, newReason);
		}
	};

	useEffect(() => {
		if (fieldToUpdate && driveLogId) {
			getDriveLogById(driveLogId).then((payload) => {
				const driveLog = payload.data;
				getStatusRedirection(driveLog, history);
				const { end_of_service_delay: originalEndOfServiceDelay } = driveLog;
				setEndOfServiceDelay(originalEndOfServiceDelay);
				const delay = driveLog[fieldToUpdate] ? driveLog[fieldToUpdate] : 0;
				if (delay > 0) {
					setCurrentDelay(delay);
				}
				if (reasonField) {
					setCurrentReason(driveLog[reasonField]);
					updateDisableValidate(delay, driveLog[reasonField]);
				}
			}).catch(err => {
				console.error(err);
			}).finally(() => {
				setLoading(false);
			});
		}
	}, [fieldToUpdate, driveLogId, history, reasonField]);


	const handleValidate = () => {
		const delay = currentDelay || 0;
		const castedDelay = parseInt(delay, 10);

		getDriveLogById(driveLogId).then((payload) => {
			const oldDrivelog = payload.data;
			if (castedDelay <= 1440 && castedDelay >= 0) {
				const updatedDrivelog = { ...oldDrivelog, ...additionnalFields };
				updatedDrivelog[fieldToUpdate] = castedDelay;
				const driverComputePayload = {
					shiftStartDate: updatedDrivelog.realized_date_start,
					shiftEndDate: updatedDrivelog.realized_date_end,
					endOfShiftDelay: updatedDrivelog.end_of_service_delay,
					dailyLegalTimeExceed: updatedDrivelog.daily_legal_time_exceed,
					restTimeReduction: updatedDrivelog.rest_time_reduction,
					diffServicePlannedPerformedTime: updatedDrivelog.diff_service_planned_performed_time,
					otherTcTime: updatedDrivelog.other_tc_time
				};
				const { endOfServiceDelay: endOfServiceDelayUpdated, restTimeReduction,
					superiorToSixHoursThirty, diffServicePlannedPerformedTime,
					otherTcTime, total } = computeTime(driverComputePayload, updatedDrivelog);
				const updatedDriveLogFinal = {
					id: driveLogId,
					end_of_service_delay: endOfServiceDelayUpdated,
					daily_legal_time_exceed: superiorToSixHoursThirty,
					rest_time_reduction: restTimeReduction,
					diff_service_planned_performed_time: diffServicePlannedPerformedTime,
					other_tc_time: otherTcTime,
					total_additional_time: total
				};
				if (reasonField) {
					updatedDriveLogFinal[reasonField] = currentReason;
				}
				updateDriveLog(updatedDriveLogFinal, { action: "modifyDelay" }).then(() => {
					history.push(delaysPageLink);
				}).catch(err => {
					console.error(err);
				});
			}
		}).catch(err => {
			console.error(err);
		});
	};

	return (
		<PopupFullPage title={t("dl:add-delay-page.title")} backLink={initedBackLink}>
			{
				!loading && (
					<div className="input-delay-page">
						<DriveLogSubHeader subHeader={title} />

						<div className="input-delay-page__input-cell-wrapper">
							<InputCell
								className="input-delay-page__input-cell"
								maxLength={4}
								value={currentDelay === 0 ? "" : currentDelay}
								onChange={handleDelayChange}
								regex={RegexUtils.numericOrEmpty}
							/>

							<div className="input-delay-page__minutes">
								{t("dl:add-delay-page.minutes")}
							</div>
						</div>

						{reasonField && <div className={"input-delay-page__reason"}>
							<Comment
								title={t(`dl:add-delay-page.${reasonField}`)}
								placeholder={t(`dl:add-delay-page.${reasonField}-placeholder`)}
								value={currentReason}
								handleChange={handleReasonChange} />
						</div>}

						<div className={`input-delay-page__error-message 
							${(hasErrorEndOfDayDelay || hasErrorMaxDelay) ? "" : "input-delay-page__error-message--hidden"}`}>

							{hasErrorEndOfDayDelay && t("dl:add-delay-page.error-max-end-of-day-delay")}
							{hasErrorMaxDelay && t("dl:add-delay-page.error-max-delay")}
						</div>

						<Button className="input-delay-page__validate-button" onClick={handleValidate} disabled={disableValidate}>
							{t("core:popup-full-page.button.validate")}
						</Button>
					</div>
				)
			}
		</PopupFullPage>
	);
};

InputDelayPage.propTypes = {
	fieldToUpdate: PropTypes.string,
	additionnalFields : PropTypes.object,
	title: PropTypes.string,
	driveLogId: PropTypes.string,
	lockDelay: PropTypes.bool,
	backlink: PropTypes.string,
	reasonField: PropTypes.string
};

export default InputDelayPage;
