import React, { useEffect, useState } from "react";
import "./purge-info-banner.scss";
import PropTypes from "prop-types";
import PurgeServices from "../../purge.services";
import { useTranslation } from "react-i18next";
import { DateUtils } from "../../../core";

const PurgeInfoBanner = (props) => {
	const { className } = props;
	const { t } = useTranslation("pg");
	const [purgeInfo, setPurgeInfo] = useState();

	useEffect(() => {
		PurgeServices.getDriveLogPurgeData().then((response) => {
			const purgeData = response.data;
			const oldestDriveLogDate = DateUtils.formatFullDate(purgeData?.oldestDriveLog?.realizedDateStart);
			const lastPurgeDate = DateUtils.formatFullDate(purgeData?.latestPurge?.timeEnd);

			setPurgeInfo({ oldestDriveLogDate, lastPurgeDate });
		}).catch(err => {
			console.error(err);
		});
	}, []);

	return (
		<footer className={`purge-banner ${className}`}>
			<div className="purge-banner__oldest-dl">
				<span className="purge-banner__oldest-dl__label">
					{ t("purge-info-banner.oldest-drivelog") }
				</span>
				<span className="purge-banner__oldest-dl__date">
					{ ` ${purgeInfo?.oldestDriveLogDate}` }
				</span>
			</div>
			<div className="purge-banner__last-purge">
				<span className="purge-banner__last-purge__label">
					{ t("purge-info-banner.last-purge") }
				</span>
				<span className="purge-banner__last-purge__date">
					{` ${purgeInfo?.lastPurgeDate}`}
				</span>
			</div>
		</footer>
	);
};

PurgeInfoBanner.propTypes = {
	className : PropTypes.string
};

export default PurgeInfoBanner;
