/**
 * Retrieve file name from Content-Disposition Header
 * @param {Object} responseStream
 * @param {Object} responseStream.headers
 * @param {String} responseStream.headers.contentdisposition
 * @returns {String} exportFileName
 */
const retrieveFileName = (responseStream = {}) => {
	if (responseStream?.headers?.["content-disposition"]) {
		const contentDisposition = responseStream.headers["content-disposition"];
		return contentDisposition.split(";")[1].split("filename")[1].split("=")[1].trim();
	}
	return "";
};

export default retrieveFileName;
