import React, { useCallback, useEffect, useState } from "react";
import { getAllObservationsInDl } from "../../../observation/observation.services";
import { useParams } from "react-router-dom";
import { ObservationDetailsList } from "../../../observation";
import { getDriveLogMissions } from "../../../mission/mission.services";
import { getAllSurveysInDl } from "../../../survey/survey.services";
import SurveyDetailsList from "../../../survey/components/survey-details/survey-details-list/survey-details-list";
import "./drivelog-printable-pdf.scss";

// DrivelogPrintablePDF allows to print in the pdf the details of the observations and the details of the surveys
const DrivelogPrintablePdf = () => {
	const { id: driveLogId } = useParams();
	const [driveLogMissions, setDriveLogMissions] = useState(null);
	const [observations, setObservations] = useState([]);
	const [surveys, setSurveys] = useState([]);

	const retrieveObservationsList = useCallback(() => {
		getAllObservationsInDl(driveLogId).then(res => {
			setObservations(res?.data);
		}).catch(err => {
			console.error(err);
		});
	}, [driveLogId]);

	const retrieveSurveysList = useCallback(() => {
		getAllSurveysInDl(driveLogId).then(res => {
			setSurveys(res?.data);
		}).catch(err => {
			console.error(err);
		});
	}, [driveLogId]);

	const updateDriveLogMissions = useCallback(() => {
		getDriveLogMissions(driveLogId).then(payload => {
			setDriveLogMissions(payload.data);
		}).catch(err => {
			console.error(err);
		});
	}, [driveLogId]);

	useEffect(retrieveObservationsList, [driveLogId, retrieveObservationsList]);
	useEffect(retrieveSurveysList, [driveLogId, retrieveSurveysList]);
	useEffect(updateDriveLogMissions, [driveLogId, updateDriveLogMissions]);

	return (
		<div className="drivelog-printable-pdf">
			{ observations.length > 0 && driveLogMissions && <ObservationDetailsList observationsList={observations} missionList={driveLogMissions} />}
			{ surveys.length > 0 && <SurveyDetailsList surveyList={surveys} displayButton={false}/>}
		</div>
	);
};

export default DrivelogPrintablePdf;
