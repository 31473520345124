import { getAllObservationsInDlByType } from "../../observation/observation.services";
import * as localForage from "localforage";

/**
 * Retrieve the next observation id without survey
 * @param driveLogId
 * @returns {Promise<*|null>}
 */
const retrieveNextObservationWithoutSurvey = async (driveLogId) => {
	const observations = await getAllObservationsInDlByType(driveLogId, "surveyLess");
	const { data } = observations;
	const observationsPassed = await localForage.getItem( `dl-${driveLogId}-observations-passed`);
	if (observationsPassed && observationsPassed.data.length > 0) {
		const observationsNotPassed =  await data.map(obs => obs.id).filter(obs => !observationsPassed?.data.includes(obs));
		return observationsNotPassed && observationsNotPassed.length > 0 ? observationsNotPassed[0] : null;
	}
	return data && data.length > 0 ? data[0].id : null;
};

export default retrieveNextObservationWithoutSurvey ;
