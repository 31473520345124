const subscribeNotificationPush = async (key) => {
	try {
		const registration = await navigator.serviceWorker.ready;
		const subscribeOptions = {
			userVisibleOnly: true,
			applicationServerKey: key
		};
		const pushSubscription = await registration.pushManager.subscribe(subscribeOptions);
		console.log("Received PushSubscription: ", JSON.stringify(pushSubscription));
		return pushSubscription;
	} catch (e) {
		console.error(e);
		throw e;
	}
};

export { subscribeNotificationPush };
