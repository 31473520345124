import React from "react";
import PropTypes from "prop-types";
import { InputField } from "../../../../../core";
import "./observation-text.scss";

/**
 * Renders an <ObservationText /> component
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.label
 * @param {string} props.value
 * @param {string} props.placeholder
 * @param {function} props.onChange
 */
const ObservationText = (props) => {
	const { className = "", label, value, onChange, placeholder, validator } = props;

	return (
		<div className={`observation-text ${className}`}>
			<label className="observation-text__label">{label}</label>
			<InputField
				className="observation-text"
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				validation={validator}
			/>
		</div>
	);
};

ObservationText.propTypes = {
	className : PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func
};

export default ObservationText;
