import React, { Component } from "react";
import { ErrorPage } from "../../index";

// ErrorBoundary is not a hooks component because error boundary feature is only available as class component.
class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.t = props.t;
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			return <ErrorPage handleOnExit={() => this.setState({ hasError: false })} />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
