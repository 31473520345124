import React from "react";
import { useTranslation } from "react-i18next";
import "./special-notice-list-header.scss";

const SpecialNoticeListHeader = (props) => {
	const { className = "", enclosed = false } = props;

	const { t } = useTranslation("special-notice");

	const renderDatesIfEnclosed = () => {
		if (!enclosed) {
			return null;
		}

		return (
			<>
				<div className="special-notice-list-row__cell">{t("special-notice.list.headers.creation-date")}</div>
				<div className="special-notice-list-row__cell">{t("special-notice.list.headers.enclosure-date")}</div>
			</>
		);
	};

	const renderScheduleIfNotEnclosed = () => {
		if (enclosed) {
			return null;
		}

		return <div className="special-notice-list-header__cell">{t("special-notice.list.headers.schedule")}</div>;
	};

	return (
		<li className={`${className} special-notice-list-header ${enclosed ? "special-notice-list-header--enclosed" : ""}`}>
			{renderDatesIfEnclosed()}
			<div className="special-notice-list-header__cell">{t("special-notice.list.headers.broadcast-zone")}</div>
			<div className="special-notice-list-header__cell">{t("special-notice.list.headers.mission")}</div>
			<div className="special-notice-list-header__cell">{t("special-notice.list.headers.title")}</div>
			<div className="special-notice-list-header__cell">{t("special-notice.list.headers.author")}</div>
			{renderScheduleIfNotEnclosed()}
			<div className="special-notice-list-header__cell">{t("special-notice.list.headers.actions")}</div>
		</li>
	);
};

export default SpecialNoticeListHeader;
