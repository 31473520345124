import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Loader, PopupFullPage } from "../../../core";
import ObservationSearch from "../../../observation/components/observation-form/observation-fields/observation-search/observation-search";
import { getReferentialList } from "../../../core/core.services";
import { getMissionInfo } from "../../utils/check-mission";
import { addMission } from "../../mission.services";

import "./add-no-mission-code.scss";


const AddNoMissionCode = () => {

	const { id: driveLogId } = useParams();
	const { t } = useTranslation();
	const history = useHistory();

	const [formValid, setFormValid] = useState(false);
	const [stationsList, setStationsList] = useState();
	const [fields, setFields] = useState({ stationStart: undefined, stationEnd: undefined });

	const editDlLink = `/drive-log/${driveLogId}/mission/add`;

	const handleSubmit = async (e) => {
		e.preventDefault();
		const { data: mission } = await getMissionInfo(undefined, {
			departureLetter: fields?.stationStart?.trigram,
			arrivalLetter: fields?.stationEnd?.trigram
		}, "-1", "trigram");

		await addMission(mission, driveLogId);
		history.push(`/drive-log/${driveLogId}`);
	};

	const handleStationChanged = (fieldToUpdate) => (value) => {
		setFields({ ...fields, [fieldToUpdate]: value });
	};

	useEffect(() => {
		getReferentialList("station").then(res => {
			setStationsList(res.data);
		});
	}, []);

	useEffect(() => {
		setFormValid(fields?.stationStart && fields?.stationEnd);
	}, [fields]);

	return (
		<PopupFullPage title={t("mission:add-mission.title")} backLink={editDlLink}>
			<Loader isLoading={!stationsList}>
				<form className="modify-stations" onSubmit={handleSubmit}>
					<ObservationSearch
						searchLabel={t("mission:modify-stations.departure.label")}
						referential="station"
						selectedStation={fields?.stationStart}
						setSelectedStation={handleStationChanged("stationStart")}
					/>
					<ObservationSearch
						searchLabel={t("mission:modify-stations.arrival.label")}
						referential="station"
						selectedStation={fields?.stationEnd}
						setSelectedStation={handleStationChanged("stationEnd")}
					/>
					<Button className="modify-stations__button" disabled={!formValid} type="submit">
						{t("mission:modify-stations.button")}
					</Button>
				</form>
			</Loader>
		</PopupFullPage>
	);
};

export default AddNoMissionCode;
