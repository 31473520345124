import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./attachment-cell.scss";

const AttachmentCell = (props) => {
	const { driveLog, header = false } = props;
	const { t } = useTranslation("dl");

	const getAttachment = (dl) => {
		return (dl.attachment_name) ? dl.attachment_name : "";
	};

	const buildHeader = () => (
		<div className="attachment-cell">
			{ t("dl-table.headers.attachment") }
		</div>
	);

	const buildCell = () => (
		<div className="attachment-cell">
			{ getAttachment(driveLog) }
		</div>
	);

	return (
		header ? buildHeader() : buildCell()
	);
};

AttachmentCell.propTypes = {
	driveLog: PropTypes.object,
	header: PropTypes.bool
};

export default AttachmentCell;
