import React, { useCallback, useEffect, useState } from "react";
import DlMissionList from "../../pages/driver/dl-page/components/dl-mission-list/dl-mission-list";
import NoMissionObservations from "../../pages/driver/dl-page/components/no-mission-observations/no-mission-observations";
import { useTranslation } from "react-i18next";
import { getAllObservationsInDl } from "../../../observation/observation.services";
import { getDriveLogMissions } from "../../../mission/mission.services";
import { useParams } from "react-router-dom";

import "./mission-list-all-obs.scss";

const MissionListAllObs = (props) => {
	const { setLoading, redirectUrl = "" } = props;
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const [driveLogMissions, setDriveLogMissions] = useState(null);
	const [observations, setObservations] = useState([]);

	const retrieveObservationsList = useCallback(() => {
		getAllObservationsInDl(driveLogId).then(res => {
			setObservations(res?.data);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			setLoading(false);
		});
	}, [driveLogId, setLoading]);

	const updateDriveLogMissions = useCallback(() => {
		getDriveLogMissions(driveLogId).then(payload => {
			setDriveLogMissions(payload.data);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			setLoading(false);
		});
	}, [driveLogId, setLoading]);

	useEffect(retrieveObservationsList, [driveLogId, retrieveObservationsList]);
	useEffect(updateDriveLogMissions, [driveLogId, updateDriveLogMissions]);

	return (
		<>
			<div>
				<h2 className="mission-list-and-obs__title">
					<span>{t("dl:dl-details.missions-done.title")}</span>
				</h2>
				<DlMissionList
					className="mission-list-and-obs__missions"
					missionList={driveLogMissions}
					updateDriveLogMissions={updateDriveLogMissions}
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					readOnly={true}
					redirectUrl={redirectUrl}
				/>
				<NoMissionObservations
					className="mission-list-and-obs__no-mission-obs"
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					readOnly={true}
					redirectUrl={redirectUrl}
				/>
			</div>
		</>
	);
};

export default MissionListAllObs;
