import React from "react";
import PropTypes from "prop-types";

import "./loader.scss";

const Loader = ( { isLoading, children, spinnerClass = "dbl-spinner" } ) => {

	if (!isLoading) {
		return (
			<>{ children }</>
		);
	}

	return (
		<div className={spinnerClass} />
	);
};

Loader.propTypes = {
	isLoading : PropTypes.bool.isRequired,
	children : PropTypes.node.isRequired
};

export default Loader;