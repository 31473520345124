import { useState, useEffect, useContext } from "react";
import { getAttachments } from "../../drive-log/drive-log.services";
import { AuthContext } from "../../user/auth/context/auth-context";

/**
 * Fetch Hook for the list of attachments
 */
const useAttachmentList = () => {
	const [attachments, setAttachments] = useState();
	const [attachmentsWithoutService, setAttachmentsWithoutService] = useState();
	const [loading, setLoading] = useState();
	const { user = {} } = useContext(AuthContext);
	const { role: userRole, attachment, attachment_code: userAttachmentCode } = user;
	const isAdminRole = userRole === "admin" || userRole === "localAdmin";
	const isPSGRole = userRole === "amp" && attachment === "PSG";

	const loadAttachments = () => {
		setLoading(true);
		getAttachments().then((response) => {
			if(isAdminRole || isPSGRole) {
				// get all attachments including the PSG attachment for the admin, localAdmin and amp with psg roles
				setAttachments(response.data);
			} else {
				// get all attachments except the PSG attachment
				setAttachments(response.data.filter(({ name = "" }) => !name.includes("PSG")));
			}
			// get all attachments of the line except the attachment for service's train (train with no passengers) and PSG attachment
			setAttachmentsWithoutService(response.data.filter(({ name = "" }) => !name.includes("service") && !name.includes("PSG")));
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			setLoading(false);
		});
	};

	useEffect(loadAttachments, [userRole, userAttachmentCode, isAdminRole, isPSGRole]);

	return {
		attachments, loading, attachmentsWithoutService
	};
};

export default useAttachmentList;
