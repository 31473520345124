import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import DlMissionRow from "./components/mission-row/dl-mission-row";
import "./dl-mission-list.scss";

/**
 * Component for display the mission list
 *
 * @component
 * @param {Object} props
 * @param {array} props.missionList - the missions array
 * @param {string} props.className - the class name
 * @param {function} props.updateDriveLogMissions - the function which retrieve all updated missions
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 */
const DlMissionList = (props) => {
	const { t } = useTranslation();
	const { missionList = [], className = "", updateDriveLogMissions, observations, retrieveObservationsList, readOnly = false, redirectUrl = "",
		uncompletedDl, setUncompletedDl, emptyTrainComposition, setEmptyTrainComposition } = props;

	const generateMissionHeaders = () => {
		return (
			<li key={uuid()} className="dl-mission-list__mission dl-mission-list__mission--header">
				<div className="dl-mission-list__mission__code dl-mission-list__mission__code--header">
					{t("dl:dl-detail-page.mission")}
				</div>
				<div className="dl-mission-list__mission__composition dl-mission-list__mission__composition--header">
					{t("dl:dl-detail-page.composition")}
				</div>
				<div className="dl-mission-list__mission__train-number dl-mission-list__mission__train-number--header">
					{t("dl:dl-detail-page.motor_number")}
				</div>
				<div className="dl-mission-list__mission__start dl-mission-list__mission__start--header">
					{t("dl:dl-detail-page.start")}
				</div>
				<div className="dl-mission-list__mission__end dl-mission-list__mission__end--header">
					{t("dl:dl-detail-page.end")}
				</div>

				{!readOnly && <div className="dl-mission-list__mission__actions dl-mission-list__mission__actions--header">
					{t("dl:dl-detail-page.actions")}
				</div>}
			</li>)
		;
	};

	const generateMissionLine = (missionData) => {
		return <DlMissionRow updateDriveLogMissions={updateDriveLogMissions}
							 observations={observations}
							 retrieveObservationsList={retrieveObservationsList}
							 missionData={missionData}
							 key={uuid()}
							 readOnly={readOnly}
							 uncompletedDl={uncompletedDl}
							 setUncompletedDl={setUncompletedDl}
							 emptyTrainComposition={emptyTrainComposition}
							 setEmptyTrainComposition={setEmptyTrainComposition}
							 redirectUrl={redirectUrl}
		/>;
	};

	return(
		<div className={`dl-mission-list ${className}`}>
			<div className="dl-mission-list__missions">
				<ul >
					{generateMissionHeaders()}
					{Array.isArray(missionList) && missionList.map(generateMissionLine)}
				</ul>
			</div>
		</div>
	);
};

DlMissionList.propTypes = {
	missionList: PropTypes.array,
	className: PropTypes.string,
	observations: PropTypes.array,
	retrieveObservationsList: PropTypes.func,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
	setUncompletedDl: PropTypes.func,
	emptyTrainComposition: PropTypes.bool,
	setEmptyTrainComposition: PropTypes.func
};

export default DlMissionList;
