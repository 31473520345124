import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatISO, parseISO } from "date-fns";
import PropTypes from "prop-types";
import { Button, Icon, Loader, Modal, SearchField } from "../../../core";
import ObservationTime from "../../../observation/components/observation-form/observation-fields/observation-time/observation-time";
import { saveConsistencyOverlapsToBeUpdated } from "../../consistency-report.service";
import "./consistency-overlaps-edit-modal.scss";

/**
 * ConsistencyOverlapsEditModal modal component to change mission station and hour
 * @param props
 * @param {boolean} props.displayModal show/hide modal
 * @param {function} props.closeModal callback when closing modal
 * @param {Array} props.stationsList the drop drop options
 * @param {object} props.data mission data and display mode start/end
 * @return {JSX.Element}
 * @constructor
 */
const ConsistencyOverlapsEditModal = (props) => {
	const { displayModal, closeModal, stationsList, data } = props;
	const { date, mission, mode } = data;
	const { id: missionId, mission_code: missionCode, service_number: serviceNumber, attachment_name: attachmentName, user_id: userId } = mission;
	const isStationStart = mode === "start";

	const { t } = useTranslation("cr");

	const [fields, setFields] = useState({
		station: {}
	});
	const [timeError, setTimeError] = useState(true);
	const [formValid, setFormValid] = useState({ station: false, dateTime: false });

	// Prefill the station and time from the current mission
	useEffect(() => {
		if (mission && stationsList) {
			const stationTrigram = isStationStart ? mission?.station_start_trigram : mission?.station_end_trigram;

			const missionStation = () => stationsList?.find(({ trigram }) => trigram === stationTrigram);
			const missionDateTime = () => {
				if (isStationStart) {
					setTimeError(!mission?.hour_start);
					return mission?.hour_start ? parseISO(mission?.hour_start) : 0;
				}

				setTimeError(!mission?.hour_end);
				return mission?.hour_end ? parseISO(mission?.hour_end) : 0;
			};

			const updateFields = (old) => {
				return { ...old, station: missionStation(), dateTime: missionDateTime() };
			};

			setFields(updateFields);
		}
	}, [isStationStart, mission, stationsList]);

	useEffect(() => {
		const isDateTimeValid = !!fields?.dateTime && !timeError;
		const isStationValid = !!fields?.station?.key;

		setFormValid({ station: isStationValid, dateTime: isDateTimeValid });
	}, [fields, mission, isStationStart, timeError]);

	const updateMissionInLocalStorage = async (content) => {
		saveConsistencyOverlapsToBeUpdated(date, { id: missionId, ...content });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let content;
		if (isStationStart) {
			content = {
				code: missionCode,
				station_start: fields?.station.name,
				hour_start: formatISO(fields?.dateTime),
				station_start_trigram: fields?.station?.trigram
			};
		} else {
			content = {
				code: missionCode,
				station_end: fields?.station.name,
				hour_end: formatISO(fields?.dateTime),
				station_end_trigram: fields?.station?.trigram
			};
		}

		updateMissionInLocalStorage(content)
			.then(() => {})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				closeModal();
			});
	};

	const handleCancel = () => {
		closeModal();
	};

	const handleFieldsChange = (fieldToUpdate) => (value) => {
		setFields({ ...fields, [fieldToUpdate]: value });
	};

	return (
		displayModal && (
			<Modal type="custom" className="consistency-overlaps-edit-modal">
				<div className="consistency-overlaps-edit-modal__header">
					<button className="consistency-overlaps-edit-modal__header__close-icon" onClick={handleCancel}>
						<Icon name="close" />
					</button>
					<div className="consistency-overlaps-edit-modal__header__title">{mission?.mission_code}</div>
					<div className="consistency-overlaps-edit-modal__header__subtitle">
						{t("consistency-report.consistency-overlaps.row.title", { serviceNumber, attachmentName, userId })}
					</div>
				</div>
				<div className="consistency-overlaps-edit-modal__content">
					<Loader isLoading={!mission || !stationsList}>
						<form className="consistency-overlaps-edit-modal__form" onSubmit={handleSubmit}>
							<div className="consistency-overlaps-edit-modal__form__station">
								<label className="consistency-overlaps-edit-modal__form__station__label">
									{isStationStart ? t("mission:modify-stations.departure.label") : t("mission:modify-stations.arrival.label")}
								</label>
								{!fields?.station && (
									<label className="consistency-overlaps-edit-modal__form__station__sub-label">{t("observation:observation-fields.search-label")}</label>
								)}
								<SearchField
									className="field__input"
									searchList={stationsList}
									line={mission?.line}
									selectedOption={fields?.station}
									onSelect={handleFieldsChange("station")}
								/>
							</div>

							<ObservationTime
								className="consistency-overlaps-edit-modal__form__time"
								label={isStationStart ? t("mission:modify-stations.departure.time") : t("mission:modify-stations.arrival.time")}
								dateTime={fields?.dateTime}
								handleChange={handleFieldsChange("dateTime")}
								startServiceDateTime={fields?.dateTime}
								setTimeError={setTimeError}
							/>
							<Button className="consistency-overlaps-edit-modal__form__button" disabled={!formValid.station || !formValid.dateTime} type="submit">
								{t("mission:modify-stations.button")}
							</Button>
						</form>
					</Loader>
				</div>
			</Modal>
		)
	);
};

ConsistencyOverlapsEditModal.propTypes = {
	displayModal: PropTypes.bool,
	closeModal: PropTypes.func,
	stationsList: PropTypes.array,
	data: PropTypes.object
};

export default ConsistencyOverlapsEditModal;
