import React from "react";
import "./mandatory-star.scss";
import PropTypes from "prop-types";

/**
 * Renders a MandatoryStar component
 */
const MandatoryStar = (props) => {
	const { className = "", label } = props;

	return (
		<div>
			<label className={className} >
				<span className="mandatory-field__label">{label}</span>
				<span className="mandatory-field">*</span>
			</label>
		</div>
	);
};

MandatoryStar.propTypes = {
	className: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default MandatoryStar;
