import { validateDelay, validateReason } from "../../components/observation-form/observation-validators";

const alarmSignal = [
	{
		fieldName: "car"
	},
	{
		fieldName: "door"
	},
	{
		fieldName: "doorSide"
	},
	{
		fieldName: "location",
		mandatory: true,
		children: [
			{
				fieldName: "station",
				mandatory: true,
				parentValue: "station",
				parentTranslated: true
			},
			{
				fieldName: "interStationStart",
				mandatory: true,
				parentValue: "inter-station",
				parentTranslated: true
			},
			{
				fieldName: "interStationEnd",
				mandatory: true,
				parentValue: "inter-station",
				parentTranslated: true
			},
			{
				fieldName: "trainStorageChoice",
				mandatory: true,
				parentValue: "train-storage",
				parentTranslated: true
			}
		]
	},
	{
		fieldName: "reasonAlarmSignal",
		mandatory: true,
		validator: validateReason
	},
	{
		fieldName: "remoteReset",
		mandatory: true,
		lines: ["A", "-1"]
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default alarmSignal;
