import React from "react";
import { Assets, DateUtils, HrPage, LoadMore, SearchBar } from "../../../../core";
import { useTranslation } from "react-i18next";
import useDrivelogList from "../../../../core/custom-hooks/use-drive-log-list";
import SearchDriveLogsByDates from "../../../components/search-drive-logs-by-dates/search-drive-logs-by-dates";
import DriveLogDelayDetailsTable from "../../../components/drive-log-delay-details-table/drive-log-delay-details-table";
import "./archived-dl-page.scss";

const ArchivedDlPage = () => {
	const { t } = useTranslation("dl");
	const storageName = "hrDlArchived";
	const columnsToSearch = ["name", "service", "id", "mission"];

	const {
		driveLogs,
		loadMore,
		handleResetDriveLogs,
		handleSearchDriveLogs,
		handleDateFilter,
		totalResultCount,
		filter
	} = useDrivelogList({
		status : ["archived"],
		storageName,
		columnsToSearch: columnsToSearch[0],
		dateSortOrder : "desc",
		restrictedLine : true
	});

	const formattedDate = DateUtils.formatFullDate(filter?.searchDate, "dd/MM/yyyy");

	const generateDriveLogList = () => {
		return (
			<>
				<h2 className="archived-dl-page__title">
					{ formattedDate ? `${t("archived-drive-logs-page.title")} du ${formattedDate}` : t("archived-drive-logs-page.title") }
				</h2>
				{driveLogs && <DriveLogDelayDetailsTable driveLogs={driveLogs} hrArchivedView={true} />}
				<div className="archived-dl-page__controls">
					{
						(driveLogs?.length < totalResultCount) && (
							<LoadMore onClick={() => loadMore(40)}/>
						)
					}
				</div>
			</>
		);
	};

	const generateEmptyDls = () => {
		return (
			<div className="archived-dl-page__empty-dls">
				<img className="archived-dl-page__searching-mouse-img" src={Assets.SearchingMouse} alt="No Archived DriveLog"/>
				<div className="archived-dl-page__empty-dls-label"> { t("archived-drive-logs-page.no-dls-archived") } </div>
			</div>
		);
	};

	return (
		<HrPage className="archived-dl-page" selectedLink={1}>
			<div className="archived-dl-page__filters">
				<SearchBar handleSearch={handleSearchDriveLogs} handleReset={handleResetDriveLogs} columnsToSearch={columnsToSearch} filter={filter}/>
				<SearchDriveLogsByDates handleDateFilter={handleDateFilter} date={filter?.searchDate}/>
			</div>
			{totalResultCount === 0 ? generateEmptyDls() :  generateDriveLogList()}
		</HrPage>
	);
};

export default ArchivedDlPage;
