import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import ServiceStartCell from "../columns/service-start-cell/service-start-cell";
import ServiceNumberCell from "../columns/service-number-cell/service-number-cell";
import SheetLineNumberCell from "../columns/sheet-line-number-cell/sheet-line-number-cell";
import IdentityCell from "../columns/identity-cell/identity-cell";
import HoursCell from "../columns/hours-cell/hours-cell";
import CommentCell from "../columns/comment-cell/comment-cell";
import StateCell from "../columns/state-cell/state-cell";
import AttachmentCell from "../columns/attachment-cell/attachment-cell";
import DelaysCells from "../columns/delays-cells/delays-cells";

import "./drive-log-table-row.scss";

const DriveLogTableRow = (props) => {
	const { driveLog, className = "", driverView = false, consultantView = false, ampValidatedView = false, hrToArchiveView = false, hrArchivedView = false ,
		refresh = {}, displayDriveLogStatus = false, isSimpleDL } = props;

	const generateRowLink = (dl) => () => {
		if (driverView) {
			return `/drive-log/${dl.id}/details`;
		}

		if(consultantView) {
			return `/consultant/drive-log/${dl.id}/details`;
		}

		if (!driverView && !hrArchivedView) {
			if(isSimpleDL) {
				return `/supervisor/drive-log/${dl.id}/details?simple=true`;
			}
			return `/supervisor/drive-log/${dl.id}/details`;
		}
		if (!driverView && hrArchivedView) {
			return `/drive-log/${dl.id}/details`;
		}
	};

	const rowClassName = classNames("drive-log-row", className,
		{ "drive-log-row--driver-view": driverView },
		{ "drive-log-row--consultant-view": consultantView },
		{ "drive-log-row--amp-validated-view": ampValidatedView },
		{ "drive-log-row--hr-to-archive-view": hrToArchiveView },
		{ "drive-log-row--hr-archived-view": hrArchivedView }
	);

	const generateCells = () => {
		const cellsMapping = {
			driverView: [ServiceStartCell, ServiceNumberCell, SheetLineNumberCell, HoursCell, CommentCell, StateCell],
			ampView: [ServiceStartCell, ServiceNumberCell, SheetLineNumberCell, HoursCell, IdentityCell, StateCell],
			hrToArchiveView: [ServiceStartCell, ServiceNumberCell, AttachmentCell, IdentityCell, DelaysCells],
			hrArchivedView: [ServiceStartCell, ServiceNumberCell, AttachmentCell, IdentityCell, DelaysCells, StateCell]
		};

		if (hrToArchiveView || hrArchivedView ) {
			const cells = hrArchivedView ? cellsMapping.hrArchivedView : cellsMapping.hrToArchiveView;

			return (
				<>
					{cells.map(cell => React.createElement(cell, { ...props, hrToArchiveView, driveLog, refresh, key: uuid() }))}
				</>
			);
		}
		return (
			<>
				<ServiceStartCell driveLog={driveLog} />
				<ServiceNumberCell driveLog={driveLog} consultantView={consultantView} />
				<SheetLineNumberCell driveLog={driveLog} />
				{!driverView && <IdentityCell driveLog={driveLog} />}
				<HoursCell driveLog={driveLog} />
				<CommentCell driveLog={driveLog} />
				{ (driverView || displayDriveLogStatus) && (<StateCell driveLog={driveLog} />)}
			</>
		);
	};

	return (
		<li className="drive-log-row-container">
			<Link className={rowClassName} to={generateRowLink(driveLog)}> {/* Split className between li and Link */}
				{driveLog && driveLog.id && generateCells() }
			</Link>
		</li>
	);
};

DriveLogTableRow.propTypes = {
	"driveLog": PropTypes.object.isRequired,
	"driverView": PropTypes.bool,
	"consultantView": PropTypes.bool,
	"ampValidatedView": PropTypes.bool,
	"hrToArchiveView": PropTypes.bool,
	"hrArchivedView": PropTypes.bool,
	"refresh": PropTypes.func,
	"displayDriveLogStatus": PropTypes.bool,
	"isSimpleDL": PropTypes.bool
};

export default DriveLogTableRow;
