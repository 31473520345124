import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SurveyPrecisionComment from "./components/survey-comment/survey-precision-comment";
import SurveyRadioButton from "./components/survey-radio-button/survey-radio-button";
import MandatoryStar from "../../../../../core/components/mandatory-star/mandatory-star";
import SurveyCheckboxComment from "./components/survey-checkbox-comment/survey-checkbox-comment";
import "./survey-fields.scss";

const SurveyFields = (props) => {
	const { t } = useTranslation();
	const { type = "", fieldName, valueName, state, handleChange, mandatory, validator, readOnly = false } = props;

	const labelBase = t(`survey:survey-fields.label.${fieldName}-${valueName}`);
	const className = `survey__field survey__field--${fieldName}`;

	if (type === "survey-checkbox") {
		return (
			<div>
				<SurveyCheckboxComment
					fieldName={fieldName}
					className={"survey-check-list-button"}
					value={state}
					onChange={handleChange}
					label={t(`survey:survey-fields.check-list-button.${fieldName}-label`)}
					subLabel={t(`survey:survey-fields.check-list-button.${fieldName}-sub-label`)}
					readOnly={readOnly}
				/>
			</div>);
	} else if (type === "radio") {
		return (
			<SurveyRadioButton
				fieldName={fieldName}
				className={className}
				value={state}
				handleChange={handleChange}
				title={mandatory ? <MandatoryStar label={labelBase} /> : labelBase}
				subtitle={t(`survey:survey-fields.radio-button.${fieldName}-sub-label`)}
				validator={validator}
				readOnly={readOnly}
			/>
		);
	} else if (type === "text") {
		return (
			<SurveyPrecisionComment
				fieldName={fieldName}
				className={className}
				value={state}
				title={mandatory ? <MandatoryStar label={labelBase} className={className}/> : labelBase}
				handleChange={handleChange}
				placeholder={t("survey:survey-fields.precision.placeholder")}
				validator={validator}
				readOnly={readOnly}
			/>
		);
	}

	return null;
};

SurveyFields.propTypes = {
	fieldSchema: PropTypes.object,
	fieldName: PropTypes.string.isRequired,
	state: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	handleChange: PropTypes.func,
	mandatory: PropTypes.bool,
	validator: PropTypes.func,
	type: PropTypes.string,
	valueName: PropTypes.string
};

export default SurveyFields;
