import React from "react";
import PropTypes from "prop-types";
import "./page-wrapper.scss";

const PageWrapper = (props) => {
	const { children, className = "" } = props;

	return (
		<div className={`page ${className}`}>
			{children}
		</div>
	);
};

PageWrapper.propTypes = {
	children: PropTypes.node
};

export default PageWrapper;
