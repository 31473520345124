import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../../user/auth/context/auth-context";
import { ExportPageWrapper } from "../../core";
import { ExportForm } from "../";
import { checkExportFileStatus, generateExportFile, getExportFile } from "../export-services";
import retrieveFileName from "../utils/retrieve-filename";
import "./export-page.scss";

const ExportPage = () => {
	const [isLoading, setIsLoading] = useState(false);

	const { user = {} } = useContext(AuthContext);
	const { excel_access: excelAccess = false } = user;

	if (!excelAccess) {
		return <Redirect to="/error-access" />;
	}

	const handleFormSubmit = (formData, event) => {
		event.preventDefault();
		setIsLoading(true);
		generateExportFile(formData)
			.then((response) => checkFileStatus(response.data))
			.catch(error => {
				setIsLoading(false);
				console.error(error);
			});
	};

	const checkFileStatus = async (id) => {
		const statusResponse = await checkExportFileStatus(id);
		if (statusResponse?.data === "succeed") {
			const document = await getExportFile(id);
			return downloadFile(document);
		} else {
			setTimeout(() => checkFileStatus(id), 5000);
		}
	};

	const downloadFile = (documentResponse) => {
		const fileName = retrieveFileName(documentResponse);
		const fileURL = window.URL.createObjectURL(new Blob([documentResponse.data]));
		const link = document.createElement("a");
		link.download = fileName;
		link.href = fileURL;
		link.click();
		setIsLoading(false);
	};

	// page access verification
	if (!user?.role && !excelAccess) {
		return <Redirect to="/error-access" />;
	}

	return (
		<ExportPageWrapper className={"export-form"} loading={isLoading}>
			<ExportForm onSubmitHandler={handleFormSubmit} />
		</ExportPageWrapper>
	);
};

export default ExportPage;
