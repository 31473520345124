import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import base64 from "base-64";
import { PopupFullPage } from "../../../core";
import observationTypes from "../../forms/observation-types";
import ObservationForm from "../../components/observation-form/observation-form";
import useObservationParams from "../../custom-hooks/use-observation-params";

const AddObservation = (props) => {
	const { id: driveLogId, missionId, typeUrl } = useParams();
	const { params = {} } = props;
	const { redirectUrl = "" } = params;

	const { t } = useTranslation();
	const { observationData } = props;

	const location = useLocation();
	const param = queryString.parse(location.search);
	const position = !!param?.position ? JSON.parse(base64.decode(param?.position)) : false;

	const linkedEntity = missionId ? "mission" : "drive-log";

	const getBacklink = () => {
		if (observationData) {
			return `${redirectUrl}/${driveLogId}`;
		}
		if (param?.position) {
			return `${redirectUrl}/${driveLogId}/mission/${missionId}/add-observation?fromApp=DigiPlan&position=${param?.position}`;
		}
		if (missionId) {
			return `${redirectUrl}/${driveLogId}/mission/${missionId}/add-observation`;
		}
		return `${redirectUrl}/${driveLogId}/add-observation`;
	};

	const observationMatch = (observation) => {
		if (observationData) {
			return observation.name === observationData.observation_type;
		}
		return observation.url === typeUrl;
	};

	const findObservation = observationTypes.find(observationMatch);
	const { fields, name } = findObservation;

	const observationParams = useObservationParams(position, name);

	return (
		<PopupFullPage title={t(`observation:observation-titles.${name}`)} backLink={getBacklink()}>
			<ObservationForm
				observationFields={fields}
				observationType={name}
				observationData={observationData}
				observationParams={observationParams}
				linkedEntity={linkedEntity}
				redirectUrl={redirectUrl}
			/>
		</PopupFullPage>
	);
};

export default AddObservation;
