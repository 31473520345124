import React from "react";
import { useLocation, useParams } from "react-router-dom";
import InputDelayPage from "../../../../components/input-delay-page/input-delay-page";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

const RestShortenedPage = (props) => {
	const { params = {} } = props;
	const { redirectUrl = "" } = params;
	const { id } = useParams();
	const location = useLocation();
	const param = queryString.parse(location.search);
	const { t } = useTranslation();

	return (
		<InputDelayPage
			driveLogId={id}
			fieldToUpdate="rest_time_reduction"
			title={t("dl:delay-list-page.modify-shortened-rest")}
			redirect={param?.redirect}
			redirectUrl={redirectUrl}
		/>
	);
};

export default RestShortenedPage;
