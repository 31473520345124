import React, { useContext, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { AuthContext } from "../../../../user/auth/context/auth-context";
import useOnClickOutside from "../../../custom-hooks/use-on-click-outside";
import { logout } from "../../../../user/auth/auth.services";
import { Icon } from "../../../index";
import NotificationModal from "../../../../drive-log/components/notification-modal/notification-modal";
import { NotificationContext } from "../../../notification/notification-context";
import "./user-infos.scss";

const UserInfos = (props) => {
	const { className = "" } = props;
	const { t } = useTranslation();
	const { isNotificationActive } = useContext(NotificationContext);
	const { user = {} } = useContext(AuthContext);
	const menuRef = useRef();
	const location = useLocation();
	const originPage  = location.pathname;

	const [showMenu, setShowMenu] = useState(false);
	const [showNotificationModal, setShowNotificationModal] = useState(false);
	useOnClickOutside(menuRef, () => setShowMenu(false));

	const { role, attachment, excel_access: excelAccess, line: userLine } = user;
	const allowedRoles = role === "admin" || role === "localAdmin" || role === "amp";

	const getInitials = (firstName = "", lastName = "") => ` ${firstName ? firstName[0] : ""}${lastName ? lastName[0] : ""}`;

	return (
		<div className={`user-infos ${className}`} ref={menuRef}>
			<div className="user-infos__name">{`${user?.firstName} ${user?.lastName}`}</div>
			<div className="user-infos__job">
				{t(`user:users-list.roles.${role}`)}
				<span className="user-infos__separator">/</span>
				{attachment ? attachment : <Trans
					parent="span"
					className="user-infos__line"
					i18nKey="core:user-infos.line"
					values={{ line: userLine }}>
				</Trans>}
			</div>
			<div className="user-infos__initials" onClick={() => setShowMenu(!showMenu)}>
				{getInitials(user?.firstName, user?.lastName)}
				{isNotificationActive && <span className="user-infos__initials__badge">
					<Icon name="notification-ring" className="user-infos__initials__badge--icon"/>
				</span>}
			</div>

			<ul className={`user-infos__menu ${showMenu ? "user-infos__menu--open": "user-infos__menu--closed"}`}>
				{/* Notification WIP */}
				{/*{ (isServiceDl || isNotificationActive) && <li className="user-infos__menu__link">*/}
				{/*	<span onClick={() => setShowNotificationModal(true)} className="user-infos__menu__link__content">*/}
				{/*		<Icon name={isNotificationActive ? "notification-remove" : "notification-plus"}*/}
				{/*			  className="user-infos__menu__link__logout--icon"/>*/}
				{/*		{ isNotificationActive ? t("core:user-menu.remove-notification") : t("core:user-menu.add-notification")}*/}
				{/*	</span>*/}
				{/*</li> }*/}
				{allowedRoles && <li className="user-infos__menu__link">
					<Link to={{ pathname: "/disable-line-sheets", state: { originPage } }} className="user-infos__menu__link__content">
						<Icon name="disable-line-sheet" className="user-infos__menu__link__logout--icon"/>
						{t("core:user-menu.disable-line-sheet")}
					</Link>
				</li>}
				<li className="user-infos__menu__link">
					<Link to={{ pathname: "/terms-of-service", state: { originPage } }} className="user-infos__menu__link__content">
						<Icon name="reader" className="user-infos__menu__link__logout--icon"/>
						{t("core:user-menu.terms-of-service")}
					</Link>
				</li>
				{excelAccess && <li className="user-infos__menu__link">
					<Link to={{ pathname: "/export", state: { originPage } }} className="user-infos__menu__link__content">
						<Icon name="folder" className="user-infos__menu__link__logout--icon"/>
						{t("core:user-menu.excel-export")}
					</Link>
				</li>}
				<hr className={"user-infos__menu__separator"}/>
				<li className="user-infos__menu__link user-infos__menu__link__logout" onClick={logout}>
					<Icon name="logout" className="user-infos__menu__link__logout--icon"/>
					{t("core:user-menu.logout")}
				</li>
			</ul>
			<NotificationModal
				showModal={showNotificationModal}
				toggleModal={setShowNotificationModal}
				confirmCallback={() => null} />
		</div>
	);
};

UserInfos.propTypes = {
	className : PropTypes.string
};

export default UserInfos;
