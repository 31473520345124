import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./attachment-card.scss";

/**
 * Renders an <AttachmentCard /> component
 * @param {String} props.name - the name of the attachment card
 * @param {String} props.key - the key of the attachment card
 * @param {Boolean} props.isSelected - the selected attachment card : true or false
 * @param {Function} props.handleClick - select a new attachment card
 */
const AttachmentCard = (props) => {
	const { t } = useTranslation();
	const { name, isSelected, handleClick, key } = props;

	const contentClassName = classNames("attachment-card__content", { "attachment-card__content--selected": isSelected });


	return (
		<li className={contentClassName} onClick={handleClick} key={key}>
			<div className="content__attachment-name">
				<p className="name__content">{name}</p>
			</div>
			<div className="content__attachment-button">
				<p className="button__content">{isSelected ? t("dl:attachment-page.deselect-button") :t("dl:attachment-page.select-button")}</p>
			</div>
		</li>
	);
};

AttachmentCard.propTypes = {
	name : PropTypes.string,
	code : PropTypes.string,
	isSelected : PropTypes.bool,
	onClick : PropTypes.func
};

export default AttachmentCard;
