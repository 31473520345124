import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Loader } from "../../../../../core";
import { useTranslation } from "react-i18next";
import { getFilesAttachmentByObs } from "../../../../../drive-log/file-attachment.services";
import FileAttachmentImage from "./file-attachment-image/file-attachment-image";
import FileAttachmentCard from "../../../../../drive-log/components/comment-with-file-attachment/file-attachment-card/file-attachment-card";
import checkAddFile from "../../../../../drive-log/utils/file-attachment/file-attachment";
import classNames from "classnames";
import { checkNetwork } from "../../../../../../config";
import OfflineObservationModal from "../../../../../drive-log/pages/driver/dl-page/components/dl-observation-row/offline-delete-observation-modal/offline-delete-observation-modal";
import "./observation-with-file-attachment.scss";

/**
 * Renders component to add file(s) to an observation inside an unsigned dl
 * and to view the files added or not
 */
const ObservationWithFileAttachment = (props) => {
	const { driveLogId, observationFiles, setObservationFiles, observationId, readOnly = false } = props;

	const { t } = useTranslation();
	const [disableAddFileBtn, setDisableAddFileBtn] = useState(false);
	const [ loading, setLoading ] = useState(true);
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ showOfflineModal, setShowOfflineModal ] = useState(false);

	// when the user wants to update or read the observation already created
	const getFilesByDlAndObs = () => {
		if (driveLogId && observationId) {
			getFilesAttachmentByObs(driveLogId, observationId).then((res) => {
				const { data } = res;
				setObservationFiles(data);
			}).catch(err => {
				console.error(err);
			}).finally(() => {
				loadingTimer();
			});
		} else {
			loadingTimer();
		}
	};

	// if updating the observation, does the file already exist in the previously created observation
	const findFileAlreadyAdded = (newFile) => observationFiles.find((currentFile) => {
		const fileToVerify = {
			...currentFile,
			file_name: currentFile?.name || currentFile?.file_name
		};
		const { file_name: currentFileName } = fileToVerify;
		const { name: newFileName } = newFile;
		return currentFileName === newFileName;
	});

	const addFileAttachment = (newFile) => {
		setErrorMessage("");
		const isFileAlreadyAdded = findFileAlreadyAdded(newFile);
		if (!isFileAlreadyAdded) {
			setObservationFiles(prevFiles => [...prevFiles, newFile]);
		} else {
			setErrorMessage(t("dl:signature-page.file-attachment.error-message.insert"));
		}
		loadingTimer();
	};

	/**
	 * Verification of the size and the file type before to add it
	 * @param newFile
	 */
	const handleAddFileAttachment = (newFile) => {
		const { type: fileType, size: fileSize } = newFile;
		try {
			checkAddFile(fileType, fileSize, true);
			setLoading(true);
			addFileAttachment(newFile);
		} catch (error) {
			console.error(error);
			setErrorMessage(t(`${error}`));
		}
	};

	const findFilesToKeep = (fileName) => {
		 return observationFiles.filter(obsFile => {
			 const currentFile = {
				 ...obsFile,
				 file_name: obsFile?.name || obsFile?.file_name
			 };
			const { file_name: currentFileName } = currentFile;
			loadingTimer();
			return fileName !== currentFileName;
		 });
	};

	/**
	 * Verification of the user permissions before to delete the file
	 * @param fileInfos
	 */
	const handleDeleteFile = (fileInfos) => {
		const fileToDelete = {
			...fileInfos,
			file_name: fileInfos?.name || fileInfos?.file_name
		};
		const { file_name: fileName } = fileToDelete;
		setErrorMessage("");
		setLoading(true);
		const filesToKeep = findFilesToKeep(fileName);
		setObservationFiles(filesToKeep);
	};

	const handleChangeFile = (e) =>  {
		if (e.target.files[0] !== undefined) {
			try {
				handleAddFileAttachment(e.target.files[0]);
			} catch (error) {
				console.error(error);
			} finally {
				e.target.value = null;
			}
		}
	};

	const isNumberOfFileOver = () => {
		const disableButton = observationFiles.length >= 4;
		setDisableAddFileBtn(disableButton);
	};

	const displayFileCard = (file) => {
		const fileToDisplay = {
			...file,
			file_name: file?.name || file?.file_name
		};
		const { file_name: fileName } = fileToDisplay;

		return (
			<React.Fragment key={`${fileName}`}>
				<FileAttachmentCard
					handleDeleteFile={handleDeleteFile}
					file={fileToDisplay}
					readOnly={readOnly}
					driveLogId={driveLogId}
					observationId={observationId}
				/>
			</React.Fragment>
		);
	};

	const loadingTimer = () => {
		const timer = setTimeout(() => setLoading(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	};

	const displayReadOnlyComponent = () => {
		return (
			<>
				<Loader isLoading={!!loading}>
					<div className={classNames("observation-file-attachment__content__card", "comment-file-attachment__content__card--read-only")} >
						{renderFileImage()}
					</div>
				</Loader>
				{ (errorMessage.length > 0) &&
				<label className="observation-file-attachment__content__error-message">{errorMessage}</label>
				}
			</>
		);
	};

	const displayEditableComponent = () => {
		return (
			<>
				<label className="observation-file-attachment__content__instruction">{t("dl:signature-page.file-attachment.instruction")}</label>
				<Loader isLoading={!!loading}>
					<div className={"observation-file-attachment__content__card"}>
						{renderFileCard()}
					</div>
				</Loader>
				{ (errorMessage.length > 0) &&
				<label className="observation-file-attachment__content__error-message">{errorMessage}</label>
				}
				<input
					type="file"
					id="input-file"
					accept="image/jpeg"
					onChange={handleChangeFile}
					name="file"
					className="observation-file-attachment__content__input"
				/>
				<div className={"observation-file-attachment__content__button-group"}>
					{!disableAddFileBtn && checkNetwork() &&
					<label
						className="observation-file-attachment__content__button"
						htmlFor="input-file">
						{t("dl:signature-page.file-attachment.add-file-attachment")}
					</label>
					}
					{disableAddFileBtn &&
					<Button className="button" disabled >{t("dl:signature-page.file-attachment.add-file-attachment")}</Button>
					}
					{!checkNetwork() &&
					<Button className="button" onClick={() => setShowOfflineModal(true)} >{t("dl:signature-page.file-attachment.add-file-attachment")}</Button>
					}
				</div>
				<OfflineObservationModal  showModal={showOfflineModal}
										  setShowModal={setShowOfflineModal}
				/>
			</>
		);
	};

	const renderFileCard = () => observationFiles?.map((res) => displayFileCard(res));

	const renderFileImage = () => {
		if (observationFiles.length === 0 && readOnly) {
			return (
				<>
					<label className="observation-file-attachment__content__no-file-message">
						{t("dl:signature-page.file-attachment.no-file-attached")}
					</label>
				</>
			);
		}
		return (
			<React.Fragment>
				<FileAttachmentImage
					files={observationFiles}
				/>
			</React.Fragment>
		);
	};

	useEffect(isNumberOfFileOver, [observationFiles]);
	useEffect(getFilesByDlAndObs, [driveLogId, observationId, setObservationFiles]);

	return (
		<>
			<div className="observation-file-attachment">
				{!readOnly && displayEditableComponent()}
				{readOnly && displayReadOnlyComponent()}
			</div>
		</>
	);
};

ObservationWithFileAttachment.propTypes = {
	driveLogId: PropTypes.string,
	observationId: PropTypes.string,
	setObservationFiles: PropTypes.func,
	observationFiles: PropTypes.array,
	readOnly: PropTypes.bool
};

export default ObservationWithFileAttachment;
