import React from "react";
import PropTypes from "prop-types";
import CarSelector from "./car-selector/car-selector";
import "./observation-car-selector.scss";

/**
 * Render Car selector observation form element
 * @param {Object} props
 * @param {function} props.handleChange
 * @param {string} props.value
 * @param {string} props.className
 * @param {string} props.label
 * @param {string} props.listType
 * @param {boolean} [props.hasInput]
 * @param {string} [props.inputPlaceholder] - input placeholder
 * @param {object} props.fieldSchema
 * @return {JSX.Element}
 */
const ObservationCarSelector = (props) => {
	const { handleChange, value, className = "", listType, label, hasInput, inputPlaceholder, fieldSchema } = props;

	return (
		<div className={`observation-car-selector-wrapper ${className}`}>
			<h3 className="observation-car-selector-wrapper__title">{label}</h3>
			<div className="observation-car-selector-wrapper__field">
				<CarSelector
					value={value}
					onChange={handleChange}
					name="car"
					listType={listType}
					hasInput={hasInput}
					inputPlaceholder={inputPlaceholder}
					fieldSchema={fieldSchema}
				/>
			</div>
		</div>
	);
};

ObservationCarSelector.propTypes = {
	handleChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	listType: PropTypes.string.isRequired,
	hasInput: PropTypes.bool,
	inputPlaceholder: PropTypes.string,
	fieldSchema: PropTypes.object.isRequired
};

export default ObservationCarSelector;
