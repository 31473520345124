import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DriverPage, Loader, PopupFullPage } from "../../../../core";
import EmptyDlList from "./components/empty-dl-list/empty-dl-list";
import DlMissionList from "./components/dl-mission-list/dl-mission-list";
import DlAddMission from "./components/dl-add-mission/dl-add-mission";
import AddObservationWithoutMission from "./components/add-observation-without-mission/add-observation-without-mission";
import FinalizeDl from "./components/finalize-dl/finalize-dl";
import NoMissionObservations from "./components/no-mission-observations/no-mission-observations";
import DocumentMenuButton from "./components/document-menu-button/document-menu-button";
import getStatusRedirection from "../../../utils/get-status-redirection";
import FinalizeMessage from "./components/finalize-message/finalize-message";
import { getAllObservationsInDl } from "../../../../observation/observation.services";
import { getDriveLogById } from "../../../drive-log.services";
import { getDriveLogMissions } from "../../../../mission/mission.services";
import { NotificationContext } from "../../../../core/notification/notification-context";
import { AuthContext } from "../../../../user/auth/context/auth-context";
import "./dl-page.scss";

/**
 * Renders a single drive log page
 */
const DlPage = (props) => {
	const { overrideRoles, params = {} } = props;
	const { redirectUrl = "" } = params;

	const { id } = useParams();
	const history = useHistory();
	const { t } = useTranslation();

	const { checkDriveLogType } = useContext(NotificationContext);

	const { user = {} } = useContext(AuthContext);
	const [dailyDriveLog, setDailyDriveLog] = useState({});
	const [driveLogMissions, setDriveLogMissions] = useState(null);
	const [observations, setObservations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [uncompletedDl, setUncompletedDl] = useState(false);
	const [emptyTrainComposition, setEmptyTrainComposition] = useState(false);
	// const [showNotificationModal, setShowNotificationModal] = useState(false);

	const getMessage = () => {
		const trainCompoMessage = "empty-compo-message";
		const uncompletedDlMessage  = "finalize-message";
		if (uncompletedDl) {
			return uncompletedDlMessage;
		} else if (emptyTrainComposition) {
			return trainCompoMessage;
		} else {
			return "";
		}
	};

	const message = getMessage();

	const updateDriveLogMissions = useCallback(() => {
		setIsLoading(true);
		getDriveLogMissions(id)
			.then((payload) => {
				setDriveLogMissions(payload.data);
				setEmptyTrainComposition(false);
				setUncompletedDl(false);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);

	const retrieveObservationsList = useCallback(() => {
		setIsLoading(true);
		getAllObservationsInDl(id)
			.then((payload) => {
				setObservations(payload.data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);

	// TODO Notification WIP
	// const checkNotificationNeed = useCallback((driveLog) => {
	// 	const { is_empty: isEmptyDl } = driveLog;
	// 	const isAlertAlreadyRefused = localStorage.getItem("dl-alert-asked");
	// 	if(!isNotificationActive && !isEmptyDl && isAlertAlreadyRefused !== driveLog.id) {
	// 		setShowNotificationModal(true);
	// 	}
	// }, [isNotificationActive]);


	const init = useCallback(() => {
		setIsLoading(true);
		getDriveLogById(id)
			.then((payload) => {
				const driveLog = payload.data;
				getStatusRedirection(driveLog, history);
				checkDriveLogType(driveLog);
				// TODO Notification WIP
				// checkNotificationNeed(driveLog);
				setDailyDriveLog(driveLog);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line
	}, [id, history]);

	const checkAuthorisation = () => {
		if (!!dailyDriveLog?.user_id && dailyDriveLog?.user_id !== user.id) {
			history.push("/");
		}
	};

	useEffect(init, [id, init]);
	useEffect(updateDriveLogMissions, [id, updateDriveLogMissions]);
	useEffect(retrieveObservationsList, [id, retrieveObservationsList]);
	useEffect(checkAuthorisation, [dailyDriveLog, history, user?.id]);

	const generateMissionList = () => {
		const isMissionListEmpty = Array.isArray(driveLogMissions) && driveLogMissions.length === 0;
		return isMissionListEmpty ? (
			<EmptyDlList
				className="drive-log-detail__dl-list"
				driveLogId={id}
				observations={observations}
				retrieveObservationsList={retrieveObservationsList}
				uncompletedDl={uncompletedDl}
				setUncompletedDl={setUncompletedDl}
				redirectUrl={redirectUrl}
			/>
		) : (
			<>
				{message && <FinalizeMessage message={message}/>}
				<DlMissionList
					className="drive-log-detail__dl-list"
					missionList={driveLogMissions}
					updateDriveLogMissions={updateDriveLogMissions}
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					uncompletedDl={uncompletedDl}
					setUncompletedDl={setUncompletedDl}
					emptyTrainComposition={emptyTrainComposition}
					setEmptyTrainComposition={setEmptyTrainComposition}
					redirectUrl={redirectUrl}
				/>
				<NoMissionObservations observations={observations} retrieveObservationsList={retrieveObservationsList} uncompletedDl={uncompletedDl} redirectUrl={redirectUrl} />

				<div className="drive-log-detail__actions">
					<DlAddMission buttonForm={true} redirectUrl={redirectUrl} />
					<AddObservationWithoutMission driveLogId={id} redirectUrl={redirectUrl} />
					<FinalizeDl driveLogId={id} missionsList={driveLogMissions} observations={observations} setUncompletedDl={setUncompletedDl} redirectUrl={redirectUrl} />
				</div>
			</>
		);
	};

	if (redirectUrl === "/drive-log") {
		return (
			<DriverPage className="drive-log-detail" overrideRoles={overrideRoles}>
				{!isLoading && generateMissionList()}
				<DocumentMenuButton driveLogId={id} />
				{/* TODO Notification WIP */}
				{/*{showNotificationModal &&*/}
				{/*	<NotificationModal*/}
				{/*		showModal={showNotificationModal}*/}
				{/*		toggleModal={setShowNotificationModal}*/}
				{/*		cancelCallback={() => localStorage.setItem("dl-alert-asked", dailyDriveLog.id)}*/}
				{/*		confirmCallback={() => localStorage.setItem("dl-alert-asked", dailyDriveLog.id)}*/}
				{/*	/>*/}
				{/*}*/}
			</DriverPage>
		);
	}

	return (
		<PopupFullPage className="edit-dl-page" title={t("dl:dl-edit-page.title")} backLink={"/drive-logs/returned"}>
			<Loader isLoading={!!isLoading}>{generateMissionList()}</Loader>
		</PopupFullPage>
	);
};

export default DlPage;
