/**
 * Strip accents, hyphens and underscores from a string, transform to lowercase
 * @param {String} string - string to strip
 * @example
 * const result = stripUnnecessaryCharacters("Saint-Rémy-lès-Chevreuse");
 * -> result: "saint remy les chevreuse"
 * @returns {String} stripped string
 */
const stripUnnecessaryCharacters = (string = "") => {
	// diacritics (Unicode standard - combining diacritical marks Unicode block)
	const diacritics = /[\u0300-\u036f]/g;
	// unicode blocks that cover all dashes: figure dash, en dash, em dash, horizontal bar
	const dashes = /[\u2012-\u2015]/g;
	// underscore
	const underscores = /[_-]/g;
	return string.normalize("NFD").replace(diacritics, "").replace(dashes, " ").replace(underscores, " ")
		.toLowerCase();
};

export default stripUnnecessaryCharacters;
