import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../auth/context/auth-context";
import Icon from "../../../../../core/components/icon-svg/icon";
import { UpdateUserModal, DeleteUserModal } from "../../../../index";
import linesIconName from "../../../../utils/lines-icon-names.json";
import { Button } from "../../../../../core";
import classnames from "classnames";
import "./user-table-row.scss";

/**
 * UserTableRow component display a row for each user
 * @param props
 * @param {object} props.user
 * @param {string} props.className
 * @param {array} props.attachmentsList
 * @param {function} props.refreshUsersList
 * @return {JSX.Element}
 * @constructor
 */
const UserTableRow = (props) => {
	const { user, className, attachmentsList, refreshUsersList, adminRolesList, localAdminRolesList, getLineFromAttachment } = props;
	const { t } = useTranslation("user");
	const [showModal, setShowModal] = useState(false);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
	const { user: currentUser = {} } = useContext(AuthContext);

	const { id, first_name: firstName, last_name: lastName, role, line, active, digiplan_access: digiplan } = user;
	const status = active ? "active" : "blocked";

	const checkUserEditable = () => {
		const isLocalAdmin = currentUser?.role === "localAdmin";
		const notEditableUsers = ["admin", "localAdmin"];
		return !(notEditableUsers.includes(role) && isLocalAdmin);
	};

	const isUserEditable = checkUserEditable();

	const handleShowModal = () => setShowModal(!showModal);

	const handleDelete = (e) => {
		e.stopPropagation();
		setShowDeleteUserModal(!showDeleteUserModal);
	};

	const userTableRowCellClassName = classnames("user-table-row--cell", className, { "user-table-row--cell--disabled": !isUserEditable });

	return (
		<>
			<li className={userTableRowCellClassName} onClick={isUserEditable ? handleShowModal : () => {}}>
				<div className="user-row--cell user__id">{id}</div>
				<div className="user-row--cell user__names">
					<span className="user-row--cell user__names__first">{lastName}</span>
					<span className="user-row--cell user__names__last">{firstName}</span>
				</div>
				<div className="user-row--cell user__role">{t(`users-list.roles.${role}`)}</div>
				<div className="user-row--cell user__line">
					<Icon className="user__line--icon" name={linesIconName[line]}/>
					<span className="user__line--label">{t("users-list.user-row.line", { line })}</span>
				</div>
				<div className="user-row--cell user__digiplan">{t(`users-list.digiplan.${digiplan}`)}</div>
				<div className="user-row--cell user__status">
					<div className={`user__status__card user__status__card--${status}`}>
						<Icon className={`user__status__card--icon--${status}`} name={active ? "validate" : "blocked"}/>
						<span className="user__status__card--label">{t(`users-list.user-row.${status}`)}</span>
					</div>
				</div>
				{isUserEditable && <Button className="button--outline user__action__delete" onClick={(e) => handleDelete(e)}>
					{t("users-list.user-row.delete")}
				</Button>}
			</li>
			<UpdateUserModal
				showModal={showModal}
				setShowModal={setShowModal}
				user={user}
				attachmentsList={attachmentsList}
				getLineFromAttachment={getLineFromAttachment}
				refreshUsersList={refreshUsersList}
				localAdminRolesList={localAdminRolesList}
				adminRolesList={adminRolesList}
			/>
			<DeleteUserModal
				showModal={showDeleteUserModal}
				setShowModal={setShowDeleteUserModal}
				user={user}
				refreshUsersList={refreshUsersList}
			/>
		</>
	);
};

UserTableRow.propTypes = {
	user: PropTypes.object,
	className: PropTypes.string,
	attachmentsList: PropTypes.object,
	getLineFromAttachment: PropTypes.func,
	refreshUsersList: PropTypes.func,
	adminRolesList: PropTypes.object,
	localAdminRolesList: PropTypes.object
};

export default UserTableRow;
