import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../../core";
import { useTranslation } from "react-i18next";
import "./home-profile-card.scss";

const HomeProfileCard = (props) => {
	const {
		className = "",
		title = "",
		link = "/",
		description = "",
		disabled = false,
		imgLink
	} = props;
	const { t } = useTranslation();
	const history = useHistory();

	const handleRedirect = () => history.push(link);

	return (
		<div className={`home-profile-card ${className}`} onClick={handleRedirect}>
			<div className="home-profile-card__title">{title}</div>
			<div className="home-profile-card__description">
				{description}
			</div>
			{imgLink && <img className="home-profile-card__img" src={imgLink} alt="home-amp"/>}
			<Button className="home-profile-card__button" disabled={disabled} onClick={handleRedirect}>{t("user:home-page.button.selection")}</Button>
		</div>
	);
};

export default HomeProfileCard;
