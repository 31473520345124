import { useCallback, useEffect } from "react";
import { getExpiredDataFromApi } from "./dispatch-get-request";
import { getExpiredLineSheetDataFromApi } from "./dispatch-line-sheet-get-request";
import { synchronizeAllStorage } from "./synchronize-storage";
import { purgeStorage } from "./purge-storage";
import checkNetwork from "./check-network";

/**
 * Loads offline data and synchronizes pending data from local storage
 * @return {null}
 */
const LoadOffline = () => {

	/**
	 * Fetch data for offline use
	 * @param {string} key
	 * @param {string} url
	 * @param {number} [interval]
	 * @param config
	 * @return {Promise<void>}
	 */
	const fetchExpiredDataFromApi = async ({ key, url, interval, config }) => await getExpiredDataFromApi(key, url, interval, config);

	/**
	 * Fetch sheet line data for offline use
	 * @param {string} key
	 * @param {string} url
	 * @param {number} [interval]
	 * @param config
	 * @return {Promise<void>}
	 */
	const fetchExpiredLineSheetDataFromApi = async ({ key, url, interval, config }) => await getExpiredLineSheetDataFromApi(key, url, interval, config);

	/**
	 * store daily drive log (if it exists) for offline use
	 * @return {Promise<void>}
	 */
	const getDailyDLOffline = useCallback(async () => {
		const dailyDL = { key: "daily-drive-log", url: "/drive-log?type=daily" };
		const dailyDLData = await fetchExpiredDataFromApi(dailyDL);

		const driveLogId = dailyDLData?.data?.id;

		if (driveLogId) {
			const dailyMissionsData = await fetchExpiredDataFromApi( { key: `dl-${driveLogId}-missions`, url: `/drive-log/${driveLogId}/mission` });

			// this function is needed, otherwise it breaks eslint (issue with react-scripts 4.0.3
			const getOfflineKeys = (dlId, mId) => {
				return { key: `dl-${dlId}-mission-${mId}`, url: `/drive-log/${dlId}/mission/${mId}` };
			};

			const offlineDailyMissionData = dailyMissionsData?.data?.map(mission => {
				const { id } = mission;
				return getOfflineKeys(driveLogId, id);
			});

			const offlineDailyDL = [
				{ key: `dl-${driveLogId}`, url: `/drive-log/${driveLogId}` },
				{ key: `dl-${driveLogId}-missions`, url: `/drive-log/${driveLogId}/mission` },
				{ key: `dl-${driveLogId}-worksheet`, url: `/drive-log/${driveLogId}/work-sheet/image`,
					config: { responseType: "blob" }, interval: 1 }
			];
			await Promise.all(offlineDailyDL.map(fetchExpiredDataFromApi));
			await Promise.all(offlineDailyMissionData.map(fetchExpiredDataFromApi));
		}
	}, []);

	/**
	 * Synchronize local storage data with API data
	 * @type {function(): Promise<void>}
	 */
	const synchronizeOfflineData = useCallback(async () => {
		const online = await checkNetwork();

		if (!online) {
			throw Error("Application is offline");
		}

		// purge expired or old local storage
		await purgeStorage();

		const offlineLineSheetData = [
			{ key: "line-sheet-A-published", url: "/line-sheet", interval: 1, config: { responseType: "blob", params: { line: "A", status: "published" } } },
			{ key: "line-sheet-B-published", url: "/line-sheet", interval: 1, config: { responseType: "blob", params: { line: "B", status: "published" } } },
			{ key: "line-sheet-A-coming", url: "/line-sheet", interval: 1, config: { responseType: "blob", params: { line: "A", status: "coming" } } },
			{ key: "line-sheet-B-coming", url: "/line-sheet", interval: 1, config: { responseType: "blob", params: { line: "B", status: "coming" } } }
		];

		const offlineData = [
			{ key: "missions", url: "/mission", interval: 1 },
			{ key: "station-codes", url: "/referential/station-code", interval: 1 },
			{ key: "station", url: "/referential/station", interval: 1 },
			{ key: "train-storage", url: "/referential/train-storage", interval: 1 },
			{ key: "office-station", url: "/referential/office-station", interval: 1 },
			{ key: "current-user", url: "/user/current", interval: 1 }
		];

		await Promise.all(offlineLineSheetData.map(fetchExpiredLineSheetDataFromApi));
		await Promise.all(offlineData.map(fetchExpiredDataFromApi));

		await getDailyDLOffline();

		await synchronizeAllStorage();
	}, [getDailyDLOffline]);

	useEffect(() => {
		synchronizeOfflineData()
			.then(() => console.info("Application synchronized"))
			.catch(() => console.info("Application not synchronized"));
	},[synchronizeOfflineData]);

	return null;
};

export default LoadOffline;
