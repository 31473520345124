import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GenericHeader from "../generic-header/generic-header";

/**
 * Renders a <HrHeader /> component
 * @param {object} props
 * @param {string} props.className - className to add to the Supervisor header Component
 * @param {string} props.selectedLink - the link-related number
 * @param {bool} props.hideNav - hide or not the nav links
 */
const SpecialNoticeHeader = props => {
	const { className = "", selectedLink = 0, hideNav = false } = props;
	const { t } = useTranslation("special-notice");

	const links = [
		{ target : "/special-notices", text : t("special-notice.header.ongoing-notices") },
		{ target : "/special-notices/enclosed", text : t("special-notice.header.enclosed-notices") }
	];

	return (
		<GenericHeader
			className={className}
			links={links}
			selectedLink={selectedLink}
			hideNav={hideNav}
		/>
	);
};

SpecialNoticeHeader.propTypes = {
	classname: PropTypes.string,
	selectedLink : PropTypes.number,
	hideNav: PropTypes.bool
};

export default SpecialNoticeHeader;

