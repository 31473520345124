import localForage from "../../../config/local-forage";

const getConsultationAttachments = async (user) => {
	const consultationAttachments = await localForage.getItem("consultation-attachments");

	const defaultUserAttachment = [{
		code: user.attachment_code,
		name: user.attachment
	}];
	// If no temporary attachment is in storage
	if (!consultationAttachments ) {
		return defaultUserAttachment;
	} else {
		return consultationAttachments.attachments;
	}
};

const setConsultationAttachments = async (attachmentInfo) => {
	const consultationAttachment = {
		attachments: [...attachmentInfo],
		dateStored : new Date()
	};
	await localForage.setItem("consultation-attachments", consultationAttachment);
};

export { getConsultationAttachments, setConsultationAttachments };
