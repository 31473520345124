import React from "react";
import PropTypes from "prop-types";
import "./responsive-text.scss";

/**
 * Renders a different text following the mobile breakpoint
 */
const ResponsiveText = (props) => {
	const { text = "", mobileText = "", className = "" } = props;
	return(
		<>
			<span className={`responsive-text ${className}`}>{text}</span>
			<span className={`responsive-text--mobile ${className}`}>{mobileText}</span>
		</>
	);
};

ResponsiveText.propTypes = {
	text : PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	mobileText : PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	className: PropTypes.string
};

export default ResponsiveText;
