import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { csrf, checkNetwork } from "../../../../config";
import localForage from "../../../../config/local-forage/index";
import { isBefore } from "date-fns";
import { getCurrentUser, setupCsrfProtection } from "../auth.services";

const AuthContext = createContext({});

/**
 * Context provider for authentication + csrf protection. Retrieves current user from api
 * @param props
 * @return {JSX.Element}
 */
const AuthProvider = (props) => {
	const { children } = props;
	const history = useHistory();

	const [user, setUser] = useState();
	const [userId, setUserId] = useState();
	const [csrfTokenLoaded, setCsrfTokenLoaded] = useState(false);

	const getUserConnected = () => {
		getCurrentUser().then((response) => {
			if (response?.data) {
				const { first_name: firstName, last_name: lastName, ...rest } = response?.data;
				setUser({ firstName, lastName, ...rest });
				setUserId(rest.id);
			}
		});
	};

	useEffect(getUserConnected, []);

	const setupCSRF = async () => {
		const online = await checkNetwork();

		if (online) {
			const response = await setupCsrfProtection();
			const csrfToken = response.headers["csrf-token"];
			csrf.saveCSRFToken(csrfToken);
		}
	};

	const getAttachment = async () => {
		const temporaryAttachment = await localForage.getItem("temporary-attachment");

		const todayThreeInMorning = new Date();
		todayThreeInMorning.setHours(3);
		todayThreeInMorning.setMinutes(0);
		todayThreeInMorning.setSeconds(0);

		const defaultUserAttachment = {
			code: user.attachment_code,
			name: user.attachment
		};
		// If no temporary attachment is in storage
		if (!temporaryAttachment) {
			return defaultUserAttachment;
		} else {
			const dateStored = new Date(temporaryAttachment.dateStored);
			const now = new Date();
			// If the temporary attachment is expired (expires every day at 3 in the morning)
			if (isBefore(dateStored, todayThreeInMorning) && isBefore(todayThreeInMorning, now)) {
				await localForage.removeItem("temporary-attachment");
				return defaultUserAttachment;
			} else {
				return {
					code: temporaryAttachment.code,
					name: temporaryAttachment.name
				};
			}
		}
	};

	const setTemporaryAttachment = async (attachmentInfo) => {
		const temporaryAttachment = {
			...attachmentInfo,
			dateStored: new Date()
		};
		await localForage.setItem("temporary-attachment", temporaryAttachment);
	};

	useEffect(() => {
		const isAuthenticated = user?.role && user?.active;
		if (isAuthenticated) {
			setCsrfTokenLoaded(false);
			setupCSRF()
				.catch(() => history.push("/error-access"))
				.finally(() => setCsrfTokenLoaded(true));
		}
	}, [history, user]);

	return <AuthContext.Provider value={{ user, userId, setTemporaryAttachment, getAttachment }}>{csrfTokenLoaded && children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
