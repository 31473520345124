import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GenericHeader from "../generic-header/generic-header";

/**
 * Renders a <HrHeader /> component
 * @param {object} props
 * @param {string} props.className - className to add to the Supervisor header Component
 * @param {string} props.selectedLink - the link-related number
 * @param {bool} props.hideNav - hide or not the nav links
 */
const SupervisorHeader = props => {
	const { className = "", selectedLink = 0, hideNav = false } = props;
	const { t } = useTranslation();

	const links = [
		{ target : "/supervisor/drive-log-list", text : t("core:header.drivelogs-to-be-validated") },
		{ target : "/supervisor/validated-drive-logs", text : t("core:header.validated-drivelogs") }
		// { target: "/supervisor/consistency-report", text: t("core:header.consistency-report") }
	];

	return (
		<GenericHeader
			className={className}
			links={links}
			selectedLink={selectedLink}
			hideNav={hideNav}
		/>
	);
};

SupervisorHeader.propTypes = {
	classname: PropTypes.string,
	selectedLink : PropTypes.number,
	hideNav: PropTypes.bool
};

export default SupervisorHeader;

