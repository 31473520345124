import { regexValidator, validateDelay, validateReason } from "../../components/observation-form/observation-validators";
import { RegexUtils } from "../../../core";
import { setDateTimeWithService } from "../../observation.services";

const materialSignal = [
	{
		fieldName: "car",
		mandatory: true
	},
	{
		fieldName: "dateTime",
		mandatory: true,
		defaultValue: ({ driveLog }) => setDateTimeWithService(driveLog?.realized_date_start, new Date())
	},
	{
		fieldName: "signalDetail",
		mandatory: true,
		group: [
			{
				fieldName: "signalDetailComment",
				validator: validateReason,
				mandatory: true
			},
			{
				fieldName: "signalDetailFiles",
				mandatory: false
			}
		]
	},
	{
		fieldName: "isolatedSecuritySystem",
		validator: regexValidator(RegexUtils.alphaNumericSpecialCharactersWithTwenty)
	},
	{
		fieldName: "signalTransmission",
		mandatory: true,
		children: [
			{
				fieldName: "pcc",
				parentValue: "yes",
				lines: ["B", "-1"]
			}
		]
	},
	{
		fieldName: "delay",
		validator: validateDelay
	}
];

export default materialSignal;
