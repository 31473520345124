import { dispatchGetRequest } from "../../config";

/**
 * get list of all referential from api
 * @param {string} referential - referential name
 * @returns {Promise} response
 */
const getReferentialList = (referential) => {
	return dispatchGetRequest(referential, `/referential/${referential}`);
};

/**
 * get list of all station codes
 * @return {Promise<Object>} response
 */
const getStationCodes = () => {
	return dispatchGetRequest("station-codes", "/referential/station-code");
};

export { getReferentialList, getStationCodes };
